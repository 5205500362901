import { accountManagementTypes } from "./types";
import { initialState } from "./initialState";

export const accountManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    // For Accounts List
    case accountManagementTypes.GET_ALL_ACCOUNT_LOADING:
      return {
        ...state,
        AccountLoading: true,
        AccountSuccess: false,
        AccountError: false,
      };

    case accountManagementTypes.GET_ALL_ACCOUNT_SUCCESS:
      if (state?.Accounts?.length == action.data.count) {
        return {
          ...state,
          AccountLoading: false,
          AccountSuccess: true,
          AccountError: false,
        };
      }
      let newAccounts =
        action.isResetState || action.pageNo == 1
          ? action.data.data
          : [...state.Accounts, ...action.data.data];
      return {
        ...state,
        AccountLoading: false,
        AccountSuccess: true,
        AccountError: false,
        totalCount: action.data.count,
        Accounts: newAccounts,
      };

    case accountManagementTypes.GET_ALL_ACCOUNT_ERROR:
      return {
        ...state,
        AccountLoading: false,
        AccountSuccess: false,
        AccountError: true,
      };

    case accountManagementTypes.GET_ACCOUNT_DETAIL_LOADING:
      return {
        ...state,
        accountDetailSuccess: false,
        accountDetailLoading: true,
        accountDetailError: false,
      };

    case accountManagementTypes.GET_ACCOUNT_DETAIL_SUCCESS:
      return {
        ...state,
        accountDetailSuccess: true,
        accountDetailLoading: false,
        accountDetailError: false,
        AccountOverview: action.data,
      };

    case accountManagementTypes.GET_ACCOUNT_DETAIL_ERROR:
      return {
        accountDetailSuccess: false,
        accountDetailLoading: false,
        accountDetailError: true,
      };

    case accountManagementTypes.SELECT_ACCOUNT:
      return {
        ...state,
        AccountOverview: state.Accounts.find((account, index) => account && account._id === action.id),
      };

    case accountManagementTypes.UPDATE_ACCOUNT_LOADING:
      return {
        ...state,
        UpdateAccountLoading: true,
        UpdateAccountSuccess: false,
        UpdateAccountError: false,
      };

    case accountManagementTypes.UPDATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        UpdateAccountLoading: false,
        UpdateAccountSuccess: true,
        UpdateAccountError: false,
        Accounts: state.Accounts.map((item) => {
          if (item._id === action.data.id) {
            for (var key in action.data) {
              if (key != "_id") {
                item[key] = action.data[key];
              }
            }
          }
          return item;
        }),
      };

    case accountManagementTypes.UPDATE_ACCOUNT_ERROR:
      return {
        ...state,
        UpdateAccountLoading: false,
        UpdateAccountSuccess: false,
        UpdateAccountError: true,
      };

    case accountManagementTypes.PASSWORD_POLICY_LOADING:
      return {
        ...state,
        passwordPolicyLoading: true,
      };

    case accountManagementTypes.PASSWORD_POLICY_SUCCESS:
      return {
        ...state,
        passwordPolicy: action.data,
        passwordPolicyLoading: false,
      };

    case accountManagementTypes.PASSWORD_POLICY_ERROR:
      return {
        ...state,
        passwordPolicy: { message: "Error occurred while retrieving password policy" },
        passwordPolicyLoading: false,
      };

    case accountManagementTypes.RESET_PASSWORD_POLICY:
      return {
        ...state,
        passwordPolicy: null,
      };
    case accountManagementTypes.UPDATE_PASSWORD_POLICY_SUCCESS:
      return {
        ...state,
        passwordPolicyUpdating: false,
        passwordPolicyUpdateSuccess: true,
        passwordPolicy: action.data,
      };

    case accountManagementTypes.UPDATE_PASSWORD_POLICY_ERROR:
      return {
        ...state,
        passwordPolicyUpdating: false,
        passwordPolicyUpdateFailed: true,
        passwordPolicyUpdateSuccess: false,
        policyUpdateErrorMessage: action.err,
      };

    case accountManagementTypes.UPDATE_PASSWORD_POLICY_LOADING:
      return {
        ...state,
        passwordPolicyUpdating: true,
      };

    case accountManagementTypes.RESET_UPDATE_PASSWORD_POLICY_FLAGS:
      return {
        ...state,
        passwordPolicyUpdating: false,
        passwordPolicyUpdateFailed: false,
        passwordPolicyUpdateSuccess: false,
        policyUpdateErrorMessage: null,
      };

    // Returning default state
    default:
      return state;
  }
};

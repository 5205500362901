import styled from "styled-components";
import { ReactComponent as DeleteIcon } from "../../../assets/Delete.svg";
import { InformationIcon } from "../../../assets";

export const StyledInformationIcon = styled(InformationIcon)`
  width: 17px;
  margin-left: 7px;
  cursor: pointer;
`;

export const DeleteIconStyled = styled(DeleteIcon)`
  width: 20px;
  cursor: ${({ isDisabled }) => (isDisabled ? "not-allowed" : "pointer")};
  .cls-1 {
    fill: ${({ isDisabled, theme }) => (isDisabled ? theme.colors.grayL5 || "#CCCCCC" : "#0D5FBE")};
  }
`;

export const BottomPanel = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 400px) {
    display: block;
    width: 100%;
    float: left;
    text-align: left;
    div:first-child {
      margin-bottom: 10px;
    }
  }
`;

export const AddShiftDurationButton = styled.div`
  & button {
    border-radius: 0.2rem;
    border: 1px solid ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
    background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
    color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
    font-family: Kanit;
    font-style: normal;
    font-size: 14px !important;
    height: 38px;
    letter-spacing: 0.5px;
    box-shadow: none;
    &:hover {
      background-color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
      color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
    }
    &:disabled {
      color: ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
      border-color: ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
      cursor: not-allowed;
      &:hover {
        background-color: transparent;
      }
    }
  }
  ${({ isActive }) =>
    isActive
      ? `color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"}; border: 1px solid ${({ theme }) =>
          theme.colors.themePrimary || "#0D5FBE"};`
      : ``}
`;

export const SaveCancelWrapper = styled.div`
  display: flex;
`;

export const StyledButton = styled.button`
  background-color: ${({ isActive, theme }) =>
    isActive ? theme.colors.greenPrimary || "#11A70B" : theme.colors.grayL6 || "#F2F2F2"};
  border: 1px solid ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"};
  margin-left: 10px;
`;

export const ShiftDurationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const ShiftDurationMessage = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: calc(100vh - 75vh);
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  font-family: "Open Sans", sans-serif;
  width: 38%;
  @media (max-width: 1100px) {
    width: 80%;
  }
`;
export const ShiftDurationSettingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  border-bottom: 1px solid #ddd;
`;

export const ShiftDurationHours = styled.div`
  display: flex;
  align-items: center;
  font-family: "Open Sans", sans-serif;
  & label {
    width: 100%;
  }
  & input {
    border: 1px solid ${({ theme }) => theme.colors.grayL3 || "#676767"};
    border-radius: 4px;
    color: ${({ theme }) => theme.colors.grayL3 || "#676767"} !important;
    font-family: "Open Sans", sans-serif;
    font-size: 14px !important;
    opacity: 1;
    margin-top: 3px;
    float: left;
    height: 33px;
    margin-left: 5px;
    padding: 5px;
    width: 145px;
  }
`;

export const ShiftDurationH = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.grayL3 || "#676767"};
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.grayL3 || "#676767"} !important;
  font-family: "Open Sans", sans-serif;
  font-size: 14px !important;
  opacity: 1;
  background: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  height: 33px;
  line-height: 33px;
  float: left;
  margin-top: 3px;
  margin-left: 8px;
  padding: 0px 6px;
`;

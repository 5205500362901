import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import styled from "styled-components";

import { updateActiveNavItem } from "../../../../redux/sideMenu/menu.action";
import AccountsActionBar from "../../Account/AccountsActionBar.component";
import ManagementPanelStyles from "./ManagementPanel.module.css";
import AccountCard from "../../Account/AccountCard.component";
import AccountList from "../../Account/AccountList.component";
import AccountManagement from "../../Account/AccountManagement.component";
import SearchBox from "../../../../components/SearchBox.component";
import AccountOverview from "../../Account/AccountOverview.component";
import { ReactComponent as rightIcon } from "../../../../assets/rightIconwhite.svg";

const AccountPanelStyled = styled.div`
  .assetMobileCss {
    background-color: ${({ theme }) => theme.colors.themePrimary || "#0d5fbe"};
  }
  .card {
    background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  }
`;

function AccountPanel({ updateActiveNavItem, companyName }) {
  const [SearchText, setSearchText] = useState("");
  const [imageLoaded, setImageLoaded] = useState(false);
  const [showAccountDetail, setShowAccountDetail] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    updateActiveNavItem("accounts");
  }, []);

  return (
    <AccountPanelStyled>
      {/* Account Action Bar */}
      <div className={`${ManagementPanelStyles.desktopManagementPanel}`}>
        {/* <AccountsActionBar /> */}
        <AccountManagement />
      </div>
      <div className={`${ManagementPanelStyles.mobileManagementPanel}`}>
        <AccountsActionBar />
        {!showAccountDetail ? <AccountCard /> : ""}
        {!showAccountDetail ? (
          <div className={`${ManagementPanelStyles.searchMobileView}`}>
            <SearchBox
              searchText={SearchText}
              setSearchText={setSearchText}
              placeholder={t("Search an account")}
            />
          </div>
        ) : (
          ""
        )}
        {!showAccountDetail ? (
          <div
            onClick={() => setShowAccountDetail(true)}
            className={`${ManagementPanelStyles.mobileListingSection}`}
          >
            <AccountList
              setImageLoaded={() => setImageLoaded(false)}
              searchText={SearchText}
              style={{ boxShadow: "rgba(0, 0, 0, 0.5) 0px 8px 8px -6px" }}
            />
          </div>
        ) : (
          <>
            <p className={`${ManagementPanelStyles.assetNameMobile} assetMobileCss`}>
              <span onClick={() => setShowAccountDetail(false)}>Your Accounts</span>{" "}
              <img height="15" src={rightIcon} alt="Right icon" />
              {` ${companyName || ""}`}
            </p>
            <AccountOverview imageLoaded={imageLoaded} setImageLoaded={(data) => setImageLoaded(data)} />
          </>
        )}
      </div>
    </AccountPanelStyled>
  );
}

const mapStateToProps = (state) => ({
  companyName: state.accounts.AccountOverview?.companyName,
});

const mapDispatchToProps = (dispatch) => ({
  updateActiveNavItem: (item) => dispatch(updateActiveNavItem(item)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountPanel));

import { InputGroup } from "react-bootstrap";
import styled from "styled-components";

export const GeneralInputContainer = styled.div`
  display: block;
  margin: 10px 0px;
  overflow: hidden;
  input[disabled].inputClass {
    color: ${({ theme }) => theme.colors.grayL3 || "#676767"};
  }
  @media (max-width: 1100px) {
    padding: 0px 10px;
  }
`;

export const LeftLabel = styled.div`
  float: left;
  width: ${({ hasAdornment }) => (hasAdornment ? "40%" : "60%")};
  @media (max-width: 1100px) {
    display: block;
    width: 100%;
    margin: 0;
  }
`;

export const RightInput = styled.div`
  float: right;
  display: ${({ hasAdornment }) => (hasAdornment ? "flex" : "block")};
  width: ${({ hasAdornment }) => (hasAdornment ? "60%" : "40%")};
  .input-group-text {
    background-color: #f2f2f2;
    font-size: 14px !important;
    font-family: "Open Sans", sans-serif;
    width: 100%;
  }
  .input-group-prepend {
    margin-right: -3px;
    width: 50%;
  }
  #enterpriseUrl div {
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
  }
  @media (max-width: 1100px) {
    display: ${({ hasAdornment }) => (hasAdornment ? "flex" : "block")};
    width: ${({ hasAdornment }) => (hasAdornment ? "100%" : "100%")};
    margin: 0;
  }
`;

export const SpanTop = styled.div`
  text-align: left;
  font-size: 16px !important;
  font-family: "Open Sans", sans-serif;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
`;
export const SpanBottom = styled.div`
  text-align: left;
  font-size: 14px !important;
  font-family: "Open Sans", sans-serif;
  color: ${({ theme }) => theme.colors.grayL3 || "#676767"};
`;
export const InputDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  div[title] {
    div {
      width: 99%;
      margin-left: 2px;
    }
  }
  input[type="number"] {
    text-align: right;
    padding-right: 8px;
  }
  .inputClass {
    margin: ${({ hasLeftRightText }) => (hasLeftRightText ? "0px 10px" : "")};
  }
`;

export const Divider = styled.div`
  clear: both;
  width: 100%;
  height: 1px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray5 || "#CCCCCC"};
`;

export const StyledInputGroup = styled(InputGroup.Text)`
  font-size: 14px;
  padding-left: 10px !important;
  padding-right: 10px !important;
  font-family: "Open Sans", sans-serif;
  color: ${({ theme }) => theme.colors.grayL3 || "#676767"};
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit;
  height: 36px;
  position: relative;
  top: 2px;
`;

export const BottomText = styled.div`
  font-size: 14px !important;
  font-weight: 500;
  font-family: "Open Sans", sans-serif;
  color: ${({ theme }) => theme.colors.grayL3 || "#676767"};
  float: left;
  text-align: left;
`;

export const LeftInputText = styled.div`
  font-size: 14px !important;
  font-weight: 500;
  font-family: "Open Sans", sans-serif;
  color: ${({ theme }) => theme.colors.grayL3 || "#676767"};
  float: left;
  text-align: left;
  position: relative;
`;

export const RightInputText = styled.div`
  font-size: 14px !important;
  font-weight: 500;
  font-family: "Open Sans", sans-serif;
  color: ${({ theme }) => theme.colors.grayL3 || "#676767"};
  float: left;
  text-align: left;
  position: relative;
`;

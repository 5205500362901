import { combineReducers } from "redux";
import { userReducer } from "./user/user.reducer";
import { userManagementReducer } from "./userManagement/userManagement.reducer";
import { accountReducer } from "./account/account.reducer";
import { assetTypeManagementReducer } from "./assetType/assetType.reducer";
import { assetManagementReducer } from "./asset/asset.reducer";
import { sideMenuReducer } from "./sideMenu/menu.reducer";
import { countReducer } from "./count/count.reducer";
import { checklistManagementReducer } from "./checklists/reducer";
import inspectionAssets from "./inspectionAssets";
import inspect from "./inspection";

import assetsReducer from "./assets";
import worksitesReducer from "./worksites";
import usersReducer from "./users";
import billingReducer from "./billing";
import configReducer from "./config";
import accounts from "./accounts";
import reports from "./reports";
import modals from "./modals";
import counts from "./counts";
import filters from "./filters";
import dropdownFilters from "./dropdownFilters";
import notificationSettingReducer from "./notification/reducer";
import mobileViewReducer from "./mobileView";
import forms from "./forms";
import errors from "./errors";
import bulkUpload from "./bulkUpload";
import assetStatus from "./assetStatus";
import userTypeReducer from "./userType";

import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import deviceInfo from "./deviceInformation/deviceInfo.reducer";
import analyticsPage from "./analyticsPage";
import { encryptTransform } from "redux-persist-transform-encrypt";

import modalDropdownFiltersReducers from "./modalDropdownFilters/reducer";

// Session Storage for current user
const persistConfig = {
  key: "root",
  storage: storage,
  whitelist: ["user", "config", "sideMenu"],
  transforms: [
    encryptTransform({
      secretKey: "12314siera453679456",
      onError: function (error) {
        console.log(error);
      },
    }),
  ],
};

const appReducer = combineReducers({
  // 1. Current User Reducer
  user: userReducer,
  users: usersReducer,
  // 2. Worksite Management Reducer
  worksites: worksitesReducer,
  // 3. User Management Reducer
  userManagement: userManagementReducer,
  // 4. Account Reducer
  account: accountReducer,
  // 5. Checklist Reducer
  checklist: checklistManagementReducer,
  // 6. Asset Type Reducer
  assetType: assetTypeManagementReducer,
  // 7. Asset Type Reducer
  asset: assetManagementReducer,
  assets: assetsReducer,
  // 8. Side menu reducer
  sideMenu: sideMenuReducer,
  // 9. Count reducer
  count: countReducer,
  //10. Inspection Assets
  inspectionAssets: inspectionAssets,
  inspect: inspect,
  //11. Config
  config: configReducer,
  //12. Billing
  billing: billingReducer,
  //13. Accounts new reducer
  accounts: accounts,
  //14. Report reduce
  reports: reports,
  //15. Modal state data
  modals: modals,
  //16. For count data
  counts: counts,
  //17. Filter state
  filters: filters,
  //18. Dropdown filter list state
  dropdownFilters: dropdownFilters,
  //19. Notification Setting
  notificationSetting: notificationSettingReducer,
  //20. Mobile View
  mobileView: mobileViewReducer,
  //20. For forms state
  forms: forms,
  //21. For API errors
  errors: errors,
  //22. For Bulk Upload
  bulkUpload: bulkUpload,
  //23. For asset status section
  assetStatus: assetStatus,
  //For device information
  deviceInfo,
  // For User Types
  userType: userTypeReducer,
  // for analytics page
  analyticsPage: analyticsPage,
  modalDropdownFilters: modalDropdownFiltersReducers,
});

const rootReducer = (state, action) => {
  if (action.type === "USER_LOGOUT") {
    state = undefined;
  }

  return appReducer(state, action);
};

export default persistReducer(persistConfig, rootReducer);

import React, { useState, useEffect } from "react";
import { Row, Col, Dropdown } from "react-bootstrap";
import ChecklistList from "./ChecklistList.component";
import Checklists from "./Checklists.component";
import BreadCrumb from "../../../components/BreadCrumb.component";
import { Trans, useTranslation } from "react-i18next";
import ChecklistTemplatesStyle from "./Templates/ChecklistTemplatesStyle.module.css";
// For Redux and request
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { updateActiveNavItem } from "../../../redux/sideMenu/menu.action";
import { setAddChecklistModal } from "../../../redux/modals/action";

// Button Component
import Button from "../../../components/Button/Button.component";
import styled from "styled-components";
import PermissionCheck from "../../../components/PermissionCheck/PermissionCheck";

const AddChecklistButton = styled.div`
  button.dropdown-toggle {
    background: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} !important;
  }
`;

const ChecklistManagement = ({
  history,
  loginDetails,
  checklistOverview,
  updateActiveNavItem,
  setAddChecklistModal,
  addChecklistModal,
}) => {
  const [editChecklist, setEditChecklist] = useState(false);
  const [filterAccountId, setFilterAccountId] = useState("all");

  const { t } = useTranslation();

  useEffect(() => {
    updateActiveNavItem("viewChecklist");
  }, []);

  useEffect(() => {
    if (!addChecklistModal) {
      setEditChecklist(false);
    }
  }, [addChecklistModal]);

  return (
    <>
      <BreadCrumb />
      {/* {editChecklist ?
        <EditChecklist editChecklist={editChecklist} setEditChecklist={setEditChecklist} /> : */}
      <>
        <PermissionCheck section="DASHBOARD" permissionName="CHECKLIST_SETTINGS" actionName="add">
          <Row className="m-0 p-0">
            <AddChecklistButton className="mr-2 pl-0 mt-2">
              <Dropdown className={`${ChecklistTemplatesStyle.addChecklistButton}`}>
                <Dropdown.Toggle id="dropdown-basic">
                  <i className="icon icon-add"></i> <Trans>ADD CHECKLIST</Trans>
                </Dropdown.Toggle>
                <Dropdown.Menu className={`${ChecklistTemplatesStyle.addChecklistButtonNested}`}>
                  <Dropdown.Item href="#" onClick={() => setAddChecklistModal(true)}>
                    <Trans>New Checklist</Trans>
                  </Dropdown.Item>
                  <Dropdown.Item href="#" onClick={() => history.push("/app/checklistmanagement/templates")}>
                    <Trans>From Template</Trans>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </AddChecklistButton>
          </Row>
        </PermissionCheck>
        <Row style={{ paddingTop: "5px" }}>
          <Col lg={3}>
            <div className="assetTypeList_hieght asset_checklist_list checklist-setting-list">
              <ChecklistList filterAccountId={filterAccountId} />
            </div>
          </Col>
          <Col lg={9}>
            <Checklists
              editChecklist={editChecklist}
              setEditChecklist={setEditChecklist}
              filterAccountId={filterAccountId}
              setFilterAccountId={setFilterAccountId}
            />
            {/* {checklistOverview && <Row className="editViewCheck">
                {
                  !editChecklist && <button onClick={() => setEditChecklist(true)} className="buttonSubmit ripple">
                    <EditIcon fill='white' width="15px" style={{ marginRight: '10px' }} /><Trans>EDIT</Trans>
                  </button>
                }
              </Row>} */}
            <PermissionCheck section="DASHBOARD" permissionName="CHECKLIST_SETTINGS" actionName="edit">
              {checklistOverview && (
                <Row className="editViewCheck">
                  {!editChecklist && (
                    <Button
                      iconClass="edit"
                      label={t("Edit")}
                      onClick={() => setEditChecklist(true)}
                      showIcon
                      buttonType="primary"
                    />
                  )}
                </Row>
              )}
            </PermissionCheck>
          </Col>
        </Row>
      </>
      {/* } */}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  updateActiveNavItem: (item) => dispatch(updateActiveNavItem(item)),
  setAddChecklistModal: (data) => dispatch(setAddChecklistModal(data)),
});

const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
  checklistOverview: state.checklist.checklistOverview,
  addChecklistModal: state.modals.addChecklistModal,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChecklistManagement));

/** This was created to isolate the settings from the crazy reloading because of the multiple BE requests from multiple places. Please FIX IT and STREAMLINE IT - TODO */

import { dropdownTypes } from "./types";
import { apiCall } from "../../utils/apiCall";

export const resetDropdownFilter = () => ({
  type: dropdownTypes.RESET_ALL_SETTINGS_DROPDOWN_FILTER,
});

export const setSettingsDropdownAccountId = (data) => ({
  type: dropdownTypes.SET_SETTINGS_DROPDOWN_ACCOUNT_ID,
  data: data,
});

export const setSettingsDropdownWorksiteId = (data) => ({
  type: dropdownTypes.SET_SETTINGS_DROPDOWN_WORKSITE_ID,
  data: data,
});

export const setSettingsDropdownAssetTypeId = (data) => ({
  type: dropdownTypes.SET_SETTINGS_DROPDOWN_ASSET_TYPE_ID,
  data: data,
});

export const setSettingsDropdownAssetId = (data) => ({
  type: dropdownTypes.SET_SETTINGS_DROPDOWN_ASSET_ID,
  data: data,
});

export const setSettingsDropdownUserTypeId = (userTypeId) => ({
  type: dropdownTypes.SET_SETTINGS_DROPDOWN_USER_TYPE_ID,
  data: userTypeId,
});

export const setSettingsDropdownUserId = (userId) => ({
  type: dropdownTypes.SET_SETTINGS_DROPDOWN_USER_ID,
  data: userId,
});

export const getDropdownAssetTypeByWorksiteLoading = () => ({
  type: dropdownTypes.GET_DROPDOWN_ASSET_TYPE_LIST_FOR_SETTINGS_LOADING,
});
const getDropdownAssetTypeByWorksiteError = () => ({
  type: dropdownTypes.GET_DROPDOWN_ASSET_TYPE_LIST_FOR_SETTINGS_ERROR,
});

export const getDropdownAssetTypeByWorksite = (siteId) => {
  return async (dispatch, getState) => {
    dispatch(getDropdownAssetTypeByWorksiteLoading());
    const state = getState();
    const dropdownAccountId = state?.user?.isSuperAdmin
      ? state?.dropdownFilters?.settings?.accountId
      : state?.user?.loginDetails?.accountId;
    const dropdownSiteId = siteId || state?.dropdownFilters?.settings?.worksiteId;

    if (dropdownSiteId === true || !dropdownSiteId || !dropdownAccountId) {
      return;
    }
    const url = `/assetType/filter/assetTypeListBysiteID/${dropdownAccountId}/${dropdownSiteId}`;

    apiCall(url)
      .then((resp) => {
        return dispatch({
          type: dropdownTypes.GET_DROPDOWN_ASSET_TYPE_LIST_FOR_SETTINGS_SUCCESS,
          data: resp.data.data,
        });
      })
      .catch((err) => {
        dispatch(getDropdownAssetTypeByWorksiteError());
        console.log(err);
      });
  };
};

export const getDropdownAssetListLoading = () => ({
  type: dropdownTypes.GET_DROPDOWN_ASSET_LIST_FOR_SETTINGS_LOADING,
});

export const getDropdownAssetListError = () => ({
  type: dropdownTypes.GET_DROPDOWN_ASSET_LIST_FOR_SETTINGS_ERROR,
});

export const getDropdownAssetList = () => {
  return async (dispatch, getState) => {
    dispatch(getDropdownAssetListLoading());
    const state = getState();
    const assetTypeId = state?.dropdownFilters?.settings?.assetTypeId;
    const accountId = state?.user?.isSuperAdmin
      ? state?.dropdownFilters?.settings?.accountId
      : state?.user?.loginDetails?.accountId;

    const worksiteId = state?.user?.isSiteAdmin
      ? state?.user?.loginDetails?.userData?.authorizedSites?.[0]
      : state?.dropdownFilters?.settings?.worksiteId;

    if (
      (accountId && typeof accountId === "boolean" && accountId === true) ||
      (worksiteId && typeof worksiteId === "boolean" && worksiteId === true) ||
      (assetTypeId && typeof assetTypeId === "boolean" && assetTypeId === true) ||
      !accountId ||
      !worksiteId ||
      !assetTypeId
    ) {
      dispatch({
        type: dropdownTypes.GET_DROPDOWN_ASSET_LIST_FOR_SETTINGS_SUCCESS,
        data: [],
      });
      return;
    }
    const url = `/dropdown/assets?accid=${accountId}&siteid=${worksiteId || "all"}&astpid=${
      assetTypeId || "all"
    }&status=true`;
    // asset/filter/assetList/:accountId/:assetTypeId/:siteId/:pageNo/:pageSize/:searchText
    // apiCall(`/asset/filter/assetList/${accountId}/${assetTypeId}/${worksiteId}/all/all/all`)
    apiCall(url)
      .then((resp) => {
        const data = resp.data.success ? resp?.data?.data?.data : [];
        return dispatch({
          type: dropdownTypes.GET_DROPDOWN_ASSET_LIST_FOR_SETTINGS_SUCCESS,
          data,
        });
      })
      .catch((err) => {
        dispatch(getDropdownAssetListError());
        console.log(err);
      });
  };
};

export const getDropdownAssetsList = (worksiteId, assetTypeId) => {
  return async (dispatch, getState) => {
    const state = getState();
    const userState = state?.user;
    const accountId = userState?.loginDetails?.accountId;
    const dropdownAccountId = state?.dropdownFilters?.accountId;
    const isSuperAdmin = userState?.isSuperAdmin;
    const isSiteAdmin = userState?.isSiteAdmin;

    const derivedWorksiteId =
      worksiteId ||
      (isSiteAdmin
        ? userState?.loginDetails?.userData?.authorizedSites?.[0]
        : state?.dropdownFilters?.worksiteId);

    const derivedAssetTypeId = assetTypeId || state?.dropdownFilters?.assetTypeId;

    // asset/filter/assetList/:accountId/:siteId/:assetTypeId/:pageNo/:pageSize/:searchText
    // const url = `/asset/filter/assetList/${isSuperAdmin ? dropdownAccountId : accountId}/${
    //   derivedWorksiteId || "all"
    // }/${derivedAssetTypeId || "all"}/all/all/all`;

    const url = `/dropdown/assets?accid=${isSuperAdmin ? dropdownAccountId : accountId}&siteid=${
      derivedWorksiteId || "all"
    }&astpid=${derivedAssetTypeId || "all"}`;

    apiCall(url)
      .then((resp) => {
        return dispatch({
          type: dropdownTypes.GET_DROPDOWN_ASSET_LIST,
          data: resp?.data?.data?.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const resetWorksiteList = (data) => ({
  type: dropdownTypes.RESET_DROPDOWN_WORKSITE_LIST,
  data: data,
});

export const resetAssetTypeList = (data) => ({
  type: dropdownTypes.RESET_DROPDOWN_ASSET_TYPE_LIST,
  data: data,
});

export const resetAssetList = (data) => ({
  type: dropdownTypes.RESET_DROPDOWN_ASSET_LIST,
  data: data,
});

export const resetUserListByUserType = (data) => ({
  type: dropdownTypes.RESET_DROPDOWN_USER_LIST,
  data: data,
});

export const setDuration = (duration) => ({
  type: dropdownTypes.SET_DURATION,
  data: duration,
});

import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  DatePickDiv,
  OnlyDatePicker,
  DatePickerRange,
  DatePickerRangeTime,
  TimePicker,
  TimePickerRange,
  ShowData,
  DateRagneFrom,
  DividerStyle,
  DateAndTime,
} from "./DatePicker.component.styles";
import PropTypes from "prop-types";
import { propTypes } from "react-bootstrap/esm/Image";

const DateRangePicker = ({
  onlyDatePicker,
  dateRangePickerFromTo,
  dateRangePickerWithTimeFromTo,
  timePicker,
  timePickerFromTo,
  size,
  disabled,
  selectedEndDate,
  onChangeEndDate,
  onChangeStartDate,
  selectedStartDate,
  startLabel,
  endLabel,
  dateAndTimePicker,
  selectedStartTime,
  selectedEndTime,
  onChangeEndTime,
  onChangeStartTime,
  timedisabled,
  timeStartValue,
  timeEndValue,
  ...rest
}) => {
  const [startTime, setStartTime] = useState(null); // State to store the selected start time

  const handleStartTimeChange = (time) => {
    setStartTime(time); // Update selected start time
  };

  const handleKeyDown = (event) => {
    // Prevent default action for all keys
    event.preventDefault();
  };

  const MyContainerStart = ({ className, children, selectedDate, showTime }) => {
    const currentDate = new Date();
    const selectedTime = showTime
      ? selectedDate.toLocaleTimeString("en-US", { hour: "numeric", minute: "numeric" })
      : "";
    return (
      <div className={className}>
        <div className="dateHeader">
          {selectedDate
            ? selectedDate.toLocaleDateString("en-US", { month: "long", day: "numeric", year: "numeric" }) +
              (showTime ? " - " + selectedTime : "")
            : currentDate.toLocaleDateString("en-US", { month: "long", day: "numeric", year: "numeric" })}
        </div>
        <div style={{ position: "relative" }}>{children}</div>
      </div>
    );
  };

  const MyContainerEnd = ({ className, children, selectedDate, showTime }) => {
    const currentDate = new Date();
    const selectedTime = showTime
      ? selectedDate.toLocaleTimeString("en-US", { hour: "numeric", minute: "numeric" })
      : "";
    return (
      <div className={className}>
        <div className="dateHeader">
          {selectedDate
            ? selectedDate.toLocaleDateString("en-US", { month: "long", day: "numeric", year: "numeric" }) +
              (showTime ? " - " + selectedTime : "")
            : currentDate.toLocaleDateString("en-US", { month: "long", day: "numeric", year: "numeric" })}
        </div>
        <div style={{ position: "relative" }}>{children}</div>
      </div>
    );
  };

  const filterEndDate = (date) => {
    return selectedStartDate ? date >= selectedStartDate : true;
  };

  const handleTimeStartChange = (event) => {
    onChangeStartTime(event.target.value);
  };

  const handleTimeEndChange = (event) => {
    onChangeEndTime(event.target.value);
  };
  return (
    <DatePickDiv size={size}>
      {onlyDatePicker && (
        <OnlyDatePicker>
          <label>Date:</label>
          <DatePicker
            calendarContainer={({ className, children }) => (
              <MyContainerStart className={className} selectedDate={selectedStartDate}>
                {children}
              </MyContainerStart>
            )}
            selected={selectedStartDate}
            onChange={onChangeStartDate}
            dateFormat="MMMM d, yyyy"
            peekNextMonth
            disabled={disabled}
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            isClearable
            placeholderText="Please select date"
            showIcon
            icon={<i className="icon icon-calendar" />}
            {...rest}
          />
        </OnlyDatePicker>
      )}
      {dateRangePickerFromTo && (
        <DatePickerRange>
          <DateRagneFrom
            className="dateRangeFrm"
            title={
              selectedStartDate
                ? selectedStartDate.toLocaleDateString("en-US", {
                    month: "long",
                    day: "numeric",
                    year: "numeric",
                  })
                : ""
            }
          >
            <label>{startLabel}</label>
            <DatePicker
              formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 3)}
              onKeyDown={handleKeyDown}
              showIcon
              icon={<i className="icon icon-calendar" />}
              selected={selectedStartDate}
              onChange={onChangeStartDate}
              dateFormat="MMMM d, yyyy"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              placeholderText="Please select start date"
              disabled={disabled}
              selectsStart
              calendarContainer={({ className, children }) => (
                <MyContainerStart className={className} selectedDate={selectedStartDate} showTime={false}>
                  {children}
                </MyContainerStart>
              )}
              {...rest}
            />
          </DateRagneFrom>
          <DividerStyle>-</DividerStyle>
          <DateRagneFrom
            className="dateRangeTo"
            title={
              selectedEndDate
                ? selectedEndDate.toLocaleDateString("en-US", {
                    month: "long",
                    day: "numeric",
                    year: "numeric",
                  })
                : ""
            }
          >
            <label>{endLabel}</label>
            <DatePicker
              formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 3)}
              onKeyDown={handleKeyDown}
              showIcon
              icon={<i className="icon icon-calendar" />}
              selected={selectedEndDate}
              onChange={onChangeEndDate}
              dateFormat="MMMM d, yyyy"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              placeholderText="Please select end date"
              disabled={disabled}
              selectsEnd
              minDate={selectedStartDate}
              filterDate={filterEndDate}
              calendarContainer={({ className, children }) => (
                <MyContainerEnd className={className} selectedDate={selectedEndDate} showTime={false}>
                  {children}
                </MyContainerEnd>
              )}
              {...rest}
            />
          </DateRagneFrom>
        </DatePickerRange>
      )}

      {dateRangePickerWithTimeFromTo && (
        <DatePickerRangeTime size={size}>
          <DateRagneFrom className="dateRangeFrm">
            <label>Date Range with Time From:</label>
            <DatePicker
              formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 3)}
              //    onKeyDown={handleKeyDown}
              showIcon
              icon={<i className="icon icon-calendar" />}
              selected={selectedStartDate}
              onChange={onChangeStartDate}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              placeholderText="Please select start date"
              disabled={disabled}
              selectsStart
              calendarContainer={({ className, children }) => (
                <MyContainerStart className={className} selectedDate={selectedStartDate} showTime>
                  {children}
                </MyContainerStart>
              )}
              timeInputLabel="Time:"
              dateFormat="MM/dd/yyyy - h:mm aa"
              showTimeSelect
              timeFormat="h:mm aa"
              timeIntervals={1}
              timeCaption="time"
              {...rest}
            />
          </DateRagneFrom>
          <DividerStyle>-</DividerStyle>
          <DateRagneFrom className="dateRangeTo">
            <label>Date Range with Time To:</label>
            <DatePicker
              formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 3)}
              //    onKeyDown={handleKeyDown}
              showIcon
              icon={<i className="icon icon-calendar" />}
              selected={selectedEndDate}
              onChange={onChangeEndDate}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              placeholderText="Please select end date"
              disabled={disabled}
              selectsEnd
              minDate={selectedStartDate}
              filterDate={filterEndDate}
              calendarContainer={({ className, children }) => (
                <MyContainerEnd className={className} selectedDate={selectedEndDate} showTime>
                  {children}
                </MyContainerEnd>
              )}
              timeInputLabel="Time:"
              dateFormat="MM/dd/yyyy - h:mm aa"
              showTimeSelect
              timeFormat="h:mm aa"
              timeIntervals={1}
              timeCaption="time"
              {...rest}
            />
          </DateRagneFrom>
        </DatePickerRangeTime>
      )}
      {timePicker && (
        <TimePicker className="timePick" size={size}>
          <label>Time:</label>
          <DatePicker
            selected={startTime}
            onChange={handleStartTimeChange}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={1}
            timeCaption="Time"
            dateFormat="h:mm aa"
            showIcon
            disabled={disabled}
            icon={<i className="icon icon-time" />}
            {...rest}
          />
        </TimePicker>
      )}
      {timePickerFromTo && (
        <TimePickerRange size={size}>
          <label>Time Range Picker:</label>
          <DatePicker
            selected={selectedStartDate}
            onChange={onChangeStartDate}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={1}
            timeCaption="Start"
            dateFormat="h:mm aa"
            disabled={disabled}
            showIcon
            icon={<i className="icon icon-time" />}
            {...rest}
          />

          <DatePicker
            selected={selectedEndDate}
            onChange={onChangeEndDate}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={1}
            timeCaption="End"
            dateFormat="h:mm aa"
            disabled={disabled}
            showIcon
            icon={<i className="icon icon-time" />}
            {...rest}
          />
        </TimePickerRange>
      )}

      {dateAndTimePicker && (
        <>
          <DatePickerRange>
            <DateRagneFrom className="dateRangeFrm">
              <label>{startLabel}</label>
              <DateAndTime
                title={
                  selectedStartDate
                    ? `${selectedStartDate.toLocaleDateString("en-US", { month: "long", day: "numeric", year: "numeric" })} ${timeStartValue ? new Date(`1970-01-01T${timeStartValue}`).toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit", hour12: true }) : ""}`
                    : ""
                }
              >
                <DatePicker
                  formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 3)}
                  onKeyDown={handleKeyDown}
                  showIcon
                  icon={<i className="icon icon-calendar" />}
                  selected={selectedStartDate}
                  onChange={onChangeStartDate}
                  dateFormat="MMMM d, yyyy"
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  placeholderText="Please select start date"
                  disabled={disabled}
                  selectsStart
                  calendarContainer={({ className, children }) => (
                    <MyContainerStart className={className} selectedDate={selectedStartDate} showTime={false}>
                      {children}
                    </MyContainerStart>
                  )}
                  {...rest}
                />
                <input
                  value={timeStartValue}
                  onChange={(e) => onChangeStartTime(e.target.value)}
                  type="time"
                  disabled={timedisabled}
                  className="inputTime"
                />
              </DateAndTime>
            </DateRagneFrom>
            <DividerStyle>-</DividerStyle>
            <DateRagneFrom className="dateRangeTo">
              <label>{endLabel}</label>
              <DateAndTime
                title={
                  selectedEndDate
                    ? `${selectedEndDate.toLocaleDateString("en-US", { month: "long", day: "numeric", year: "numeric" })} ${timeEndValue ? new Date(`1970-01-01T${timeEndValue}`).toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit", hour12: true }) : ""}`
                    : ""
                }
              >
                <DatePicker
                  formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 3)}
                  onKeyDown={handleKeyDown}
                  showIcon
                  icon={<i className="icon icon-calendar" />}
                  selected={selectedEndDate}
                  onChange={onChangeEndDate}
                  dateFormat="MMMM d, yyyy"
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  placeholderText="Please select end date"
                  disabled={disabled}
                  selectsEnd
                  minDate={selectedStartDate}
                  filterDate={filterEndDate}
                  calendarContainer={({ className, children }) => (
                    <MyContainerEnd className={className} selectedDate={selectedEndDate} showTime={false}>
                      {children}
                    </MyContainerEnd>
                  )}
                  {...rest}
                />
                <input
                  value={timeEndValue}
                  onChange={(e) => onChangeEndTime(e.target.value)}
                  type="time"
                  disabled={timedisabled}
                  className="inputTime"
                />
              </DateAndTime>
            </DateRagneFrom>
          </DatePickerRange>
        </>
      )}
    </DatePickDiv>
  );
};

DateRangePicker.propTypes = {
  /**
   * Determines whether the component should display a single date picker.
   */
  onlyDatePicker: PropTypes.bool,
  /**
   * Controls whether the component should display a date range picker with separate start and end date pickers.
   */
  dateRangePickerFromTo: PropTypes.bool,
  /**
   * Specifies whether the component should display a date range picker with time selection, including start and end times.
   */
  dateRangePickerWithTimeFromTo: PropTypes.bool,
  /**
   * Determines if the component should display a time picker for selecting a single time.
   */
  timePicker: PropTypes.bool,
  /**
   * Controls whether the component should display a time range picker with separate start and end time pickers.
   */
  timePickerFromTo: PropTypes.bool,
  /**
   * Specifies the size of the component, with options for "small," "medium," and "large."
   */
  size: PropTypes.oneOf(["small", "medium", "large"]),
  /**
   * Specify whether the input elements within the component should be disabled.
   */
  disabled: PropTypes.bool,
  /**
   * checkbox startLabel
   */
  startLabel: PropTypes.string.isRequired,
  /**
   * checkbox endLabel
   */
  endLabel: PropTypes.string.isRequired,
  dateAndTimePicker: propTypes.bool,
};

DateRangePicker.defaultProps = {
  onlyDatePicker: false,
  dateRangePickerFromTo: false,
  dateRangePickerWithTimeFromTo: false,
  timePicker: false,
  timePickerFromTo: false,
  dateAndTimePicker: false,
  size: "small",
  startLabel: "Date Range | From",
  endLabel: "To",
};

export default DateRangePicker;

/**
 * TODO: Clean up this file when time permits. There is a shit load of garbage
 */

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import SettingsSection from "../../../../components/SettingsSection";
import {
  getOperatorCertificationAlertSettingsByUserId,
  updateOperatorCertificationAlertSettings,
  resetOperatorCertificationAlertSettings,
  resetNotificationSettingsFlags,
} from "../../../../redux/notification/action";
import { isEqual } from "../../../../utils/validations/utilities";
import { SettingWrapper } from "./NotificationSetting.component.styles";

import ActionsBar from "./ActionsBar";
import SettingsHeader from "../common/SettingsHeader.component";
import { SettingsWrapper, SettingsContainer, SettingsFooter } from "../Settings.Styles";
import NoSettingsDataFound from "../common/NoAssetFound.component";
import { bulkSettingsUpdate, bulkUpdateOperatorCertificateSettings } from "../../../../redux/config/action";
import SnackBar from "../../SnackBar";
import Recipients from "./emailerRecipients/Recipients";
import { handleRecipientsChangeUtil } from "./common";
import { getUserTypeDetailList } from "../../../../redux/userType/action";
import validationConstant from "../../../../constant/messages/validation.json";

const OperatorCertificationExpirySettings = ({
  loginDetails,
  notificationSetting,
  isAccountAdmin,
  isAccountOwner,
  isSiteAdmin,
  dropdownAccountId,
  dropdownUserId,
  getOperatorCertificationAlertSettingsByUserId,
  updateOperatorCertificationAlertSettings,
  resetOperatorCertificationAlertSettings,
  bulkSettingsUpdate,
  bulkUpdateOperatorCertificateSettings,
  loading,
  notificationUpdateLoading,
  notificationBulkUpdateSuccess,
  getUserTypeDetailList,
  operatorCertificateExpiryAlertSettingsSuccess,
  resetNotificationSettingsFlags,
}) => {
  const [operatorCertificateExpiryAlertSettings, setOperatorCertificateExpiryAlertSettings] = useState({});
  const [currentAccountId, setCurrentAccountId] = useState(null);
  const [applyToAllObj, setApplyToAllObj] = useState({});
  const [checkApplyToAll, setCheckApplyToAll] = useState(false);
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);
  const [clearSelection, setClearSelection] = useState(false);
  const { t } = useTranslation();
  const settingsOptions = [
    {
      label: t("All User Types"),
      isDropdown: false,
      id: "all",
      isCheckedKey: "all",
      radioGroup: "userTypes",
    },
    {
      label: t("Selected User Types"),
      id: "selected",
      isCheckedKey: "selected",
      isDropdown: true,
      labelKey: "label",
      radioGroup: "userTypes",
      dropdown: {
        placeholder: "Select a User Type",
      },
    },
    {
      label: t("All Worksites"),
      isDropdown: false,
      id: "all",
      isCheckedKey: "all",
      radioGroup: "worksites",
    },
    {
      label: t("Selected Worksites"),
      id: "selected",
      isCheckedKey: "selected",
      isDropdown: true,
      labelKey: "name",
      radioGroup: "worksites",
      dropdown: {
        placeholder: "Select a Worksite",
      },
    },

    {
      label: t("All Users"),
      isDropdown: false,
      id: "all",
      isCheckedKey: "all",
      radioGroup: "users",
    },
    {
      label: t("Selected Users"),
      id: "selected",
      isCheckedKey: "selected",
      isDropdown: true,
      labelKey: "fullName",
      radioGroup: "users",
      dropdown: {
        placeholder: "Select a User",
      },
    },
  ];

  useEffect(() => {
    if (notificationBulkUpdateSuccess) {
      getOperatorCertificationAlertSettingsByUserId(dropdownUserId);
      setApplyToAllObj({});
      setCheckApplyToAll(false);
      setClearSelection(true);
    }
  }, [notificationBulkUpdateSuccess]);

  useEffect(() => {
    return () => {
      handleCloseSnackBar();
    };
  }, [resetNotificationSettingsFlags]);

  useEffect(() => {
    const acId =
      isAccountAdmin || isAccountOwner || isSiteAdmin
        ? loginDetails && loginDetails.accountId
        : dropdownAccountId;
    setCurrentAccountId(acId);
  }, [dropdownAccountId, loginDetails, isAccountAdmin, isAccountOwner, isSiteAdmin]);

  const setDefaultNotificationSettings = () => {
    setClearSelection(true);
    setOperatorCertificateExpiryAlertSettings(notificationSetting?.operatorCertificateExpiryAlertSettings);
  };

  const hideButtonsForWorksiteSettings = isEqual(
    notificationSetting?.operatorCertificateExpiryAlertSettings,
    operatorCertificateExpiryAlertSettings
  );

  useEffect(() => {
    if (notificationSetting) {
      setDefaultNotificationSettings();
    }
  }, [notificationSetting.operatorCertificateExpiryAlertSettings]);

  const handleOperatorCertificateExpiryAlertSettingsChange = (field, value) => {
    if (field === "headerSwitch") {
      setOperatorCertificateExpiryAlertSettings((prevAlert) => ({
        ...prevAlert,
        beforeMonth: value,
        beforeWeek: value,
        beforeDay: value,
      }));
    } else {
      setOperatorCertificateExpiryAlertSettings((prevAlert) => ({
        ...prevAlert,
        [field]: value,
      }));
    }
  };

  const onSubmit = () => {
    if (Object.keys(applyToAllObj).length !== 0) {
      const siteIds =
        applyToAllObj?.worksites?.all ||
        !applyToAllObj?.worksites?.selectedItems ||
        applyToAllObj?.worksites?.selectedItems?.length === 0
          ? "all"
          : applyToAllObj?.worksites?.selectedItems?.map((site) => site.value);
      const userTypeIds = applyToAllObj?.userTypes?.all
        ? "all"
        : applyToAllObj?.userTypes?.selectedItems?.map((userType) => userType.value);
      const userIds = applyToAllObj?.users?.all
        ? "all"
        : applyToAllObj?.users?.selectedItems?.map((user) => user.value);

      const updatableSettings = {
        sites: siteIds,
        userTypeIds: userTypeIds,
        userIds: userIds,
        settings: { operatorCertificateExpiryAlertSettings },
      };
      bulkUpdateOperatorCertificateSettings(updatableSettings, setIsUpdateLoading);
    }
    updateOperatorCertificationAlertSettings(
      dropdownUserId,
      { operatorCertificateExpiryAlertSettings },
      setIsUpdateLoading
    );
  };

  const handleCloseSnackBar = () => {
    resetNotificationSettingsFlags();
    setApplyToAllObj({});
    setCheckApplyToAll(false);
    setIsUpdateLoading(false);
  };

  const displaySettings = {
    expirationAlerts: {
      title: "EXPIRATION_ALERTS",
      tableHeader: ["EXPIRATION_ALERTS", "Alert"],
      onChange: handleOperatorCertificateExpiryAlertSettingsChange,
      accountId: currentAccountId,
    },
    operatorCertificateExpiryAlertSettings: {
      title: "operatorCertificateExpirationAlert",
      description: "operatorCertificateExpirationAlertDesc",
      tableHeader: false,
      onChange: handleOperatorCertificateExpiryAlertSettingsChange,
      isBodyHidable: true,
      summarySwitch: {
        label: "Alert for Expiration",
        value: !!(
          operatorCertificateExpiryAlertSettings?.beforeMonth ||
          operatorCertificateExpiryAlertSettings?.beforeWeek ||
          operatorCertificateExpiryAlertSettings?.beforeDay
        ),
        field: "headerSwitch",
        headerSwitch: true,
      },
      tableData: [
        {
          label: "Send alert 30 days before expiry",
          value: !!operatorCertificateExpiryAlertSettings?.beforeMonth,
          field: "beforeMonth",
        },
        {
          label: "Send alert 7 days before expiry",
          value: !!operatorCertificateExpiryAlertSettings?.beforeWeek,
          field: "beforeWeek",
        },
        {
          label: "Send alert 1 day before expiry",
          value: !!operatorCertificateExpiryAlertSettings?.beforeDay,
          field: "beforeDay",
        },
      ],
    },
  };

  useEffect(() => {
    if (dropdownUserId) {
      getOperatorCertificationAlertSettingsByUserId(dropdownUserId);
    }
  }, [dropdownUserId]);

  const onReset = () => {
    resetOperatorCertificationAlertSettings(dropdownUserId, setIsUpdateLoading);
  };

  const handleRecipientsChange = (userType, isChecked) => {
    handleRecipientsChangeUtil(
      userType,
      isChecked,
      operatorCertificateExpiryAlertSettings,
      setOperatorCertificateExpiryAlertSettings
    );
  };

  const handleCustomEmailsChange = (email) => {
    const temp = {
      ...operatorCertificateExpiryAlertSettings,
      customEmails: [...email],
    };
    setOperatorCertificateExpiryAlertSettings(temp);
  };

  useEffect(() => {
    getUserTypeDetailList();
  }, []);

  return (
    <SettingsWrapper>
      <SettingsHeader
        title={t("An alert will be sent when a user's certificate is going to expire")}
        showUserTypeFilter={true}
        showUserFilter={true}
        showAssetTypeFilter={false}
        showAssetFilter={false}
      />
      {dropdownUserId && typeof dropdownUserId === "boolean" && dropdownUserId === true ? (
        <NoSettingsDataFound type={"User"} />
      ) : (
        <>
          <SettingsContainer>
            <SettingWrapper>
              <SettingsSection
                {...displaySettings.expirationAlerts}
                isLoading={notificationUpdateLoading || loading}
              />
              <SettingsSection
                {...displaySettings.operatorCertificateExpiryAlertSettings}
                isLoading={notificationUpdateLoading || loading}
              />

              <Recipients
                customEmails={operatorCertificateExpiryAlertSettings?.customEmails}
                handleCustomEmailsChange={handleCustomEmailsChange}
                recipients={operatorCertificateExpiryAlertSettings?.userTypes}
                handleRecipientsChange={handleRecipientsChange}
              />
            </SettingWrapper>
          </SettingsContainer>
          <SettingsFooter>
            <ActionsBar
              showAppyToAll={true}
              updatableChanges={!hideButtonsForWorksiteSettings || Object.keys(applyToAllObj).length !== 0}
              handleCancel={setDefaultNotificationSettings}
              isLoading={isUpdateLoading}
              showWorksiteSelection={false}
              applyToAllObj={applyToAllObj}
              setApplyToAllObj={setApplyToAllObj}
              checkApplyToAll={Object.keys(applyToAllObj).length !== 0}
              setCheckApplyToAll={setCheckApplyToAll}
              handleUpdate={onSubmit}
              handleReset={onReset}
              settingsOptions={settingsOptions}
              clearSelection={clearSelection}
              setClearSelection={setClearSelection}
            />
          </SettingsFooter>
        </>
      )}
      <SnackBar
        isSuccess={
          notificationBulkUpdateSuccess || isUpdateLoading || operatorCertificateExpiryAlertSettingsSuccess
        }
        label={t(validationConstant.notification_settings_updated)}
        handleClose={handleCloseSnackBar}
      />
    </SettingsWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    loginDetails: state.user.loginDetails,
    isAccountAdmin: state.user.isAccountAdmin,
    isAccountOwner: state.user.isAccountOwner,
    isSiteAdmin: state.user.isSiteAdmin,
    notificationSetting: state.notificationSetting,
    dropdownAccountId: state.dropdownFilters.accountId,
    dropdownUserId: state.dropdownFilters?.settings?.userId,
    loading: state.notificationSetting.loading,
    notificationBulkUpdateSuccess: state.notificationSetting?.notificationBulkUpdateSuccess,
    notificationUpdateLoading: state.notificationSetting?.notificationUpdateLoading,
    operatorCertificateExpiryAlertSettingsSuccess:
      state.notificationSetting?.operatorCertificateExpiryAlertSettingsSuccess,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getOperatorCertificationAlertSettingsByUserId: (userId) =>
    dispatch(getOperatorCertificationAlertSettingsByUserId(userId)),
  updateOperatorCertificationAlertSettings: (userId, data, setIsUpdateLoading) =>
    dispatch(updateOperatorCertificationAlertSettings(userId, data, setIsUpdateLoading)),
  resetOperatorCertificationAlertSettings: (userId, setIsUpdateLoading) =>
    dispatch(resetOperatorCertificationAlertSettings(userId, setIsUpdateLoading)),
  bulkSettingsUpdate: (data, setUpdateLoading) => dispatch(bulkSettingsUpdate(data, setUpdateLoading)),
  bulkUpdateOperatorCertificateSettings: (data, setUpdateLoading) =>
    dispatch(bulkUpdateOperatorCertificateSettings(data, setUpdateLoading)),
  getUserTypeDetailList: () => dispatch(getUserTypeDetailList()),
  resetNotificationSettingsFlags: () => dispatch(resetNotificationSettingsFlags()),
});

export default connect(mapStateToProps, mapDispatchToProps)(OperatorCertificationExpirySettings);

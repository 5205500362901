import React, { useState } from "react";
import UnauthorizedUsageReportTable from "../../../../../components/DataTableComponent/DataTable.component";
import { toDownloadFiles } from "../../../../../redux/config/action";
import awsConstant from "../../../../../constant/awsFolder.json";
import PopoverCustom from "../../../../../components/PopoverCustom.component";
import { useTranslation, Trans } from "react-i18next";
import ReportDownload from "../../../Report/ReportShare/ReportShareModal.component";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment-timezone";
import { getTimeWithTimeZone } from "../../../../../utils/timezoneConvert";

import { tableHeader } from "./constants";
import { NoReportsDiv } from "./UnauthorizedDashboard.styles";

const UnauthorizedUsageReport = ({
  data,
  loginDetails,
  isSiteAdmin,
  toDownloadFiles,
  fileDownloadLoading,
  isSingleAccountMultiSiteUser,
  setIsSuccess,
  setIsError,
}) => {
  const [loadingReportId, setLoadingReportId] = useState("");
  const { t } = useTranslation();
  const downloadReportApiCall = (id) => {
    setLoadingReportId(id);
    toDownloadFiles(awsConstant.unauthorizedReport, `${id}.pdf`);
  };

  const viewReportClickHandler = (e, row) => {
    localStorage.setItem("unauthorizedReportObject", JSON.stringify(row));
    window.open("/#/app/unauthorized-access-report", "_blank");
  };

  const userData = {
    token: loginDetails?.token,
    sharedByUserName: loginDetails?.userData?.fullName,
  };

  const ActionCell = ({ row }) => {
    return (
      <>
        {fileDownloadLoading && row._id === loadingReportId ? (
          <div className="spinner-border spinner-border-sm" role="status"></div>
        ) : (
          <div className="action-div">
            <i title="View" onClick={(e) => viewReportClickHandler(e, row)} className="icon icon-view"></i>
            <i
              title="Download"
              onClick={() => downloadReportApiCall(row._id)}
              className="icon icon-download"
            ></i>

            <i title="Share">
              <span style={{ display: "block" }}>
                <PopoverCustom
                  children={
                    <ReportDownload
                      userData={userData}
                      reportDetails={{
                        accountId: data?.accountId,
                        accountName: data?.accountId?.companyName,
                        reportType: 3,
                        reportId: data?._id,
                      }}
                      setIsSuccess={setIsSuccess}
                      setIsError={setIsError}
                    />
                  }
                />
              </span>
            </i>
          </div>
        )}
      </>
    );
  };

  const columns = tableHeader
    .filter((data) => {
      if (
        (!isSingleAccountMultiSiteUser && data.name === "Account") ||
        (isSiteAdmin && data.name === "Worksite")
      ) {
        return false;
      }
      return true;
    })

    .map((data) => {
      if (data.name == "Account") {
        return {
          name: t(data.name),
          selector: (row) => row?.accountName || "NA",
          sortable: true,
        };
      }
      if (data.name == "Worksite") {
        return {
          name: t(data.name),
          selector: (row) => row?.siteName || "NA",
          sortable: true,
        };
      }

      if (data.name == "Asset Type") {
        return {
          name: t(data.name),
          selector: (row) => row?.assetType || "NA",
          sortable: true,
        };
      }

      if (data.name == "Asset Name") {
        return {
          name: t(data.name),
          selector: (row) => row?.assetName || "NA",
          sortable: true,
        };
      }

      if (data.name === "Actions") {
        return {
          name: t(data.name),
          centered: true,
          selector: (row) => <ActionCell row={row} />,
          width: 120,
        };
      }

      if (data.name === "Date, Time") {
        return {
          name: t(data.name),
          selector: (row) => {
            const isStatus = row.hasOwnProperty("status");
            const isDate = row.hasOwnProperty("impactRecordedAt") && typeof row.impactRecordedAt === "string";
            const feildName = "impactRecordedAt";

            if (!isStatus && !isDate) {
              return row[feildName] || row.email || "NA";
            } else if (isDate) {
              return `${moment(row[feildName])
                .utcOffset(row?.timeZone?.split?.(" ")[0])
                .format("MMM-DD-YYYY, hh:mm A")} ${getTimeWithTimeZone(row?.timeZone)}`;
            } else {
              return "NA";
            }
          },
          sortable: true,
        };
      }

      return {
        name: data.name,
        selector: () => data.feildName,
        sortable: true,
      };
    });

  // columns.unshift(...columnsWithCheckboxes);

  const mobileCheckboxDateTime = [
    { columnName: "Worksite", className: "worksiteStyle" },
    { columnName: "Asset Type", className: "assetTyleStyle" },
    { columnName: "Asset Name", className: "assetNameStyle" },
    { columnName: "Incident By", className: "inspectedByStyle" },
    { columnName: "Report Status", className: "reportStatusStyle" },
  ];

  const mobileCheckboxAccordion = ["Worksite", "Asset Type", "Asset Name", "Incident By"];

  if (!Array.isArray(data)) {
    return (
      <NoReportsDiv>
        <Trans>No Unauthorized Access Reports Available</Trans>
      </NoReportsDiv>
    );
  }

  return (
    <>
      <UnauthorizedUsageReportTable
        title=""
        columns={columns}
        data={data}
        mobileCheckboxDateTime={mobileCheckboxDateTime}
        mobileCheckboxAccordion={mobileCheckboxAccordion}
        responsive
        dense
        loading
        rowHeight={"565"}
        scrollable={true}
        fixedHeader
        mobileCheckboxOnly
        mobileDateTimeOnly
        mobileActionItemOnly
        noDataMessage={t("No Unauthorized Access Reports Available")}
        rowsPerPageOptions={[5]}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
  isSuperAdmin: state.user.isSuperAdmin,
  isSiteAdmin: state.user.isSiteAdmin,
  unauthorizedReportList: state.reports.unauthorizedReportList,
  getUnauthorizedReportLoading: state.reports.getUnauthorizedReportLoading,
  totalUnauthorizedReportCount: state.reports.totalUnauthorizedReportCount,
  getZipFileImpactReportLoading: state.reports.getZipFileImpactReportLoading,
  dropdownWorksiteList: state.dropdownFilters.worksiteList,
  dropdownAccountList: state.dropdownFilters.accountList,
  dropdownAssetList: state.dropdownFilters.assetList,
  dropdownAssetTypeList: state.dropdownFilters.assetTypeList,
  unauthorizedAccessFilter: state.filters.unauthorizedAccessFilter,
  dropdownFilterAccountId: state.dropdownFilters.accountId,
  dropdownFilterWorksiteId: state.dropdownFilters.worksiteId,
  fileDownloadLoading: state.config.fileDownloadLoading,
  isSingleAccountMultiSiteUser: state.user.isSingleAccountMultiSiteUser,
});

const mapDispatchToProps = (dispatch) => ({
  toDownloadFiles: (folderName, fileName) => dispatch(toDownloadFiles(folderName, fileName)),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UnauthorizedUsageReport));

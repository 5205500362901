import React from "react";
import SearchBox from "../../../../components/SearchBox/Searchbox.component";

const SearchBoxComponent = ({ searchText, setSearchText, getAssetTypeBySearchText, placeholder }) => {
  return (
    <SearchBox
      label={"Your User Types"}
      placeholder={"Search a user type"}
      searchText={searchText}
      setSearchText={setSearchText}
    />
  );
};

export default SearchBoxComponent;

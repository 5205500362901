import styled from "styled-components";

export const UserTypeListingDiv = styled.div`
  .tableListSec thead {
    display: none;
  }
  .tableHeight {
    height: calc(100vh - 260px);
  }

  tr.tableTr p {
    width: 80%;
  }
`;

export const ListUserTypeDiv = styled.div`
  clear: both;
  width: 100%;
  float: left;
  border-bottom: 1px solid #f2f2f2;
  padding: 10px;
`;

export const DotIcon = styled.i`
  float: left;
  position: relative;
  font-size: 14px !important;
  top: 4px;
  margin-right: 10px;
  color: ${({ theme }) => theme.colors.greenPrimary || "#11A70B"};
`;

export const UserTypeName = styled.div`
  font-family: "Open Sans";
  font-size: 16px !important;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  text-align: left;
`;

import React from "react";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation, Trans } from "react-i18next";
import DataTableInspection from "../../../../../components/DataTableComponent/DataTable.component";
import { tableHeader, rowPerPageDropdown } from "../constants";
import { StyledSpan, StatusDiv, StatusSpan } from "./TableWrapper.component.styles";

const statusMap = {
  ns: { statusText: "Pending", className: "status-pending" },
  ip: { statusText: "In Progress", className: "status-in-progress" },
  f: { statusText: "Failed", className: "status-failed" },
  s: { statusText: "Completed", className: "status-completed" },
};

const requestTypeMapping = {
  s3logscsv: "S3 Error Logs",
};

const DownloadStatusTable = ({
  isSuperAdmin,
  isSiteAdmin,
  handlePageLength,
  previousPage,
  nextPage,
  pageSize,
  pageNo,
  rowHeight,
  data,
  totalCount,
}) => {
  const { t } = useTranslation();
  const timeZoneString = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const timeZoneShort = moment.tz(timeZoneString).format("z");
  const columns = tableHeader
    .filter((data) => {
      if ((!isSuperAdmin && data.name === "Account") || (isSiteAdmin && data.name === "Worksite")) {
        return false;
      }
      return true;
    })

    .map((data) => {
      if (data.name === "Account") {
        return {
          name: t(data.name),
          selector: (row) => row?.accountId?.companyName || "NA",
          sortable: true,
        };
      }
      if (data.name === "Request Type") {
        return {
          name: t(data.name),
          selector: (row) => (row?.reqType ? requestTypeMapping[row?.reqType] || row?.reqType : "NA"),
          sortable: true,
        };
      }

      if (data.name === "Request Data Range") {
        return {
          name: t(data.name),
          selector: (row) => {
            const jsonData = JSON.parse(row?.meta?.query || null);
            const startDate = jsonData?.timeRecordedAt?.$gte?.$date
              ? moment(jsonData?.timeRecordedAt?.$gte?.$date)?.format("MM/DD/YYYY")
              : null;
            const endDate = jsonData?.timeRecordedAt?.$lt?.$date
              ? moment(jsonData?.timeRecordedAt?.$lt?.$date)?.format("MM/DD/YYYY")
              : null;

            return startDate && endDate ? `${startDate} - ${endDate}` : "NA";
          },
          sortable: true,
        };
      }
      if (data.name === "Requested By") {
        return {
          name: t(data.name),
          selector: (row) => row?.createdBy?.fullName || "NA",
          sortable: true,
        };
      }
      if (data.name === "Requested At") {
        return {
          name: t(data.name),
          selector: (row) =>
            row?.createdAt
              ? moment.utc(row?.createdAt).local().format("MM/DD/YYYY, hh:mm A ") + timeZoneShort
              : "-",
          sortable: true,
        };
      }

      if (data.name === "Completed At") {
        return {
          name: t(data.name),
          selector: (row) =>
            row?.finishedAt
              ? moment.utc(row?.finishedAt).local().format("MM/DD/YYYY, hh:mm A ") + timeZoneShort
              : "-",
          sortable: true,
        };
      }

      if (data.name === "Status") {
        return {
          name: t(data.name),
          selector: (row) => {
            const statusInfo = statusMap[row?.status] || { statusText: "-", className: "status-default" };
            return (
              <StatusDiv>
                <StatusSpan title={statusInfo.statusText} className={statusInfo.className}>
                  {statusInfo.statusText}
                </StatusSpan>
              </StatusDiv>
            );
          },
          sortable: true,
        };
      }

      return {
        name: data.name,
        selector: () => data.feildName,
        sortable: true,
      };
    });

  if (!Array.isArray(data)) {
    return (
      <StyledSpan height={rowHeight}>
        <Trans>No_Download_Status_available</Trans>
      </StyledSpan>
    );
  }

  return (
    <>
      <DataTableInspection
        title=""
        columns={columns}
        data={data}
        pagination
        responsive
        dense
        rowsPerPageOptions={[10, 25, 50, 100]}
        loading
        rowHeight={rowHeight || "370"}
        scrollable={true}
        fixedHeader
        noDataMessage={t("No_Download_Status_available")}
        pageSize={pageSize}
        pageNo={pageNo}
        handlePageLength={handlePageLength}
        paginationOptions={rowPerPageDropdown}
        previousPage={previousPage}
        nextPage={nextPage}
        totalPageReport={totalCount}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  isSuperAdmin: state.user.isSuperAdmin,
  isSiteAdmin: state.user.isSiteAdmin,
});

const mapDispatchToProps = (dispatch) => ({});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DownloadStatusTable));

import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import AccountList from "./AccountList.component";
import Account from "./Account.component";
import AccountActionBar from "./AccountsActionBar.component";
import SearchBox from "../../../components/SearchBox/Searchbox.component";

const AccountManagement = () => {
  const [SearchText, setSearchText] = useState("");
  const [imageLoaded, setImageLoaded] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <div>
        <AccountActionBar />
      </div>

      <Row style={{ paddingTop: "10px" }} className="AccountPageDes">
        <Col lg={3}>
          <SearchBox
            searchText={SearchText}
            setSearchText={setSearchText}
            placeholder={t("Search an account")}
            label={t("Your Accounts")}
            onChange={(e) => setSearchText(e.target.value)}
          />

          <AccountList
            setImageLoaded={() => setImageLoaded(false)}
            searchText={SearchText}
            style={{ boxShadow: "rgba(0, 0, 0, 0.5) 0px 8px 8px -6px" }}
          />
        </Col>
        <Col lg={9}>
          <Account imageLoaded={imageLoaded} setImageLoaded={(data) => setImageLoaded(data)} />
        </Col>
      </Row>
    </>
  );
};

export default AccountManagement;

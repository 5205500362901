import deviceInfoTypes from "./deviceInfo.types";
import { apiCall } from "../../utils/apiCall";

export const setSelectedAccountId = (data) => ({
  type: deviceInfoTypes.SET_SELECTED_ACCOUNT_ID,
  data,
});

export const setSelectedWorksiteId = (data) => ({
  type: deviceInfoTypes.SET_SELECTED_WORKSITE_ID,
  data,
});

export const setSelectedSieraProductId = (data) => ({
  type: deviceInfoTypes.SET_SELECTED_SIERA_PRODUCT_ID,
  data,
});

export const setSelectedAssetTypeId = (data) => ({
  type: deviceInfoTypes.SET_SELECTED_ASSET_TYPE_ID,
  data,
});

export const getDeviceInformationSuccess = (data) => ({
  type: deviceInfoTypes.GET_DEVICE_INFORMATION_SUCCESS,
  data,
});

export const getDeviceInformationError = (data) => ({
  type: deviceInfoTypes.GET_DEVICE_INFORMATION_ERROR,
  data,
});

export const setPageSize = (data) => ({
  type: deviceInfoTypes.SET_PAGE_SIZE,
  data,
});

export const setPageNo = (data) => ({
  type: deviceInfoTypes.SET_PAGE_NO,
  data,
});

export const setDataLoading = () => ({
  type: deviceInfoTypes.SET_DATA_LOADING,
});

export const resetDataLoading = () => ({
  type: deviceInfoTypes.RESET_DATA_LOADING,
});

export const resetData = () => ({
  type: deviceInfoTypes.RESET_DEVICE_DATA,
});

export const resetDataToInitialState = () => ({
  type: deviceInfoTypes.RESET_DEVICE_DATA_TO_INITIAL_STATE,
});

export const setSearchText = (data) => ({
  type: deviceInfoTypes.SET_SEARCH_TEXT,
  data,
});

export const getDeviceInformation = (searchText) => {
  return async (dispatch, getState) => {
    const state = getState();
    const {
      selectedAccountId,
      selectedWorksiteId,
      selectedSieraProductId,
      selectedAssetTypeId,
      pageSize,
      pageNo,
      searchText,
    } = state?.deviceInfo;

    dispatch(setDataLoading());
    const data = {
      productType: selectedSieraProductId || "all",
    };

    const actionURL = `/asset/getDeviceInformation/${selectedAccountId}/${selectedWorksiteId}/${selectedAssetTypeId}/all/${pageNo}/${pageSize}/${
      searchText || "all"
    }`;

    //const actionURL = "/asset/getDeviceInformation/61797e88c861cd127da8c985/all/617986e3c861cd127da8ca06/all/all/all/all";

    apiCall(actionURL, { method: "POST", data })
      .then((resp) => {
        dispatch(resetDataLoading());
        dispatch(getDeviceInformationSuccess({ deviceList: resp.data.data, totalCount: resp.data.count }));
      })
      .catch((err) => {
        dispatch(resetDataLoading());
        console.log("Errr Occurred--------------------", err);
      });
  };
};

export const setGPSDataLoading = () => ({
  type: deviceInfoTypes.SET_GPS_DATA_LOADING,
});

export const resetGPSDataLoading = () => ({
  type: deviceInfoTypes.RESET_GPS_DATA_LOADING,
});

export const getGPSDataSuccess = (data) => ({
  type: deviceInfoTypes.GET_GPS_DATA_SUCCESS,
  data,
});

export const setGPSLocationDates = (data) => ({
  type: deviceInfoTypes.SET_GPS_LOCATION_DATES,
  data,
});

export const getGPSTrackingInfo = (assetId, startDateUTC, endDateUTC) => {
  return async (dispatch, getState) => {
    dispatch(setGPSDataLoading());
    const actionURL = `/locationData/getLocationData?assetId=${assetId}&startDate=${startDateUTC}&endDate=${endDateUTC}`;

    apiCall(actionURL)
      .then((resp) => {
        dispatch(resetGPSDataLoading());
        dispatch(getGPSDataSuccess(resp.data.data));
        dispatch(
          setGPSLocationDates({
            startDateUTC,
            endDateUTC,
          })
        );
      })
      .catch((err) => {
        dispatch(resetGPSDataLoading());
        console.log("Errr Occurred--------------------", err);
      });
  };
};

import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Input from '../../../../../../components/Input';

const UserTypeSelection = ({ isSuperAdmin, selectedAccessLevel, handleAccessLevelChange, dropdownAccountList, dropdownAccountId, handleAccountChange }) => {
  return (
    <Row style={{ marginBottom: "12px" }}>
      <Col md={4} style={{ textAlign: "left" }}>
        <label
          htmlFor="Account"
          style={{
            fontSize: "12px",
            color: "rgb(154, 154, 154)",
            fontFamily: "'Open Sans', sans-serif",
            marginBottom: "1px",
          }}
        >
          User Type Access Level
        </label>
        <Input
          options={[
            {
              _id: "ALL_ACCOUNTS",
              label: "All Accounts",
            },
            {
              _id: "SINGLE_ACCOUNT",
              label: "Single Account",
            },
          ]}
          isValidationError={false}
          type="select"
          as="select"
          label="Account"
          value={selectedAccessLevel}
          onChange={(value) => handleAccessLevelChange(value)}
        />
      </Col>

      <Col md={4} style={{ textAlign: "left" }}>
        <label
          htmlFor="Account"
          style={{
            fontSize: "12px",
            color: "rgb(154, 154, 154)",
            fontFamily: "'Open Sans', sans-serif",
            marginBottom: "1px",
          }}
        >
          Account Name
        </label>
        <Input
          options={dropdownAccountList?.map((data) => ({
            _id: data._id,
            label: data.companyName,
          }))}
          isValidationError={false}
          type="select"
          as="select"
          label="Account"
          value={dropdownAccountId}
          onChange={(accId) => handleAccountChange(accId)}
          disabled={isSuperAdmin && selectedAccessLevel !== "SINGLE_ACCOUNT"}
        />
      </Col>
    </Row>
  );
};

export default UserTypeSelection;

import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { Form, Modal } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

import { apiCall } from "../../../utils/apiCall";
import { isInvalidUrl } from "../../../utils/validations/checkValidation";
import timeZone from "../../../constant/timeZone";
import { updateUser } from "../../../redux/user/user.action";
import { updateActiveNavItem } from "../../../redux/sideMenu/menu.action";
import config from "../../../config";
import { ReactComponent as ProfileImage } from "../../../assets/image.svg";
import units from "../../../constant/units";
import useAlert from "../../../utils/useAlert";
import toasterConstant from "../../../constant/messages/toaster.json";
import {
  AccountProfileContainer,
  InputProfileDiv,
  InputProfileDivCont,
  InputWrapper,
  SettingsWrapper,
  Container,
  UrlText,
  AvailableText,
  NotAvailableText,
  Divider,
  ContainerImage,
  LabelContainer,
  CompanyLogo,
  ChangeImage,
  LeftLabel,
  SpanTop,
  SpanBottom,
  DivScroller,
  ButtonDiv,
  ButtonWrapper,
  ModalBodyHeight,
  AvatarImage,
  StyledProfileImage,
} from "./AccountProfile.Styles";
import Button from "../../../components/Button/Button.component";
import SettingsHeader from "../Profile/generalSetting/GeneralSettingHeader/GeneralSettingHeader.component";
import GeneralInputField from "../Profile/generalSetting/GeneralComponent/GeneralInput.component";
import GeneralDropdownField from "../Profile/generalSetting/GeneralComponent/GeneralDropdown.component";
import ModalComponent from "../../../components/ModalPopupComponent/ModalPopup.component";

const { account_profile_update, account_update_error } = toasterConstant;
const s3BucketLogo = config.s3BucketLogo;
// Styled components

function AccountProfile(props) {
  const { account, updateUser, updateActiveNavItem } = props;
  const inputRef = useRef();
  const accountNameInputRef = useRef(null);
  const { showAlert } = useAlert();
  const { t } = useTranslation();

  const [form, setForm] = useState({
    companyName: "",
    ownerName: "",
    address: "",
    enterpriseUrl: "",
    timeZone: "",
    unitType: "",
    logo: "",
  });

  const [formErrors, setFormErrors] = useState({
    companyNameError: false,
    unitTypeError: false,
    timeZoneError: false,
    enterpriseUrlError: false,
  });

  const [editMode, setEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isUrlAvailable, setIsUrlAvailable] = useState(true);

  const [modalOpen, setModalOpen] = useState(false);
  const [crop, setCrop] = useState({
    unit: "%",
    width: 100,
    height: 100,
  });
  const [croppedImage, setCroppedImage] = useState(null);
  const [imageSrc, setImageSrc] = useState(null);
  const [previousImage, setPreviousImage] = useState(null); // State to store previous image URL
  const imageRef = useRef(null);

  useEffect(() => {
    if (account) {
      setForm({
        companyName: account?.companyName,
        ownerName: account?.userData?.fullName,
        address: account?.address,
        enterpriseUrl: account?.enterpriseUrl,
        timeZone: account?.timeZone,
        logo: account?.form?.logo,
        ...account,
      });
    }
    if (editMode && accountNameInputRef.current) {
      accountNameInputRef.current.focus();
    }
  }, [account, editMode]);

  const isFormValid = (e) => {
    const { companyName, unitType, timeZone, enterpriseUrl } = form;
    let isValid = true;
    if (account?.companyName !== "" && !companyName) {
      handleFormError("companyName");

      return false;
    }
    if (!unitType) {
      handleFormError("unitType");
      isValid = false;
    }
    if (!timeZone) {
      handleFormError("timeZone");
      isValid = false;
    }
    if (enterpriseUrl === "") {
      isValid = true;
    } else if (!isUrlAvailable) {
      isValid = false;
    }
    return isValid;
  };

  const handleFormError = (fieldName) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [`${fieldName}Error`]: fieldName === "companyName" ? (!account?.companyName ? true : false) : true,
    }));
  };

  const submit = async (e) => {
    e.preventDefault();
    if (isFormValid()) {
      setIsLoading(true);
      setUrlAvailability(form.enterpriseUrl);
      if (await checkUrlAvailability(form.enterpriseUrl)) {
        let formData = new FormData();
        formData.append(
          "accountData",
          JSON.stringify({
            companyName: form.companyName,
            ownerName: form.ownerName,
            address: form.address,
            enterpriseUrl: form.enterpriseUrl,
            timeZone: form.timeZone,
            unitType: form.unitType,
          })
        );
        if (croppedImage) {
          const response = await fetch(croppedImage);
          const blob = await response.blob();
          formData.append("logo", blob, "croppedImage.jpeg");
        }
        apiCall(`/account/${account.accountId}`, {
          method: "PUT",
          data: formData,
          type: "file",
        })
          .then((data) => {
            localStorage.setItem("unitType", data?.data?.data?.unitType);
            if (data?.data) {
              updateUser({
                ...data?.data?.data,
              });
            }
            showAlert("success", t(account_profile_update));
            setIsLoading(false);
            setEditMode(false);
          })
          .then(() => {
            // Trigger Redux state update
            updateActiveNavItem("accountProfile");
          })
          .catch((err) => {
            showAlert("error", t(account_update_error));
            setIsLoading(false);
          });
      } else {
        setIsLoading(false);
      }
    }
  };

  const handleDropdownChange = (value, name) => {
    if (name === "enterpriseUrl") {
      checkUrlAvailability(value);
    }
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleInputChange = (e) => {
    const { name, value, required } = e.target;

    if (name === "enterpriseUrl") {
      if (isInvalidUrl(value)) return;

      checkUrlAvailability(encodeURI(value.trim()));
      setForm((prevState) => ({
        ...prevState,
        [name]: encodeURI(value.trim()),
      }));
    } else {
      setForm((prevState) => ({
        ...prevState,
        [name]: value,
      }));

      //debugger;
      if (required && value === "") {
        setFormErrors((prevState) => ({
          ...prevState,
          [`${name}Error`]: name === "companyName" ? (!account?.companyName ? false : true) : true,
        }));
      } else {
        setFormErrors((prevState) => ({
          ...prevState,
          [`${name}Error`]: false,
        }));
      }
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      setPreviousImage(URL.createObjectURL(file));

      const reader = new FileReader();
      reader.onload = () => {
        setImageSrc(reader.result);
        setCrop({
          unit: "%",
          x: 0,
          y: 0,
          width: 50,
          height: 50,
        });
        setModalOpen(true);
      };
      reader.readAsDataURL(file);
    }

    // Reset the input value to allow re-selection of the same file
    e.target.value = null;
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setSelectedFile(null);
    setCroppedImage(null);
    setImageSrc(null); // Reset image source
    setCrop({
      // Reset crop state
      unit: "%",
      x: 0,
      y: 0,
      width: 50,
      height: 50,
    });
  };

  useEffect(() => {
    if (!form.enterpriseUrl || form.enterpriseUrl === account.enterpriseUrl) {
      setIsUrlAvailable(true);
    } else {
      setUrlAvailability(form.enterpriseUrl);
    }
  }, [form.enterpriseUrl]);

  const setUrlAvailability = async (result) => setIsUrlAvailable(await checkUrlAvailability(result));

  const checkUrlAvailability = async (enterpriseUrl) => {
    if (enterpriseUrl === "") {
      return true;
    }
    let result = await apiCall(`/account/urlAvailabilty/${account.accountId}/${enterpriseUrl}`, {
      method: "GET",
      cancelPreviousRequest: true,
    });
    setIsUrlAvailable(result?.data?.success);
    return result?.data?.success;
  };

  useEffect(() => {
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      enterpriseUrlError: !isUrlAvailable,
    }));
  }, [isUrlAvailable]);

  useEffect(() => {
    apiCall(`/account/${account.accountId}`)
      .then((resp) => {
        updateUser({
          ...resp.data.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });

    updateActiveNavItem("accountProfile");
  }, [account.accountId, updateActiveNavItem, updateUser]);

  const onCropComplete = (crop) => {
    makeClientCrop(crop);
  };

  const makeClientCrop = async (crop) => {
    if (imageRef.current && crop.width && crop.height) {
      const croppedImageUrl = await getCroppedImg(imageRef.current, crop, "croppedImage.jpeg");
      setCroppedImage(croppedImageUrl);
    }
  };

  const getCroppedImg = (image, crop, fileName) => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    // Ensure the canvas background is transparent
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Draw the cropped image onto the canvas
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    // Return the cropped image as a PNG to preserve transparency
    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          reject(new Error("Canvas is empty"));
          return;
        }
        resolve(URL.createObjectURL(blob));
      }, "image/png"); // Set to PNG to preserve transparency
    });
  };

  return (
    <SettingsWrapper>
      <SettingsHeader title={t("ACCOUNT_PROFILE_HEADER")} />

      <Form validated={true} onSubmit={(e) => e.preventDefault()}>
        <AccountProfileContainer>
          <DivScroller>
            <InputProfileDiv>
              <InputProfileDivCont>
                <InputWrapper>
                  <GeneralInputField
                    lineDivider={true}
                    leftLabel={t("Account Name")}
                    rightLabel={t("Your company name")}
                    maxLength="128"
                    placeholder={t("Enter Account Name")}
                    onChange={handleInputChange}
                    value={form.companyName}
                    id="companyName"
                    name="companyName"
                    disabled={!editMode}
                    errorMessage={t("Account Name is required")}
                    isError={account?.companyName !== "" && formErrors.companyNameError}
                    accountNameInputRef={accountNameInputRef}
                    required={true}
                  />
                </InputWrapper>
              </InputProfileDivCont>

              <InputProfileDivCont>
                <InputWrapper>
                  <GeneralDropdownField
                    lineDivider={true}
                    leftLabel={t("Unit Type")}
                    rightLabel={t("Your preferred Unit system for your account")}
                    id="unitType"
                    placeholder={t("Enter Preferred Units")}
                    name="unitType"
                    onChange={(value) => handleDropdownChange(value, "unitType")}
                    value={form.unitType}
                    disabled={!editMode}
                    errorMessage={t("Preferred Units is required")}
                    options={units}
                    isError={form.unitType && formErrors.unitTypeError}
                  />
                </InputWrapper>
              </InputProfileDivCont>

              <InputProfileDivCont>
                <InputWrapper>
                  <GeneralDropdownField
                    lineDivider={true}
                    leftLabel={t("Timezone")}
                    rightLabel={t("The timezone of the main office")}
                    id="timeZone"
                    placeholder={t("Enter Time Zone")}
                    name="timeZone"
                    onChange={(value) => handleDropdownChange(value, "timeZone")}
                    value={form.timeZone}
                    disabled={!editMode}
                    errorMessage={t("Time Zone is required")}
                    options={timeZone}
                    isError={formErrors.timeZoneError}
                  />
                </InputWrapper>
              </InputProfileDivCont>

              <InputProfileDivCont>
                <InputWrapper marginTop="10px">
                  <GeneralInputField
                    leftLabel={t("Enterprise URL")}
                    rightLabel={t("Choose a personalized URL for your company")}
                    id="enterpriseUrl"
                    fullWidth
                    placeholder={t("Enter Enterprise URL")}
                    name="enterpriseUrl"
                    startAdornment="manage.siera.ai/#/"
                    onChange={handleInputChange}
                    value={form.enterpriseUrl}
                    disabled={!editMode}
                    isInvalid={form.enterpriseUrl && formErrors.enterpriseUrlError}
                    maxLength="128"
                  />
                </InputWrapper>
                {form.enterpriseUrl && (
                  <Container>
                    <UrlText>
                      <Trans>URL Availability</Trans>:
                      {isUrlAvailable ? (
                        <AvailableText>
                          <Trans>You are good to go</Trans>
                        </AvailableText>
                      ) : (
                        <NotAvailableText>
                          <Trans>Enterprise URL is not available</Trans>
                        </NotAvailableText>
                      )}
                    </UrlText>
                  </Container>
                )}
                <br></br>
                <Divider />
              </InputProfileDivCont>

              <ContainerImage>
                <LabelContainer>
                  <LeftLabel>
                    <SpanTop>
                      <Trans>Account Logo</Trans>
                    </SpanTop>
                    <SpanBottom>
                      <Trans>Your company's logo / emblem</Trans>
                    </SpanBottom>
                  </LeftLabel>
                </LabelContainer>
                <CompanyLogo>
                  {croppedImage ? (
                    <AvatarImage src={croppedImage} alt="Profile" />
                  ) : form.logo ? (
                    <AvatarImage src={s3BucketLogo + form.logo} alt="Profile" />
                  ) : (
                    <StyledProfileImage className="icon-svg" />
                  )}
                  {editMode && (
                    <ChangeImage className="change-image" onClick={() => inputRef?.current?.click?.()}>
                      <div>
                        <span>
                          <Trans>{croppedImage || form.logo ? t("Change image") : t("Add image")}</Trans>
                        </span>
                        <input type="file" ref={inputRef} accept="image/*" onChange={handleImageChange} />
                      </div>
                    </ChangeImage>
                  )}
                </CompanyLogo>

                <ModalComponent
                  size="lg"
                  centered
                  backdrop="static"
                  title={t("Crop Image")}
                  handleClose={handleModalClose}
                  show={modalOpen}
                  onHide={handleModalClose}
                >
                  <ModalBodyHeight>
                    <Modal.Body>
                      {imageSrc && (
                        <ReactCrop
                          src={imageSrc}
                          crop={crop}
                          onImageLoaded={(image) => (imageRef.current = image)}
                          onComplete={onCropComplete}
                          onChange={(newCrop) => setCrop(newCrop)}
                        />
                      )}
                    </Modal.Body>
                  </ModalBodyHeight>
                  <Modal.Footer>
                    <Button
                      label={t("Cancel")}
                      showIcon={false}
                      buttonType="ghost"
                      onClick={handleModalClose}
                    />
                    <Button
                      showIcon
                      iconClass="save"
                      buttonType="save"
                      label={t("Done")}
                      onClick={() => setModalOpen(false)}
                    />
                  </Modal.Footer>
                </ModalComponent>
              </ContainerImage>
            </InputProfileDiv>
          </DivScroller>

          {!editMode ? (
            <ButtonWrapper>
              <ButtonDiv>
                <Button
                  label={t("Edit")}
                  iconClass="edit"
                  onClick={() => setEditMode(true)}
                  showIcon
                  buttonType="secondary"
                />
              </ButtonDiv>
            </ButtonWrapper>
          ) : (
            <ButtonWrapper>
              <ButtonDiv style={{ marginRight: "15px" }}>
                <Button
                  label={t("Cancel")}
                  onClick={() => {
                    setSelectedFile(null);
                    setEditMode(false);
                    setFormErrors({
                      companyNameError: false,
                      unitTypeError: false,
                      timeZoneError: false,
                      enterpriseUrlError: false,
                    });
                  }}
                  showIcon={false}
                  buttonType="ghost"
                />
              </ButtonDiv>
              <ButtonDiv>
                <Button
                  label={t("Save")}
                  iconClass="save"
                  disabled={isLoading || (account?.companyName !== "" && formErrors.companyNameError)}
                  onClick={submit}
                  showIcon
                  buttonType="save"
                />
              </ButtonDiv>
            </ButtonWrapper>
          )}
        </AccountProfileContainer>
      </Form>
    </SettingsWrapper>
  );
}

const mapStateToProps = (state) => {
  return {
    account: state?.user?.loginDetails,
    token: state?.user?.loginDetails?.token,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateUser: (data) => dispatch(updateUser(data)),
  updateActiveNavItem: (item) => dispatch(updateActiveNavItem(item)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountProfile);

import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { resetBulkUploadAssetState } from "../../../../../redux/bulkUpload/action";
import AssetBulkUploadDownloadTemplate from "./AssetBulkUploadDownloadTemplate.component";
import AssetBulkUploadErrorHandle from "./AssetBulkUploadErrorHandle.component";
import AssetBulkUploadUploadFiles from "./AssetBulkUploadUploadFiles.component";
import bulkUploadStatusType from "./bulkUploadStatusTypes";

// Button Component
import Button from "../../../../../components/Button/Button.component";
import { AssetBulkUploadModalPopup } from "./AssetBulkUpload.component.styles";
function AssetBulkUpload(props) {
  let { resetBulkUploadAssetState, assetBulkUploadStatus } = props;

  const [activeSection, setActiveSection] = useState("downloadTemplate");
  const [uploadStatus, setUploadStatus] = useState("");

  const { t } = useTranslation();

  const localModalClose = () => {
    setActiveSection("downloadTemplate");
    resetBulkUploadAssetState();
    props && props.toogleModalClose();
  };

  useEffect(() => {
    resetBulkUploadAssetState();
    setUploadStatus("");
  }, []);

  useEffect(() => {
    if (assetBulkUploadStatus == "") {
      setUploadStatus("");
    }
    if (assetBulkUploadStatus?.success) {
      console.log("Inserted", assetBulkUploadStatus["Inserted Records"]);
      console.log("Total", assetBulkUploadStatus["Total Records"]);
      console.log("Failed Records", assetBulkUploadStatus["Failed Records"]);
      if (assetBulkUploadStatus["Inserted Records"] == assetBulkUploadStatus["Total Records"]) {
        setUploadStatus(bulkUploadStatusType.BULK_UPLOAD_SUCCESS);
      }
      if (
        assetBulkUploadStatus["Inserted Records"] != assetBulkUploadStatus["Total Records"] &&
        assetBulkUploadStatus["Inserted Records"] != 0
      ) {
        setUploadStatus(bulkUploadStatusType.BULK_UPLOAD_PARTIAL_ADDED);
      }
      if (assetBulkUploadStatus["Total Records"] == assetBulkUploadStatus["Failed Records"]) {
        setUploadStatus(bulkUploadStatusType.BULK_UPLOAD_PARTIAL_ADDED);
      }
    } else {
      if (assetBulkUploadStatus["message"] == "Column mismatch") {
        setUploadStatus(bulkUploadStatusType.BULK_UPLOAD_DATA_MISMATCH);
      }
      if (assetBulkUploadStatus["message"] == "No Records") {
        setUploadStatus(bulkUploadStatusType.BULK_UPLOAD_EMPTY_FILE);
      }
    }
  }, [assetBulkUploadStatus]);

  // handle API error for bulk upload when API fails i.e. useEffect("assetBulkUploadFileError")

  return (
    <>
      <Modal
        className="modal_bulk_upload"
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        {uploadStatus ? (
          <AssetBulkUploadErrorHandle
            type={uploadStatus}
            data={""}
            setActiveSection={setActiveSection}
            localModalClose={localModalClose}
          />
        ) : (
          <AssetBulkUploadModalPopup>
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
                <Trans>Asset Bulk Upload</Trans>
              </Modal.Title>

              <Button
                label={t("Cancel")}
                onClick={() => {
                  localModalClose();
                }}
                showIcon={false}
                buttonType="ghost"
              />
            </Modal.Header>
            <Modal.Body className="bulk_upload_body">
              <div className="button_head_bulk" style={{ marginBottom: "10px" }}>
                <div className="button_bulk">
                  <Button
                    label={t("Download Template")}
                    className={activeSection === "downloadTemplate" ? "ActiveBulkButton" : "buttonUnsel"}
                    onClick={() => setActiveSection("downloadTemplate")}
                    buttonType="primary"
                  ></Button>
                </div>
                <div className="button_bulk">
                  <Button
                    label={t("Upload Files")}
                    className={activeSection === "uploadFiles" ? "ActiveBulkButton" : "buttonUnsel"}
                    onClick={() => setActiveSection("uploadFiles")}
                    buttonType="primary"
                  ></Button>
                </div>
              </div>
              {activeSection === "downloadTemplate" && <AssetBulkUploadDownloadTemplate />}
              {activeSection === "uploadFiles" && <AssetBulkUploadUploadFiles />}
            </Modal.Body>
          </AssetBulkUploadModalPopup>
        )}
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => ({
  assetBulkUploadStatus: state.bulkUpload.assetBulkUploadFileUploadStatus,
});

const mapDispatchToProps = (dispatch) => ({
  resetBulkUploadAssetState: () => dispatch(resetBulkUploadAssetState()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AssetBulkUpload));

import styled from "styled-components";

export const SearchTextHeighlight = styled.div`
  font-weight: bold;
  color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
  display: initial;
`;
export const TextStyle = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const AnalyticsPageWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  font-family: "Open Sans", sans-serif;
`;

export const MenuContainer = styled.div`
  flex: 1 1 0%;
  background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  width: 270px;
  display: flex;
  flex-direction: column;
`;

export const ContentContainer = styled.div`
  flex: 3 1 0%;
  overflow-y: auto;
  height: calc(100vh - 225px);
  padding-bottom: 300px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  .App {
    height: auto;
    border: 1px solid ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
    margin-bottom: 5px;
    border-radius: 4px;
    z-index: 0;
  }
`;
export const MenuDivStyle = styled.div`
  .activeMain {
    background: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
    font-weight: bold;
    box-shadow: rgba(0, 0, 0, 0.6) 0px 3px 6px -3px;
  }
`;

export const MenuListStylediv = styled.div`
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
  height: calc(100vh - 225px);
  overflow: auto;
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
  }

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"};
  }
`;

export const ActiveSubmenuStyle = styled.div``;
export const ActiveMainMenuStyle = styled.div``;

export const MainMenuDiv = styled.div`
  cursor: pointer;
  padding: 8px 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
  transition: transform 0.3s ease-in-out 0s;
  text-align: left;
  display: block;
  position: relative;
  span {
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
  }
`;

export const ManinMenuUl = styled.ul`
  list-style-type: none;
  width: 100%;
  padding: 0;
  margin-bottom: 0px;
  height: 100%;
  li .activeMain {
    background: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
    font-weight: bold;
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
    box-shadow: rgba(0, 0, 0, 0.6) 0px 3px 6px -3px;
  }
  li .active {
    background: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
    font-weight: bold;
    > ${MainMenuDiv} {
      background: green;
    }
  }
`;
export const SubmenuUl = styled.ul`
  list-style-type: none;
  width: 100%;
  padding: 0;
  margin-bottom: 0px;
  li.activeSub {
    font-weight: bold;
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
    background-color: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
  }
`;
export const SubmenuUlLi = styled.li`
  cursor: pointer;
  text-align: left;
  padding: 8px 30px 8px 45px !important;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  transition: transform 0.3s ease-in-out 0s;
  margin-bottom: 0px;
  &:hover {
    background-color: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
  }
`;

export const IconUpSide = styled.div`
  float: right;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  font-size: 16px !important;
  height: 20px;
  width: 20px;
  position: relative;
`;

export const IconDownSide = styled.div`
  float: right;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  font-size: 16px !important;
  height: 20px;
  width: 20px;
  position: relative;
`;

export const SearchContainer = styled.div`
  position: relative;
  margin-bottom: 10px;
  display: flex;
`;

export const ButtonDivStyle = styled.div`
  margin-right: 5px;
  button {
    i {
    }
  }
`;

export const SearchDiv = styled.div`
  width: 100%;
  > div:first-child {
    margin-top: 0px;
    height: 36px;
    input {
      width: 100%;
      opacity: 1;
    }
    input:placeholder {
      color: ${({ theme }) => theme.colors.grayL3 || "#676767"};
      font-weight: 400;
    }
    input:focus {
      color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
      font-weight: 500;
      > div:second-child {
      }
    }
    > div:nth-of-type(3) {
      background-color: transparent;
      z-index: 3;
    }
  }
`;

export const BlankMessageDiv = styled.div`
  font-family: "Open Sans", sans-serif;
  width: 100%;
  height: 100%;
  display: inline-grid;
  vertical-align: middle;
  text-align: center;
  align-items: center;
  align-content: center;
  p {
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 5px;
    font-size: 18px !important;
  }
`;

export const BlankMessageDivStyle = styled.div`
  font-family: "Open Sans", sans-serif;
  width: 100%;
  height: 150%;
  display: grid;
  position: absolute;
  text-align: center;
  -webkit-box-align: center;
  align-items: center;
  z-index: 1;
  p {
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 5px;
    font-size: 16px !important;
    color: ${({ theme }) => theme.colors.grayL3 || "#676767"};
  }
`;

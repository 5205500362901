import React from "react";
import { Trans, useTranslation } from "react-i18next";
import {
  UserTypeDetailsDiv,
  UserTypeDetailsTableDescription,
  RowDiv,
  Column,
  RowsContainer,
  RadioWrapper,
  MobileViewText,
  ColumnReorder,
} from "./styles/UserTypeDetailsTabView.styles";
import RadioButton from "../../../../../components/RadioButton/Radio.component";
import { tableHeader } from "../const/usertypeConstants";

const UserTypeDetailsTable = (props) => {
  const { customFields, userTypeDetailsTableCB, isDisabled, type } = props;
  const { t } = useTranslation();
  const changeHandler = (data, isOptional) => {
    customFields.forEach((field) => {
      const tempField = field;
      if (data.name === field.name) {
        if (isOptional === "Optional") {
          tempField.isRequired = false;
        } else {
          tempField.isRequired = true;
        }
        userTypeDetailsTableCB(customFields);
      }
    });
  };

  const getFieldTypeUIString = (type) => {
    switch (type) {
      case "PHONE":
        return "Phone";
      case "PIN":
        return "PIN";
      case "EMAILANDPASSWORD":
        return "Email & Password";
      default:
        return "";
    }
  };

  return (
    <UserTypeDetailsDiv>
      <UserTypeDetailsTableDescription>
        <RowDiv isHeader={true}>
          {tableHeader.map((data) => (
            <Column isHeader={true} textleft={data.textleft}>
              <Trans>{data.name}</Trans>
            </Column>
          ))}
        </RowDiv>

        <RowsContainer className="userTypeDetailsHeight">
          {customFields?.map((data) => (
            <RowDiv>
              <Column textleft={"left"} className="firstMobileCss">
                <Trans>{data.name}</Trans>
              </Column>
              <Column className="secondMobileCss" textleft={"left"}>
                <Trans>{getFieldTypeUIString(data.type)}</Trans>
              </Column>
              <ColumnReorder textleft={"left"}>
                <MobileViewText>Required</MobileViewText>
                <RadioWrapper>
                  <RadioButton
                    id={data.name + "Yes_" + type}
                    checked={data.isRequired}
                    label={t("Yes")}
                    onClick={() => {
                      changeHandler(data, "Yes");
                    }}
                    size="small"
                    disabled={data.isDisabled || isDisabled}
                  />
                  <RadioButton
                    id={data.name + "Optional_" + type}
                    checked={!data.isRequired}
                    label={t("Optional")}
                    onClick={() => {
                      changeHandler(data, "Optional");
                    }}
                    size="small"
                    disabled={data.isDisabled || isDisabled}
                  />
                </RadioWrapper>
              </ColumnReorder>
              <ColumnReorder textleft={"left"}>
                <Trans>{data.description}</Trans>
              </ColumnReorder>
            </RowDiv>
          ))}
        </RowsContainer>
      </UserTypeDetailsTableDescription>
    </UserTypeDetailsDiv>
  );
};

export default UserTypeDetailsTable;

import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";
// Redux
import { connect } from "react-redux";
import TemplateList from "./ChecklistTemplatesList.component";
import BreadCrumb from "../../../../components/BreadCrumb.component";
import ChecklistTemplatesOverview from "./ChecklistTemplatesOverview.complete";
import { updateActiveNavItem } from "../../../../redux/sideMenu/menu.action";
import { useTranslation } from "react-i18next";

// Button Component
import Button from "../../../../components/Button/Button.component";

function ChecklistList({ updateActiveNavItem }) {
  const { t } = useTranslation();

  useEffect(() => {
    updateActiveNavItem("checklistTemplates");
  }, []);

  return (
    <div>
      <BreadCrumb />

      <Row style={{ paddingTop: "20px" }} className="m-0 p-0">
        <div className="mr-2 pl-0 mt-2">
          <NavLink to="/app/checklistmanagement">
            <Button iconClass="icon-angle-left" label={t("Your Checklists")} showIcon buttonType="primary" />
          </NavLink>
        </div>
      </Row>

      <Row className="worksiteMainbox mt-2">
        <Col lg={3}>
          <TemplateList />
        </Col>
        <Col lg={9}>
          <ChecklistTemplatesOverview />
        </Col>
      </Row>
    </div>
  );
}

const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
});

const mapDispatchToProps = (dispatch) => ({
  updateActiveNavItem: (item) => dispatch(updateActiveNavItem(item)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChecklistList);

import styled from "styled-components";

export const UserPageWrapper = styled.div`
  display: block;
  /* grid-template-columns: 33% 33% 33%;*/
  height: calc(100vh - 575px);
  overflow-x: hidden;
  overflow-y: auto;
  margin-bottom: 10px;
  @media (max-width: 1100px) {
    height: auto;
    overflow: hidden;
  }
  overflow-x: hidden;
  overflow-y: auto;
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
  }

  ::-webkit-scrollbar {
    width: 6px;
    background-color: green !important;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"};
  }
`;
export const UserCol = styled.div`
  margin: 4px 0px;
  float: left;
  width: 31%;
  margin-right: 16px;
  .astr {
    display: none;
  }

  @media (max-width: 1100px) {
    width: 100%;
    margin: 5px 0px;
  }
`;
export const FullWidth = styled.div`
  display: flex;
  width: 100%;
`;

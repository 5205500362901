export const reportTypes = {
  GET_INSPECTION_REPORT_LIST_LOADING: "GET_INSPECTION_REPORT_LIST_LOADING",
  GET_INSPECTION_REPORT_LIST_SUCCESS: "GET_INSPECTION_REPORT_LIST_SUCCESS",
  GET_INSPECTION_REPORT_LIST_ERROR: "GET_INSPECTION_REPORT_LIST_ERROR",
  GET_ZIP_FILE_REPORT_LOADING: "GET_ZIP_FILE_REPORT_LOADING",
  GET_ZIP_FILE_REPORT_SUCCESS: "GET_ZIP_FILE_REPORT_SUCCESS",
  GET_IMPACT_REPORT_LIST_LOADING: "GET_IMPACT_REPORT_LIST_LOADING",
  GET_IMPACT_REPORT_LIST_SUCCESS: "GET_IMPACT_REPORT_LIST_SUCCESS",
  GET_UNAUTORIZED_USE_REPORT_LOADING: "GET_UNAUTORIZED_USE_REPORT_LOADING",
  GET_UNAUTORIZED_USE_REPORT_SUCCESS: "GET_UNAUTORIZED_USE_REPORT_SUCCESS",
  GET_IMPACT_REPORT_LIST_ERROR: "GET_IMPACT_REPORT_LIST_ERROR",
  GET_UNAUTHORIZED_LIST_LOADING: "GET_UNAUTHORIZED_LIST_LOADING",
  GET_UNAUTHORIZED_LIST_SUCCESS: "GET_UNAUTHORIZED_LIST_SUCCESS",
  GET_UNAUTHORIZED_LIST_ERROR: "GET_UNAUTHORIZED_LIST_ERROR",
  GET_ZIP_FILE_IMPACT_REPORT_LOADING: "GET_ZIP_FILE_IMPACT_REPORT_LOADING",
  GET_ZIP_FILE_IMPACT_REPORT_SUCCESS: "GET_ZIP_FILE_IMPACT_REPORT_SUCCESS",
  GET_LAST_HOUR_METER_LIST_LOADING: "GET_LAST_HOUR_METER_LIST_LOADING",
  GET_LAST_HOUR_METER_LIST_SUCCESS: "GET_LAST_HOUR_METER_LIST_SUCCESS",
  UPDATE_HOUR_METER_LIST_ITEM: "UPDATE_HOUR_METER_LIST_ITEM",
  EXPORT_ALL_INSPECTION_CSV_LOADING: "EXPORT_ALL_INSPECTION_CSV_LOADING",
  EXPORT_ALL_INSPECTION_CSV_SUCCESS: "EXPORT_ALL_INSPECTION_CSV_SUCCESS",
  EXPORT_ALL_INSPECTION_CSV_ERROR: "EXPORT_ALL_INSPECTION_CSV_ERROR",
  RESET_ALL_INSPECTION_CSV_STATE: "RESET_ALL_INSPECTION_CSV_STATE",
  EXPORT_ALL_IMPACT_CSV_LOADING: "EXPORT_ALL_IMPACT_CSV_LOADING",
  EXPORT_ALL_IMPACT_CSV_SUCCESS: "EXPORT_ALL_IMPACT_CSV_SUCCESS",
  EXPORT_ALL_IMPACT_CSV_ERROR: "EXPORT_ALL_IMPACT_CSV_ERROR",
  RESET_ALL_IMPACT_CSV_STATE: "RESET_ALL_IMPACT_CSV_STATE",
  EXPORT_ALL_IMPACT_CSV_RESET: "EXPORT_ALL_IMPACT_CSV_RESET",
  CLEAR_INSPECTION_REPORT_LIST_SUCCESS: "CLEAR_INSPECTION_REPORT_LIST_SUCCESS",
  CLEAR_IMPACT_REPORT_LIST_SUCCESS: "CLEAR_IMPACT_REPORT_LIST_SUCCESS",
  CLEAR_UNAUTHORIZED_REPORT_LIST_SUCCESS: "CLEAR_UNAUTHORIZED_REPORT_LIST_SUCCESS",
};

import styled from "styled-components";

export const ReportDivWrapp = styled.div``;
export const ReportPageWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  margin: 20px auto;
  margin-bottom: 20px;
  box-shadow: 0 0 0.5cm rgb(0 0 0 / 50%);
  min-height: 15in;
  padding: 1.5cm !important;
  position: relative;
  text-align: left;
  max-width: 1140px;
`;

export const ReportTopSection = styled.div`
  margin-bottom: 20px;
`;

export const PageNumber = styled.div`
  font-family: kanit;
  font-weight: 400;
  float: right;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
`;

export const SieraLogo = styled.img`
  height: 40px;
  margin-bottom: 10px;
`;

export const DetailHeading = styled.div`
  font-size: 2rem !important;
  font-family: kanit;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  span {
    display: block;
    font-size: 1.5rem !important;
  }
`;

export const EmailHelpText = styled.p`
  font-family: "Open Sans", sans-serif;
  margin: 0px;
  color: ${({ theme }) => theme.colors.grayL3 || "#676767"};
  font-weight: 600;
`;

export const Anchor = styled.a`
  color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
`;

export const ReportName = styled.div`
  margin-top: 24px;
  font-size: 4rem !important;
  font-family: kanit;
  font-weight: 400;
  margin-bottom: 16px;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  span {
    display: block;
    font-size: 1.5rem !important;
  }
`;

export const ReportDataSection = styled.div``;

export const ReprtHeaderTop = styled.div`
  font-size: 24px !important;
  padding: 1% 2%;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
  font-family: kanit;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
`;

export const ReportDataHeaderTop = styled.div`
  background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  font-size: 20px !important;
  padding: 1% 2%;
  font-family: "Open Sans", sans-serif;
  color: #707070;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  overflow: hidden;
`;

export const ReportStatus = styled.div`
  float: right;
`;

export const PassReport = styled.div`
  background-color: ${({ theme }) => theme.colors.greenPrimary || "#11A70B"};
  color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  outline: none;
  border: none;
  border-radius: 5px;
  font-size: 14px !important;
  padding: 5px 30px;
  font-weight: bold;
`;

export const FailReport = styled.div`
  background-color: ${({ theme }) => theme.colors.redPrimary || "#DD0000"};
  color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  outline: none;
  border: none;
  border-radius: 5px;
  font-size: 14px !important;
  padding: 5px 30px;
  font-weight: bold;
`;

export const TimedoutReport = styled.div`
  background-color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  outline: none;
  border: none;
  border-radius: 5px;
  font-size: 14px !important;
  padding: 5px 30px;
  font-weight: bold;
`;

export const ReportCenterData = styled.div`
  background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  font-size: 20px !important;
  padding: 1% 2%;
  font-family: "Open Sans", sans-serif;
  color: #707070;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  overflow: hidden;
  ${PassReport} {
    line-height: 30px;
    font-size: 18px !important;
  }
  ${FailReport} {
    line-height: 30px;
    font-size: 18px !important;
  }
`;
export const ReportRightSec = styled.div`
  float: right;
  line-height: 30px;
  font-size: 20px !important;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  font-weight: normal;
`;

export const VehicalDetailsData = styled.div``;

export const VehicleDatNA = styled.div`
  text-align: center;
  line-height: 50vh;
`;

export const ReportPageFooter = styled.div`
  position: absolute;
  bottom: 15px;
  left: 0;
  right: 0;
  text-align: center;
  color: ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"};
  font-family: kanit;
  font-weight: 300;
`;

export const ReportCenterDataTable = styled.div`
  overflow: hidden;
  margin-top: 5px;
`;

export const ReportTableView = styled.div`
  margin: 10px 0px;
`;

export const ReportTableViewHeader = styled.div`
  font-size: 24px !important;
  padding: 1% 2%;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
  font-family: kanit;
  font-weight: 500;
`;

export const SectionData = styled.div``;

export const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 24px !important;
  padding: 1% 2%;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
  font-family: kanit;
  font-weight: 500;
`;

export const SectionBody = styled.div``;

export const SectionName = styled.div`
  font-size: 24px !important;
`;

export const SectionTotalTime = styled.div`
  font-size: 20px !important;
`;

export const QuestionData = styled.div`
  display: flex;
  padding: 1% 2%;
  border-radius: 5px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
`;

export const WarningSign = styled.div`
  flex: 1;
`;

export const QuestionBody = styled.div`
  flex: 18;
  font-size: 18px !important;
  font-family: "Open Sans", sans-serif;
  align-items: center;
  display: inline-flex;
`;

export const QuestionResponse = styled.div`
  flex: 3;
  display: flex;
  justify-content: center;
  font-size: 18px !important;
  font-family: "Open Sans", sans-serif;
  align-items: center;
  display: inline-flex;
`;

export const QuestionResponseTime = styled.div`
  flex: 3;
  display: flex;
  justify-content: right;
  font-size: 18px !important;
  font-family: "Open Sans", sans-serif;
  align-items: center;
  display: inline-flex;
`;

export const ReportTableCenterSec = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  font-size: 20px !important;
  padding: 1% 2%;
  font-family: "Open Sans", sans-serif;
  color: ${({ theme }) => theme.colors.grayL3 || "#676767"};
`;

export const ReportTableNameLeft = styled.div`
  font-size: 18px !important;
  font-family: "Open Sans", sans-serif;
  flex: 1;
  align-items: center;
  display: inline-flex;
`;
export const ReportTableNameCenter = styled.div`
  text-align: center;
  font-size: 18px !important;
  font-family: "Open Sans", sans-serif;
  flex: 1;
  align-items: center;
  display: grid;
`;
export const ReportTableNameRight = styled.div`
  display: block;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
`;

/* Incident Report Impact Severity Status Button */
export const ImpactSeverityLow = styled.div`
  background-color: #11a70b;
  color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  outline: none;
  border: none;
  border-radius: 5px;
  font-size: 14px !important;
  padding: 5px 30px;
  font-weight: bold;
`;
export const ImpactSeverityMedium = styled.div`
  background-color: ${({ theme }) => theme.colors.orangePrimary || "#DD9000"};
  color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  outline: none;
  border: none;
  border-radius: 5px;
  font-size: 14px !important;
  padding: 5px 30px;
  font-weight: bold;
`;
export const ImpactSeverityHigh = styled.div`
  background-color: ${({ theme }) => theme.colors.redPrimary || "#DD0000"};
  color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  outline: none;
  border: none;
  border-radius: 5px;
  font-size: 14px !important;
  padding: 5px 30px;
  font-weight: bold;
`;

export const PageMoreDetails = styled.div`
  margin-top: 10px;
  position: absolute;
  bottom: 60px;
  svg {
    width: 16px;
    display: inline;
  }
  p {
    display: inline;
    margin-left: 10px;
    margin-right: 10px;
    font-family: "Open Sans", sans-serif;
    color: ${({ theme }) => theme.colors.grayL3 || "#676767"};
  }
`;

export const ReportCenterDataTableImg = styled.div`
  overflow: hidden;
  margin-top: 5px;
  img {
    width: 100%;
    height: fit-content;
  }
`;

export const UnauthorizedButton = styled.div`
  background-color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  outline: none;
  border: none;
  border-radius: 5px;
  font-size: 14px !important;
  padding: 5px 30px;
  font-weight: bold;
`;

export const PedestrianButtonStyle = styled.div`
  background-color: ${({ theme }) => theme.colors.redPrimary || "#DD0000"};
  color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  outline: none;
  border: none;
  border-radius: 5px;
  font-size: 14px !important;
  padding: 5px 30px;
  font-weight: bold;
`;

export const ObjectButtonStyle = styled.div`
  background-color: ${({ theme }) => theme.colors.orangePrimary || "#DD9000"};
  color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  outline: none;
  border: none;
  border-radius: 5px;
  font-size: 14px !important;
  padding: 5px 30px;
  font-weight: bold;
`;

export const PedestrianPositionButton = styled.div`
  text-transform: uppercase;
  background-color: ${({ theme }) => theme.colors.redPrimary || "#DD0000"};
  color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  outline: none;
  border: none;
  font-weight: bold;
  border-radius: 5px;
  font-size: 14px !important;
  padding: 5px 20px;
  display: inline-flex;
  margin-bottom: 10px;
  width: 180px;
  i {
    font-size: 1rem;
    margin-right: 10px;
  }
`;

export const ObjectPositionButton = styled.div`
  text-transform: uppercase;
  background-color: ${({ theme }) => theme.colors.orangePrimary || "#DD9000"};
  color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  outline: none;
  border: none;
  font-weight: bold;
  border-radius: 5px;
  font-size: 14px !important;
  padding: 5px 20px;
  display: inline-flex;
  margin-bottom: 10px;
  width: 180px;
  i {
    font-size: 1rem;
    margin-right: 10px;
  }
`;
export const ReportDetailsSection = styled.div``;

import styled from "styled-components";

export const RegisterContainer = styled.div``;

export const RegisterDiv = styled.div`
  div {
    margin-bottom: 5px;
  }
`;

export const ErrorContainer = styled.div`
  color: ${({ theme }) => theme.colors.redPrimary || "#DD0000"};
  position: relative;
  text-align: left;
  width: 100%;
  margin: 0 auto 17px;
  font-family: "Open Sans", sans-serif;
`;
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { Trans } from "react-i18next";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { Form } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

import styled from "styled-components";

import { ReactComponent as ViewShow } from "../../../assets/ViewShow.svg";
import { ReactComponent as Download } from "../../../assets/Download.svg";
import { getTimeWithTimeZone } from "../../../utils/timezoneConvert";
import { apiCall } from "../../../utils/apiCall";
import awsConstant from "../../../constant/awsFolder.json";
import Styles from "./Tabs/TabsStyles.module.css";
import { toDownloadFiles } from "../../../redux/config/action";

const FooterTableStyle = styled.div`
  .noSection_cD p {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  }
`;
const AssetOvweviewPage = styled.div`
  table.assetTablesz thead th {
    float: left;
    text-align: left;
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
    background-color: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"} !important;
  }
  table.assetTablesz thead {
    text-align: left;
    background: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"} !important;
  }
  table.assetTablesz tbody td {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  }
  table.assetTablesz tbody td {
    .cls-1 {
      fill: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} !important;
    }
  }
  button.btn-outline-Pass {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  }
`;

const stylesCss = {
  message: {
    color: "rgba(0,0,0,0.5)",
  },
};

function AssetOverviewInspectionReports({
  loginDetails,
  assetId,
  assetOverview,
  AssetLoading,
  toDownloadFiles,
}) {
  const [inspectionReport, setInspectionReport] = useState(null);
  const [IsLoading, setIsLoading] = useState(false);

  const loadInspectionReport = async () => {
    setInspectionReport([]);
    setIsLoading(true);
    try {
      const response = await apiCall(`/asset/inspectionreports/all/all/all/${assetId}/all/all/all/1/5/all`);
      if (response.data.success === true) {
        setIsLoading(false);
        setInspectionReport(response.data.data);
      } else {
        setIsLoading(false);
        setInspectionReport([]);
      }
    } catch (error) {
      console.log("Error occurred");
    }
  };

  useEffect(() => {
    setInspectionReport([]);
    if (assetOverview) {
      loadInspectionReport();
    }
  }, [assetOverview]);

  const viewReportClickHandler = (e, data) => {
    localStorage.setItem("inspectionReportObject", JSON.stringify(data));
    window.open("/#/app/inspection-report", "_blank");
  };

  const downloadReportApiCall = (id) => {
    toDownloadFiles(awsConstant.inspectionReport, `${id}.pdf`);
  };

  return (
    <AssetOvweviewPage>
      <div style={{ width: "100%" }}>
        {(IsLoading || AssetLoading) && (
          <div>
            <Skeleton className="m-2" count={6} width={"90%"} />
          </div>
        )}
        <div className={`table-responsive ${Styles.AssetInspectionTable}`}>
          {assetOverview &&
            !AssetLoading &&
            !IsLoading &&
            inspectionReport &&
            inspectionReport?.length > 0 && (
              <>
                <table className="table  assetTablesz assetOverReportT asset-inspect-table">
                  <thead>
                    <tr>
                      <th>
                        <Trans>Inspected By</Trans>
                      </th>
                      <th>
                        <Trans>Date, Time</Trans>
                      </th>
                      <th>
                        {" "}
                        <Trans>Result</Trans>
                      </th>
                      <th>
                        <Trans>Action</Trans>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {inspectionReport &&
                      inspectionReport.map((data) => (
                        <>
                          <tr>
                            <td className="filterable-cell">
                              {data.inspectorId && data.inspectorId.fullName}
                            </td>
                            <td className="filterable-cell">
                              {assetOverview &&
                                assetOverview.siteId &&
                                assetOverview.siteId.timeZone &&
                                moment(data?.inspectionAt)
                                  .utcOffset(data?.siteId?.timeZone?.split?.(" ")?.[0])
                                  .format("MMM-DD-YYYY hh:mm A")}{" "}
                              {getTimeWithTimeZone(data?.siteId?.timeZone)}
                            </td>
                            <td className="filterable-cell">
                              <Form.Group controlId="exampleForm.ControlInput1">
                                {data.status == "passed" && (
                                  <button
                                    className="btn PassFail btn-outline-Pass"
                                    style={{ cursor: "default" }}
                                  >
                                    Pass
                                  </button>
                                )}
                                {data.status == "failed" && (
                                  <button
                                    className="btn PassFail btn-outline-Fail"
                                    style={{ cursor: "default" }}
                                  >
                                    Fail
                                  </button>
                                )}
                                {data.status == "timedout" && (
                                  <button
                                    className="btn PassFail btn-outline-Timedout"
                                    style={{ cursor: "default" }}
                                  >
                                    Timed Out
                                  </button>
                                )}

                                {/* {data.status ? <ResultPass /> : <ResultFail />} */}
                              </Form.Group>
                            </td>
                            <td className="filterable-cell">
                              <span onClick={(e) => viewReportClickHandler(e, data)}>
                                <ViewShow
                                  style={{
                                    width: 20,
                                    marginRight: 10,
                                    cursor: "pointer",
                                  }}
                                  fill=""
                                />
                              </span>

                              <Download
                                onClick={() => downloadReportApiCall(data._id)}
                                style={{
                                  width: 20,
                                  marginRight: 10,
                                  cursor: "pointer",
                                }}
                                fill=""
                              />
                            </td>
                          </tr>
                        </>
                      ))}
                  </tbody>
                </table>
              </>
            )}
          {assetOverview &&
            !AssetLoading &&
            !IsLoading &&
            inspectionReport &&
            inspectionReport.length <= 0 && (
              <FooterTableStyle>
                <div className="noSection_cD mt-4">
                  <div align="center" style={stylesCss.message}>
                    <Trans>There is no report on this asset right now</Trans>
                  </div>
                  <div align="center" style={stylesCss.message}>
                    <Trans>Click 'INSPECT' to inspect an asset</Trans>
                  </div>
                </div>
              </FooterTableStyle>
            )}
        </div>
      </div>
    </AssetOvweviewPage>
  );
}

const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
  assetOverview: state.assets.AssetOverview,
  AssetLoading: state.assets.AssetLoading,
});

const mapDispatchToProps = (dispatch) => ({
  toDownloadFiles: (folderName, fileName) => dispatch(toDownloadFiles(folderName, fileName)),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AssetOverviewInspectionReports));

import React, { useEffect } from "react";
import { Switch, Route, useRouteMatch, Redirect, useHistory, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import BreadCrumb from "../../../../components/BreadCrumb.component";
import { updateActiveNavItem } from "../../../../redux/sideMenu/menu.action";
import { TabWrapper, Container, DropdownWrapper } from "./SystemSetting.component.styles";
import MenuBar from "./MenuBar.component";
import { getDropdownAccountList } from "../../../../redux/dropdownFilters/action";
import { setSettingsDropdownAccountId } from "../../../../redux/dropdownFilters/dropdownActions";
import Input from "../../../../components/Input";

import AccountProfile from "../../Settings/AccountProfile";
import MFAAuth from "../../Settings/MFAAuth";
import UserProfile from "../../Settings/UserProfile/UserProfile.component";
import ChangePassword from "../../Settings/ChangePassword";
import PasswordPolicy from "../../Settings/PaawordPolicy/PasswordPolicy.component";
import ChangePin from "../../Settings/ChangePin/ChangePin.component";

function GeneralSetting({
  updateActiveNavItem,
  isSiteAdmin,
  dropdownAccountList,
  loginDetails,
  isSuperAdmin,
  isAccountAdmin,
  isAccountOwner,
  dropdownAccountId,
  setSettingsDropdownAccountId,
  getDropdownAccountList,
}) {
  const match = useRouteMatch();
  const history = useHistory();

  useEffect(() => {
    updateActiveNavItem("systemSettings");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isAccountAdmin || isAccountOwner || isSiteAdmin) {
      setSettingsDropdownAccountId(loginDetails && loginDetails.accountId);
    }
  }, [loginDetails]);

  useEffect(() => {
    if (!dropdownAccountList || dropdownAccountList?.length === 0) {
      getDropdownAccountList();
    }
  }, []);

  return (
    <div className="settings">
      <BreadCrumb />
      <Container>
        <TabWrapper>
          <MenuBar history={history} />
        </TabWrapper>
        <Switch>
          {isAccountOwner && <Route path={`${match.url}/account-profile`} component={AccountProfile} />}
          <Route path={`${match.url}/mfa-authentication`} component={MFAAuth} />
          <Route path={`${match.url}/password-policy`} component={PasswordPolicy} />

          <Route path={`${match.url}/user-profile`} component={UserProfile} />
          <Route path={`${match.url}/password`} component={ChangePassword} />
          <Route path={`${match.url}/pin`} component={ChangePin} />

          {isAccountOwner ? (
            <Redirect to={`${match.url}/account-profile`} />
          ) : (
            <Redirect to={`${match.url}/user-profile`} />
          )}
        </Switch>
      </Container>
    </div>
  );
}

const mapStateToProps = (state) => ({
  isSiteAdmin: state.user.isSiteAdmin,
  loginDetails: state.user.loginDetails,
  isSuperAdmin: state.user.isSuperAdmin,
  isAccountAdmin: state.user.isAccountAdmin,
  isAccountOwner: state.user.isAccountOwner,
  dropdownAccountList: state.dropdownFilters?.accountList,
  dropdownAccountId: state.dropdownFilters?.settings?.accountId,
});

const mapDispatchToProps = (dispatch) => ({
  updateActiveNavItem: (item) => dispatch(updateActiveNavItem(item)),
  setSettingsDropdownAccountId: (accountId) => dispatch(setSettingsDropdownAccountId(accountId)),
  getDropdownAccountList: () => dispatch(getDropdownAccountList()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GeneralSetting));

import { Table } from "react-bootstrap";
import { connect } from "react-redux";

import { Trans, useTranslation } from "react-i18next";
import { tableHeader } from "./constants";
import { TableResponsive, Thead, Tbody, Tr, Th, Td, NoDataWrapper } from "./TableData.component.styles";
import Pagination from "./Pagination.component";
import { setPageNo, setPageSize } from "../../../redux/deviceInformation/deviceInfo.action";
import moment from "moment";

const TableData = (props) => {
  const {
    setShowDeviceDetails,
    setSelectedAsset,
    devciceInformationList = [],
    selectedAccountId,
    setPageNo,
    setPageSize,
    pageNo,
    pageSize,
    totalCount,
    isLoading,
  } = props;
  const { t } = useTranslation();

  const clickHandler = (selectedAssset) => {
    setSelectedAsset(selectedAssset);
    setShowDeviceDetails(true);
  };
  return (
    <TableResponsive className="table-responsive">
      <Table bordered>
        <Thead fixed>
          <Tr>
            {tableHeader.map((data, i) => (
              <Th scope="col" key={i}>
                <Trans>{data.name}</Trans>
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {isLoading ? (
            <Tr>
              <Td height={"calc(100vh - 314px)"} width={"100%"} colSpan={5}>
                <NoDataWrapper>
                  <span className="spinner-border test mt-4" role="status"></span>
                </NoDataWrapper>
              </Td>
            </Tr>
          ) : devciceInformationList?.length > 0 && selectedAccountId ? (
            devciceInformationList.map((data) => {
              const lastSeenTimeStamp = moment(data?.lastSeenAt).year() > 2001 ? data?.lastSeenAt : null;
              const lastSeen = lastSeenTimeStamp
                ? moment(lastSeenTimeStamp).startOf("minute").fromNow()
                : "NA";
              return (
                <Tr>
                  {/*  <Td>
                  <span title={selectedAccountId}>
                    {(accountListById &&
                      selectedAccountId &&
                      accountListById?.[selectedAccountId]?.companyName) ||
                      "Account"}
                  </span>
                </Td> */}

                  <Td data-label="Worksite">
                    <span title={data?.siteId?.name}>{data?.siteId?.name || "NA"}</span>
                  </Td>
                  <Td
                    data-label="Asset Name"
                    textColor="#0D5FBE"
                    boldFont="bold"
                    cursorFont="pointer"
                    onClick={() => clickHandler(data)}
                  >
                    <span title={data.assetName}>{data.assetName || "NA"}</span>
                  </Td>
                  <Td data-label="Asset Tag">
                    <span title={data?.lastDeviceInformation?.assetTag}>
                      {data?.lastDeviceInformation?.assetTag || "NA"}
                    </span>
                  </Td>
                  <Td data-label="SIERA.AI Products">
                    <span title={data.sieraProduct}>{data.productType || "NA"}</span>
                  </Td>
                  <Td data-label="Last Seen">
                    <span>{lastSeen || "NA"}</span>
                  </Td>
                </Tr>
              );
            })
          ) : (
            <Tr>
              <Td height={"calc(100vh - 314px)"} width={"100%"} colSpan={5}>
                <NoDataWrapper>
                  {selectedAccountId
                    ? t("NO_RESULT_FOR_THE_SEARCH")
                    : t("SELECT_ACCOUNT_FOR_DEVICE_INFORMATION")}
                </NoDataWrapper>
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
      {selectedAccountId !== "all" && (
        <Pagination
          totalCount={totalCount}
          pageNo={pageNo}
          pageSize={pageSize}
          setPageSize={setPageSize}
          setPageNo={setPageNo}
        />
      )}
    </TableResponsive>
  );
};

const mapStateToProps = (state) => ({
  selectedAccountId: state.deviceInfo.selectedAccountId,
  accountListById: state.dropdownFilters.accountListById,
  devciceInformationList: state.deviceInfo.data,
  pageNo: state.deviceInfo.pageNo,
  pageSize: state.deviceInfo.pageSize,
  totalCount: state.deviceInfo.totalCount,
  isLoading: state.deviceInfo.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  setPageNo: (data) => dispatch(setPageNo(data)),
  setPageSize: (data) => dispatch(setPageSize(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TableData);

import { assetManagementTypes } from "./types";
import moment from "moment";
import { initialState } from "./initialState";
import { getDateTimeInFormatExportData } from "../../utils/time/datetimehelper";

export const assetManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    // For Assets List
    case assetManagementTypes.GET_ALL_ASSETS_LOADING:
      return {
        ...state,
        AssetLoading: true,
        AssetSuccess: false,
        AssetError: false,
      };

    case assetManagementTypes.GET_ALL_ASSETS_SUCCESS:
      if (state.Assets?.length === action.data.count && !action.isResetState) {
        return {
          ...state,
          AssetLoading: false,
          AssetSuccess: true,
          AssetError: false,
        };
      }
      let newAssets =
        action.isResetState || action.pageNo === 1
          ? action.data.data
          : [...state.Assets, ...action.data.data];
      return {
        ...state,
        AssetLoading: false,
        AssetSuccess: true,
        AssetError: false,
        totalCount: action.data.count || newAssets.length,
        Assets: newAssets,
      };

    case assetManagementTypes.GET_ALL_ASSETS_LIST_SUCCESS:
      return {
        ...state,
        allAssetsList: action.data.data,
      };

    case assetManagementTypes.UPDATE_ASSET_ITEM:
      return {
        ...state,
        Assets: state.Assets?.map((asset) => {
          if (asset._id === action.data?._id) {
            return action.data;
          }
          return asset;
        }),
        AssetOverview: action.data,
      };

    case assetManagementTypes.GET_ALL_ASSETS_ERROR:
      return {
        ...state,
        AssetLoading: false,
        AssetSuccess: false,
        AssetError: true,
      };

    // For asset detail fetch
    case assetManagementTypes.GET_ASSET_DETAIL_LOADING:
      return {
        ...state,
        assetDetailSuccess: false,
        assetDetailLoading: true,
        assetDetailError: false,
      };

    case assetManagementTypes.GET_ASSET_DETAIL_SUCCESS:
      return {
        ...state,
        assetDetailSuccess: true,
        assetDetailLoading: false,
        assetDetailError: false,
        AssetOverview: action.data,
      };

    case assetManagementTypes.GET_ASSET_DETAIL_ERROR:
      return {
        assetDetailSuccess: false,
        assetDetailLoading: false,
        assetDetailError: true,
      };

    // For Assets Types
    case assetManagementTypes.GET_ASSET_TYPE_LOADING:
      return {
        ...state,
        AssetTypeLoading: true,
        AssetTypeSuccess: false,
        AssetTypeError: false,
      };

    case assetManagementTypes.GET_ASSET_TYPE_SUCCESS:
      if (state?.AssetTypes?.length == action.data.count) {
        return {
          ...state,
          AssetTypeLoading: false,
          AssetTypeSuccess: true,
          AssetTypeError: false,
        };
      }
      let newAssetTypes =
        action.isResetState || action.pageNo == 1
          ? action.data.data
          : [...state.AssetTypes, ...action.data.data];
      return {
        ...state,
        AssetTypeLoading: false,
        AssetTypeSuccess: true,
        AssetTypeError: false,
        totalCount: action.data.count || newAssetTypes?.length,
        AssetTypes: newAssetTypes,
      };

    case assetManagementTypes.GET_ASSET_TYPE_ERROR:
      return {
        ...state,
        AssetTypeLoading: false,
        AssetTypeSuccess: false,
        AssetTypeError: true,
      };

    // get asset type detail fetch
    case assetManagementTypes.GET_ASSET_TYPE_DETAIL_LOADING:
      return {
        ...state,
        assetTypeDetailSuccess: false,
        assetTypeDetailLoading: true,
        assetTypeDetailError: false,
      };

    case assetManagementTypes.GET_ASSET_TYPE_DETAIL_SUCCESS:
      return {
        ...state,
        assetTypeDetailSuccess: true,
        assetTypeDetailLoading: false,
        assetTypeDetailError: false,
        AssetTypeOverview: action.data,
      };

    case assetManagementTypes.GET_ASSET_TYPE_DETAIL_ERROR:
      return {
        assetTypeDetailSuccess: false,
        assetTypeDetailLoading: false,
        assetTypeDetailError: true,
      };

    //For Asset Status
    case assetManagementTypes.UPDATE_ASSET_STATUS_LOADING:
      return {
        ...state,
        AssetStatusLoading: true,
        AssetStatusSuccess: false,
        AssetStatusError: false,
      };

    case assetManagementTypes.UPDATE_ASSET_STATUS_SUCCESS:
      return {
        ...state,
        AssetStatusLoading: false,
        AssetStatusSuccess: true,
        AssetStatusError: false,
        Assets: state.Assets.map((item) => {
          if (item._id === action.data.id) {
            return {
              ...item,
              AssetStatus: !item.AssetStatus,
            };
          }
          return item;
        }),
        AssetOverview: {
          ...state.AssetOverview,
          AssetStatus: !state.AssetOverview.AssetStatus,
        },
        AssetStatusMessage: action.data && action.data.message,
      };

    // case assetManagementTypes.UPDATE_ASSET_STATUS_FAILED:
    //   return {
    //     ...state,
    //     AssetStatusLoading: false,
    //     AssetStatusSuccess: false,
    //     AssetStatusError: false,
    //     assetStatusFailed: true,
    //     Assets: state.Assets.map((item) => {
    //       if (item._id === action.data.id) {
    //         return {
    //           ...item,
    //           AssetStatus: !item.AssetStatus,
    //         };
    //       }
    //       return item;
    //     }),
    //     AssetStatusMessage: action.data && action.data.message,
    //     assetStatusData: action.data && action.data.data,
    //   };

    case assetManagementTypes.UPDATE_ASSET_STATUS_FAILED:
      return {
        ...state,
        AssetStatusLoading: false,
        AssetStatusSuccess: false,
        AssetStatusError: false,
        assetStatusFailed: true,
        AssetStatusMessage: action.data?.message,
        assetStatusData: action.data?.data,
      };

    case assetManagementTypes.UPDATE_ASSET_STATUS_ERROR:
      return {
        ...state,
        AssetStatusLoading: false,
        AssetStatusSuccess: false,
        AssetStatusError: true,
      };

    case assetManagementTypes.STORE_SELECTED_ASSET:
      return {
        ...state,
        AssetOverview: state.Assets.find((asset) => asset._id === action.data),
      };

    // For Adding New Asset
    case assetManagementTypes.ADD_NEW_ASSET_LOADING:
      return {
        ...state,
        AddAssetLoading: true,
        AddAssetSuccess: false,
        AddAssetError: false,
      };

    case assetManagementTypes.ADD_NEW_ASSET_SUCCESS:
      return {
        ...state,
        AddAssetLoading: false,
        AddAssetSuccess: true,
        AddAssetError: false,
        Assets: [...state.Assets, action.data],
      };

    case assetManagementTypes.ADD_NEW_ASSET_ERROR:
      return {
        ...state,
        AddAssetLoading: false,
        AddAssetSuccess: false,
        AddAssetError: true,
      };

    // For Editing Asset
    case assetManagementTypes.EDIT_ASSET_NAME_LOADING:
      return {
        ...state,
        EditAssetNameLoading: false,
        EditAssetNameSuccess: false,
        EditAssetNameError: false,
      };

    case assetManagementTypes.EDIT_ASSET_NAME_SUCCESS:
      return {
        ...state,
        EditAssetNameLoading: false,
        EditAssetNameSuccess: false,
        EditAssetNameError: false,
        Assets: state.Assets.map((item) => {
          if (item._id === action.data.id) {
            return {
              ...item,
              assetName: action.data.data.assetName,
            };
          }
          return item;
        }),
      };

    case assetManagementTypes.EDIT_ASSET_NAME_ERROR:
      return {
        ...state,
        EditAssetNameLoading: false,
        EditAssetNameSuccess: false,
        EditAssetNameError: false,
      };

    // For add and Edit asset
    case assetManagementTypes.ADD_ASSET_IMAGE_LOADING:
      return {
        ...state,
        AssetImageLoading: true,
        AssetImageSuccess: false,
        AssetImageError: false,
      };

    case assetManagementTypes.ADD_ASSET_IMAGE_SUCCESS:
      return {
        ...state,
        AssetImageLoading: false,
        AssetImageSuccess: true,
        AssetImageError: false,
        AssetImage: action.data.data.data,
        Assets: state.Assets.map((item) => {
          if (item._id === action.data.id) {
            return {
              ...item,
              assetImage: action.data.data.data,
            };
          }
          return item;
        }),
      };

    case assetManagementTypes.UPDATE_ASSET:
      let assetLists = [...state.Assets];
      assetLists.forEach((asset, index) => {
        if (asset._id === action.data._id) {
          for (var key in action.data) {
            if (key === "assetName") {
              assetLists[index].assetName = action.data.assetName;
            } else {
              assetLists[index][key] = action.data[key];
            }
          }
        }
      });
      return {
        ...state,
        Assets: assetLists,
        AssetOverview: { ...state.AssetOverview, ...action.data },
      };

    case assetManagementTypes.ADD_ASSET_IMAGE_ERROR:
      return {
        ...state,
        AssetImageLoading: false,
        AssetImageSuccess: false,
        AssetImageError: true,
      };

    case assetManagementTypes.RESET_STATE:
      return {
        ...state,
        Loading: false,
        Success: false,
        Error: false,
        AssetImageLoading: false,
        AssetImageSuccess: false,
        AssetImageError: false,
      };

    // To generate CSV
    case assetManagementTypes.GENERATE_CSV_LOADING:
      return {
        ...state,
        GenerateCSVLoading: true,
        GenerateCSVSuccess: false,
        GenerateCSVError: false,
        GeneratingCSVData: [],
      };

    case assetManagementTypes.GENERATE_CSV_SUCCESS:
      return {
        ...state,
        GenerateCSVLoading: false,
        GenerateCSVSuccess: true,
        GenerateCSVError: false,
        GeneratingCSVData: action?.data?.map?.((asset) => {
          return {
            assetName: asset?.assetName,
            assetType: asset?.assetTypeId?.AssetTypeName,
            worksite: asset?.siteId?.name,
            manager: asset?.siteId?.manager?.fullName || "",
            meterReading:
              asset?.lastHourMeterReading || asset?.lastHourMeterReading == 0
                ? asset.lastHourMeterReading
                : "NA",
            status:
              asset?.inspectionReport?.length > 0
                ? asset.inspectionReport[0].status
                  ? "Pass"
                  : "Fail"
                : "NA",
            inspector: asset?.inspectionReport?.[0]?.inspectorId?.fullName || "NA",
            date: getDateTimeInFormatExportData(
              asset?.siteId?.timeZone,
              asset?.inspectionReport?.[0]?.createdAt
            ),
            lastPassedInspectionAt: getDateTimeInFormatExportData(
              asset?.siteId?.timeZone,
              asset?.lastPassedInspectionAt
            ),
            lastFailedInspectionAt: getDateTimeInFormatExportData(
              asset?.siteId?.timeZone,
              asset?.lastFailedInspectionAt
            ),
            lastTimedoutInspectionAt: getDateTimeInFormatExportData(
              asset?.siteId?.timeZone,
              asset?.lastTimedoutInspectionAt
            ),
            lastLowImpactAt: getDateTimeInFormatExportData(asset?.siteId?.timeZone, asset?.lastLowImpactAt),

            lastMediumImpactAt: getDateTimeInFormatExportData(
              asset?.siteId?.timeZone,
              asset?.lastMediumImpactAt
            ),

            lastHighImpactAt: getDateTimeInFormatExportData(asset?.siteId?.timeZone, asset?.lastHighImpactAt),

            lastImpactBy: asset?.impactReport?.[0]?.userId?.fullName || "NA",
            lastimpactSeverity: asset?.impactReport?.[0]?.impactSeverity || "NA",
            lastImpactDate: getDateTimeInFormatExportData(
              asset?.siteId?.timeZone,
              asset?.impactReport?.[0]?.impactRecordedAt
            ),
          };
        }),
      };

    case assetManagementTypes.GENERATE_CSV_ERROR:
      return {
        ...state,
        GenerateCSVLoading: false,
        GenerateCSVSuccess: false,
        GenerateCSVError: true,
      };

    // To Store Inspection Asset
    case assetManagementTypes.TO_STORE_INSPECTION_ASSET:
      return {
        ...state,
        InpectionAsset: action.data,
      };

    //To Store Selected Asset type
    case assetManagementTypes.STORE_SELECTED_ASSET_TYPE:
      return {
        ...state,
        AssetTypeOverview: state.AssetTypes.find((assetType) => assetType._id === action.data),
      };

    //To reset all the state
    case assetManagementTypes.RESET_ALL_ASSET_STATUS:
      return {
        ...state,
        AssetStatusLoading: false,
        AssetStatusSuccess: false,
        AssetStatusError: false,
        AssetStatusMessage: "",
        assetStatusFailed: false,
        Success: false,
        Error: false,
        AssetImageSuccess: false,
      };

    case assetManagementTypes.ASSET_ADDED:
      return {
        ...state,
        totalCount: (state.totalCount ? state.totalCount : state.Assets) + 1,
        Assets: [...state.Assets, action.data],
        addassetSuccessFlag: true,
        newlyAddedAsset: action.data,
      };

    case assetManagementTypes.RESET_ASSETS_SUCCESS_FLAG:
      return {
        ...state,
        addassetSuccessFlag: false,
        newlyAddedAsset: null,
      };

    case assetManagementTypes.SET_ASSET_TYPE_SUCCESS:
      return {
        ...state,
        addAssetTypeSuccessFlag: true,
      };

    case assetManagementTypes.RESET_ASSET_TYPE_SUCCESS:
      return {
        ...state,
        addAssetTypeSuccessFlag: false,
      };

    // Update asset overview
    case assetManagementTypes.UPDATE_ASSET_OVERVIEW:
      return {
        ...state,
        AssetOverview: {
          ...state.AssetOverview,
          lastHourMeterReading: action.data.lastHourMeterReading,
          commulativeHourMeterReading: action.data.commulativeHourMeterReading,
        },
      };

    case assetManagementTypes.UPDATE_ASSET_TYPE_CHECKLIST_LOADING:
      return {
        ...state,
        UpdateAssetTypeChecklistLoading: true,
        UpdateAssetTypeChecklistSuccess: false,
        UpdateAssetTypeChecklistError: false,
      };

    case assetManagementTypes.UPDATE_ASSET_TYPE_CHECKLIST_SUCCESS:
      return {
        ...state,
        UpdateAssetTypeChecklistLoading: false,
        UpdateAssetTypeChecklistSuccess: true,
        UpdateAssetTypeChecklistError: false,
        AssetTypeOverview: {
          ...state.AssetTypeOverview,
          checklists: action.data || [],
        },
      };

    case assetManagementTypes.UPDATE_ASSET_TYPE_CHECKLIST_ERROR:
      return {
        ...state,
        UpdateAssetTypeChecklistLoading: false,
        UpdateAssetTypeChecklistSuccess: false,
        UpdateAssetTypeChecklistError: true,
      };

    case assetManagementTypes.RESET_UPDATE_ASSET_TYPE_CHECKLIST_STATE:
      return {
        ...state,
        UpdateAssetTypeChecklistLoading: false,
        UpdateAssetTypeChecklistSuccess: false,
        UpdateAssetTypeChecklistError: false,
      };
    // Returning default state
    default:
      return state;
  }
};

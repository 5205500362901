import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import CustomStepper from "../../../../../components/StepperComponent/Stepper.Component";
import Button from "../../../../../components/Button/Button.component";
import ModalPopup from "../../../../../components/ModalPopupComponent/ModalPopup.component";
import OverviewStep from "../common/OverviewStep.component";
import PermissionsMatrix from "../common/PermissionsMatrix.component";
import DetailsStep from "../common/DetailsStep.component";
import { updateUserType } from "../../../../../redux/userType/action";
import { ModalEditWrapper, UserTypeEditDiv, ButtonWrapper } from "./EditUserTypeModal.styles";
import { allSteps, accessLevelMapping } from "../const/usertypeConstants";

function EditUserTypeModal(props) {
  const { updateUserType, UserTypeUpdateSuccess, isSuperAdmin, UserTypeDetail, toggleModalClose } = props;
  const { t } = useTranslation();
  const [selectedValues, setSelectedValues] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [addUserTypeModalData, setAddUserTypeModalData] = useState(UserTypeDetail);

  useEffect(() => {
    setAddUserTypeModalData(UserTypeDetail);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [UserTypeDetail]);

  useEffect(() => {
    setSelectedValues(
      addUserTypeModalData.accessLevel === UserTypeDetail.accessLevel
        ? UserTypeDetail?.permissions
        : accessLevelMapping?.[addUserTypeModalData?.accessLevel]
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [UserTypeDetail?.permissions, addUserTypeModalData.accessLevel]);

  const getAddUserTypeModalStep = (step, setActiveStep) => {
    switch (step) {
      case 0:
        return (
          <OverviewStep
            addUserTypeModalData={addUserTypeModalData}
            setAddUserTypeModalData={setAddUserTypeModalData}
          />
        );
      case 1:
        return (
          <PermissionsMatrix
            addUserTypeModalData={addUserTypeModalData}
            setAddUserTypeModalData={setAddUserTypeModalData}
            selectedValues={selectedValues}
            setSelectedValues={setSelectedValues}
            accessLevel={addUserTypeModalData?.accessLevel}
          />
        );
      case 2:
        return (
          <DetailsStep
            addUserTypeModalData={addUserTypeModalData}
            setAddUserTypeModalData={setAddUserTypeModalData}
          />
        );
      default:
        return <OverviewStep />;
    }
  };

  const isDataValidForTheActiveStep = () => {
    const { accessLevel, accountId, name } = addUserTypeModalData;
    switch (activeStep) {
      case 0: {
        return isSuperAdmin
          ? !accessLevel || !name.trim() || (accessLevel === "ALL_ACCOUNTS" ? false : !accountId)
          : !accessLevel || !name.trim();
      }
      case 2: {
        return false;
      }
      default:
        return false;
    }
  };

  const handleNext = () => {
    isDataValidForTheActiveStep(activeStep);
    setActiveStep((prevStep) => prevStep + 1);
  };

  const resetModalData = () => {
    setSelectedValues(UserTypeDetail?.permissions);
    setActiveStep(0);
    setAddUserTypeModalData(UserTypeDetail);
  };

  const handlePrevious = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleModalCloseCustom = () => {
    resetModalData();
    toggleModalClose();
  };

  const handleSubmit = () => {
    updateUserType(addUserTypeModalData._id, addUserTypeModalData);
  };

  useEffect(() => {
    if (UserTypeUpdateSuccess) {
      handleModalCloseCustom();
    }
  }, [UserTypeUpdateSuccess]);

  return (
    <ModalEditWrapper>
      <ModalPopup
        {...props}
        size="lg"
        centered
        backdrop="static"
        title={t("Edit User Type")}
        handleClose={handleModalCloseCustom}
      >
        <CustomStepper className="mt-2 mb-4" allSteps={allSteps} activeStep={activeStep} />
        <UserTypeEditDiv>{getAddUserTypeModalStep(activeStep, setActiveStep)}</UserTypeEditDiv>
        <ButtonWrapper>
          <div className="buttonWrapper">
            {activeStep > 0 && activeStep < allSteps.length && (
              <Button
                label={t("Back")}
                onClick={() => {
                  handlePrevious();
                }}
                showIcon
                iconClass="arrow-left"
                buttonType="ghost"
              />
            )}
            {activeStep < allSteps.length - 1 && (
              <Button
                label={t("Next")}
                onClick={handleNext}
                style={{ marginLeft: "10px" }}
                iconClass="arrow-right"
                showIcon
                iconPosition="after"
                disabled={isDataValidForTheActiveStep()}
              />
            )}
            {activeStep === allSteps.length - 1 && (
              <Button
                label={t("Update")}
                style={{ marginLeft: "10px" }}
                onClick={handleSubmit}
                iconClass="save"
                showIcon
                buttonType="save"
              />
            )}
          </div>
        </ButtonWrapper>
      </ModalPopup>
    </ModalEditWrapper>
  );
}

const mapStateToProps = (state) => ({
  UserTypeUpdateSuccess: state.userType.UserTypeUpdateSuccess,
  isSuperAdmin: state.user.isSuperAdmin,
  UserTypeDetail: state.userType.UserTypeDetail,
});

const mapDispatchToProps = (dispatch) => ({
  updateUserType: (userTypeId, userTypeData) => dispatch(updateUserType(userTypeId, userTypeData)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditUserTypeModal));

import React from "react";

import "bootstrap/dist/css/bootstrap.css";
import { useTranslation } from "react-i18next";
import "react-multi-carousel/lib/styles.css";
import { CarouselWrapperDiv } from "./UserMultiCard.component.styles";
import CarouselComponent from "../../../components/CarouselComponent/Carousel.component"; // Updated import

const responsive_b = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const UserMultiCard = ({ data }) => {
  const { t } = useTranslation();
  const carouselData = [
    {
      name: t("Inspections Performed"),
      iconClass: "inspections-performed",
      iconColor: "GrayL4",
      feildName: "totalInspection",
      type: "ThemePrimary",
    },
    {
      name: t("Inspections Passed"),
      iconClass: "inspection-passed",
      iconColor: "GrayL4",
      feildName: "PassInspection",
      type: "ThemePrimary",
    },
    {
      name: t("Inspections Failed"),
      iconClass: "inspection-failed",
      iconColor: "GrayL4",
      feildName: "FailedInspection",
      type: "ThemePrimary",
    },
    {
      name: t("Inspections Timed Out"),
      iconClass: "inspection-timed-out",
      iconColor: "GrayL4",
      feildName: "TimedoutInspection",
      type: "ThemePrimary",
    },
  ];

  return (
    <CarouselWrapperDiv>
      <CarouselComponent
        size="small"
        type="ThemePrimary"
        responsive={responsive_b}
        countData={data}
        carouselData={carouselData}
        swipeable={true}
        autoPlay={true}
        autoPlaySpeed={5000}
        slidesToSlide={1}
        infinite={true}
        isColored={true}
      />
    </CarouselWrapperDiv>
  );
};

export default UserMultiCard;

import styled from "styled-components";

export const SettingsWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
  border-radius: 3px;
  width: 98%;
  margin-left: 0% !important;
  @media (max-width: 768px) {
    margin-bottom: 20px;
    width: 100%;
  }
`;

export const InputWrapper = styled.div`
  margin-top: ${({ marginTop }) => marginTop};
`;

export const Divider = styled.div`
  clear: both;
  width: 100%;
  height: 1px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray5 || "#CCCCCC"};
`;

export const DivScroller = styled.div`
  clear: both;
  height: calc(100vh - 260px);
  overflow-y: auto;
  @media (max-width: 1100px) {
    height: ${({ isSuperAdmin }) => (isSuperAdmin ? 'calc(100vh - 400px)' : 'calc(100vh - 330px)')};
  }
  &::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
    border: 1px solid #f2f2f2;
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: green !important;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
  }
`;

export const ChangePasswordContainer = styled.div`
  width: 100%;
  margin: auto;
`;

export const InputProfileDiv = styled.div`
  width: 96%;
  margin: auto;
  @media (max-width: 1100px) {
    width: 100%;
  }
`;

export const InputProfileDivCont = styled.div``;

export const ButtonDiv = styled.div`
  text-align: right;
  @media (max-width: 1100px) {
    button[title="Cancel"] {
      float: left;
    }
  }
`;
export const ButtonWrapper = styled.div`
  width: 96%;
  margin: 10px auto;
  display: flex;
  justify-content: end;
  @media (max-width: 1100px) {
    display: block;
  }
`;

export const PasswordRulesWrapper = styled.div`
  .alignTop {
    align-items: flex-start;
  }
  padding: 5px;
  border-radius: 4px;
  font-size: 14px !important;
  font-family: Open Sans;
  text-align: left;
  transition: border 0.5s;
`;

export const PassNotMatch = styled.div`
  font-size: 14px !important;
  font-family: Open Sans;
  text-align: left;
  color: ${({ theme }) => theme.colors.redPrimary || "#DD0000"};
  display: flex;
  align-items: center;
  line-height: 14px;
`;
export const IconDanger = styled.div`
  font-size: 22px !important;
  color: ${({ theme }) => theme.colors.redPrimary || "#DD0000"};
  float: left;
  margin-right: 10px;
`;
export const RulesText = styled.div`
  line-height: 20px;
`;
export const LineDIV = styled.div``;

import React, { useState, useEffect } from "react";
import List from "../../../components/List";
import BreadCrumb from "../../../components/BreadCrumb.component";
import StartInspection from "./StartInspection/StartInspection";
import { connect } from "react-redux";
import { Row, Col, Form } from "react-bootstrap";
import { getAssetCheckListByAssetId, toResetAllAssetState } from "../../../redux/inspectionAssets/action";
import { toStoreSelectedAsset } from "../../../redux/inspection/action";
import { setDropdownAssetTypeId, setDropdownWorksiteId } from "../../../redux/dropdownFilters/action";
import { getSelectedAsset, toStoreInspectionAsset, getAllAsset } from "../../../redux/assets/action";
import { setDropdownAccountId } from "../../../redux/dropdownFilters/action";
import DropdownFilter from "../../../components/DropdownFilter.component";
import { getInspectionPlan } from "../../../redux/config/action";
import { updateActiveNavItem } from "../../../redux/sideMenu/menu.action";
import { Trans, useTranslation } from "react-i18next";
import AccountNameFilter from "../../../components/DropdownFilter.component";
import SearchBox from "../../../components/SearchBox/Searchbox.component";

import { DivContainer, InspectAssetDiv, BarFlex, InspectText } from "./styles";

const listType = require("../../../constant/listType");

const InspectAsset = ({
  classes,
  assetTypes,
  assets,
  getAssetCheckListByAssetId,
  assetCheckList,
  assetCheckListLoading,
  AssetLoading,
  assetCheckListSuccess,
  details,
  toResetAllAssetState,
  selectedAsset,
  getInspectionPlan,
  assetList,
  toStoreSelectedAsset,
  totalCount,
  getAllAsset,
  getSelectedAsset,
  dropdownAssetTypeList,
  setDropdownAssetTypeId,
  dropdownAccountList,
  setDropdownAccountId,
  isSuperAdmin,
  updateActiveNavItem,
  setDropdownWorksiteId,
  dropdownWorksiteList,
  accountId,
  isSiteAdmin,
  showWorksiteDropdown,
}) => {
  const [SearchText, setSearchText] = useState("");
  const [ShowInspection, setShowInspection] = useState(false);
  const [SelectedCheckList, setSelectedCheckList] = useState("");
  const [SelectedAsset, setSelectedAsset] = useState(null);
  const [ShowResult, setShowResult] = useState(false);
  const [assetTotalChecklist, setAssetTotalChecklist] = useState("");

  const { t, i18n } = useTranslation();

  const handleShowInspect = (asset) => {
    toResetAllAssetState();
    toStoreSelectedAsset(asset);
    setShowResult(false);
  };

  useEffect(() => {
    setTimeout(() => {
      getInspectionPlan(details && details.accountId);
    }, 1000);
  }, [ShowResult]);

  useEffect(() => {
    // getAssetTypes();
    //getAllAsset();
    updateActiveNavItem("inspectAsset");
  }, []);

  useEffect(() => {
    if (!selectedAsset) {
      setShowInspection(false);
    } else {
      getAssetCheckListByAssetId(accountId, selectedAsset?._id);
      // toResetAllAssetState();
      // selectedAsset.assetChecklists.map((list) =>
      //   getAssetCheckListByAssetId(list.checklistId)
      // );
      // let defaultList = selectedAsset.assetChecklists.find(
      //   (list) => list.isDefaultChecklist
      // );
      // if (defaultList) {
      //   let ID = defaultList && defaultList.checklistId;
      //   setSelectedCheckList(ID);
      // }
      setSelectedAsset(selectedAsset);
      setShowInspection(true);
    }
  }, [selectedAsset]);

  useEffect(() => {
    setAssetTotalChecklist(assetCheckList);
  }, [assetCheckList]);

  const listDetail = {
    label: "Your Assets",
    emptyListText: t("NO_ASSETS_FOUND"),
    listType: listType.ASSET,
  };

  return (
    <DivContainer>
      <BarFlex>
        <BreadCrumb />
      </BarFlex>
      <Row>
        <Col lg={3}>
          <div className="inspect-asset-height">
            <SearchBox
              label={t("Your Assets")}
              placeholder={t("Search an Asset")}
              searchText={SearchText}
              setSearchText={setSearchText}
              onChange={(e) => setSearchText(e.target.value)}
            />

            <div className="assetPageList assetPageData">
              <List
                hideHeader={true}
                listDetail={listDetail}
                listData={assetList}
                selectItem={(id) => getSelectedAsset(id)}
                handleInspect={(asset) => handleShowInspect(asset)}
                selectedId={selectedAsset && selectedAsset._id}
                isFilterChange={false}
                loadData={getAllAsset}
                totalCount={totalCount}
                searchKey={SearchText.length > 3 ? SearchText : ""}
                dataLoading={AssetLoading}
              />
            </div>
          </div>
        </Col>
        <Col lg={9}>
          <Row>
            {isSuperAdmin && (
              <Col md={4}>
                <Form.Group className="selectBoxDiv" style={{ width: "100%", paddingBottom: "6px" }}>
                  <AccountNameFilter
                    filter={{
                      type: "Account",
                      key: "companyName",
                      data: dropdownAccountList,
                    }}
                    label={t("filter")}
                    handleFilter={(value) => setDropdownAccountId(value)}
                  />
                </Form.Group>
              </Col>
            )}
            {showWorksiteDropdown && (
              <Col md={4}>
                <Form.Group className="selectBoxDiv" style={{ width: "100%", paddingBottom: "6px" }}>
                  <DropdownFilter
                    filter={{
                      type: "Worksite",
                      key: "name",
                      data: dropdownWorksiteList,
                    }}
                    label={t("filter")}
                    handleFilter={(value) => setDropdownWorksiteId(value)}
                  />
                </Form.Group>
              </Col>
            )}
            <Col md={4}>
              <Form.Group className="selectBoxDiv" style={{ width: "100%", paddingBottom: "6px" }}>
                <DropdownFilter
                  filter={{
                    type: "Asset Type",
                    key: "AssetTypeName",
                    data: dropdownAssetTypeList,
                  }}
                  label={t("filter")}
                  handleFilter={(value) => setDropdownAssetTypeId(value)}
                />
              </Form.Group>
            </Col>
          </Row>

          {ShowInspection ? (
            <StartInspection
              selectedCheckList={SelectedCheckList}
              assetCheckList={assetTotalChecklist}
              details={details}
              selectedAsset={SelectedAsset}
              setShowInspection={setShowInspection}
            />
          ) : assetList && assetList.length > 0 ? (
            <InspectAssetDiv>
              <InspectText>
                <div className="clickDesktop">
                  <Trans>CLICK_ADD_INSPECT_BUTTON</Trans>
                </div>
                <div className="tapMobile">
                  <Trans>CLICK_ADD_INSPECT_BUTTON_MOB</Trans>
                </div>
              </InspectText>
            </InspectAssetDiv>
          ) : (
            <InspectAssetDiv>
              <InspectText>
                <Trans>NO_ASSET_IN_ACCOUNT</Trans>
              </InspectText>
            </InspectAssetDiv>
          )}
        </Col>
      </Row>
    </DivContainer>
  );
};

const mapStateToProps = (state) => ({
  assetTypes: state.inspectionAssets.AssetTypes,
  assets: state.inspectionAssets.Assets,
  assetCheckList: state.inspectionAssets.AssetCheckList,
  assetCheckListLoading: state.inspectionAssets.AssetCheckListLoading,
  AssetLoading: state.assets.AssetLoading,
  assetCheckListSuccess: state.inspectionAssets.AssetCheckListSuccess,
  details: state.user.loginDetails,
  selectedAsset: state.inspect.selectedAsset,
  assetList: state.assets.Assets,
  assetOverview: state.assets.AssetOverview,
  AssetSuccess: state.assets.AssetSuccess,
  totalCount: state.assets.totalCount,
  dropdownAssetTypeList: state.dropdownFilters.assetTypeList,
  dropdownAccountList: state.dropdownFilters.accountList,
  isSuperAdmin: state.user.isSuperAdmin,
  dropdownWorksiteList: state.dropdownFilters.worksiteList,
  accountId: state.user.loginDetails?.accountId,
  isSiteAdmin: state.user.isSiteAdmin,
  showWorksiteDropdown: state.user.showWorksiteDropdown,
});
const mapDispatchToProps = (dispatch) => ({
  getAssetCheckListByAssetId: (accountId, assetId) =>
    dispatch(getAssetCheckListByAssetId(accountId, assetId)),
  toResetAllAssetState: () => dispatch(toResetAllAssetState()),
  toStoreSelectedAsset: (data) => dispatch(toStoreSelectedAsset(data)),
  getInspectionPlan: (accountId) => dispatch(getInspectionPlan(accountId)),
  getSelectedAsset: (id) => dispatch(getSelectedAsset(id)),
  getAllAsset: (paginationData) => dispatch(getAllAsset(paginationData)),
  setDropdownAssetTypeId: (assetTypeId) => dispatch(setDropdownAssetTypeId(assetTypeId)),
  setDropdownAccountId: (accountId) => dispatch(setDropdownAccountId(accountId)),
  updateActiveNavItem: (item) => dispatch(updateActiveNavItem(item)),
  setDropdownWorksiteId: (siteId) => dispatch(setDropdownWorksiteId(siteId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InspectAsset);
import { configTypes } from "./types";
import { apiCall } from "../../utils/apiCall";

//To get worksite report setting success
const getWorksiteReportSettingSuccess = (data) => ({
  type: configTypes.GET_WORKSITE_REPORT_SETTING_SUCCESS,
  data: data,
});

//To get worksite report setting error
const getWorksiteReportSettingError = () => ({
  type: configTypes.GET_WORKSITE_REPORT_SETTING_ERROR,
});

// To update worksite report setting loading
const updateWorksiteReportSettingLoading = () => ({
  type: configTypes.UPDATE_WORKSITE_REPORT_SETTING_LOADING,
});

// To update worksite report setting success
const updateWorksiteReportSettingSuccess = (data) => ({
  type: configTypes.UPDATE_WORKSITE_REPORT_SETTING_SUCCESS,
  data: data,
});

// To update worksite report setting error
const updateWorksiteReportSettingError = () => ({
  type: configTypes.UPDATE_WORKSITE_REPORT_SETTING_ERROR,
});

const resetWorksiteReportSettingState = () => ({
  type: configTypes.RESET_WORKSITE_REPORT_SETTING,
});

export const getReportSettingsByAssetId = (assetId, actionAfterGet) => {
  return async (dispatch, getState) => {
    //dispatch(getWorksiteAutoLogoutSettingLoading());
    apiCall(`/systemSettings/reportSettings/${assetId}`)
      .then((response) => {
        actionAfterGet(false);
        dispatch(getWorksiteReportSettingSuccess(response.data.data));
      })
      .catch((err) => {
        actionAfterGet(false);
        console.log("getAssetAutoLogoutSettings -- error", err);
        dispatch(getWorksiteReportSettingError());
      });
  };
};

export const updateReportSettingsByAssetId = (assetId, data, callbackAfterUpdate) => {
  return async (dispatch, getState) => {
    dispatch(updateWorksiteReportSettingLoading());
    apiCall(`/systemSettings/reportSettings/${assetId}`, {
      method: "PUT",
      data: { reportSettings: data },
    })
      .then((response) => {
        callbackAfterUpdate(true);
        dispatch(updateWorksiteReportSettingSuccess(data));
        dispatch(resetWorksiteReportSettingState());
      })
      .catch((err) => {
        console.log("updateReportSettingsByAssetId -- error", err);
        dispatch(updateWorksiteReportSettingError());
      });
  };
};

export const resetReportSettingsByAssetId = (assetId, actionAfterReset) => {
  return async (dispatch, getState) => {
    dispatch(updateWorksiteReportSettingLoading());
    apiCall(`/systemSettings/reportSettings/${assetId}`, {
      method: "PATCH",
    })
      .then((response) => {
        actionAfterReset(true);
        dispatch(updateWorksiteReportSettingSuccess(response.data.data));
        dispatch(resetWorksiteReportSettingState());
      })
      .catch((err) => {
        console.log("resetReportSettingsByAssetId -- error", err);
        dispatch(updateWorksiteReportSettingError());
      });
  };
};

import { userTypes } from "./types";
import { apiCall } from "../../utils/apiCall";
import { CacheManager } from "../../utils/cache/CacheManager";
import { CacheRefs } from "../../utils/cache/CacheRefs";
import { setEditAlertModal } from "./../modals/action";

//To get all users
const getUsersLoading = () => ({
  type: userTypes.GET_ALL_USERS_LOADING,
});

const getUsersSuccess = (data, isResetState, pageNo) => ({
  type: userTypes.GET_ALL_USERS_SUCCESS,
  data,
  isResetState,
  pageNo,
});

export const updateUserSuccess = (data) => ({
  type: userTypes.UPDATE_USER_SUCCESS,
  data,
});

const getUsersError = (err) => ({
  type: userTypes.GET_ALL_USERS_ERROR,
  err,
});

// get users list
export const getUsers = (paginationData, isResetState, bypassCache) => {
  let isPendingUserList = false;
  return async (dispatch, getState) => {
    dispatch(getUsersLoading());
    const state = getState();
    let accountId = state?.user?.loginDetails?.accountId;
    const isSuperAdmin = state?.user?.isSuperAdmin;

    if (isSuperAdmin) {
      accountId = state?.dropdownFilters?.accountId;
    }

    let siteId = state?.dropdownFilters?.worksiteId;

    if (state?.user?.isSiteAdmin) {
      siteId = state?.user?.loginDetails?.userData?.authorizedSites?.[0];
    }
    const dropdownUserAccessLevel = state?.dropdownFilters?.dropdownUserAccessLevel;

    const { pageNo, pageSize, searchKey } = paginationData;
    // let url = `${baseUrl}/user/getUserbyRole/${dropdownUserAccessLevel}/${accountId}/${siteId}/${pageNo}/${pageSize}/${
    //   searchKey ? searchKey : "all"
    // }`;

    let url = `/user/list/${dropdownUserAccessLevel}/${accountId}/${siteId}/${pageNo}/${pageSize}/${
      encodeURIComponent(searchKey) ? searchKey : "all"
    }`;

    if (isPendingUserList) {
      url = `/requestAccess/${accountId}/${pageNo}/${pageSize}`;
    }
    const cache = CacheManager.getInstance();
    //bypass is used to fix SIER-5635
    if (cache && !bypassCache) {
      const cacheResult1 = await cache.get(url);
      if (cacheResult1) {
        dispatch(getUsersSuccess(cacheResult1, isResetState, pageNo));
        return;
      }
    }
    apiCall(url)
      .then((resp) => {
        //What is this
        if (!resp.data.data) {
          resp.data.data = [];
        }
        if (cache) {
          if (resp?.data?.data?.length > 0) {
            cache.set(url, resp.data);
          }
        }
        dispatch(getUsersSuccess(resp.data, isResetState, pageNo));
      })
      .catch((err) => {
        dispatch(getUsersError(err));
      });
  };
};

// get user detail by Id
const getUserDetailLoading = () => ({
  type: userTypes.GET_USER_DETAIL_LOADING,
});

const getUserDetailSuccess = (data) => ({
  type: userTypes.GET_USER_DETAIL_SUCCESS,
  data,
});

const getUserDetailError = (err) => ({
  type: userTypes.GET_USER_DETAIL_ERROR,
  err,
});

export const getUserDetailById = (userId, setIsLoading = true) => {
  return async (dispatch, getState) => {
    setIsLoading && dispatch(getUserDetailLoading());
    let url = `/user/${userId}`;

    apiCall(url)
      .then((resp) => {
        //What is this
        if (!resp.data.data) {
          resp.data.data = [];
        }
        dispatch(getUserDetailSuccess(resp.data?.data[0]));
      })
      .catch((err) => {
        dispatch(getUserDetailError(err));
      });
  };
};

// Get pending user
export const getPendingUsers = () => {
  return async (dispatch, getState) => {
    dispatch(getUsersLoading());
    const siteID = getState?.()?.user?.loginDetails?.userData?.authorizedSites?.[0];
    const action = siteID ? `/user/bySite/${siteID}` : `/user/allUser`;

    apiCall(action)
      .then((resp) => {
        dispatch(getUsersSuccess(resp.data));
      })
      .catch((err) => {
        dispatch(getUsersError(err));
      });
  };
};

//To create new worksite
const postUserLoading = () => ({
  type: userTypes.POST_NEW_USER_LOADING,
});

const postUserSuccess = (data) => ({
  type: userTypes.POST_NEW_USER_SUCCESS,
  data,
});

const postUserError = (err) => ({
  type: userTypes.POST_NEW_USER_ERROR,
  err,
});

export const resetAddUserState = () => ({
  type: userTypes.RESET_ADD_USER_STATE,
});

export const updateUserDetail = (data) => ({
  type: userTypes.UPDATE_USER_DETAIL,
  data,
});

export const postUser = (data, cb) => {
  return async (dispatch, getState) => {
    dispatch(postUserLoading());

    try {
      const resp = await apiCall(`/user/create`, { method: "POST", data: data, type: "file" });
      // eslint-disable-next-line no-unused-expressions
      resp.data.success ? cb?.(resp.data.data) : "";
      if (resp.data.success) {
        await CacheRefs.removeCacheForUsers();
        dispatch(
          postUserSuccess({
            message: resp.data.message,
            data: resp.data.data,
          })
        );
      } else {
        dispatch(postUserError({ message: resp.data.message }));
      }
    } catch (err) {
      dispatch(postUserError(err));
    }
  };
};

export const updateUser = (data, formData, cb) => {
  return async (dispatch, getState) => {
    dispatch(postUserLoading());

    try {
      const updatedData = await apiCall(`/user/updateAccessRole`, { method: "PUT", data: data });
      // eslint-disable-next-line no-unused-expressions
      if (updatedData.data.success) {
        cb();
        dispatch(updateUserSuccess(formData));
        dispatch(getUserDetailSuccess(formData));
        dispatch(resetUsersState());
        dispatch(setEditAlertModal(false));
        await CacheRefs.removeCacheForUsers();
      } else {
        dispatch(postUserError({ message: updatedData.data.message }));
      }
    } catch (err) {
      dispatch(postUserError(err));
    }
  };
};

//To update the user status
const userStatusLoading = () => ({
  type: userTypes.USER_STATUS_UPDATE_LOADING,
});

const userStatusSuccess = (data) => ({
  type: userTypes.USER_STATUS_UPDATE_SUCCESS,
  data,
});

const userStatusFailed = (data) => ({
  type: userTypes.USER_STATUS_UPDATE_FAILED,
  data,
});

const userStatusError = (err) => ({
  type: userTypes.USER_STATUS_UPDATE_ERROR,
  err,
});

export const userStatusUpdate = (id, status) => {
  return async (dispatch, getState) => {
    dispatch(userStatusLoading());

    const data = {
      status: status,
    };

    try {
      const resp = await apiCall(`/user/status/${id}`, { method: "PUT", data: data });
      if (resp.data.success) {
        await CacheRefs.removeCacheForUsers();
        dispatch(userStatusSuccess({ id: id, message: resp.data.message, status }));
      } else {
        dispatch(userStatusFailed({ message: resp.data.message, data: resp.data.data }));
        // dispatch(userStatusSuccess({ id: id, message: resp.data.message }));
      }
    } catch (err) {
      dispatch(userStatusError(err));
    }
  };
};

//To update the worksite status
const worksiteDetailsLoading = () => ({
  type: userTypes.WORKSITE_DETAILS_UPDATE_LOADING,
});

const worksiteDetailsSuccess = (data) => ({
  type: userTypes.WORKSITE_DETAILS_UPDATE_SUCCESS,
  data,
});

const worksiteDetailsError = (err) => ({
  type: userTypes.WORKSITE_DETAILS_UPDATE_ERROR,
  err,
});

export const worksiteDetailsUpdate = (id, details) => {
  return async (dispatch, getState) => {
    dispatch(worksiteDetailsLoading());

    try {
      const resp = await apiCall(`/site/${id}`, { method: "PUT", data: details });
      if (resp.data.success) {
        await CacheRefs.removeCacheForSites();
        dispatch(
          worksiteDetailsSuccess({
            id: id,
            message: resp.data.message,
            details,
          })
        );
      } else {
        dispatch(worksiteDetailsError({ id: id, message: resp.data.message }));
      }
    } catch (err) {
      dispatch(worksiteDetailsError(err));
    }
  };
};

//To update the worksite name
const worksiteNameLoading = () => ({
  type: userTypes.WORKSITE_NAME_UPDATE_LOADING,
});

const worksiteNameSuccess = (data) => ({
  type: userTypes.WORKSITE_NAME_UPDATE_SUCCESS,
  data,
});

const worksiteNameError = (err) => ({
  type: userTypes.WORKSITE_NAME_UPDATE_ERROR,
  err,
});

export const worksiteNameUpdate = (id, name) => {
  return async (dispatch, getState) => {
    dispatch(worksiteNameLoading());
    const data = {
      name: name,
    };

    try {
      const resp = await apiCall(`/site/${id}`, { method: "PUT", data: data });
      if (resp.data.success) {
        await CacheRefs.removeCacheForSites();
        dispatch(
          worksiteNameSuccess({
            id: id,
            message: resp.data.message,
            name,
          })
        );
      } else {
        dispatch(worksiteNameError({ id: id, message: resp.data.message }));
      }
    } catch (err) {
      dispatch(worksiteNameError(err));
    }
  };
};

// To get selected user
export const selectedUser = (data) => ({
  type: userTypes.SELECTED_USER,
  data,
});

//To reset the state
export const resetUsersState = () => ({
  type: userTypes.RESET_USERS_STATE,
});

//For getting user bulk upload templates
const getUserBulkUploadLoading = () => ({
  type: userTypes.USER_BULK_UPLOAD_TEMPLATE_LOADING,
});

const getUserBulkUploadSuccess = (data) => ({
  type: userTypes.USER_BULK_UPLOAD_TEMPLATE_SUCCESS,
  data,
});

const getUserBulkUploadError = (err) => ({
  type: userTypes.USER_BULK_UPLOAD_TEMPLATE_ERROR,
  err,
});

export const getUserBulkUploadTemplates = () => {
  return async (dispatch, getState) => {
    dispatch(getUserBulkUploadLoading());
    let action = `/user/getBulkUploadTemplates`;

    apiCall(action)
      .then((response) => {
        dispatch(getUserBulkUploadSuccess(response?.data?.data));
      })
      .catch((error) => {
        dispatch(getUserBulkUploadError(error));
      });
  };
};
//below is to avoid the intermittent issue on the User successfully Added modal
export const resetUsersSuccessFlag = () => ({
  type: userTypes.RESET_USERS_SUCCESS_FLAG,
});

const getUsersByWorksitesSuccess = (data) => ({
  type: userTypes.GET_USERS_BY_SITES_SUCCESS,
  data,
});

const getUsersByWorksitesError = (error) => ({
  type: userTypes.GET_USERS_BY_SITES_ERROR,
  error,
});

const getUsersByWorksitesLoading = () => ({
  type: userTypes.GET_USERS_BY_SITES_LOADING,
});

export const getUsersByWorksites = (sites, permissions, isAssetPermission) => {
  return async (dispatch, getState) => {
    dispatch(getUsersByWorksitesLoading());
    const state = getState();
    const data = {
      accountId: state?.user?.isSuperAdmin
        ? isAssetPermission
          ? state.dropdownFilters.accountId
          : state.dropdownFilters.settings.accountId || "all"
        : state?.user?.loginDetails.accountId,
      sites: sites || "all",
      userTypes: permissions,
    };
    apiCall("/user/getUsersBySiteIds", { method: "POST", data })
      .then((response) => dispatch(getUsersByWorksitesSuccess(response?.data?.data)))
      .catch((error) => dispatch(getUsersByWorksitesError(error)));
  };
};

const getAssetTypesByWorksitesSuccess = (data) => ({
  type: userTypes.GET_ASSET_TYPES_BY_SITES_SUCCESS,
  data,
});

const getAssetTypesByWorksitesError = (error) => ({
  type: userTypes.GET_ASSET_TYPES_BY_SITES_ERROR,
  error,
});
const getAssetTypesByWorksitesLoading = () => ({
  type: userTypes.GET_ASSET_TYPES_BY_SITES_LOADING,
});
export const getAssetTypesByWorksites = (sites) => {
  return async (dispatch, getState) => {
    dispatch(getAssetTypesByWorksitesLoading());
    let worksites = sites;
    if (sites?.length > 0 && sites?.[0] === "all") {
      const state = getState();
      const worksiteList = state?.dropdownFilters?.worksiteList;
      worksites = worksiteList.map((worksite) => worksite._id);
    } else if (!sites || sites?.length === 0) {
      console.log("<<------------------No Sites selected------------------>>");
    }
    const data = {
      sites: worksites,
    };

    apiCall("/assetType/getAssetTypeBySiteIds", { method: "POST", data })
      .then((response) => dispatch(getAssetTypesByWorksitesSuccess(response?.data?.data)))
      .catch((error) => dispatch(getAssetTypesByWorksitesError(error)));
  };
};

const getAssetsByAssetTypesSuccess = (data) => ({
  type: userTypes.GET_ASSETS_BY_ASSET_TYPES_SUCCESS,
  data,
});

const getAssetsByAssetTypesError = (error) => ({
  type: userTypes.GET_ASSETS_BY_ASSET_TYPES_ERROR,
  error,
});

const getAssetsByAssetTypesLoading = () => ({
  type: userTypes.GET_ASSETS_BY_ASSET_TYPES_LOADING,
});

export const getAssetsByAssetType = (data, isAssetPermission) => {
  return async (dispatch, getState) => {
    dispatch(getAssetsByAssetTypesLoading());
    const state = getState();

    const accountId = state.user?.isSuperAdmin
      ? isAssetPermission
        ? state.dropdownFilters?.accountId
        : state.dropdownFilters?.settings?.accountId
      : state.user?.loginDetails?.accountId;
    if (!accountId || accountId === "all") {
      return;
    }

    const dataObj = {
      accountId,
      sites: data.siteIds?.length > 0 ? data.siteIds : "all",
      assetTypes: data.assetTypeIds,
    };

    apiCall("/asset/assetsByAssetTypeIds", { method: "POST", data: dataObj })
      .then((response) => {
        dispatch(getAssetsByAssetTypesSuccess(response?.data?.data));
      })
      .catch((error) => dispatch(getAssetsByAssetTypesError(error)));
  };
};

export const getUserExportDataLoading = () => ({
  type: userTypes.GET_EXPORT_DATA_LOADING,
});

export const getUserExportDataSuccess = (data) => ({
  type: userTypes.GET_EXPORT_DATA_SUCCESS,
  data: data,
});

export const getUserExportDataError = (data) => ({
  type: userTypes.GET_EXPORT_DATA_ERROR,
});

export const getUserExportData = (siteId) => {
  return async (dispatch, getState) => {
    const state = getState();
    const siteID = state?.user?.loginDetails?.userData?.authorizedSites?.[0];
    let isSuperAdmin = state?.user?.isSuperAdmin;
    let accountId = isSuperAdmin ? "all" : state?.user?.loginDetails?.accountId;

    const url = `/user/exportData/${accountId}/${siteID ? siteID : 0}`;

    const cache = CacheManager.getInstance();
    if (cache) {
      const cacheResult = await cache.get(url);
      if (cacheResult) {
        dispatch(getUserExportDataSuccess(cacheResult?.data));
        return;
      }
    }
    try {
      const resp = await apiCall(url, { method: "GET" });
      if (resp?.data) {
        dispatch(getUserExportDataSuccess(resp.data?.data ? resp.data.data : resp.data));
        if (cache) {
          cache.set(url, resp.data, CacheRefs.getTtlShort());
        }
      } else dispatch(getUserExportDataError());
    } catch (err) {
      console.log(err);
      dispatch(getUserExportDataError(err));
    }
  };
};

const getAllWorksitesUsersSuccess = (data) => ({
  type: userTypes.GET_ALL_WORKSITES_USERS_SUCCESS,
  data,
});

const getWorksitesUsersError = (err) => ({
  type: userTypes.GET_ALL_WORKSITES_USERS_ERROR,
  err,
});

const getAllWorksitesUsersLoading = () => ({
  type: userTypes.GET_ALL_WORKSITES_USERS_LOADING,
});

export const getAllWorksiteUsersByAccountId = (accountId) => {
  return async (dispatch, getState) => {
    dispatch(getAllWorksitesUsersLoading());
    apiCall(`/user/getAllWorksiteUsers/${accountId}`)
      .then((resp) => {
        dispatch(getAllWorksitesUsersSuccess(resp.data?.data?.data));
      })
      .catch((err) => {
        dispatch(getWorksitesUsersError(err));
      });
  };
};

import styled from "styled-components";
export const CarouselWrapperDiv = styled.div`
  .borderCar {
    background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
    border: 1px solid ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
  }
  .count-card {
    color: ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"};
    span {
      color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} !important;
    }
  }
  .borderLeftStyle {
    border-left: 1px solid ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
  }
  label {
    color: ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"} !important;
  }
  .input-group-text {
    background-color: ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"};
  }
  input.form-control {
    border: 1px solid ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"} !important;
  }
  .form-control {
    background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  }
  .form-control:disabled {
    background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
    color: ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"} !important;
  }
  input.form-control::placeholder {
    color: ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"} !important;
  }
  input.formControlStyle {
    background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  }
`;
export const FormControlStyle = styled.div`
  input.formControlStyle {
    background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  }
  .form-control:hover,
  .input-group input:hover {
    border-color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} !important;
  }
  .form-control:focus,
  .input-group input:focus {
    border-color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} !important;
  }
`;

export const WorksiteIconconDiv = styled.div`
  font-size: 15px !important;
  color: ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"};
  &:before {
    top: 4px;
    right: 7px;
    position: relative;
  }
`;
export const AssetIconconDiv = styled.div`
  font-size: 15px !important;
  color: ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"};
  &:before {
    top: 4px;
    right: 7px;
    position: relative;
  }
`;
export const UserIconconDiv = styled.div`
  font-size: 15px !important;
  color: ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"};
  &:before {
    top: 4px;
    right: 7px;
    position: relative;
  }
`;

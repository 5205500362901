import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import { Row, Col, Form } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { ThemeContext } from "styled-components";

import timeZones from "../../../constant/timeZone";
import SnackBar from "../SnackBar/SnackBar";
import UpdateButtons from "../../../components/UpdateButtons.component";
import { worksiteDetailsUpdate, resetWorksitesState } from "../../../redux/worksites/action";
import { setEditForm } from "../../../redux/forms/action";

import Input from "../../../components/Input";
import ShiftDurationPanel from "./ShiftDurationPanel.component";
import { ManagementPanelWrapper } from "./WorksiteOverviewTab.component.styles";
import PermissionCheck from "../../../components/PermissionCheck/PermissionCheck";
import SieraTabs from "../../../components/TabComponent/SieraTabs.Component";
import SieraTab from "../../../components/TabComponent/SieraTab.Component";

const labelStyle = {
  position: "relative",
  height: "0px%",
  width: "100%",
  textAlign: "left",
  marginBottom: "0px",
  fontSize: "12px",
  color: "#919191",
};

function WorksiteOverviewTab({
  worksiteOverview,
  OverviewDetails,
  statusButton,
  worksiteDetailsUpdate,
  updateLoading,
  updateSuccess,
  updateMessage,
  resetWorksitesState,
  WorksiteLoading,
  editForm,
  setEditForm,
}) {
  const { manager, address, timeZone, accountId, _id } = OverviewDetails
    ? OverviewDetails
    : { manager: "", address: "", timeZone: timeZones[0].value };
  const [value, setValue] = useState("overview");
  const [worksiteDetails, setWorksiteDetails] = useState({ manager, address, timeZone, _id });
  const [updateError, setUpdatetError] = useState("");
  const { t } = useTranslation();
  const [shiftSettings, setShiftSettings] = useState(null);

  useEffect(() => {
    setWorksiteDetails({
      ...worksiteDetails,
      manager: manager ? manager : null,
      address,
      timeZone,
      accountId,
      _id,
    });
    setUpdatetError("");
    setShiftSettings(OverviewDetails.shiftSettings);
  }, [OverviewDetails]);

  useEffect(() => {
    handleCancel();
  }, [editForm]);

  const handleChange = (newValue) => {
    setUpdatetError("");
    setValue(newValue);
  };

  const handleCancel = () => {
    setWorksiteDetails({ manager, address, timeZone, accountId, _id });
  };

  const handleUpdate = (event) => {
    event.preventDefault();
    worksiteDetailsUpdate(worksiteDetails._id, { ...worksiteDetails, shiftSettings }, setEditForm);
  };

  const handleCloseSnackBar = () => {
    resetWorksitesState();
  };

  const handleMessage = (updateSuccess, updateError) => {
    if (updateSuccess) {
      return t(updateMessage);
    } else if (updateError) {
      return updateMessage;
    }
  };

  return (
    <>
      <ManagementPanelWrapper>
        <SieraTabs
          title="tab"
          defaultActiveKey="overview"
          variant="horizontalNew"
          activeKey={value}
          onSelect={handleChange}
        >
          <SieraTab eventKey="overview" title={t("Worksite Overview")}>
            <div value={value} index="overview">
              {WorksiteLoading && (
                <div>
                  <Skeleton className="m-2" count={4} width={"90%"} />
                </div>
              )}
              {!WorksiteLoading && worksiteOverview && (
                <>
                  <Row className="worksite-overview-height">
                    <Col md={4}>
                      <div>
                        <Form.Group
                          disabled={editForm}
                          value={worksiteDetails.address}
                          className="text-open-sans"
                          onChange={(e) =>
                            setWorksiteDetails({
                              ...worksiteDetails,
                              address: e.target.value,
                            })
                          }
                        >
                          <Form.Label style={labelStyle}>
                            <Trans>Address</Trans>
                          </Form.Label>
                          <Form.Control
                            disabled={editForm}
                            value={worksiteDetails.address}
                            onChange={(e) =>
                              setWorksiteDetails({
                                ...worksiteDetails,
                                address: e.target.value,
                              })
                            }
                            type="text"
                            minLength={1}
                            maxLength={128}
                            placeholder={t("Enter address here")}
                          />
                        </Form.Group>
                      </div>
                    </Col>
                    <Col md={4} className="work-label">
                      <Input
                        disabled={editForm}
                        showLabel={true}
                        label={t("Time Zone")}
                        type="select"
                        options={timeZones?.map((option, i) => ({
                          _id: option._id,
                          label: option.label,
                        }))}
                        as="select"
                        value={worksiteDetails.timeZone}
                        onChange={(value) =>
                          setWorksiteDetails({
                            ...worksiteDetails,
                            timeZone: value,
                          })
                        }
                      />
                    </Col>
                  </Row>

                  <PermissionCheck section="DASHBOARD" permissionName="WORKSITE_MANAGEMENT" actionName="edit">
                    <Row className="editWorks editworksiteT worksiteRight">
                      <Col md={4}></Col>
                      <Col md={4}></Col>
                      <UpdateButtons
                        editOverview={editForm}
                        setEditOverview={setEditForm}
                        statusButton={statusButton}
                        handleUpdate={handleUpdate}
                        IsLoading={updateLoading}
                        overviewDetail={worksiteOverview}
                        handleCancel={handleCancel}
                      />
                    </Row>
                  </PermissionCheck>
                </>
              )}
            </div>
          </SieraTab>

          <SieraTab eventKey="shifts" title={t("Worksite Shifts")}>
            <div value={value} index="shifts">
              {!WorksiteLoading && worksiteOverview && (
                <ShiftDurationPanel
                  savedShiftSettings={OverviewDetails.shiftSettings}
                  updateLoading={updateLoading}
                  handleUpdate={handleUpdate}
                  worksiteId={worksiteOverview ? worksiteOverview._id : ""}
                  shiftSettings={shiftSettings}
                  setShiftSettings={setShiftSettings}
                  statusButton={statusButton}
                />
              )}
            </div>
          </SieraTab>
        </SieraTabs>
        <SnackBar
          isSuccess={updateSuccess}
          isFailed={updateError}
          label={handleMessage(updateSuccess, updateError)}
          handleClose={handleCloseSnackBar}
        />
      </ManagementPanelWrapper>
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  worksiteDetailsUpdate: (id, details, setEditForm) =>
    dispatch(worksiteDetailsUpdate(id, details, setEditForm)),
  resetWorksitesState: () => dispatch(resetWorksitesState()),
  setEditForm: (data) => dispatch(setEditForm(data)),
});

const mapStateToProps = (state) => ({
  worksiteOverview: state.worksites.WorksiteOverview,
  updateLoading: state.worksites.UpdateLoading,
  updateSuccess: state.worksites.UpdateSuccess,
  updateMessage: state.worksites.UpdateMessage,
  updateError: state.worksites.UpdateError,
  WorksiteLoading: state.worksites.WorksiteLoading,
  editForm: state.forms.editForm,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WorksiteOverviewTab));

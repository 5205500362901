const timeZoneLookUp = {
  "-12:00 International Date Line West": "DST",
  "-11:00 Coordinated Universal Time-11": "UTC",
  "-10:00 Hawaii": "HAST",
  "-09:00 Alaska": "AKST",
  "-08:00 Baja California": "PSTM",
  "-08:00 Alaska": "AKDT",
  "-08:00 Pacific Time (US & Canada)": "PST",
  "-07:00 Pacific Time (US & Canada)": "PDT",
  "-07:00 Arizona": "USMST",
  "-07:00 Chihuahua, La Paz, Mazatlan": "MSTM",
  "-07:00 Mountain Time (US & Canada)": "MST",
  "-06:00 Mountain Time (US & Canada)": "MDT",
  "-06:00 Central America": "CAST",
  "-06:00 Central Time (US & Canada)": "CST",
  "-06:00 Guadalajara, Mexico City, Monterrey": "CSTM",
  "-06:00 Saskatchewan": "SSK",
  "-05:00 Bogota, Lima, Quito": "SAPST",
  "-05:00 Eastern Time (US & Canada)": "EST",
  "-05:00 Indiana (East)": "USEST",
  "-05:00 Central America": "CDT",
  "-04:30 Caracas": "VNZ",
  "-04:00 Eastern Time (US & Canada)": "EDT",
  "-04:00 Asuncion": "PYT",
  "-04:00 Atlantic Time (Canada)": "AST",
  "-04:00 Cuiaba": "CBST",
  "-04:00 Georgetown, La Paz, Manaus, San Juan": "SAEST",
  "-04:00 Santiago": "SA",
  "-03:30 Newfoundland": "NLT",
  "-03:00 Brasilia": "ESAST",
  "-03:00 Buenos Aires": "ART",
  "-03:00 Cayenne, Fortaleza": "SAEST",
  "-03:00 Greenland": "CGT",
  "-03:00 Montevideo": "MVD",
  "-03:00 Salvador": "BRT",
  "-02:00 Coordinated Universal Time-02": "UTC",
  "-02:00 Mid-Atlantic - Old": "MAST",
  "-01:00 Azores": "AZOST",
  "-01:00 Cape Verde Is.": "CVT",
  "00:00 Casablanca": "MRC",
  "+00:00 Coorinated Universal Time": "UTC",
  "00:00 Edinburgh, London": "GMT",
  "+01:00 Edinburgh, London": "GMT",
  "00:00 Dubln, Lisbon": "GMT",
  "00:00 Monrvia, Reykjavik": "GST",
  "+01:00 Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna": "WET",
  "+01:00 Belgrade, Bratislava, Budapest, Ljubljana, Prague": "CET",
  "+01:00 Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna, Belgrade, Bratislava, Budapest, Ljubljana, Prague":
    "CET",
  "+01:00 Brussels, Copenhagen, Madrid, Paris": "ROM",
  "+01:00 Sarajevo, Skopje, Warsaw, Zagreb": "CEST",
  "+01:00 West Central Africa": "WCAST",
  "+01:00 Windhoek": "NMT",
  "+02:00 Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna, Belgrade, Bratislava, Budapest, Ljubljana, Prague":
    "CEDT",
  "+02:00 Athens, Bucharest": "GTB",
  "+02:00 Beirut": "MEST",
  "+02:00 Cairo": "EGY",
  "+02:00 Damascus": "EEST",
  "+02:00 E. Europe": "EET",
  "+02:00 Harare, Pretoria": "SAST",
  "+02:00 Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius": "FLE",
  "+03:00 Istanbul": "GTB",
  "+02:00 Jerusalem": "ISRAEL",
  "+02:00 Tripoli": "EET",
  "+03:00 Amman": "JRD",
  "+03:00 Baghdad": "ARABIC",
  "+02:00 Kaliningrad": "KST",
  "+03:00 Kuwait, Riyadh": "ARAB",
  "+03:00 Nairobi": "EAT",
  "+03:00 Moscow, St. Petersburg, Volgograd, Minsk": "MSK",
  "+04:00 Samara, Ulyanovsk, Saratov": "SAMT",
  "+03:30 Tehran": "IRST",
  "+04:00 Abu Dhabi, Muscat": "ARABIA",
  "+04:00 Baku": "AZT",
  "+04:00 Port Louis": "MUT",
  "+04:00 Tbilisi": "GET",
  "+04:00 Yerevan": "AMST",
  "+04:30 Kabul": "AFT",
  "+05:00 Ashgabat, Tashkent": "WAT",
  "+05:00 Yekaterinburg": "YEKT",
  "+05:00 Islamabad, Karachi": "PAK",
  "+05:30 Chennai, Kolkata, Mumbai, New Delhi": "IST",
  "+05:30 Sri Jayawardenepura": "SLST",
  "+05:45 Kathmandu": "NPT",
  "+06:00 Nur-Sultan (Astana)": "CAT",
  "+06:00 Dhaka": "CAT",
  "+06:30 Yangon (Rangoon)": "MMT",
  "+07:00 Bangkok, Hanoi, Jakarta": "SEA",
  "+07:00 Novosibirsk": "NCAST",
  "+08:00 Beijing, Chongqing, Hong Kong, Urumqi": "CHN",
  "+08:00 Krasnoyarsk": "NAST",
  "+08:00 Kuala Lumpur, Singapore": "SST",
  "+08:00 Perth": "AWST",
  "+08:00 Taipei": "TWT",
  "+08:00 Ulaanbaatar": "ULAT",
  "+08:00 Irkutsk": "NAEST",
  "+09:00 Osaka, Sapporo, Tokyo": "JST",
  "+09:00 Seoul": "KST",
  "+09:30 Adelaide": "ACDT",
  "+09:30 Darwin": "ACST",
  "+10:00 Brisbane": "AEST",
  "+10:00 Canberra, Melbourne, Sydney": "AEDT",
  "+10:00 Guam, Port Moresby": "WPT",
  "+10:00 Hobart": "AEST",
  "+09:00 Yakutsk": "YAKT",
  "+11:00 Solomon Is., New Caledonia": "SBT",
  "+11:00 Vladivostok": "VLAT",
  "+12:00 Auckland, Wellington": "NZST",
  "+12:00 Coordinated Universal Time+12": "UTC",
  "+12:00 Fiji": "FJST",
  "+12:00 Magadan": "MAGT",
  "+12:00 Petropavlovsk-Kamchatsky - Old": "PETT",
  "+13:00 Nuku'alofa": "TOT",
  "+13:00 Samoa": "SST",
};

export const getTimeWithTimeZone = (timezone) => {
  if (timeZoneLookUp[timezone]) {
    return timeZoneLookUp[timezone];
  } else {
    return "UTC";
  }
};

//TODO: Clean up this file when time permits. Optimize code, remove unused dependencies

import React, { useEffect, useState } from "react";
//import moment from "moment";
//import { Row, Col } from "react-bootstrap";
//import permission from "../../../../../../constant/permission";
//import permissions from "../../../../../../constant/permission";
import DropdownFilter from "../../../../../../components/DropdownFilter.component";

//redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  getDropdownWorksiteList,
  getDropdownAccountList,
  setDropdownAccountId,
  setDropdownWorksiteId,
  setDropdownUserAccessLevel,
} from "../../../../../../redux/dropdownFilters/action";
import { updateUser } from "../../../../../../redux/userManagement/userManagement.action";
import { setEditAlertModal } from "../../../../../../redux/modals/action";
import { ChartDivs, FilterDrop } from "./ChartsFilter.component.styles";
//Language support
import { Trans, useTranslation } from "react-i18next";
import Input from "../../../../../../components/Input";
import DurationFilterComponent from "./daurationFilter/DurationFilter.component";
import accessLevelsMap from "../../../../../../constant/accessLevelsMap";

function ChartsFilters({
  loginDetails,
  updateUser,
  getDropdownWorksiteList,
  getDropdownAccountList,
  setDropdownAccountId,
  setDropdownWorksiteId,
  setDropdownUserAccessLevel,
  userAccessLevelForSite,
  userAccessLevelForAccount,
  dropdownWorksiteList,
  dropdownAccountList,
  editForm,
  setEditAlertModal,
  dropdownFilterAccountId,
  dropdownFilterWorksiteId,
  dropdownUserAccessLevel,
  showWorksiteDropdown,
  isSingleAccountMultiSiteUser,
  isSuperAdmin,
}) {
  //const [role, setRole] = useState(null);
  const { t } = useTranslation();

  /* useEffect(() => {
    if (!loginDetails?.userData?.userType?.accessLevel) {
      return;
    }
    setRole(loginDetails?.userData?.userType?.accessLevel);

    if (!loginDetails?.permission?.permissionName) {
      return;
    }
    setRole(loginDetails?.permission?.permissionName);
  }, [loginDetails]); */

  return (
    <ChartDivs>
      {isSuperAdmin && (
        <FilterDrop>
          <DropdownFilter
            filter={{
              type: "Account",
              key: "companyName",
              data: dropdownAccountList,
            }}
            value={dropdownFilterAccountId}
            label={t("filter")}
            handleFilter={(value) => {
              setDropdownAccountId(value);
            }}
          />
        </FilterDrop>
      )}

      {showWorksiteDropdown && (
        <FilterDrop>
          <DropdownFilter
            filter={{
              type: "Worksite",
              key: "name",
              data: dropdownWorksiteList,
            }}
            value={dropdownFilterWorksiteId}
            handleFilter={(value) => {
              setDropdownWorksiteId(value);
            }}
          />
        </FilterDrop>
      )}
      {<DurationFilterComponent />}

      {/* <Col>
        <DropdownFilter
          filter={{
            type: "User Access Level",
            key: "name",
            data:
              role == permissions.SITE_ADMIN || role == permissions.SITE_MANAGER
                ? userAccessLevelForSite
                : userAccessLevelForAccount,
          }}
          value={dropdownUserAccessLevel}
          handleFilter={(value) => (editForm ? setDropdownUserAccessLevel(value) : setEditAlertModal(true))}
        />
      </Col> */}
      {/* {(role == permission.SITE_ADMIN || role == permission.SITE_MANAGER) && <Col md={3}></Col>} */}
    </ChartDivs>
  );
}

const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
  worksiteList: state.worksites.Worksites,
  accountList: state.accounts.Accounts,
  dropdownWorksiteList: state.dropdownFilters.worksiteList,
  dropdownAccountList: state.dropdownFilters.accountList,
  userAccessLevelForAccount: state.dropdownFilters.userAccessLevelForAccount,
  userAccessLevelForSite: state.dropdownFilters.userAccessLevelForSite,
  editForm: state.forms.editForm,
  dropdownFilterAccountId: state.dropdownFilters.accountId,
  dropdownFilterWorksiteId: state.dropdownFilters.worksiteId,
  dropdownUserAccessLevel: state.dropdownFilters.dropdownUserAccessLevel,
  showWorksiteDropdown: state.user.showWorksiteDropdown,

  isSuperAdmin: state.user.isSuperAdmin,
  isSingleAccountMultiSiteUser: state.user.isSingleAccountMultiSiteUser,
});

const mapDispatchToProps = (dispatch) => ({
  updateUser: (userDetail) => dispatch(updateUser(userDetail)),
  getDropdownWorksiteList: () => dispatch(getDropdownWorksiteList()),
  getDropdownAccountList: () => dispatch(getDropdownAccountList()),
  setDropdownAccountId: (accountId) => dispatch(setDropdownAccountId(accountId)),
  setDropdownWorksiteId: (siteId) => dispatch(setDropdownWorksiteId(siteId)),
  setDropdownUserAccessLevel: (accessLevel) => dispatch(setDropdownUserAccessLevel(accessLevel)),
  setEditAlertModal: (data) => dispatch(setEditAlertModal(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChartsFilters));

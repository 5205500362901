import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { InputGroup } from "react-bootstrap";
import InputField from "../../../../../components/InputFieldComponent/InputField.component";
import {
  GeneralInputContainer,
  BottomText,
  Divider,
  InputDiv,
  LeftInputText,
  LeftLabel,
  RightInput,
  RightInputText,
  SpanBottom,
  SpanTop,
  StyledInputGroup,
} from "./GeneralInput.styles";

const GeneralInputField = forwardRef(
  (
    {
      leftLabel,
      bottomLabel,
      startAdornment,
      lineDivider,
      rightLabel,
      bottomTextLabel,
      rightInputText,
      leftInputText,
      ...inputProps
    },
    ref
  ) => {
    return (
      <>
        <GeneralInputContainer>
          <LeftLabel hasAdornment={!!startAdornment}>
            <SpanTop>{leftLabel}</SpanTop>
            <SpanBottom>{rightLabel && <span>{rightLabel}</span>}</SpanBottom>
          </LeftLabel>

          <RightInput hasAdornment={!!startAdornment}>
            {startAdornment && (
              <InputGroup.Prepend>
                <StyledInputGroup id="basic-addon3">{startAdornment}</StyledInputGroup>
              </InputGroup.Prepend>
            )}
            <InputDiv hasLeftRightText={!!leftInputText || !!rightInputText}>
              <LeftInputText>{leftInputText}</LeftInputText>
              <InputField className="inputClass" size="medium" ref={ref} {...inputProps} />
              <RightInputText>{rightInputText}</RightInputText>
            </InputDiv>
            <BottomText>{bottomTextLabel}</BottomText>
          </RightInput>
        </GeneralInputContainer>

        {lineDivider && <Divider></Divider>}
      </>
    );
  }
);

GeneralInputField.propTypes = {
  leftLabel: PropTypes.string.isRequired,
  rightLabel: PropTypes.string,
  lineDivider: PropTypes.bool,
  bottomTextLabel: PropTypes.string,
  rightInputText: PropTypes.string,
  leftInputText: PropTypes.string,
};

export default GeneralInputField;

import styled from "styled-components";
export const ManagementPanelWrapper = styled.div`
  background: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  header {
    background: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  }
  .buttontabpanels span {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  }
  label {
    color: ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"} !important;
  }
  .input-group-text {
    background-color: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
    border: 1px solid ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
  }
  .input-group-text:disabled:hover {
    background-color: none;
  }
  .form-control {
    background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
    border: 1px solid ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
  }
  .form-control:disabled {
    background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
    color: ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"} !important;
  }
  inpit.enterpriseUrlStyle:focus {
    background-color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} !important;
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
    border: 1px solid ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
  }
  .input-group input:focus {
    border-color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} !important;
    border: 2px solid;
  }
  .enterpriseUrlStyle[disabled]:hover {
    border-color: ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"} !important;
  }
  .enterpriseUrlStyle:hover {
    border-color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} !important;
  }
  .div_logo_company {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
    height: 100px;
  }

  .div_logo_company img {
    max-width: 100% !important;
    max-height: 100% !important;
  }
  .tabpanelSize .MuiTabs-indicator {
    background-color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} !important;
  }
`;

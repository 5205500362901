import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Form } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import { updateLoginUserDetail } from "../../../../redux/user/user.action";
import { getUserDetailById } from "../../../../redux/users/action";
import GeneralInputField from "../../Profile/generalSetting/GeneralComponent/GeneralInput.component";
import Button from "../../../../components/Button/Button.component";
import SettingsHeader from "../../Profile/generalSetting/GeneralSettingHeader/GeneralSettingHeader.component";
import { apiCall } from "../../../../utils/apiCall";
import SnackBar from "../../SnackBar/SnackBar";
import {
  SettingsWrapper,
  ChangePasswordContainer,
  InputProfileDiv,
  InputProfileDivCont,
  InputWrapper,
  DivScroller,
  ButtonDiv,
  ButtonWrapper,
  PasswordRulesWrapper,
  PassNotMatch,
  LineDIV,
  IconDanger,
  RulesText,
} from "./ChangePin.component.styles";

function ChangePin({ isSuperAdmin, userId, userOverview, getUserDetailById, updateLoginUserDetail }) {
  const { t } = useTranslation();

  const [pinForm, setPinForm] = useState({
    userId: userId,
    currentPin: "",
    newPin: "",
    verifyPin: "",
  });
  const [validated, setValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    getUserDetailById(userId);
  }, [userId, getUserDetailById]);

  useEffect(() => {
    if (userOverview?.pin) {
      setPinForm((prevForm) => ({
        ...prevForm,
        currentPin: userOverview.pin,
      }));
    }
  }, [userOverview?.pin]);

  const validatePin = (pin) => {
    const pinRegex = /^[a-zA-Z0-9]{4,64}$/;
    return !pinRegex.test(pin);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setValidated(true);

    const pinError = validatePin(pinForm.newPin);
    if (pinError) {
      setError(t("INVALID_PIN_FORMAT"));
      return;
    }

    if (pinForm.newPin !== pinForm.verifyPin) {
      setError(t("PIN_NOT_MATCHED"));
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const response = await apiCall(`/user/update/pin`, {
        method: "PUT",
        data: {
          pin: pinForm.newPin,
          userId: pinForm.userId,
        },
      });

      setIsLoading(false);
      if (response.data.success) {
        updateLoginUserDetail({ pin: pinForm.newPin, _id: userId });
        setIsSuccess(true);

        setPinForm({
          ...pinForm,
          currentPin: pinForm.newPin,
          newPin: "",
          verifyPin: "",
        });
        setValidated(false);
      } else {
        setError(t(response?.data?.message || "ERROR_OCCURRED_WHILE_UPDATING_PIN_PLEASE_TRY_AGAIN"));
      }
    } catch (error) {
      setIsLoading(false);
      setError(t("ERROR_OCCURRED_WHILE_UPDATING_PIN_PLEASE_TRY_AGAIN"));
    }
  };

  const handleCancel = () => {
    setPinForm({
      ...pinForm,
      newPin: "",
      verifyPin: "",
    });
    setValidated(false);
    setError("");
    setIsSuccess(false);
  };

  const handleCloseSnackBar = () => {
    setIsSuccess(false);
    setError("");
  };

  const newPinError = validated && validatePin(pinForm.newPin);
  const confirmPinError = validated && pinForm.newPin !== pinForm.verifyPin;

  return (
    <SettingsWrapper>
      <SettingsHeader title={t("CHANGE_PIN_HEADER")} />
      <Form id="changeProfileForm" noValidate className="no-flex" onSubmit={handleSubmit}>
        <ChangePasswordContainer>
          <DivScroller isSuperAdmin={isSuperAdmin}>
            <InputProfileDiv>
              {pinForm.currentPin && (
                <InputProfileDivCont>
                  <InputWrapper>
                    <GeneralInputField
                      lineDivider={true}
                      leftLabel={t("YOUR_CURRENT_PIN")}
                      rightLabel={t(
                        "REQUIRED_FOR_LOGIN_TO_THE_TABLET_AND_PERFORM_INSPECTION_AND_OTHER_ACTIVITIES"
                      )}
                      required
                      variant="standard"
                      id="currentPin"
                      name="currentPin"
                      value={pinForm.currentPin}
                      type="text"
                    />
                  </InputWrapper>
                </InputProfileDivCont>
              )}

              <>
                <InputProfileDivCont>
                  <InputWrapper>
                    <GeneralInputField
                      lineDivider={true}
                      leftLabel={t("New PIN")}
                      rightLabel=""
                      name={"newPin"}
                      minLength="4"
                      maxLength="32"
                      placeholder={t("ENTER_YOUR_NEW_PIN")}
                      value={pinForm.newPin}
                      onChange={(e) =>
                        setPinForm({
                          ...pinForm,
                          newPin: e.target.value,
                        })
                      }
                      isInvalid={newPinError}
                    />
                  </InputWrapper>
                </InputProfileDivCont>

                <InputProfileDivCont>
                  <InputWrapper>
                    <GeneralInputField
                      lineDivider={true}
                      leftLabel={t("CONFIRM_NEW_PIN")}
                      rightLabel=""
                      name="verifyPin"
                      required
                      minLength={"4"}
                      placeholder={t("CONFIRM_NEW_PIN")}
                      value={pinForm.verifyPin}
                      onChange={(e) =>
                        setPinForm({
                          ...pinForm,
                          verifyPin: e.target.value,
                        })
                      }
                      isInvalid={confirmPinError}
                    />
                  </InputWrapper>
                </InputProfileDivCont>

                {confirmPinError && (
                  <PasswordRulesWrapper>
                    <PassNotMatch>
                      <IconDanger className="icon icon-alert-icon"></IconDanger>
                      <Trans>PIN_NOT_MATCHED</Trans>
                    </PassNotMatch>
                  </PasswordRulesWrapper>
                )}
                {newPinError && (
                  <PasswordRulesWrapper>
                    <PassNotMatch className="alignTop">
                      <IconDanger className="icon icon-alert-icon"></IconDanger>
                      <RulesText>
                        <LineDIV>
                          <Trans>PIN_RULES_TEXT</Trans>
                        </LineDIV>
                      </RulesText>
                    </PassNotMatch>
                  </PasswordRulesWrapper>
                )}
              </>
            </InputProfileDiv>
          </DivScroller>

          <ButtonWrapper>
            {(pinForm.newPin || pinForm.verifyPin) && (
              <ButtonDiv style={{ marginRight: "15px" }}>
                <Button label={t("Cancel")} showIcon={false} buttonType="ghost" onClick={handleCancel} />
              </ButtonDiv>
            )}

            <ButtonDiv>
              <Button
                label={t("Save")}
                iconClass="save"
                type="submit"
                showIcon
                buttonType="save"
                disabled={
                  isLoading ||
                  !(
                    pinForm.newPin &&
                    pinForm.verifyPin &&
                    pinForm.newPin.length >= 4 &&
                    pinForm.newPin === pinForm.verifyPin
                  )
                }
              />
            </ButtonDiv>
          </ButtonWrapper>
        </ChangePasswordContainer>
      </Form>

      {(isSuccess || error) && (
        <SnackBar
          isSuccess={isSuccess}
          isWarning={!!error}
          label={isSuccess ? t("USER_PIN_UPDATED_SUCCESSFULLY") : error}
          handleClose={handleCloseSnackBar}
        />
      )}
    </SettingsWrapper>
  );
}

const mapStateToProps = (state) => ({
  userId: state.user.loginDetails.userData?._id,
  userOverview: state.users.UserOverview,
  isSuperAdmin: state.user.isSuperAdmin,
});

const mapDispatchToProps = (dispatch) => ({
  updateLoginUserDetail: (data) => dispatch(updateLoginUserDetail(data)),
  getUserDetailById: (id) => dispatch(getUserDetailById(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangePin);

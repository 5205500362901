/**
 * TODO:  Create a common filter component for all the reports/Dashboard pages when time permits
 * Do code optimization
 * Fix lint issues
 * Once completed, please remove this comment
 * Create common components
 */

import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  exportAllImpactCSV,
  getZipDownloadImpactReport,
  exportAllImpactCSVResetState,
} from "../../../../redux/reports/action";
import { setDropdownAccountId, setDropdownWorksiteId } from "../../../../redux/dropdownFilters/action";
import { setIncidentReportFilter } from "../../../../redux/filters/action";
import { updateActiveNavItem } from "../../../../redux/sideMenu/menu.action";

import "./../Report.css";
import { getImpactReportListing } from "../../../../redux/reports/action";
import { toDownloadFiles } from "../../../../redux/config/action";
import DownloadAllReportModal from "../../Modals/DownloadAllReportModal";

import Filters from "./components/Filters.component";

import { rowPerPageDropdown } from "./constants";
import SaveToOptions from "./components/SaveToOptions.component";

import ImpactReportsDataTable from "./TableWrapper/TableWrapper.component";

function InspectionReports(props) {
  const {
    loginDetails,

    isSuperAdmin,
    isSiteAdmin,

    setDropdownAccountId,
    setDropdownWorksiteId,
    getImpactReportListing,
    inspectionReportList,

    totalInspectionReport,
    getZipDownloadImpactReport,
    getZipFileImpactReportLoading,
    dropdownWorksiteList,
    dropdownAccountList,
    dropdownAssetList,
    dropdownAssetTypeList,
    incidentReportFilter,
    setIncidentReportFilter,
    dropdownFilterAccountId,
    dropdownFilterWorksiteId,
    exportAllImpactCSV,
    exportAllImpactCSVLoading,
    exportAllImpactCSVSuccess,
    exportAllImpactCSVResetState,
  } = props;

  //const { t } = useTranslation();
  const [filterWorksiteId, setFilterWorksiteId] = useState("all");
  const [filterAccountId, setFilterAccountId] = useState("all");

  const [filterByInspectionResult, setFilterByInspectionResult] = useState("all");
  const [filterAssetId, setFilterAssetId] = useState("all");
  const [filterAssetTypeId, setFilterAssetTypeId] = useState("all");
  const [filterInspectorId, setFilterInspectorId] = useState("all");
  //const [loadingReportId, setLoadingReportId] = useState("");
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(incidentReportFilter?.pageSize || 10);
  const [downloadReportSet, addDownloadReportSet] = useState(new Set());
  //const [currentActiveKey, setCurrentActiveKey] = useState("");
  const [isAllSelected, setSelectAll] = useState(false);
  const [incidentTypeFilter, setIncidentTypeFilter] = useState("all");
  const [isDownloadAllSelected, setIsDownloadAllSelected] = useState(false);
  const [showDownloadAllReportModal, setShowDownloadAllReportModal] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(null);

  let { filterByType } = incidentReportFilter;

  /*   const userData = {
    token: loginDetails?.token,
    sharedByUserName: loginDetails?.userData?.fullName,
  }; */

  /*   const toogleDropdown = (eventKey) => {
    if (eventKey !== currentActiveKey) {
      setCurrentActiveKey(eventKey);
    } else {
      setCurrentActiveKey("");
    }
  }; */

  useEffect(() => {
    setFilterWorksiteId("all");
  }, [filterAccountId]);

  useEffect(() => {
    setFilterAssetTypeId("all");
    setFilterAssetId("all");
    setFilterInspectorId("all");
    setFilterByInspectionResult("all");
    setIncidentTypeFilter("all");
  }, [filterByType]);

  const handleCheckboxClick = (e) => {
    const newDownloadReportSet = new Set(downloadReportSet);
    if (e.target.checked) {
      newDownloadReportSet.add(e.target.value);
    } else {
      newDownloadReportSet.delete(e.target.value);
    }
    addDownloadReportSet(newDownloadReportSet);
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      const newDownloadReportSet = new Set(downloadReportSet);
      inspectionReportList &&
        inspectionReportList.forEach((data, i) => {
          newDownloadReportSet.add(data._id);
        });
      addDownloadReportSet(newDownloadReportSet);
      setSelectAll(true);
    } else {
      const newDownloadReportSet = new Set();
      addDownloadReportSet(newDownloadReportSet);
      setSelectAll(false);
    }
  };

  useEffect(() => {
    setIncidentReportFilter({
      ...incidentReportFilter,
      assetId: "all",
      assetTypeId: "all",
      inspectorId: "all",
      impactSeverity: "all",
      pageNo: 1,
    });
  }, [dropdownFilterAccountId]);

  const handlePageLength = (e) => {
    setPageSize(e.target.value);
    setIncidentReportFilter({
      ...incidentReportFilter,
      pageSize: e.target.value,
      pageNo: 1,
    });
    addDownloadReportSet(new Set());
    setPageNo(1);
    //getImpactReportListing(filterData)
  };

  useEffect(() => {
    setIncidentReportFilter({ ...incidentReportFilter, worksiteId: "all" });
    if (incidentReportFilter.accountId === "all") {
      setSelectedAccount(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incidentReportFilter.accountId]);

  useEffect(() => {
    setIncidentReportFilter({
      ...incidentReportFilter,
      assetTypeId: "all",
      assetId: "all",
      inspectorId: "all",
      impactSeverity: "all",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incidentReportFilter.filterByType]);

  const nextPage = () => {
    if (pageNo > Math.ceil((totalInspectionReport || 0) / pageSize) - 1) {
      return;
    }
    let newPage = pageNo + 1;
    setIncidentReportFilter({ ...incidentReportFilter, pageNo: newPage });
    setPageNo(newPage);
    addDownloadReportSet(new Set());
    //getImpactReportListing(filterData)
  };

  const previousPage = () => {
    if (pageNo <= 1) {
      return;
    }
    let newPage = pageNo - 1;
    setIncidentReportFilter({ ...incidentReportFilter, pageNo: newPage });
    setPageNo(newPage);
    addDownloadReportSet(new Set());
    //getImpactReportListing(filterData)
  };

  useEffect(() => {
    incidentReportFilter.obstacleType = incidentReportFilter.obstacleType || "all";
    incidentReportFilter.incidentType = incidentReportFilter.incidentType || "all";

    getImpactReportListing(incidentReportFilter);
    addDownloadReportSet(new Set());
  }, [incidentReportFilter]);

  useEffect(() => {
    if (!isSuperAdmin) {
      setIncidentReportFilter({
        ...incidentReportFilter,
        accountId: loginDetails && loginDetails.accountId,
      });
    }
    getImpactReportListing(incidentReportFilter);
  }, [loginDetails]);

  useEffect(() => {
    const startResultNumber = pageSize * (pageNo - 1);
    const endResultNumber =
      parseInt(pageSize * (pageNo - 1)) + parseInt(pageSize) < totalInspectionReport
        ? parseInt(pageSize * (pageNo - 1)) + parseInt(pageSize)
        : totalInspectionReport;
    const currentTotalReports = endResultNumber - startResultNumber;

    if (downloadReportSet.size == currentTotalReports && currentTotalReports != 0) {
      setSelectAll(true);
    } else {
      setIsDownloadAllSelected(false);
      setSelectAll(false);
    }
  }, [downloadReportSet]);

  /*   const downloadReportApiCall = (id) => {
    setLoadingReportId(id);
    toDownloadFiles(awsConstant.impactReport, `${id}.pdf`);
  };

  const handleClearAllSelection = () => {
    addDownloadReportSet(new Set());
    setIsDownloadAllSelected(false);
  }; */

  useEffect(() => {
    if (!showDownloadAllReportModal) {
      addDownloadReportSet(new Set());
    }
  }, [showDownloadAllReportModal]);

  useEffect(() => {
    if (exportAllImpactCSVSuccess) {
      setShowDownloadAllReportModal(true);
      exportAllImpactCSVResetState();
    }
  }, [exportAllImpactCSVSuccess]);

  /* const viewReportClickHandler = (e, data) => {
    if (
      data?.impactSeverity?.toLowerCase?.() === "low" ||
      data?.impactSeverity?.toLowerCase?.() === "medium" ||
      data?.impactSeverity?.toLowerCase?.() === "high"
    ) {
      localStorage.setItem("impactReportObject", JSON.stringify(data));
      window.open("/#/app/impact-report", "_blank");
    } else {
      localStorage.setItem("nearMissReportObject", JSON.stringify(data));
      window.open("/#/app/near-miss-report", "_blank");
    }
  }; */

  return (
    <>
      <Filters
        isSuperAdmin={isSuperAdmin}
        isSiteAdmin={isSiteAdmin}
        setDropdownAccountId={setDropdownAccountId}
        setDropdownWorksiteId={setDropdownWorksiteId}
        dropdownWorksiteList={dropdownWorksiteList}
        dropdownAccountList={dropdownAccountList}
        incidentReportFilter={incidentReportFilter}
        setIncidentReportFilter={setIncidentReportFilter}
        dropdownAssetList={dropdownAssetList}
        dropdownAssetTypeList={dropdownAssetTypeList}
        loginDetails={loginDetails}
        dropdownFilterWorksiteId={dropdownFilterWorksiteId}
        setIncidentTypeFilter={setIncidentTypeFilter}
        incidentTypeFilter={incidentTypeFilter}
        setPageNo={setPageNo}
        selectedAccount={selectedAccount}
        setSelectedAccount={setSelectedAccount}
      />
      <SaveToOptions
        downloadReportSet={downloadReportSet}
        getZipDownloadImpactReport={getZipDownloadImpactReport}
        loginDetails={loginDetails}
        exportAllImpactCSV={exportAllImpactCSV}
        incidentReportFilter={incidentReportFilter}
        getImpactReportListing={getImpactReportListing}
        addDownloadReportSet={addDownloadReportSet}
        getZipFileImpactReportLoading={getZipFileImpactReportLoading}
        isDownloadAllSelected={isDownloadAllSelected}
        exportAllImpactCSVLoading={exportAllImpactCSVLoading}
        inspectionReportList={inspectionReportList}
      />
      <br />

      <ImpactReportsDataTable
        data={inspectionReportList}
        isSiteAdmin={isSiteAdmin}
        isSuperAdmin={isSuperAdmin}
        handleSelectAll={handleSelectAll}
        handleCheckboxClick={handleCheckboxClick}
        downloadReportSet={downloadReportSet}
        isAllSelected={isAllSelected}
        handlePageLength={handlePageLength}
        pageSize={pageSize}
        paginationOptions={rowPerPageDropdown}
        pageNo={pageNo}
        totalPageReport={totalInspectionReport}
        previousPage={previousPage}
        nextPage={nextPage}
        selectedAccount={selectedAccount}
        rowHeight={"370"}
      />

      <DownloadAllReportModal
        showDownloadAllReportModal={showDownloadAllReportModal}
        setShowDownloadAllReportModal={setShowDownloadAllReportModal}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  incidentReportFilter: state.filters.incidentReportFilter,
  worksiteList: state.worksites.Worksites,
  accountList: state.accounts.Accounts,
  fileDownloadLoading: state.config.fileDownloadLoading,
  assetOverview: state.assets.AssetOverview,
  assetsList: state.assets.Assets,
  assetTypeList: state.assets.AssetTypes,
  dropdownWorksiteList: state.dropdownFilters.worksiteList,
  dropdownAccountList: state.dropdownFilters.accountList,
  dropdownAssetList: state.dropdownFilters.assetList,
  dropdownAssetTypeList: state.dropdownFilters.assetTypeList,
  dropdownFilterAccountId: state.dropdownFilters.accountId,
  dropdownFilterWorksiteId: state.dropdownFilters.worksiteId,
  loginDetails: state.user.loginDetails,
  isSuperAdmin: state.user.isSuperAdmin,
  isSiteAdmin: state.user.isSiteAdmin,
  inspectionReportList: state.reports.impactReportList,
  getImpactReportListLoading: state.reports.getImpactReportListLoading,
  totalInspectionReport: state.reports.impactReportTotalCount,
  exportAllImpactCSVLoading: state.reports.exportAllImpactCSVLoading,
  exportAllImpactCSVSuccess: state.reports.exportAllImpactCSVSuccess,
  getZipFileImpactReportLoading: state.reports.getZipFileImpactReportLoading,
});

const mapDispatchToProps = (dispatch) => ({
  getImpactReportListing: (filterData) => dispatch(getImpactReportListing(filterData)),
  getZipDownloadImpactReport: (filterReportArray) => dispatch(getZipDownloadImpactReport(filterReportArray)),
  setDropdownAccountId: (accountId) => dispatch(setDropdownAccountId(accountId)),
  setDropdownWorksiteId: (worksiteId) => dispatch(setDropdownWorksiteId(worksiteId)),
  setIncidentReportFilter: (filterData) => dispatch(setIncidentReportFilter(filterData)),
  updateActiveNavItem: (item) => dispatch(updateActiveNavItem(item)),
  toDownloadFiles: (folderName, fileName) => dispatch(toDownloadFiles(folderName, fileName)),
  exportAllImpactCSV: () => dispatch(exportAllImpactCSV()),
  exportAllImpactCSVResetState: () => dispatch(exportAllImpactCSVResetState()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InspectionReports));

import styled from "styled-components";
export const AssetBulkUploadModalPopup = styled.div`
  background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
  .modal-title {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  }
  .bulkTable table thead {
    background-color: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
  }
  .bulkTable table thead tr th {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  }
  .table td,
  .table th {
    padding: 0.5rem;
    vertical-align: top;
    border-top: 1px solid ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
  }
  td.respo_bulk_a,
  td.respo_bulk_b {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  }
  td.respo_bulk_c svg .cls-1 {
    fill: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
  }
  .bulkTable table tbody {
    border: 1px solid ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
  }
  .custom-file label svg {
    fill: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  }
  .uploadStyleCode {
    background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
    border: 2px solid ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} !important;
  }
  .uploadStyleCode span.font-weight-bolder {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  }
  .uploadStyleCode span {
    color: ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"};
  }
  .table_TD table th {
    background: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  }
  .table-striped tbody tr:nth-of-type(odd) {
    background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  }
  .table-striped tbody tr:nth-of-type(odd) td {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  }
  .table-striped tbody tr:nth-of-type(odd) div svg .cls-1 {
    fill: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
  }
  p.elipsis_asset {
    background: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
  }
  .bulk_information_header_white {
    background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
  }
`;

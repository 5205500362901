import styled from "styled-components";

export const ChartDivs = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 10px;
  position: relative;
`;

export const FilterDrop = styled.div`
  width: 30%;
  margin-right: 2%;
`;

import React, { useState, useEffect } from "react";
import { SlideContainer, ToggleButton, SlideContent, Icon, DivContent } from "./InfoDiv.component.styles";
import PropTypes from "prop-types";

const SlideButton = ({ buttonName, iconName, divContent }) => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleVisibility = () => {
    setIsOpen((prevState) => !prevState);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1100) {
        setIsOpen(false);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <SlideContainer open={isOpen}>
      <ToggleButton onClick={toggleVisibility} open={isOpen}>
        {iconName && <Icon className={`icon-${iconName}`} alt="Icon" />}
        {buttonName}
      </ToggleButton>
      <SlideContent open={isOpen}>
        <DivContent>{divContent}</DivContent>
      </SlideContent>
    </SlideContainer>
  );
};

SlideButton.propTypes = {
  /**
   * The name of the button.
   * Used to display the text content of the button.
   */
  buttonName: PropTypes.string.isRequired,

  /**
   * The name of the icon to be displayed inside the button.
   * Used to visually represent an icon associated with the button.
   */
  iconName: PropTypes.string,

  /**
   * The content to be displayed inside the sliding panel when the button is clicked.
   * Used to provide additional information or details related to the button.
   */
  divContent: PropTypes.string,
};

SlideButton.defaultProps = {
  iconName: null,
};

export default SlideButton;

import React from "react";
import styled from "styled-components";
const SearchBarStyledCss = styled.div`
  #searchQueryInput {
    background: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
    border: 2px solid ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"} !important;
  }
`;

const SearchIconClass = styled.div`
  font-size: 17px !important;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  position: relative;
  top: 2px;
  right: -7px;
`;
function SearchBox({ placeholder, SearchText, setSearchText }) {
  return (
    <SearchBarStyledCss>
      <div className="searchBar">
        <input
          id="searchQueryInput"
          maxLength={64}
          minLength={1}
          type="text"
          name="searchQueryInput"
          placeholder={placeholder}
          onChange={(e) => setSearchText(e.target.value)}
          value={SearchText}
          style={{ border: "2px solid #f1f1f1", borderRadius: "none" }}
        />
        <button id="searchQuerySubmit" type="submit" name="searchQuerySubmit">
          <SearchIconClass className="icon icon-search"></SearchIconClass>
        </button>
      </div>
    </SearchBarStyledCss>
  );
}

export default SearchBox;

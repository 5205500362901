import React from "react";

import { Trans, useTranslation } from "react-i18next";
import { ReactComponent as Information } from "../../../../../assets/Information_Icon.svg";
import { ReactComponent as IconSuccess } from "../../../../../assets/AddIcon/IconRight.svg";
import { ReactComponent as Download } from "../../../../../assets/Download.svg";
import { ReactComponent as IconPartial } from "../../../../../assets/AddIcon/iconPartial.svg";
import { ReactComponent as IconFailure } from "../../../../../assets/AddIcon/iconFailure.svg";
import { ReactComponent as DeleteIcon } from "../../../../../assets/AddIcon/Delete Button.svg";
import bulkUploadStatusType from "./bulkUploadStatusTypes";
import { resetBulkUploadAssetState } from "../../../../../redux/bulkUpload/action";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

function AssetBulkUploadErrorHandle(props) {
  let { type, data, setActiveSection, localModalClose, resetBulkUploadAssetState, assetBulkUploadStatus } =
    props;

  const handleContinueButton = () => {
    localModalClose();
  };

  const handleReuploadButton = () => {
    resetBulkUploadAssetState();
    setActiveSection("uploadFiles");
  };

  return (
    <>
      {type === bulkUploadStatusType.BULK_UPLOAD_SUCCESS && (
        <div className="uploadMessage">
          <h3 className="text-kanit">
            <Trans>Assets Added</Trans>
          </h3>
          <p className="rightIcon">
            <IconSuccess />
          </p>
          <p className="text-open-sans text-uploadMessage">
            <Trans>The file was uploaded successfully</Trans>.
          </p>
          <p className="text-open-sans userAddedUpload">
            <Trans>Items successfully added</Trans> :{" "}
            <span className="successfullAddedS3">{assetBulkUploadStatus["Inserted Records"]}</span>
          </p>
          <br></br>
          {/* <p class="text-open-sans userFailedUpload"><span>{userFailedCount} </span>Items <span className="redFailed">failed </span>to add</p> */}
          <button
            style={{ marginBottom: "10px" }}
            className="buttonSubmit bulkUploadYes"
            onClick={() => {
              handleContinueButton();
            }}
          >
            <Trans>CONTINUE</Trans>
          </button>
          <br></br>
          {/* <span onClick={() => handleReuploadButton()} style={{ cursor: "pointer" }}>RE-UPLOAD</span> */}
          <p className="s3InstructionModal">
            <Information />
            <span>
              <Trans>An email has also been sent to your account with the details of assets added</Trans>
            </span>
          </p>
        </div>
      )}

      {type === bulkUploadStatusType.BULK_UPLOAD_INTERNAL_ERROR && (
        <div className="uploadMessage">
          <h3 className="text-kanit">
            <Trans>Upload Failed</Trans>
          </h3>
          <p className="rightIcon">
            <IconFailure />
          </p>
          <p className="text-open-sans text-uploadMessage">
            <Trans>The file was not uploaded</Trans>.
          </p>
          <p className="text-open-sans userAddedUpload">
            <span className="redFailed">
              <Trans>An internal error occurred</Trans>.<br></br>
              <Trans>Please try uploading again</Trans>.
            </span>
          </p>
          <br></br>
          <button style={{ marginBottom: "10px" }} className="buttonSubmit bulkUploadYes" onClick={() => {}}>
            <Trans>TRY AGAIN</Trans>
          </button>
          <br></br>
          <span
            className="bulkUploadcontinue"
            onClick={() => {
              handleContinueButton();
            }}
            style={{ cursor: "pointer" }}
          >
            <Trans>CONTINUE</Trans>
          </span>
          <br></br>
          <br></br>
          {/* <p><img src={Information} />Please remove the error column from CSV file to avoid data mismatch errors.</p> */}
        </div>
      )}

      {type === bulkUploadStatusType.BULK_UPLOAD_PARTIAL_ADDED && (
        <div className="uploadMessage">
          <h3 className="text-kanit">
            <Trans>Assets Added</Trans>
          </h3>
          <p className="rightIcon">
            <IconPartial />
          </p>

          <p className="text-open-sans text-uploadMessage">
            <Trans>The file was uploaded with some errors</Trans>.
          </p>
          <p className="text-open-sans userAddedUpload">
            <Trans>Items successfully added</Trans> :{" "}
            <span className="successfullAddedS3"> {assetBulkUploadStatus["Inserted Records"]} </span>
          </p>
          <p className="text-open-sans userFailedUpload">
            <Trans>Items failed to add</Trans>:{" "}
            <span className="redFailed">{assetBulkUploadStatus["Failed Records"]} </span>
          </p>
          <p className="text-open-sans text-uploadMessage">
            <Trans>Click</Trans>{" "}
            <a className="clickHereA" href={assetBulkUploadStatus["ErrorLog"]} target="_blank">
              <Trans>here</Trans>
            </a>{" "}
            <Trans>to download the CSV file of failed items</Trans>.
          </p>
          <button
            style={{ marginBottom: "10px" }}
            className="buttonSubmit bulkUploadYes"
            onClick={() => handleContinueButton()}
          >
            <Trans>CONTINUE</Trans>
          </button>
          <br></br>
          <span
            className="bulkUploadcontinue"
            onClick={() => handleReuploadButton()}
            style={{ cursor: "pointer" }}
          >
            <Trans>RE-UPLOAD</Trans>
          </span>
          <br></br>
          <p className="s3InstructionModal">
            <Information />
            <span>
              <Trans>The reasons for errors are listed in the CSV file of failed items</Trans>.<br></br>
              <Trans>
                Please remove the error column from the 'failed items' CSV file to avoid data mismatch errors
              </Trans>
              .
            </span>
          </p>
        </div>
      )}

      {type === bulkUploadStatusType.BULK_UPLOAD_EMPTY_FILE && (
        <div className="uploadMessage">
          <h3 className="text-kanit">
            <Trans>Unable to Add Assets</Trans>
          </h3>
          <p className="rightIcon">
            <IconFailure />
          </p>
          <p className="text-open-sans text-uploadMessage">
            <Trans>The assets were not added from the file</Trans>
          </p>
          <p className="text-open-sans userAddedUpload">
            <span className="redFailed">
              <Trans>The uploaded file is empty</Trans>.
            </span>
          </p>
          <br></br>
          <button
            style={{ marginBottom: "10px" }}
            className="buttonSubmit bulkUploadYes"
            onClick={() => handleReuploadButton()}
          >
            <Trans>RE-UPLOAD</Trans>
          </button>
          <br></br>
          <span
            className="bulkUploadcontinue"
            onClick={() => handleContinueButton()}
            style={{ cursor: "pointer" }}
          >
            <Trans>CONTINUE</Trans>
          </span>
          <br></br>
          <br></br>
          <p className="s3InstructionModal">
            <Information />
            <span>
              <Trans>Please enter the information of your employees in this file and upload again</Trans>.
            </span>
          </p>
        </div>
      )}

      {type === bulkUploadStatusType.BULK_UPLOAD_DATA_MISMATCH && (
        <div className="uploadMessage">
          <h3 className="text-kanit">
            <Trans>Unable to Add Assets</Trans>
          </h3>
          <p className="rightIcon">
            <IconFailure />
          </p>
          <p className="text-open-sans text-uploadMessage">
            <Trans>The assets were not added from the file</Trans>
          </p>
          <p className="text-open-sans userAddedUpload">
            <span className="redFailed">
              <Trans>Data mismatch in columns</Trans>.
            </span>
          </p>
          <p className="text-open-sans userAddedUpload">
            <span className="redFailed">
              <Trans>Please remove extra data columns and upload again</Trans>.
            </span>
          </p>
          <br></br>
          <button
            style={{ marginBottom: "10px" }}
            className="buttonSubmit bulkUploadYes"
            onClick={() => handleReuploadButton()}
          >
            <Trans>RE-UPLOAD</Trans>
          </button>
          <br></br>
          <span
            className="bulkUploadcontinue"
            onClick={() => handleContinueButton()}
            style={{ cursor: "pointer" }}
          >
            <Trans>CONTINUE</Trans>
          </span>
          <br></br>
          <br></br>
          <p className="s3InstructionModal">
            <Information />
            <span>
              <Trans>Please remove the error column from CSV file to avoid data mismatch errors</Trans>.
            </span>
          </p>
        </div>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  assetBulkUploadStatus: state.bulkUpload.assetBulkUploadFileUploadStatus,
});

const mapDispatchToProps = (dispatch) => ({
  resetBulkUploadAssetState: () => dispatch(resetBulkUploadAssetState()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AssetBulkUploadErrorHandle));

import React from "react";
import AccountNameFilter from "../../../components/DropdownFilter.component";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import permissions from "../../../constant/permission";
import { useTranslation } from "react-i18next";
import { setDropdownAccountId } from "../../../redux/dropdownFilters/action";

function WorksiteFilters({ loginDetails, filterAccountId, setDropdownAccountId, dropdownAccountList }) {
  const { t } = useTranslation();

  return (
    <>
      {loginDetails?.permission?.permissionName == permissions.SUPER_ADMIN && (
        <AccountNameFilter
          filter={{
            type: "Account",
            name: "companyName",
            key: "companyName",
            data: dropdownAccountList,
          }}
          label={t("filter")}
          handleFilter={(value) => setDropdownAccountId(value)}
        />
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
  accountList: state.accounts.Accounts,
  dropdownAccountList: state.dropdownFilters.accountList,
});

const mapDispatchToProps = (dispatch) => ({
  setDropdownAccountId: (accountId) => dispatch(setDropdownAccountId(accountId)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WorksiteFilters));

export default {
  dashboard: "",
  management: "Management",
  assettype: "Asset Types",
  userType: "User Types",
  qrcode: "QR Codes",
  add: "Add",
  checklistmanagement: "Checklist Settings",
  setting: "Settings",
  inspectAsset: "Inspect Asset",
  "add-checklist": "Add Checklist",
  billing: "Billing",
  payment: "Payment",
  report: "Reports",
  "system-setting": "System Settings",
  settings: "",
  "user-profile": "User Profile",
  "account-profile": "Account Profile",
  "s2-settings": "",
  "s3-settings": "",
  "notification-settings": "",
  templates: "Checklist Templates",
  assetstatus: "Asset Status",
  "error-logs": "Error Logs",
  "device-information": "Device Information",
  analytics: "Analytics",
  "login-records": "Login Records",
  "general-setting": "General Settings",
  "password-policy": "Password Policy",
  "mfa-authentication": "MFA Authentication",
  pin: "Change PIN",
  password: "Change Password",
};

import styled from "styled-components";
import { ReactComponent as ProfileImage } from "../../../assets/image.svg";

export const SettingsWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
  border-radius: 3px;
  width: 98%;
  margin-left: 0% !important;
  @media (max-width: 768px) {
    margin-bottom: 20px;
    width: 100%;
  }
`;

export const InputWrapper = styled.div`
  margin-top: ${({ marginTop }) => marginTop};
`;

export const Container = styled.div`
  display: flex;
  justify-content: right;
  clear: both;
  width: 100%;
  margin-top: -10px;
  @media (max-width: 1100px) {
    justify-content: left;
    width: 93%;
    margin: -8px 10px auto;
  }
`;

export const UrlText = styled.div``;

export const AvailableText = styled.span`
  color: green;
  margin-left: 5px;
`;

export const NotAvailableText = styled.span`
  color: red;
  margin-left: 5px;
`;
export const Divider = styled.div`
  clear: both;
  width: 100%;
  height: 1px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray5 || "#CCCCCC"};
`;

export const DivScroller = styled.div`
  clear: both;
  height: calc(100vh - 260px);
  overflow-y: auto;
  @media (max-width: 1100px) {
    height: ${({ isSuperAdmin }) => (isSuperAdmin ? "calc(100vh - 400px)" : "calc(100vh - 330px)")};
  }
  &::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
    border: 1px solid #f2f2f2;
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: green !important;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
  }
`;

export const AccountProfileContainer = styled.div`
  width: 100%;
  margin: auto;
`;

export const InputProfileDiv = styled.div`
  width: 96%;
  margin: auto;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const InputProfileDivCont = styled.div``;

export const ContainerImage = styled.div`
  margin-bottom: 16px;
  display: flex;
  margin-top: 10px;
  @media (max-width: 1100px) {
    display: block;
    overflow: hidden;
    padding: 0px 10px;
  }
`;

export const LabelContainer = styled.div`
  flex: 1;
  @media (max-width: 1100px) {
    width: 100%;
  }
`;

export const FormLabel = styled.label`
  font-size: 1rem;
`;

export const ChangeImage = styled.div`
  height: 50px;
  color: #fff;
  line-height: 40px;
  width: 100%;
  padding: 5px;
  position: absolute;
  bottom: 0;
  text-align: center;
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.5);
  span {
    font-family: "Open Sans", sans-serif;
    font-size: 18px !important;
  }
`;

export const CompanyLogo = styled.div`
  margin: 5px 0px;
  width: 40%;
  border-radius: 4px;
  height: 120px;
  float: left;
  border: 1px solid ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  background-image: ${({ imageUrl }) => `url(${imageUrl})`};
  &:hover ${ChangeImage} {
    visibility: visible;
  }

  ${ChangeImage} input[type="file"] {
    visibility: hidden;
  }
  @media (max-width: 1100px) {
    width: 100%;
  }
`;
export const ProfilePicUpload = styled.div`
  position: relative;
`;

export const InputUploader = styled.input`
  opacity: 0;
  position: absolute;
  width: 150px;
  height: 150px;
  cursor: pointer;
  z-index: 3333;
`;

export const HeaderLabel = styled.div`
  color: ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"};
  font-size: 14px !important;
  font-family: "Open Sans", sans-serif;
  line-height: 18px;
  margin-bottom: 5px;
`;

export const HoverText = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  font-size: 14px;
  color: white;
  opacity: 0;
  transition: opacity 0.3s ease;
  width: 150px;
  height: 75px;
  background-color: rgba(0, 0, 0, 0.5);
  /* clip-path: ellipse(75px 37.5px at 50% 100%); Clip to create half-circle */
  padding: 5px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  text-align: center;
`;

export const ImageWrapper = styled.div`
  position: relative;
  width: 150px;
  height: 150px;
  border-radius: 100%;
  overflow: hidden;
  &:hover ${HoverText} {
    opacity: 1;
  }
`;

export const ImagePreview = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 100%;
  cursor: pointer;
  transition: border-color 0.3s ease;
  /* filter: brightness(0) saturate(100%) invert(42%) sepia(12%) saturate(3894%) hue-rotate(175deg) brightness(83%) contrast(116%); */
  &:hover ${HoverText} {
    opacity: 1;
  }
`;

export const FileUploaderWrapper = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Open+Sans:wght@300;400;500;600;700&display=swap");
  display: flex;
  background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  label.labelContainer {
    color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
    font-size: 14px !important;
    font-family: "Open Sans", sans-serif;
    line-height: 18px;
    margin-bottom: 0px;
  }

  .BorderDefault {
    border: 2px solid ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"};
    &:hover {
      border: 2px dashed ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
    }
  }
  .BorderError {
    border: 2px dashed ${({ theme }) => theme.colors.redPrimary || "#DD0000"};
  }
`;

export const FileUploaderDiv = styled.div`
  flex: 1;
  position: relative;
`;

export const FileUploaderCont = styled.span``;

export const LeftLabel = styled.div`
  float: left;
`;

export const RightInput = styled.div`
  float: right;
  width: 40%;
`;

export const SpanTop = styled.div`
  text-align: left;
  font-size: 16px !important;
  font-family: "Open Sans", sans-serif;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
`;
export const SpanBottom = styled.div`
  text-align: left;
  font-size: 14px !important;
  font-family: "Open Sans", sans-serif;
  color: ${({ theme }) => theme.colors.grayL3 || "#676767"};
`;

export const ButtonDiv = styled.div`
  text-align: right;
  @media (max-width: 1100px) {
    button[title="Cancel"] {
      float: left;
    }
  }
`;
export const ButtonWrapper = styled.div`
  width: 96%;
  margin: 10px auto;
  display: flex;
  justify-content: end;
  @media (max-width: 1100px) {
    display: block;
  }
`;

export const ModalBodyHeight = styled.div`
  height: calc(100vh - 260px);
  align-content: center;
  text-align: center;
  .ReactCrop {
    align-items: center;
    justify-content: center;
  }
  img.ReactCrop__image {
    /*  height: calc(100vh - 260px); */
    height: 100%;
    max-height: calc(100vh - 280px);
  }
`;

export const AvatarImage = styled.img`
  max-width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const StyledProfileImage = styled(ProfileImage)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  &.icon-svg {
    width: 100%;
    height: 100%;
  }
`;

/**
 * @author Nagarjuna Naidu <nagarjuna.naidu@siera.ai>
 *  SIERA DASHBOARD PAGE USER MANAGMENT ACTION CREATORS
 */

import { userManagementTypes } from "./userManagement.types";
import config from "../../../src/config";
import axios from "axios";
import { apiCall } from "../../utils/apiCall";
import { CacheRefs } from "../../utils/cache/CacheRefs";
const baseUrl =  config.baseUrl
// 1. Adding user to enterprise account with payload user details
export const addUser = (userDetails) => ({
  type: userManagementTypes.ADD_USER,
  payload: userDetails,
});

// 2. Selecting all users from enterprise account with payload users
export const userList = (users) => ({
  type: userManagementTypes.LIST_USERS,
  payload: users,
});

// 3. Selected user overview with payload user id
export const userOverview = (userId) => ({
  type: userManagementTypes.USER_OVERVIEW,
  payload: userId,
});

// 4. Selected default user overview from the user list
export const defaultUserOverview = () => ({
  type: userManagementTypes.DEFAULT_USER_OVERVIEW,
});

// 5. Update the user list
export const updateUser = (user) => ({
  type: userManagementTypes.UPDATE_USER,
  payload: user,
});

//To edit asset name
const toEditUserNameLoading = () => ({
  type: userManagementTypes.EDIT_USERNAME_LOADING,
});

const toEditUserNameSuccess = (data) => ({
  type: userManagementTypes.EDIT_USERNAME_SUCCESS,
  data,
});

const toEditUserNameError = (err) => ({
  type: userManagementTypes.EDIT_USERNAME_ERROR,
  err,
});

//To edit username
export const toEditUserName = (value, id) => {
  return async (dispatch, getState) => {
    dispatch(toEditUserNameLoading());

    let userData = new FormData();
    let data = {
      fullName: value,
    };

    userData.append("userData", JSON.stringify(data));

    try {
      const resp = await apiCall(`/user/${id}`, { method: "PUT", data: userData });
      console.log(resp);
      await CacheRefs.removeCacheForUsers();
      dispatch(toEditUserNameSuccess({ value, id }));
    } catch (err) {
      dispatch(toEditUserNameError(err));
      console.log(err);
    }
  };
};

export const resetUserNameState = () => ({
  type: userManagementTypes.RESET_USERNAME_STATE,
});

export const setSelectedAdminUser = (userData) => ({
  type: userManagementTypes.SET_SELECTED_NEW_ACCOUNT_OWNER,
  userData,
});

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Form } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import { updateUser } from "../../../redux/user/user.action";
import { apiCall } from "../../../utils/apiCall";
import toasterConstant from "../../../constant/messages/toaster.json";
import { toValidatePassword, toValidateConfirmPassword } from "../../../utils/validations/checkValidation";
import useAlert from "../../../utils/useAlert";
import Button from "../../../components/Button/Button.component";
import SettingsHeader from "../Profile/generalSetting/GeneralSettingHeader/GeneralSettingHeader.component";
import GeneralInputField from "../Profile/generalSetting/GeneralComponent/GeneralInput.component";
import { getPasswordPolicy } from "../../../redux/accounts/action";
import { generatePasswordRegex } from "../../../utils/validations/utilities";
import PasswordErrorMessages from "../../../components/password/PasswordErrorMessages";
import {
  SettingsWrapper,
  ChangePasswordContainer,
  InputProfileDiv,
  InputProfileDivCont,
  InputWrapper,
  DivScroller,
  ButtonDiv,
  ButtonWrapper,
  PasswordRulesWrapper,
  PassNotMatch,
  IconDanger,
  InfoWrapper,
} from "./ChangePassword.styles";
import SnackBar from "../SnackBar";

const { password_update, password_update_error, current_password_error } = toasterConstant;

function ChangePassword(props) {
  const { userData, getPasswordPolicy, passwordPolicy, isSuperAdmin } = props;
  const { changeOwnPasswords, changeOthersPasswords } = userData;

  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [passwordForm, setPasswordForm] = useState({});
  const [validated, setValidated] = useState(false);
  const [currentPasswordError, setCurrentPasswordError] = useState(false);
  const { showAlert } = useAlert();

  const [passwordRegex, setPasswordRegex] = useState({});
  const [passwordError, setPasswordError] = useState(null);
  const [highlightPasswordRules, setHighlightPasswordRules] = useState(false);

  const superAdminPasswordPolicy = isSuperAdmin
    ? {
        minLength: 8,
        upperCaseRequired: true,
        lowerCaseRequired: true,
        numberRequired: true,
        specialCharacterRequired: true,
        changeOwnPasswords: true,
        changeOthersPasswords: true,
      }
    : null;

  useEffect(() => {
    if (!isSuperAdmin) {
      getPasswordPolicy();
    }
  }, []);

  useEffect(() => {
    if (isSuperAdmin) {
      const passwordRegex = generatePasswordRegex(superAdminPasswordPolicy);
      setPasswordRegex(passwordRegex);
    } else if (passwordPolicy) {
      const passwordRegex = generatePasswordRegex(passwordPolicy);
      setPasswordRegex(passwordRegex);
    }
  }, [passwordPolicy]);

  const submit = (e) => {
    e.preventDefault();
    setCurrentPasswordError("");
    setValidated(true);
    const isInvalidPassword = toValidatePassword(passwordForm.newPassword, passwordRegex);
    const isInvalidVerifyPassword = toValidateConfirmPassword(
      passwordForm.newPassword,
      passwordForm.verifyPassword
    );
    if (isInvalidPassword || isInvalidVerifyPassword) {
      e.preventDefault();
      e.stopPropagation();
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    apiCall(`/user/password/profile/change`, {
      method: "POST",
      url: "",
      data: { ...passwordForm, userId: userData._id },
    })
      .then((data) => {
        setIsLoading(false);
        if (data?.data.success) {
          setCurrentPasswordError("");
          showAlert("success", t(password_update));
          resetForm();
        } else {
          if (data?.data?.data?.code === "PASSWORD_ALREADY_USED") {
            setPasswordError(t("PASSWORD_HAS_BEEN_USED_BEFORE"));
            setHighlightPasswordRules(true);
          } else {
            showAlert(
              "error",
              t(
                data?.data.txt ||
                  data?.data.message ||
                  data?.data ||
                  data?.data?.errors?.errors?.[0]?.msg ||
                  current_password_error
              )
            );
          }
        }
      })
      .catch((err) => {
        console.error("err", err);
        setIsLoading(false);
        showAlert("error", err?.response?.message ?? t(password_update_error));
      });
  };

  const isSaveDisabled = () => {
    const { currentPassword, newPassword, verifyPassword } = passwordForm;
    return !currentPassword || !newPassword || !verifyPassword;
  };

  const newPasswordError = validated && toValidatePassword(passwordForm.newPassword, passwordRegex);
  const confirmPasswordError =
    validated && toValidateConfirmPassword(passwordForm.newPassword, passwordForm.verifyPassword);

  const resetForm = () => {
    setPasswordForm({});
    setValidated(false);
    setPasswordError("");
    setHighlightPasswordRules(false);
  };

  const handleCloseSnackBar = () => {
    setPasswordError("");
  };

  return (
    <SettingsWrapper>
      <SettingsHeader title={t("PASSWORD_CHANGE_HEADER")} />
      <Form noValidate id="changeCurrentPasswordForm" onSubmit={submit} className="no-flex">
        <ChangePasswordContainer>
          <DivScroller isSuperAdmin={isSuperAdmin}>
            <InputProfileDiv>
              <InputProfileDivCont>
                <InputWrapper>
                  <GeneralInputField
                    lineDivider={true}
                    leftLabel={t("Your Password")}
                    rightLabel={t(
                      "Required for login to dashboard and administrator access on the tablet devices"
                    )}
                    name={"currentPassword"}
                    minLength={"8"}
                    type="password"
                    placeholder={t("Enter your current password")}
                    value={passwordForm.currentPassword}
                    autocomplete="current-password"
                    disabled={!changeOwnPasswords}
                    onChange={(e) =>
                      setPasswordForm({
                        ...passwordForm,
                        currentPassword: e.target.value,
                      })
                    }
                    isError={currentPasswordError}
                  />
                </InputWrapper>
              </InputProfileDivCont>

              <InputProfileDivCont>
                <InputWrapper>
                  <GeneralInputField
                    lineDivider={true}
                    leftLabel={t("New Password")}
                    rightLabel=""
                    name={"password"}
                    minLength={"8"}
                    type="password"
                    placeholder={t("Enter your new password")}
                    value={passwordForm.newPassword}
                    autocomplete="disabled"
                    disabled={!changeOwnPasswords}
                    onChange={(e) =>
                      setPasswordForm({
                        ...passwordForm,
                        newPassword: e.target.value,
                      })
                    }
                    isError={validated && toValidatePassword(passwordForm.newPassword, passwordRegex)}
                    // errorMessage={validated && toValidatePassword(passwordForm.newPassword)}
                  />
                </InputWrapper>
              </InputProfileDivCont>

              <InputProfileDivCont>
                <InputWrapper>
                  <GeneralInputField
                    lineDivider={true}
                    leftLabel={t("Confirm New Password")}
                    rightLabel=""
                    name="passwordVerification"
                    required
                    minLength={"8"}
                    type="password"
                    placeholder={t("Confirm your new password")}
                    autocomplete="disabled"
                    value={passwordForm.verifyPassword}
                    disabled={!changeOwnPasswords}
                    onChange={(e) =>
                      setPasswordForm({
                        ...passwordForm,
                        verifyPassword: e.target.value,
                      })
                    }
                    isError={
                      validated &&
                      toValidateConfirmPassword(passwordForm.newPassword, passwordForm.verifyPassword)
                    }
                  />
                </InputWrapper>
              </InputProfileDivCont>
              {confirmPasswordError && (
                <PasswordRulesWrapper>
                  <PassNotMatch>
                    <IconDanger className="icon icon-alert-icon"></IconDanger>
                    <Trans>PASSWORD_MISMATCH</Trans>
                  </PassNotMatch>
                </PasswordRulesWrapper>
              )}

              <PasswordErrorMessages
                useDefaultPasswordPolicy={isSuperAdmin}
                isError={newPasswordError || passwordError || highlightPasswordRules}
                hideIcon={!newPasswordError}
                parentPasswordPolicy={superAdminPasswordPolicy}
              />
            </InputProfileDiv>
            {!changeOwnPasswords && (
              <InfoWrapper>
                Changing your own passwords has been disabled by your account administrator
              </InfoWrapper>
            )}
          </DivScroller>

          <ButtonWrapper>
            <ButtonDiv style={{ marginRight: "15px" }}>
              {!(isLoading || isSaveDisabled()) && (
                <Button label={t("Cancel")} showIcon={false} buttonType="ghost" onClick={resetForm} />
              )}
            </ButtonDiv>
            <ButtonDiv>
              <Button
                label={t("Save")}
                form="changeCurrentPasswordForm"
                iconClass="save"
                disabled={isLoading || isSaveDisabled()}
                showIcon
                buttonType="save"
              />
            </ButtonDiv>
          </ButtonWrapper>
        </ChangePasswordContainer>
      </Form>
      <SnackBar isWarning={!!passwordError} label={passwordError} handleClose={handleCloseSnackBar} />
    </SettingsWrapper>
  );
}

const mapStateToProps = (state) => {
  return {
    userData: state?.user?.loginDetails?.userData,
    passwordPolicy: state?.accounts?.passwordPolicy,
    isSuperAdmin: state?.user?.isSuperAdmin,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateUser: (data) => dispatch(updateUser(data)),
  getPasswordPolicy: () => dispatch(getPasswordPolicy()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);

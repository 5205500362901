//TODO- Cleanup this file when time permits, separate styles, optimize code, separate sub components, move constants to a separate file, remove unused code

import React, { useState, useEffect, useContext } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { Trans, useTranslation } from "react-i18next";
import { Modal, Row, Col } from "react-bootstrap";
import { ThemeContext } from "styled-components";

import AssetMedia from "./AssetMedia.component";
import SnackBar from "../SnackBar";

import { addAsset } from "../../../redux/asset/asset.action";
import { updateAsset } from "../../../redux/assets/action";
import validationConstant from "../../../constant/messages/validation.json";
import alertConstant from "../../../constant/messages/toaster.json";
import { apiCall } from "../../../utils/apiCall";
import Styles from "./Tabs/TabsStyles.module.css";
import Button from "../../../components/Button/Button.component";
import PermissionCheck from "../../../components/PermissionCheck/PermissionCheck";
import config from "../../../config";
const s3BaseUrl = config.s3BaseUrl;

const AssetMediaTabStyled = styled.div`
  .noSection_cD p {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  }
  table.assetTablesz thead {
    background: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"} !important;
  }
  table.assetTablesz thead th,
  table.assetTablesz tbody td {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  }
  table.assetTablesz tbody tr {
    border-bottom: 1px solid ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"} !important;
  }
  table.assetTablesz tbody td a svg .cls-1,
  table.assetTablesz tbody td a svg {
    fill: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} !important;
  }
  .custom-modal {
    display: none;
    background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
  }
`;

const ViewIconButton = styled.a`
  font-size: 15px;
  line-height: 26px;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  float: left;
  flex: 1;
  text-align: right;
  padding-right: 5px;
  :hover {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
  }
`;

const DeleteIconButton = styled.div`
  font-size: 15px !important;
  line-height: 26px;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  float: left;
  flex: 1;
  text-align: left;
  padding-left: 5px;
  cursor: pointer;
  :hover {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
  }
`;

const AssetMediaTab = ({ assetOverview, statusButton, loginDetails, assetList, updateAsset }) => {
  const [Media, setMedia] = useState([]);
  const [ModalShow, setModalShow] = useState(false);
  const [FormData, setFormData] = useState({});
  const [IsLoading, setIsLoading] = useState(false);
  const [IsSuccess, setIsSuccess] = useState(false);
  const [IsDeleteLoading, setIsDeleteLoading] = useState(false);
  const [IsDeleteSuccess, setIsDeleteSuccess] = useState(false);
  const [show, setShow] = useState(false);
  const [mediaToDelete, setMediaToDelete] = useState({});
  const [isUpdateError, setIsUpdateError] = useState(false);
  const themeContext = useContext(ThemeContext);
  const stylesCss = {
    message: {
      color: themeContext?.colors?.grayL2 || "#434343",
    },
    buttonWrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      background: themeContext?.colors?.grayL7 || "#FFFFFF",
      bottom: 0,
      width: "100%",
      padding: "10px 0",
      position: "absolute",
      clear: "both",
    },
  };
  const { t } = useTranslation();

  useEffect(() => {
    setMedia(assetOverview && assetOverview.assetMedia);
  }, []);

  const handleMediaCloseModal = () => setShow(false);
  const handleMediaDeleteShow = (file) => {
    setMediaToDelete(file);
    setShow(true);
  };

  const handleDelete = async (file) => {
    let data = {
      assetMediaFileId: file && file._id,
      assetMediaFile: file.assetMediaFile,
    };

    setIsDeleteLoading(true);
    await apiCall(`/asset/deletemedia/${assetOverview && assetOverview._id}`, {
      method: "PUT",
      data: data,
    })
      .then((response) => {
        if (response.data.success === true) {
          let assetLists = assetList;
          let filteredMedia = Media.filter((m) => m._id !== file._id);
          assetList.map((asset, i) => {
            if (asset._id == assetOverview._id) {
              assetLists[i].assetMedia = filteredMedia;
            }
          });
          addAsset({ ...assetLists });
          updateAsset({ assetMedia: [...filteredMedia], _id: assetOverview._id });
          setMedia([...filteredMedia]);
          setShow(false);
          setIsDeleteLoading(false);
          setIsDeleteSuccess(true);
        } else {
          setIsDeleteLoading(false);
        }
      })
      .catch((error) => {
        // alert("Internal Error");
        console.log(error);
        setIsDeleteLoading(false);
      });
  };

  const handleSubmit = async () => {
    if (!FormData.assetMedia || (FormData && FormData.assetMedia && FormData.assetMedia.length == 0)) {
      setIsUpdateError(validationConstant.file_required);
      return;
    }
    let medias =
      assetOverview &&
      assetOverview.assetMedia.map((name) => {
        return { assetMediaFile: name.assetMediaFile };
      });
    let data = {
      assetMedia: [...medias, ...FormData.assetMedia],
    };

    setIsLoading(true);
    await apiCall(`/asset/updateasset/${assetOverview && assetOverview._id}`, {
      method: "PUT",
      data: data,
    })
      .then((response) => {
        if (response.data.success === true) {
          let assetLists = assetList;
          let media = response.data.data.assetMedia;
          assetList.map((asset, i) => {
            if (asset._id == assetOverview._id) {
              assetLists[i].assetMedia = response.data.data.assetMedia;
            }
          });
          addAsset({ ...assetLists });
          updateAsset({ assetMedia: [...media], _id: assetOverview._id });
          setMedia([...media]);
          setModalShow(false);
          setIsLoading(false);
          setIsSuccess(true);
        } else {
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const handleCloseSnackBar = () => {
    setIsSuccess(false);
    setIsUpdateError(false);
  };

  const handleMessage = (IsSuccess) => {
    if (IsSuccess) {
      return t(alertConstant.media_added);
    }
  };

  let media = assetOverview && assetOverview.assetMedia;
  return (
    <AssetMediaTabStyled>
      <div
        className="text-style border-0 shadow-none"
        style={{
          width: "100%",
        }}
      >
        <div className={`${Styles.AssetMediaTable}`}>
          <table className="table  assetTablesz assetMediaTab">
            {media && media.length > 0 ? (
              <>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>{t("Media Name")}</th>
                    <th style={{ textAlign: "center" }}>{t("Actions")}</th>
                  </tr>
                </thead>
                <>
                  <tbody>
                    {Media.map((file, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{file.assetMediaFile}</td>
                        <td style={{ textAlign: "center", display: "flex" }}>
                          <ViewIconButton
                            href={`${s3BaseUrl}/asset/${file.assetMediaFile}`}
                            target="_blank"
                            className="icon icon-view"
                          />
                          {/* <a
                            href={`${s3BaseUrl}/media/${file.assetMediaFile}`}
                            target="_blank"
                            className={classes.view}
                          >
                            <ViewShow
                              style={{
                                width: 20,
                                marginRight: 10,
                                cursor: "pointer",
                              }}
                              fill=""
                            />
                          </a> */}
                          <PermissionCheck
                            section="DASHBOARD"
                            permissionName={"ASSET_MANAGEMENT"}
                            actionName="edit"
                          >
                            <DeleteIconButton
                              className="icon icon-delete"
                              onClick={() => handleMediaDeleteShow(file)}
                            />
                            {/* <DeleteIcon
                              className={classes.delete}
                              style={{ color: "rgba(0,0,0,0.7)" }}
                              onClick={() => handleMediaDeleteShow(file)}
                            /> */}
                          </PermissionCheck>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </>
              </>
            ) : (
              <div className="noSection_cD mt-4">
                <div align="center" style={stylesCss.message}>
                  <Trans>There is no asset media file on this asset</Trans>.
                </div>
                <div align="center" style={stylesCss.message}>
                  <Trans>Click '+ ADD MEDIA' to add media files</Trans>.
                </div>
              </div>
            )}
          </table>
        </div>
        <PermissionCheck section={"DASHBOARD"} permissionName={"ASSET_MANAGEMENT"} actionName={"edit"}>
          <div style={stylesCss.buttonWrapper}>
            <Button
              label={t("Add Media")}
              disabled={!statusButton}
              onClick={() => setModalShow(true)}
              showIcon
              iconClass="add"
              buttonType="secondary"
            />
          </div>
        </PermissionCheck>
        <Modal
          backdrop="static"
          size="lg"
          className="text-style custom-modal"
          show={ModalShow}
          onHide={() => setModalShow(false)}
        >
          {/*   <<div style={{ padding: 30 }}>*/}
          {/* 
          <div className="asserMedia_respons"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography style={{ fontSize: "20px", fontWeight: 600 }}>
              {t('Add Asset Media')}
            </Typography>
            <Button className="float-right buttonCancel ripple" onClick={() => setModalShow(false)}>CANCEL</Button>
          </div>*/}

          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              <Trans>Add Asset Media</Trans>
            </Modal.Title>

            <Button
              label={t("Cancel")}
              onClick={() => setModalShow(false)}
              showIcon={false}
              buttonType="ghost"
            />

            {/*<button
            onClick={() => setModalShow(false)}
            className={`${ManagementPanelStyles.ButtonMobileCancel}`}
          >
            <Trans>X</Trans>
        </button>*/}
          </Modal.Header>

          <Modal.Body>
            <div style={{ marginTop: 10 }}>
              <AssetMedia formData={FormData} setFormData={setFormData} />
            </div>
            <div className="text-right">
              <Button
                iconClass="save"
                label={t("Submit")}
                onClick={handleSubmit}
                showIcon={true}
                buttonType="save"
              />
            </div>
          </Modal.Body>
        </Modal>

        <Modal size="lg" className="custom-modal discard-modal" show={show} onHide={handleMediaCloseModal}>
          <Modal.Body className="text-center modalPopup">
            <Row className="mb-4 mt-4 ">
              <Col>
                <span className="mb-2 text-open-sans text-center modal-font-color">
                  <Trans>Are you sure you want to delete this document </Trans> ?
                </span>
              </Col>
            </Row>

            {/*  <Modal.Footer>
          <Button variant="secondary" onClick={handleMediaCloseModal}>
          <Trans>Close</Trans>
          </Button>
          <Button variant="primary" onClick={() => handleDelete(mediaToDelete)}>
          <Trans>Delete</Trans>
            {IsDeleteLoading && <div class="spinner-border spinner-border-sm" role="status">
              <span class="sr-only">Loading...</span>
            </div>}
          </Button>
        </Modal.Footer>*/}

            <div className="justify-content-center">
              <div className=" d-block mb-2">
                <Button
                  label={t("Yes")}
                  style={{ width: "160px" }}
                  onClick={() => handleDelete(mediaToDelete)}
                  buttonType="primary"
                />
              </div>

              <div className=" d-block">
                <Button
                  style={{ width: "160px" }}
                  label={t("Cancel")}
                  onClick={handleMediaCloseModal}
                  showIcon={false}
                  buttonType="ghost"
                />
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <SnackBar
          isFailed={isUpdateError || isUpdateError}
          isSuccess={IsSuccess}
          label={isUpdateError ? isUpdateError : handleMessage(IsSuccess)}
          handleClose={handleCloseSnackBar}
        />
      </div>
    </AssetMediaTabStyled>
  );
};

const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
  assetList: state.assets.Assets,
});

const mapDispatchToProps = (dispatch) => ({
  updateAsset: (data) => dispatch(updateAsset(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AssetMediaTab);

import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";

import { updateAccounts } from "../../../redux/accounts/action";
import DropdownFilter from "../../../components/DropdownFilter.component";
import UpdateButtons from "../../../components/UpdateButtons.component";
import { ReactComponent as IconRight } from "../../../assets/AddIcon/IconRight.svg";

import { setEditForm } from "../../../redux/forms/action";
import { apiCall } from "../../../utils/apiCall";
import Input from "../../../components/Input";
import units from "../../../constant/units";
import { ManagementPanelWrapper } from "./AccountOverviewTab.component.styles";
import PermissionCheck from "../../../components/PermissionCheck/PermissionCheck";
import SieraTabs from "../../../components/TabComponent/SieraTabs.Component";
import SieraTab from "../../../components/TabComponent/SieraTab.Component";

const timeZone = require("../../../constant/timeZone");

import config from "../../../config";
const s3BucketLogo = config.s3BucketLogo;

const labelStyle = {
  position: "relative",
  width: "100%",
  textAlign: "left",
  marginBottom: "0px",
  fontSize: "12px",
  color: "#919191",
  left: "3px",
};

function AccountOverviewTab({
  AccountOverview,
  updateAccounts,
  updateAccountLoading,
  accountDetails,
  setAccountDetails,
  imageLoaded,
  setImageLoaded,
  AccountLoading,
  editForm,
  setEditForm,
}) {
  const [value, setValue] = React.useState(0);
  const [errors, setErrors] = useState(false);

  useEffect(() => {
    if (!AccountOverview) {
      return;
    }
    let { logo, enterpriseUrl, unitType, _id, timeZone } = AccountOverview;
    setAccountDetails({
      ...accountDetails,
      _id,
      logo,
      enterpriseUrl,
      unitType,
      timeZone,
    });
  }, [AccountOverview, updateAccountLoading]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleUpdate = (event) => {
    event.preventDefault();
    if (errors.enterpriseUrl === "NotAvailable") {
      return;
    }
    let bodyFormData = new FormData();
    bodyFormData.append("accountData", JSON.stringify({ ...accountDetails }));
    updateAccounts(event.target.id, bodyFormData, setEditForm);
  };

  // Enterprise URL Check
  const handleEnterpriseUrl = (e) => {
    const fullUrl = e.target.value;
    const actualUrl = fullUrl.substring(fullUrl.indexOf("/") + 1);
    setAccountDetails({ ...accountDetails, enterpriseUrl: actualUrl });
    apiCall(`/account/urlAvailabilty/${AccountOverview._id}/${actualUrl}`)
      .then((response) => {
        if (response.data.success === true) {
          setErrors({ enterpriseUrl: "Available" });
        } else if (response.data.success === false) {
          setErrors({ enterpriseUrl: "NotAvailable" });
        }
      })
      .catch((error) => {
        setErrors({ enterpriseUrl: "NotAvailable" });
      });
  };

  const { t } = useTranslation();

  return (
    <>
      <ManagementPanelWrapper>
        <SieraTabs
          defaultActiveKey="accountOverview"
          variant="horizontalNew"
          activeKey="accountOverview"
          onSelect={handleChange}
        >
          <SieraTab eventKey="accountOverview" title={t("Account Overview")}>
            {AccountLoading && (
              <div>
                <Skeleton className="m-2" count={6} width={"90%"} />
              </div>
            )}
            {!AccountLoading && AccountOverview && (
              <>
                <Row className="worksite-overview-height mt-3">
                  <Col md={6}>
                    <div className="text-left mb-2">
                      <Input
                        className="enterpriseUrlStyle"
                        key={"enterpriseUrl"}
                        id={"enterpriseUrl"}
                        label={t("Enterprise URL")}
                        fullWidth
                        required={false}
                        name={"enterpriseUrl"}
                        onChange={handleEnterpriseUrl}
                        value={accountDetails.enterpriseUrl}
                        disabled={editForm}
                        startAdornment={"manage.siera.ai/#/"}
                        maxLength="128"
                      />

                      {/* <span><Required style={{marginTop:'4px'}} /></span> */}
                      <div style={{ marginTop: "2px" }} className="text-danger">
                        {errors.enterpriseUrl === t("Please enter enterprise url")
                          ? errors.enterpriseUrl
                          : ""}
                      </div>
                      {errors.enterpriseUrl === "Available" ? (
                        <div style={{ marginTop: "8px" }}>
                          <Trans>URL Availability</Trans>: <IconRight />
                          <span style={{ color: "green" }}>
                            <Trans>You are good to go</Trans>
                          </span>
                        </div>
                      ) : (
                        ""
                      )}
                      {errors.enterpriseUrl === "NotAvailable" ? (
                        <div style={{ marginTop: "8px" }}>
                          <Trans>URL Availability</Trans>:{" "}
                          <span style={{ color: "red" }}>
                            <Trans>Enterprise URL is not available</Trans>
                          </span>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </Col>

                  <Col md={6}>
                    <div style={{ marginTop: "-8px" }}>
                      <Input
                        key={"unitType"}
                        id={"unitType"}
                        fullWidth
                        required={true}
                        name={"unitType"}
                        onChange={(value) => setAccountDetails({ ...accountDetails, unitType: value })}
                        value={accountDetails.unitType ? accountDetails.unitType : "Imperial"}
                        type={"select"}
                        options={units}
                        disabled={editForm}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <label style={labelStyle}>
                      <Trans>Account Logo</Trans>
                    </label>
                    <div className="div_logo_company">
                      {AccountOverview && AccountOverview.logo ? (
                        <>
                          <img
                            style={imageLoaded ? {} : { display: "none", width: "200px" }}
                            alt="company logo"
                            src={
                              AccountOverview && AccountOverview.logo
                                ? s3BucketLogo + AccountOverview.logo
                                : ""
                            }
                            onLoad={() => setImageLoaded(true)}
                          />
                          {!imageLoaded && (
                            <div className="spinner-border" style={{ marginTop: "12%" }} role="status"></div>
                          )}
                        </>
                      ) : (
                        <span></span>
                      )}
                    </div>
                  </Col>
                  <Col md={6} className="dropdow_acc_f">
                    <DropdownFilter
                      disabled={editForm}
                      value={accountDetails.timeZone}
                      useAsDropdown={true}
                      filter={{
                        type: "Time Zone",
                        name: "label",
                        key: "label",
                        data: timeZone,
                      }}
                      label={t("filter")}
                      handleFilter={(value) => setAccountDetails({ ...accountDetails, timeZone: value })}
                    />
                  </Col>
                </Row>

                <PermissionCheck section="DASHBOARD" permissionName={"ACCOUNT_MANAGEMENT"} actionName="edit">
                  <Row>
                    <Col md={5}></Col>
                    <Col md={5}></Col>
                    <Row className="editWorks editworksiteT worksiteRight">
                      <Col md={4}></Col>
                      <Col md={4}></Col>

                      <UpdateButtons
                        editOverview={editForm}
                        setEditOverview={setEditForm}
                        statusButton={true}
                        handleUpdate={handleUpdate}
                        IsLoading={updateAccountLoading}
                        overviewDetail={AccountOverview}
                      />
                    </Row>
                  </Row>
                </PermissionCheck>
              </>
            )}
          </SieraTab>
        </SieraTabs>
      </ManagementPanelWrapper>
    </>
  );
}

const mapStateToProps = (state) => ({
  AccountOverview: state.accounts.AccountOverview,
  updateAccountLoading: state.accounts.UpdateAccountLoading,
  AccountLoading: state.accounts.AccountLoading,
  editForm: state.forms.editForm,
});

const mapDispatchToProps = (dispatch) => ({
  updateAccounts: (id, data, setEditForm) => dispatch(updateAccounts(id, data, setEditForm)),
  setEditForm: (data) => dispatch(setEditForm(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountOverviewTab));

/**
 * @author Nagarjuna Naidu <nagarjuna.naidu@siera.ai>
 *  SIERA DASHBOARD PAGE USER MANAGMENT SELECTORS FOR SUBSCRIBING DATA ON COMPONENTS
 */

import { createSelector } from "reselect";

// Selecting the User Management State from User Management Reducer
const selectUserManagement = (state) => state.userManagement;

// 1. Select User List from Enterprise Account
export const selectUserList = createSelector(
  [selectUserManagement],
  (userManagement) => userManagement.users
);

// 1. Selected User Overview from Enterprise Account
export const selectUserOverview = createSelector(
  [selectUserManagement],
  (userManagement) => userManagement.userOverview
);

//TODO - When time permits, review and optmize this file. Remove unused/commented code. Create cleaner sub components

import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import UserList from "./UserList.component";
import Users from "./Users.component";
import { setEditAlertModal } from "../../../redux/modals/action";
import UserRequestAccessOverview from "./UserRequestAccessOverview.component";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

//custom components
import SearchBox from "../../../components/SearchBox/Searchbox.component";

const UserManagement = ({ pendingList, setEditAlertModal, editForm, editFormSecond }) => {
  const [SearchText, setSearchText] = useState("");
  const [UserTypeFilter, setUserTypeFilter] = useState("all");
  const [imageLoaded, setImageLoaded] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <Row className="worksiteMainbox">
        <Col lg={3}>
          <SearchBox
            label={t("Your Users")}
            placeholder={t("Search a User")}
            onChange={(e) => setSearchText(e.target.value)}
            searchText={SearchText}
            setSearchText={setSearchText}
          />

          <UserList
            pendingList={pendingList}
            style={{ boxShadow: "rgba(0, 0, 0, 0.5) 0px 8px 8px -6px" }}
            searchText={SearchText}
            setSearchText={setSearchText}
            userTypeFilter={UserTypeFilter}
            setImageLoaded={(data) => setImageLoaded(data)}
            setShowUserDetail={() => {}}
          />
        </Col>
        {pendingList ? (
          <Col lg={9}>
            <UserRequestAccessOverview />
          </Col>
        ) : (
          <Col lg={9}>
            <Users
              pendingList={pendingList}
              searchText={SearchText}
              setSearchText={setSearchText}
              imageLoaded={imageLoaded}
              setImageLoaded={(data) => setImageLoaded(data)}
            />
          </Col>
        )}
      </Row>
    </>
  );
};

const mapStateToProps = (state) => ({
  assetTypes: state.assets.AssetTypes,
  editForm: state.forms.editForm,
  editFormSecond: state.forms.editFormSecond,
});

const mapDispatchToProps = (dispatch) => ({
  setEditAlertModal: (data) => dispatch(setEditAlertModal(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserManagement);

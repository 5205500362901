import styled from "styled-components";

export const CompanyIcon = styled.div`
  width: 100%;
  margin-bottom: 20px;
  text-align: center;
  display: block;
  height: 90px;
  img {
    height: auto;
    max-height: 100%;
    width: auto;
    max-width: 100%;
    margin-bottom: 10px;
    text-align: center;
  }
`;

export const Profile = styled.div`
  display: flex;
  img {
    width: 68px;
    height: 68px;
    object-fit: cover;
    object-popsition: top;
  }
`;
export const ProfileImg = styled.span`
  width: 100px;
`;

export const ProfileTextContent = styled.span`
  margin-left: 10px;
  overflow: auto;
`;

export const ProfileName = styled.div`
  font-family: "Kanit";
  font-size: 21px !important;
  font-style: normal;
  text-align: left;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: 21px;
  text-transform: capitalize;
  font-weight: 500;
  @media (max-width: 768px) {
    font-size: 18px !important;
  }
`;

export const ProfileCompanyName = styled.div`
  font-family: "Open Sans";
  font-size: 15px !important;
  font-style: normal;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  font-weight: 500;
  @media (max-width: 768px) {
    font-size: 13px !important;
  }
`;

export const ProfileAccountName = styled.div`
  font-family: "Open Sans";
  font-size: 14px !important;
  font-style: normal;
  text-align: left;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
  overflow: hidden;
  font-weight: 600;
  white-space: nowrap;
  @media (max-width: 768px) {
    font-size: 13px !important;
  }
`;

export const SidbarMenuMainCont = styled.div`
  .pro-sidebar
    .pro-sidebar-content
    .pro-menu
    .pro-menu-item
    .pro-inner-item:hover
    .pro-icon-wrapper
    .pro-icon {
    animation: none;
  }

  .pro-sidebar {
    transition: none !important;
    height: 100vh;
    box-shadow: 2px 2px 8px ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
    .pro-sidebar-inner {
      .pro-sidebar-layout {
        overflow-y: overlay;
      }
    }
    .pro-menu.shaped .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
      background-color: transparent;
      transition: none !important;
      :hover {
        transition: none !important;
      }
    }
    .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item {
      background-color: transparent;
    }
    > .pro-sidebar-inner {
      background: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
    }
    .pro-sidebar-header {
      .pro-menu {
        .pro-menu-item {
          .pro-inner-item {
            padding: 8px 8px 8px 8px;
          }
        }
      }
    }

    .pro-sidebar-content {
      .pro-menu {
        margin-bottom: 0px;
        max-height: 60vh;
        overflow: auto;
        padding-top: 3px;
        padding-bottom: 3px;
        .pro-menu-item {
          .pro-inner-item {
            padding-top: 5px;
            padding-bottom: 5px;
          }
        }
        ul {
          li {
            :hover {
              .pro-inner-item {
                span.pro-icon-wrapper {
                  span.pro-icon {
                    i {
                      font-size: 30px;
                      filter: none;
                      color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
                    }
                  }
                }
              }
            }
          }
        }
        .active {
          background-color: rgba(0, 0, 0, 0.08);
          .pro-inner-item {
            span.pro-icon-wrapper {
              span.pro-icon {
                i {
                  font-size: 26px;
                  filter: none;
                  color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
                }
              }
            }
          }
        }
        .open {
          background-color: rgba(0, 0, 0, 0.08);
          .pro-inner-item {
            span.pro-icon-wrapper {
              span.pro-icon {
                i {
                  font-size: 30px;
                  filter: none;
                  color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
                  .cls-1 {
                    color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
                  }
                }
              }
            }
          }
        }
        .pro-menu-item {
          color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
          font-family: Open Sans, sans-serif;
          font-size: 14px !important;
          font-weight: 600;
          :hover div span span i {
            font-size: 30px;
          }
          .pro-inner-item {
            /* padding: 5px 35px 5px 15px;*/
            &:focus {
              background-color: rgba(0, 0, 0, 0.08);
              color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
            }
            &:active {
              background-color: rgba(0, 0, 0, 0.08);
              color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
            }
            &:hover {
              color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
              .pro-icon-wrapper {
                .pro-icon {
                  animation: scale (1);
                  i {
                    font-size: 30px;
                    filter: none;
                    color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
                  }
                }
              }
            }

            .pro-icon-wrapper {
              .pro-icon {
                /*  animation: scale (1); */
                i {
                  font-size: 26px;
                  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
                  &:hover {
                    font-size: 30px;
                  }
                  
                }
              }
            }
          }
          .pro-sub-menu {
            .pro-inner-list-item {
              .pro-inner-item {
                padding: 8px 28px 4px 15px;
              }
            }
          }
        }

        ul {
          .pro-sub-menu {
            .pro-inner-list-item {
              padding: 0px;
              div {
                ul {
                  padding-top: 0px;
                  padding-bottom: 0px;
                  margin-left: 0px;
                  li {
                    div {
                      span.pro-item-content {
                        padding-left: 50px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .sidebar-btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    .sidebar-btn {
      padding: 1px 15px;
      border-radius: 40px;
      background: rgba(255, 255, 255, 0.05);
      color: ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
      text-decoration: none;
      margin: 0 auto;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-overflow: ellipsis;
      overflow: hidden;
      span {
        margin-left: 5px;
        font-size: 13px;
      }
      &:hover {
        color: ;
      }
    }
  }

  .collapsed {
    .pro-sidebar-inner {
      .pro-sidebar-layout {
        .pro-sidebar-content {
          nav.pro-menu {
            ul {
              li.pro-sub-menu {
                .pro-inner-item {
                  :hover {
                    span.pro-icon-wrapper {
                      span.pro-icon {
                        background: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .pro-menu {
      ul {
        .pro-sub-menu {
          &:hover {
            > .pro-inner-list-item {
              transition: visibility, transform 0.3s;
              visibility: visible;
            }
            .pro-icon-wrapper {
              .pro-icon {
                animation: scale (1);
              }
            }
          }
        }
        .pro-menu-item.pro-sub-menu {
          .pro-inner-list-item {
            .popper-inner {
              background-color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
              padding-left: 0px;
              ul {
                margin-left: 0px !important;
                li.pro-menu-item {
                  div {
                    padding: 10px 10px 10px 10px;
                    span {
                      color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
                      padding-left: 0px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .sidebar-btn {
      display: inline-block;
      border-radius: 50%;
      width: 35px;
      height: 35px;
      line-height: 40px;
      padding: 0;
    }
  }

  .pro-sidebar.collapsed .pro-menu {
    > ul {
      > .pro-menu-item.pro-sub-menu {
        &:hover {
          .pro-icon-wrapper {
            .pro-icon {
              animation: scale (2);
              i {
                font-size: 30px;
                filter: none;
                color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * @author Nagarjuna Naidu <nagarjuna.naidu@siera.ai>
 *  Index File with all the necesary libraries imported for the application
 */

import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "./constant/i18n/i18n";

// Bootstrap Library for Responsivness
import "bootstrap/dist/css/bootstrap.min.css";

// BrowserRouter Libray for Routing Application
import { BrowserRouter } from "react-router-dom";

// Provider Library from react redux bridge
import { Provider } from "react-redux";

// Store for storage and persistor for state persistance
import { store, persistor } from "../src/redux/store";

// Persist Gate bridge between redux and persist library
import { PersistGate } from "redux-persist/integration/react";
import { CacheManager } from "./utils/cache/CacheManager";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <PersistGate persistor={persistor}>
          <App />
        </PersistGate>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
CacheManager.getInstance();
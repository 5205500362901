import styled from "styled-components";

export const MFAWrapper = styled.div`
  align-items: center;
  margin-top: 15px;
`;

export const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  float: left;
  font-family: "Open Sans";
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
`;

export const OptionsWrapper = styled.div`
  display: flex;
  position: relative;
  top: 6px;
`;

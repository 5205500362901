import styled from "styled-components";
import { InformationIcon } from "../../../assets";

export const ApplyToAllWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
`;

export const StyledLabel = styled.label`
  margin: 0;
  display: flex;
  align-items: center;
`;
export const StyledInformationIcon = styled(InformationIcon)`
  width: 17px;
  margin-left: 7px;
  cursor: pointer;
`;

export const StyledCancelButton = styled.button`
  background-color: transparent;
  margin-right: 10px;
  border-radius: 0.2rem;
  border: none;
  height: 40px;
  width: 110px;
  box-shadow: none;
  &:hover {
    background-color: #f2f2f2;
  }
`;

import React, { useEffect, useState, useContext } from "react";

import { Row, Col, Modal } from "react-bootstrap";
import { withRouter } from "react-router-dom";

import WorksiteManagement from "../../../Worksite/WorksiteManagement.component";
import { ReactComponent as BulkUpload } from "../../../../../assets/Blue_Bulk Upload.svg";

// redux
import { connect } from "react-redux";
import { getWorksites } from "../../../../../redux/worksites/action";
import {
  setModalWaitingToOpen,
  setAddSiteModal,
  setEditAlertModal,
} from "../../../../../redux/modals/action";
import { updateActiveNavItem } from "../../../../../redux/sideMenu/menu.action";
import { setShowFilters } from "../../../../../redux/mobileView/action";

import { Trans, useTranslation } from "react-i18next";

// Custom CSS
import ManagementPanelStyles from "./../ManagementPanel.module.css";

// Button Component
import Button from "../../../../../components/Button/Button.component";

//Custom Components
import WorksiteActionBar from "../../../Worksite/WorksiteActionBar.component";
import WorksiteCard from "../../../Worksite/WorksiteCard.component";
import SearchBox from "../../../../../components/SearchBox.component";
import WorksiteList from "../../../Worksite/WorksiteList.component";
import WorksiteFiltersComponent from "../../../Worksite/WorksiteFilters.component";
import WorksiteOverview from "../../../Worksite/WorksiteOverview.component";
import { ReactComponent as FilterIcon } from "../../../../../assets/filtericon.svg";
import { ReactComponent as rightIcon } from "../../../../../assets/rightIconwhite.svg";
import AddWorsiteStepper from "./AddWorkSiteStepper";

import CommonSuccessMessage from "../../../common/CommonSuccessMessage.component";
import { ThemeContext } from "styled-components";
import styled from "styled-components";

import PermissionCheck from "../../../../../components/PermissionCheck/PermissionCheck";
import ModalPopup from "../../../../../components/ModalPopupComponent/ModalPopup.component";

const WorksitePanelStyled = styled.div`
  .assetMobileCss {
    background-color: ${({ theme }) => theme.colors.themePrimary || "#0d5fbe"};
  }
  .filterButtonCss {
    background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
    border: 1px solid ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
  }
`;

const FilterIconStyled = styled.div`
  font-size: 20px !important;
  color: ${({ theme }) => theme.colors.themePrimary || "#0d5fbe"};
  position: relative;
  top: 3px;
`;

function WorksitePanel(props) {
  let {
    setAddSiteModal,
    addSiteModal,
    updateActiveNavItem,
    setShowFilters,
    showFilters,
    worksiteName,
    isSuperAdmin,
    editForm,
    setEditAlertModal,
    setModalWaitingToOpen,
  } = props;

  const { t } = useTranslation();
  const [worksiteBulkUploadShow, setWorksiteBulkUploadShow] = useState(false);
  const [SearchText, setSearchText] = useState("");
  const [filterAccountId, setFilterAccountId] = useState("all");
  const [showWorksiteDetail, setShowWorksiteDetail] = useState(false);

  useEffect(() => {
    updateActiveNavItem("worksites");
  }, []);

  return (
    <WorksitePanelStyled>
      <div className={`${ManagementPanelStyles.desktopManagementPanel}`}>
        <PermissionCheck section="DASHBOARD" permissionName="WORKSITE_MANAGEMENT" actionName="add">
          <Row className="m-0 p-0">
            <div className="mr-2 pl-0">
              <Button
                iconClass="add"
                label={t("Add Worksite")}
                onClick={() => (editForm ? setAddSiteModal(true) : setEditAlertModal(true))}
                showIcon
                buttonType="secondary"
              />
            </div>
            {/* commented as part of SIER-5320 <div className="mr-2 pl-0">
              <Button
                disabled
                iconClass="bulk-upload"
                label={t("Bulk Upload")}
                onClick={() => setWorksiteBulkUploadShow(true)}
                showIcon
                type="secondary"
              />
            </div> */}
            {/* <Col  md={{ offset: 5 }}><SearchAWorksite /></Col> */}
          </Row>
        </PermissionCheck>
        <WorksiteManagement />
      </div>
      <div className={`${ManagementPanelStyles.mobileManagementPanel}`}>
        <WorksiteActionBar
          setAddSiteModal={setAddSiteModal}
          setWorksiteBulkUploadShow={setWorksiteBulkUploadShow}
        />
        {!showWorksiteDetail ? (
          <>
            <WorksiteCard />
            <div className={`${ManagementPanelStyles.searchBoxFilter}`}>
              <div style={{ width: "88%" }}>
                <div className={`${ManagementPanelStyles.searchMobileView}`}>
                  <SearchBox
                    searchText={SearchText}
                    setSearchText={setSearchText}
                    placeholder={t("Search a worksite")}
                  />
                </div>
              </div>
              {isSuperAdmin && (
                <div style={{ width: "12%" }} className={`${ManagementPanelStyles.filterButtonStyle}`}>
                  <p onClick={() => setShowFilters(!showFilters)}>
                    {showFilters ? (
                      <button className={`${ManagementPanelStyles.filterButtonStyleHide} filterButtonCss`}>
                        <FilterIconStyled className="icon icon-filter"></FilterIconStyled>
                      </button>
                    ) : (
                      <button className={`${ManagementPanelStyles.filterButtonStyleShow} filterButtonCss`}>
                        <FilterIconStyled className="icon icon-filter"></FilterIconStyled>
                      </button>
                    )}
                  </p>
                </div>
              )}
            </div>
            <div className={`${ManagementPanelStyles.filterSelectStyle}`}>
              {showFilters ? <WorksiteFiltersComponent /> : ""}
            </div>
            <div className={`${ManagementPanelStyles.mobileListingSection}`}>
              <WorksiteList
                style={{ boxShadow: "rgba(0, 0, 0, 0.5) 0px 8px 8px -6px" }}
                searchText={SearchText}
                filterAccountId={filterAccountId}
                setShowWorksiteDetail={() => setShowWorksiteDetail(true)}
              />
            </div>
          </>
        ) : (
          <>
            <p className={`${ManagementPanelStyles.assetNameMobile} assetMobileCss`}>
              <span onClick={() => setShowWorksiteDetail(false)}>Your Worksite</span>{" "}
              <img height="15" src={rightIcon} />
              {` ${worksiteName || ""}`}
            </p>
            <WorksiteOverview filterAccountId={filterAccountId} />
          </>
        )}
      </div>
      <WoksiteCentralModal
        show={addSiteModal}
        onHide={() => {
          setAddSiteModal(false);
          setModalWaitingToOpen(false);
        }}
        toogleModalClose={() => {
          setAddSiteModal(false);
          setModalWaitingToOpen(false);
        }}
      />
      <WoksiteBulkUploadModal
        show={worksiteBulkUploadShow}
        onHide={() => setWorksiteBulkUploadShow(false)}
        toogleModalClose={() => setWorksiteBulkUploadShow(false)}
      />
      <CommonSuccessMessage />
    </WorksitePanelStyled>
  );
}

export function WoksiteCentralModal(props) {
  const { t } = useTranslation();
  return (
    <ModalPopup
      {...props}
      size="lg"
      centered
      backdrop="static"
      title={t("Add Worksite")}
      handleClose={props.toogleModalClose}
    >
      <AddWorsiteStepper />
    </ModalPopup>
  );
}

function WoksiteBulkUploadModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{ padding: "200px", height: "500px" }}
    >
      <Modal.Body>
        <Row className="pl-4 pr-4 justify-content-center">
          <h4 className="text-style">
            <Trans>This feature will be here soon, kindly contact</Trans>{" "}
            <a href="mailto:support@siera.ai">support@siera.ai</a> <Trans>for more information</Trans>.
          </h4>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  isSuperAdmin: state.user.isSuperAdmin,
  loginDetails: state.user.loginDetails,
  accountList: state.account.accountList,
  menuList: state.sideMenu.menuState,
  addWorksiteSuccess: state.worksites.AddWorksiteSuccess,
  addWorksiteSuccessFlag: state.worksites.addWorksiteSuccessFlag,
  addWorksiteError: state.worksites.AddWorksiteError,
  worksiteMessage: state.worksites.WorksiteMessage,
  worksiteLoading: state.worksites.WorksiteLoading,
  addSiteModal: state.modals.addSiteModal,
  showFilters: state.mobileView.showFilters,
  worksiteName: state?.worksites?.WorksiteOverview?.name,
  editForm: state.forms.editForm,
});

const mapDispatchToProps = (dispatch) => ({
  getWorksites: () => dispatch(getWorksites()),
  setAddSiteModal: (data) => dispatch(setAddSiteModal(data)),
  updateActiveNavItem: (item) => dispatch(updateActiveNavItem(item)),
  setShowFilters: (data) => dispatch(setShowFilters(data)),
  setEditAlertModal: (data) => dispatch(setEditAlertModal(data)),
  setModalWaitingToOpen: (data) => dispatch(setModalWaitingToOpen(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WorksitePanel));

import React from "react";
import moment from "moment-timezone";
import Checkbox from "../../../../../components/Checkbox/Checkbox.component";
import TableErrorLogs from "../../../../../components/DataTableComponent/DataTable.component";
import {
  mobileCheckboxAccordian,
  errorLogDetails,
  accordianColumns,
  mobileCheckboxDateTime,
} from "./constant";

const TableWrapperError = ({
  data,
  errorLogLoading,
  noDataMessage,
  pageSize,
  pageNo,
  totalPageReport,
  nextPage,
  previousPage,
  handlePageLength,
  handleSelectAll,
  isAllSelected,
  handleCheckboxClick,
  downloadReportSet,
  paginationOptions,
}) => {
  const columns = errorLogDetails
    .filter((column) => column.show)
    .map((data) => {
      switch (data.name) {
        case "Asset Name":
          return {
            name: data.name,
            selector: (row) => row?.assetName || "NA",
          };
        case "Error Description":
          return {
            name: data.name,
            selector: (row) => row?.errorDescription || "NA",
          };
        case "Screen Name":
          return {
            name: data.name,
            selector: (row) => row?.ScreenName || "NA",
          };
        case "Worksite":
          return {
            name: data.name,
            selector: (row) => row?.siteName || "NA",
          };
        case "Date, Time":
          return {
            name: data.name,
            selector: (row) => {
              if (!row.timeRecordedAt) return "NA";
              const formattedDate = moment(row.timeRecordedAt).format("MMM-DD-YYYY, hh:mm A");
              const timeZoneString = Intl.DateTimeFormat().resolvedOptions().timeZone;
              const timeZoneShort = moment.tz(timeZoneString).format("z");
              return `${formattedDate} ${timeZoneShort}`;
            },
          };
        case "App Version":
          return {
            name: data.name,
            selector: (row) => row?.appVersion || "NA",
          };
        case "S3 Version":
          return {
            name: data.name,
            selector: (row) => row?.s3Version || "NA",
          };
        default:
          return {
            name: data.name,
            selector: (row) => row[data.feildName] || "NA",
          };
      }
    });
  const columnAccordian = errorLogDetails.map((data) => {
    if (data.name === "Account") {
      return {
        name: data.name,
        selector: (row) => row?.accountName || "NA",
      };
    }
    if (data.name === "Account ID") {
      return {
        name: data.name,
        selector: (row) => row?.accountId || "NA",
      };
    }
    if (data.name === "Worksite") {
      return {
        name: data.name,
        selector: (row) => row?.siteName || "NA",
      };
    }
    if (data.name === "Worksite ID") {
      return {
        name: data.name,
        selector: (row) => row?.siteId?._id || "NA",
      };
    }

    if (data.name === "Asset") {
      return {
        name: data.name,
        selector: (row) => row?.assetName || "NA",
      };
    }
    if (data.name === "Asset ID") {
      return {
        name: data.name,
        selector: (row) => row?.assetId || "NA",
      };
    }
    if (data.name === "Error Type") {
      return {
        name: data.name,
        selector: (row) => row?.errorCategory || "NA",
      };
    }
    if (data.name === "Error Description") {
      return {
        name: data.name,
        selector: (row) => row?.errorDescription || "NA",
      };
    }
    if (data.name === "Date, Time") {
      return {
        name: data.name,
        selector: (row) => {
          if (!row.timeRecordedAt) return "NA";
          const formattedDate = moment(row.timeRecordedAt).format("MMM-DD-YYYY, hh:mm A");
          const timeZoneString = Intl.DateTimeFormat().resolvedOptions().timeZone;
          const timeZoneShort = moment.tz(timeZoneString).format("z");
          return `${formattedDate} ${timeZoneShort}`;
        },
      };
    }
    if (data.name === "User Logged In") {
      return {
        name: data.name,
        selector: (row) => (row?.userLoggedIn ? "YES" : "NO"),
      };
    }
    if (data.name === "First and Last Name") {
      return {
        name: data.name,
        selector: (row) => row?.userName || "NA",
      };
    }
    if (data.name === "User ID") {
      return {
        name: data.name,
        selector: (row) => row?.userId || "NA",
      };
    }
    if (data.name === "App Version") {
      return {
        name: data.name,
        selector: (row) => row?.appVersion || "NA",
      };
    }
    if (data.name === "S3 Version") {
      return {
        name: data.name,
        selector: (row) => row?.s3Version || "NA",
      };
    }
    if (data.name === "Bluetooth Version") {
      return {
        name: data.name,
        selector: (row) => row?.bluetoothVersion || "NA",
      };
    }
    if (data.name === "Screen Name") {
      return {
        name: data.name,
        selector: (row) => row?.ScreenName || "NA",
      };
    }
    if (data.name === "Camera Position - Disconnected") {
      return {
        name: data.name,
        selector: (row) =>
          row?.disconnectedCameras?.length > 0 && row?.disconnectedCameras !== "NA"
            ? row?.disconnectedCameras?.join?.(",")
            : "NA",
      };
    }

    if (data.name === "Camera Position - Occluded") {
      return {
        name: data.name,
        selector: (row) =>
          row?.occludedCameras?.length > 0 && row?.occludedCameras !== "NA"
            ? row?.occludedCameras?.join?.(",")
            : "NA",
      };
    }
    if (data.name === "Camera Position - Upside Down") {
      return {
        name: data.name,
        selector: (row) =>
          row?.orientedCameras?.length > 0 && row?.orientedCameras !== "NA"
            ? row?.orientedCameras?.join?.(",")
            : "NA",
      };
    }

    return {
      name: data.name,
      selector: (row) => row[data.feildName] || "NA",
    };
  });

  const columnsWithCheckboxes = [
    {
      name: <Checkbox type="checkbox" onClick={(e) => handleSelectAll(e)} checked={isAllSelected} />,
      width: 34,
      selector: (row) => (
        <Checkbox
          onClick={(e) => handleCheckboxClick(e)}
          value={row._id}
          checked={downloadReportSet.has(row._id)}
        />
      ),
    },
  ];
  columns.unshift(...columnsWithCheckboxes);
  return (
    <TableErrorLogs
      data={data}
      columns={columns}
      columnAccordian={columnAccordian}
      accordianColumns={accordianColumns}
      pagination={true}
      accordion={true}
      pageSize={pageSize}
      pageNo={pageNo}
      totalPageReport={totalPageReport}
      nextPage={nextPage}
      previousPage={previousPage}
      handlePageLength={handlePageLength}
      paginationOptions={paginationOptions}
      showLoadingIndicator={errorLogLoading}
      noDataMessage={noDataMessage}
      mobileCheckboxDateTime={mobileCheckboxDateTime}
      mobileCheckboxAccordian={mobileCheckboxAccordian}
      responsive
      dense
      loading
      rowHeight={"370"}
      scrollable={true}
      fixedHeader
    />
  );
};

export default TableWrapperError;

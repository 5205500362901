import React from "react";
import { Trans, useTranslation } from "react-i18next";

import Styles from "./TabsStyles.module.css";
import PermissionCheck from "../../../../components/PermissionCheck/PermissionCheck";

function AssetsTabBar({ activeTab, setActiveTab }) {
  const { t } = useTranslation();
  const handleSelect = (e) => {
    setActiveTab(e.target.value);
  };

  return (
    <>
      <div style={{ width: "100%" }}>
        <div className={`${Styles.tabbar}`}>
          <p
            className={`${Styles.tabbaritem} ${activeTab === "assetOverview" ? Styles.tabbaritemactive : ""}`}
            onClick={() => setActiveTab("assetOverview")}
          >
            <Trans>Asset Overview</Trans>
          </p>
          <p
            className={`${Styles.tabbaritem} ${
              activeTab === "assetChecklists" ? Styles.tabbaritemactive : ""
            }`}
            onClick={() => setActiveTab("assetChecklists")}
          >
            <Trans>Asset Checklists</Trans>
          </p>
          <p
            className={`${Styles.tabbaritem} ${activeTab === "assetMedia" ? Styles.tabbaritemactive : ""}`}
            onClick={() => setActiveTab("assetMedia")}
          >
            <Trans>Asset Media</Trans>
          </p>
          <PermissionCheck section={"DASHBOARD"} permissionName={"REPORTS"} actionName={"view"}>
            <p
              className={`${Styles.tabbaritem} ${
                activeTab === "inspectionReports" ? Styles.tabbaritemactive : ""
              }`}
              onClick={() => setActiveTab("inspectionReports")}
            >
              <Trans>Inspection Reports</Trans>
            </p>
          </PermissionCheck>
        </div>
        <div className={`${Styles.mobiletabbar}`}>
          <div>
            <select value={activeTab} onChange={(val) => handleSelect(val)}>
              <option value="assetOverview">{t("Asset Overview")}</option>
              <option value="assetChecklists">{t("Asset Checklists")}</option>
              <option value="assetMedia">{t("Asset Media")}</option>
              <option value="inspectionReports">{t("Inspection Reports")}</option>
            </select>
          </div>
        </div>
      </div>
    </>
  );
}

export default AssetsTabBar;

import { useState, useEffect } from "react";
import { CSVLink } from "react-csv";
import { Trans } from "react-i18next";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import moment from "moment-timezone";

import { CSVButtonWrapper, CustomDropdown, ClickWrapper, CSVSEND, OptionText } from "./ExportToCSV.styles";
import { csvHeadersData } from "./constants";
import PopoverCustom from "../../../../components/PopoverCustom.component";
import ErrorLogsReportShare from "./ErrorLogsReportShare.component";
import SnackBar from "../../../Dashboard/SnackBar";
import DownloadAllReportModal from "../../Modals/DownloadAllReportModal";
import { getTimeWithTimeZone } from "../../../../utils/timezoneConvert";
import Button from "../../../../components/Button/Button.component";
import ModalPopup from "../../../../components/ModalPopupComponent/ModalPopup.component";
import DownloadBulkLogsFilters from "./DownloadBulkLogsFilters.component";

const ExportToCSV = (props) => {
  const { downloadReportSet, reportList, isDownloadAllSelected, loginDetails, localAccountId, date } = props;
  const [showMenu, setShowMenu] = useState(false);
  const [isError, setIsError] = useState(false);
  const [showDownloadAllReportModal, setShowDownloadAllReportModal] = useState(false);

  const handleCloseSnackBar = () => {
    setIsError(false);
  };

  useEffect(() => {
    if (downloadReportSet.size <= 0) {
      setShowMenu(false);
    }
  }, [downloadReportSet]);

  const getSelectedErrors = () => reportList.filter((report) => downloadReportSet.has(report._id));

  const generateErrorData = () => {
    const selectedErrorArr = getSelectedErrors();

    return selectedErrorArr.map((data) => {
      data.userLoggedINText = data.userLoggedIN ? "TRUE" : "FALSE";
      data.occludedCameras = data.occludedCameras?.length > 0 ? data.occludedCameras : "NA";
      data.occludedCameras = data.occludedCameras?.length > 0 ? data.occludedCameras : "NA";
      data.disconnectedCameras = data.disconnectedCameras?.length > 0 ? data.disconnectedCameras : "NA";
      data.orientedCameras = data.orientedCameras?.length > 0 ? data.orientedCameras : "NA";
      data.timeRecordedAtText = `${moment(data?.timeRecordedAt)
        .utcOffset(data?.siteId?.timeZone.split(" ")[0])
        .format("MMM-DD-YYYY hh:mm A")} ${getTimeWithTimeZone(data?.siteId?.timeZone)}`;
      return data;
    });
  };

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const userData = {
    token: loginDetails?.token,
    sharedByUserName: loginDetails?.userData?.fullName,
  };

  const linkClickHandler = () => {
    setShowMenu(!showMenu);
  };

  const [showDownloadBulkLog, setShowDownloadBulkLog] = useState(false);
  const [downloadBulkLogsSuccess, setDownloadBulkLogsSuccess] = useState(false);
  const handleShowDownloadBulkLog = () => {
    setShowDownloadBulkLog(true);
    toggleMenu();
  };
  return (
    <>
      <CSVButtonWrapper>
        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Export CSV</Tooltip>}>
          <Button onClick={toggleMenu} iconOnly showIcon iconClass="csv-icon" buttonType="iconCSV" />
        </OverlayTrigger>

        {showMenu && (
          <CustomDropdown>
            <>
              <ClickWrapper>
                {!isDownloadAllSelected && (
                  <>
                    {downloadReportSet.size <= 0 ? (
                      <OptionText isDisabled={true}>
                        <Trans>Download Selected Logs menu option</Trans>
                      </OptionText>
                    ) : (
                      <CSVLink
                        data={generateErrorData()}
                        style={
                          downloadReportSet.size <= 0
                            ? {
                                textDecoration: "none",
                                pointerEvents: "none",
                                color: "#ccc",
                                opacity: 0.65,
                                border: 0,
                              }
                            : {
                                textDecoration: "none",
                              }
                        }
                        headers={csvHeadersData}
                        onClick={linkClickHandler}
                        filename={"error_logs_report" + new Date().toString() + ".csv"}
                      >
                        <Trans>Download Selected Logs menu option</Trans>
                      </CSVLink>
                    )}
                    <br />
                    <OptionText onClick={handleShowDownloadBulkLog}>
                      <Trans>Download Bulk Logs menu option</Trans>
                    </OptionText>
                  </>
                )}
              </ClickWrapper>
            </>
            {isDownloadAllSelected && (
              <PopoverCustom
                children={
                  <ErrorLogsReportShare
                    userData={userData}
                    localAccountId={localAccountId}
                    date={date}
                    setIsError={setIsError}
                    setShowDownloadAllReportModal={setShowDownloadAllReportModal}
                  />
                }
                customLabelComponent={
                  <CSVSEND>
                    <Trans>Send CSV to Email</Trans>
                  </CSVSEND>
                }
              />
            )}
          </CustomDropdown>
        )}
      </CSVButtonWrapper>
      <SnackBar isError={isError} label={isError} handleClose={handleCloseSnackBar} />
      <DownloadAllReportModal
        showDownloadAllReportModal={downloadBulkLogsSuccess}
        setShowDownloadAllReportModal={setDownloadBulkLogsSuccess}
      />

      {/*   <SnackBar
        isSuccess={downloadBulkLogsSuccess}
        label={"Download initiated successfully"}
        handleClose={() => {
          setDownloadBulkLogsSuccess(false);
        }}
      /> */}

      <ModalPopup
        show={showDownloadBulkLog}
        size="lg"
        centered
        backdrop="static"
        title="Download Bulk Logs"
        handleClose={() => {
          setShowDownloadBulkLog(false);
        }}
      >
        <DownloadBulkLogsFilters
          setShowDownloadBulkLog={setShowDownloadBulkLog}
          setDownloadBulkLogsSuccess={setDownloadBulkLogsSuccess}
        />
      </ModalPopup>
    </>
  );
};

export default ExportToCSV;

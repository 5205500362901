import React from "react";
import { Col } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Carousel from "react-multi-carousel";
import { Trans } from "react-i18next";
import "react-multi-carousel/lib/styles.css";
import {
  CarouselWrapperDiv,
  AccountIconDiv,
  WorksiteIconconDiv,
  AssetIconconDiv,
  UserIconconDiv,
} from "./AccountCard.component.styles";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const styles = {
  vertical: {
    // borderLeft: "1px solid #C6C6C6",
    height: "72px",
  },
};
const AccountCard = ({ count }) => (
  <>
    <CarouselWrapperDiv>
      <Carousel responsive={responsive} className="shadow-sm borderCar Account_Carousel">
        <div className="fexDiv">
          <Col className="count-card">
            <Trans>Accounts</Trans>
            <br />
            {/* <img alt="Account" src={AccountSvg} style={{ height: "20px", marginRight: "10px" }} /> */}
            <AccountIconDiv className="icon icon-accounts">
              <span className="h3" style={{ verticalAlign: "middle", color: "#397CCA" }}>
                {count.accounts || 0}
              </span>
            </AccountIconDiv>
          </Col>
          <div style={styles.vertical} className="borderLeftStyle"></div>
        </div>

        <div className="fexDiv">
          <Col className="count-card">
            <Trans>Worksites</Trans>
            <br />
            {/* <img alt="Worksite" src={WorksiteSvg} style={{ height: "20px", marginRight: "10px" }} />*/}
            <WorksiteIconconDiv className="icon icon-worksites">
              <span className="h3" style={{ verticalAlign: "middle", color: "#397CCA" }}>
                {count.sites || 0}
              </span>
            </WorksiteIconconDiv>
          </Col>
          <div style={styles.vertical} className="borderLeftStyle"></div>
        </div>
        <div className="fexDiv">
          <Col className="count-card">
            <Trans>Assets</Trans>
            <br />
            {/*  <img alt="Asset" src={AssetSvg} style={{ height: "20px", marginRight: "10px" }} />*/}
            <AssetIconconDiv className="icon icon-assets">
              <span className="h3" style={{ verticalAlign: "middle", color: "#397CCA" }}>
                {count.assets || 0}
              </span>
            </AssetIconconDiv>
          </Col>
          <div style={styles.vertical} className="borderLeftStyle"></div>
        </div>
        <div className="fexDiv">
          <Col className="count-card">
            <Trans>Users</Trans>
            <br />
            {/*   <img alt="User" src={UserSvg} style={{ height: "20px", marginRight: "10px" }} />*/}
            <UserIconconDiv className="icon icon-users">
              <span className="h3" style={{ verticalAlign: "middle", color: "#397CCA" }}>
                {count.users || 0}
              </span>
            </UserIconconDiv>
          </Col>
          <div style={styles.vertical} className="borderLeftStyle"></div>
        </div>
      </Carousel>
    </CarouselWrapperDiv>
  </>
);
const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
  count: state.counts.allCount,
});

const mapDispatchToProps = (dispatch) => ({});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountCard));

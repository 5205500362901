import { Trans } from "react-i18next";

import {
  PaginationReport,
  ReportPageArrow,
  ReportPageRow,
  ReportPageNumber,
  FormGroupReport,
} from "./Pagination.styles";
import { ArrowRight, ArrowLeft } from "../../../assets";
import { Form } from "react-bootstrap";

const paginationOptions = [
  { name: 10, value: 10 },
  { name: 25, value: 25 },
  { name: 50, value: 50 },
  { name: 100, value: 100 },
];

const Pagination = (props) => {
  const { totalCount, pageNo, pageSize, setPageSize, setPageNo } = props;
  const handlePageLength = (e) => {
    setPageSize(e.target.value);
  };

  const previousPage = () => {
    if (pageNo > 1) {
      setPageNo(pageNo - 1);
    }
  };

  const nextPage = () => {
    if (pageNo * pageSize < totalCount) {
      setPageNo(pageNo + 1);
    }
  };

  return (
    <PaginationReport>
      <ReportPageRow>
        <FormGroupReport onChange={(e) => handlePageLength(e)}>
          <Form.Label>
            <Trans>Rows Per Page</Trans> :{" "}
          </Form.Label>
          <Form.Control as="select" value={pageSize}>
            {paginationOptions.map((option) => (
              <option key={option.name} value={option.value}>
                {option.name}
              </option>
            ))}
          </Form.Control>
        </FormGroupReport>
      </ReportPageRow>

      <ReportPageNumber>
        <p className="text-muted">
          <Trans>Showing</Trans>
          {` ${pageSize * (pageNo - 1) || 0} - ${
            parseInt(pageSize * (pageNo - 1)) + parseInt(pageSize) < totalCount
              ? parseInt(pageSize * (pageNo - 1)) + parseInt(pageSize)
              : totalCount || 0
          } `}{" "}
          <Trans>of</Trans> {totalCount || 0}{" "}
        </p>
      </ReportPageNumber>
      <ReportPageArrow>
        <ArrowLeft
          onClick={() => previousPage()}
          style={{
            cursor: "pointer",
            width: 16,
            marginRight: 10,
            transform: "rotate(180deg)",
          }}
          fill="#F2F2F2"
        />
        <ArrowRight
          onClick={() => nextPage()}
          style={{ cursor: "pointer", width: 16, marginRight: 10 }}
          fill="#F2F2F2"
        />
      </ReportPageArrow>
    </PaginationReport>
  );
};

export default Pagination;

import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import List from "../../../components/List";
import { useTranslation } from "react-i18next";
import {
  getSelectedAsset,
  toStoreInspectionAsset,
  getAllAsset,
  getAssetDetailById,
  updateAssetItem,
} from "../../../redux/assets/action";
import { toStoreSelectedAsset } from "../../../redux/inspectionAssets/action";
import { setEditAlertModal } from "../../../redux/modals/action";
import UnlockModal from "../../Modals/UnlockModal";
const listType = require("../../../constant/listType");

function AssetList({
  assetList,
  assetOverview,
  history,
  searchText,
  siteFilter,
  assetType,
  toStoreSelectedAsset,
  filterAccountId,
  getAllAsset,
  totalCount,
  AssetLoading,
  getAssetDetailById,
  loginDetails,
  updateAssetItem,
  editForm,
  editFormSecond,
  setEditAlertModal,
  setShowAssetDetail,
}) {
  const { t } = useTranslation();

  const [selectedAsset, setSelectedAsset] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const listDetail = {
    label: "Your Assets",
    emptyListText: t("NO_ASSETS_FOUND"),
    listType: listType.ASSET,
  };

  const handleInspect = (data) => {
    toStoreSelectedAsset(data);
    history.push("/app/inspectAsset");
  };

  return (
    <div className="assetPageList assetPageData">
      <UnlockModal
        modalShow={showModal}
        updateItem={updateAssetItem}
        handleModalClose={() => setShowModal(false)}
        token={loginDetails?.token}
        data={selectedAsset}
      />
      <List
        hideHeader={true}
        listDetail={listDetail}
        listData={assetList}
        selectItem={(id) => {
          /*  if (id == (assetOverview && assetOverview._id)) {
            return;
          } */
          if (editForm && editFormSecond) {
            getAssetDetailById(id);
          } else {
            setEditAlertModal(true);
          }
          setShowAssetDetail();
        }}
        handleInspect={(data) => (editForm && editFormSecond ? handleInspect(data) : setEditAlertModal(true))}
        selectedId={assetOverview && assetOverview._id}
        isFilterChange={filterAccountId + assetType + siteFilter + searchText}
        loadData={getAllAsset}
        totalCount={totalCount}
        searchKey={searchText.length > 3 ? searchText : ""}
        dataLoading={AssetLoading}
        overviewDataEmpty={assetOverview ? false : true}
        iconSpace={true}
        icon="Lock"
        boolIconList={["Lock", "Unlock"]}
        iconContainerClass="list-icon-container"
        falseIconContainerClass="hide-icon"
        trueIconContainerClass="icon-danger-border"
        showLockKey={"isLocked"}
        onIconClick={(data) => {
          setSelectedAsset(data);
          setShowModal(true);
        }}
        showActiveInactiveTabs={true}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
  assetList: state.assets.Assets,
  assetOverview: state.assets.AssetOverview,
  AssetSuccess: state.assets.AssetSuccess,
  totalCount: state.assets.totalCount,
  AssetLoading: state.assets.AssetLoading,
  editForm: state.forms.editForm,
  editFormSecond: state.forms.editFormSecond,
});

const mapDispatchToProps = (dispatch) => ({
  getAssetDetailById: (id) => dispatch(getAssetDetailById(id)),
  getSelectedAsset: (id) => dispatch(getSelectedAsset(id)),
  toStoreInspectionAsset: (data) => dispatch(toStoreInspectionAsset(data)),
  toStoreSelectedAsset: (data) => dispatch(toStoreSelectedAsset(data)),
  getAllAsset: (paginationData, isResetState) => dispatch(getAllAsset(paginationData, isResetState, true)),
  updateAssetItem: (data) => dispatch(updateAssetItem(data)),
  setEditAlertModal: (data) => dispatch(setEditAlertModal(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AssetList));


export const  tableHeader = [
  { name: "Account", space: 0, feildName: "accountName" },
  { name: "Worksite", space: 0, feildName: "worksite" },
  { name: "Asset Type", space: 0, feildName: "assetId" },
  { name: "Asset Name", space: 0, feildName: "assetName" },
  { name: "Date, Time", space: 0, feildName: "createdAt" },
  { name: "Actions", space: 1, feildName: "inspectionCountMonthly" },
];

export const  mobileRowHeader = [
  { name: "Worksite", keyName: "worksite", space: 1, feildName: "siteName" },
  {
    name: "Asset Type",
    keyName: "assetType",
    space: 1,
    feildName: "assetType",
  },
  {
    name: "Asset Impacted",
    keyName: "assetImpacted",
    space: 1,
    feildName: "assetName",
  },
  {
    name: "Date, Time",
    keyName: "createdAt",
    space: 1,
    isDate: true,
    feildName: "impactRecordedAt",
  },
];

export const  tableRowData = [
  {
    name: "Account",
    keyName: "accountName",
    space: 0,
    feildName: "accountName",
  },
  { name: "Worksite", keyName: "worksite", space: 0, feildName: "siteName" },
  {
    name: "Asset Type",
    keyName: "assetType",
    space: 0,
    feildName: "assetType",
  },
  {
    name: "Asset Name",
    keyName: "assetName",
    space: 0,
    feildName: "assetName",
  },
  {
    name: "Date, Time",
    keyName: "createdAt",
    space: 0,
    isDate: true,
    feildName: "impactRecordedAt",
  },
];

export const  filterByTypeData = [
  { name: "Asset", _id: "asset" },
  { name: "Asset Type", _id: "assetType" },
];

export const rowPerPageDropdown = [
  { name: 10, value: 10 },
  { name: 25, value: 25 },
  { name: 50, value: 50 },
  { name: 100, value: 100 },
];
import { reportTypes } from "./types";
import { initialState } from "./initialState";

export const configReducer = (state = initialState, action) => {
  switch (action.type) {
    case reportTypes.GET_INSPECTION_REPORT_LIST_LOADING:
      return {
        ...state,
        getInspectionReportListSuccess: false,
        getInspectionReportListLoading: true,
        getInspectionReportListError: false,
      };

    case reportTypes.GET_INSPECTION_REPORT_LIST_SUCCESS:
      return {
        ...state,
        getInspectionReportListSuccess: true,
        getInspectionReportListLoading: false,
        getInspectionReportListError: false,
        inspectionReportList: action.data.data,
        inspectionReportTotalCount: action.data.count,
      };

    case reportTypes.GET_INSPECTION_REPORT_LIST_ERROR:
      return {
        ...state,
        getInspectionReportListSuccess: false,
        getInspectionReportListLoading: false,
        getInspectionReportListError: true,
      };

    case reportTypes.GET_ZIP_FILE_REPORT_LOADING:
      return {
        ...state,
        getZipFileReportLoading: true,
        ggetZipFileReportSuccess: false,
      };

    case reportTypes.GET_ZIP_FILE_REPORT_SUCCESS:
      return {
        ...state,
        getZipFileReportLoading: false,
        getZipFileReportSuccess: true,
      };

    case reportTypes.GET_IMPACT_REPORT_LIST_LOADING:
      return {
        ...state,
        getImpactReportListSuccess: false,
        getImpactReportListLoading: true,
        getImpactReportListError: false,
      };

    case reportTypes.GET_IMPACT_REPORT_LIST_SUCCESS:
      return {
        ...state,
        getImpactReportListSuccess: true,
        getImpactReportListLoading: false,
        getImpactReportListError: false,
        impactReportList: action.data.data,
        impactReportTotalCount: action.data.totalcount,
      };

    case reportTypes.GET_UNAUTORIZED_USE_REPORT_LOADING:
      return {
        ...state,
        getUnauthorizedUseReportSuccess: false,
        getUnauthorizedUseReportLoading: true,
      };

    case reportTypes.GET_UNAUTORIZED_USE_REPORT_SUCCESS:
      return {
        ...state,
        getUnauthorizedUseReportLoading: false,
        getUnauthorizedUseReportSuccess: false,
        unauthorizedUseReport: action.data.data,
        unauthorizedUseCount: action.data.totalcount,
      };

    case reportTypes.GET_IMPACT_REPORT_LIST_ERROR:
      return {
        ...state,
        getImpactReportListSuccess: false,
        getImpactReportListLoading: false,
        getImpactReportListError: true,
      };

    case reportTypes.GET_UNAUTHORIZED_LIST_LOADING:
      return {
        ...state,
        getUnauthorizedReportSuccess: false,
        getUnauthorizedReportLoading: true,
        getUnauthorizedReportError: false,
      };

    case reportTypes.GET_UNAUTHORIZED_LIST_SUCCESS:
      return {
        ...state,
        getUnauthorizedReportLoading: false,
        getUnauthorizedReportSuccess: true,
        getUnauthorizedReportError: false,
        unauthorizedReportList: action.data.data,
        totalUnauthorizedReportCount: action.data.totalcount,
      };

    case reportTypes.GET_UNAUTHORIZED_LIST_ERROR:
      return {
        ...state,
        getUnauthorizedReportSuccess: false,
        getUnauthorizedReportLoading: false,
        getUnauthorizedReportError: true,
      };

    case reportTypes.GET_ZIP_FILE_IMPACT_REPORT_LOADING:
      return {
        ...state,
        getZipFileImpactReportLoading: true,
        ggetZipFileImpactReportSuccess: false,
      };

    case reportTypes.GET_ZIP_FILE_IMPACT_REPORT_SUCCESS:
      return {
        ...state,
        getZipFileImpactReportLoading: false,
        getZipFileImpactReportSuccess: true,
      };

    case reportTypes.GET_LAST_HOUR_METER_LIST_LOADING:
      return {
        ...state,
        getLastHourMeterListLoading: true,
        getLastHourMeterListSuccess: false,
      };

    case reportTypes.GET_LAST_HOUR_METER_LIST_SUCCESS:
      return {
        ...state,
        assetStatusList: action.data,
        getLastHourMeterListLoading: false,
        getLastHourMeterListSuccess: true,
      };

    case reportTypes.UPDATE_HOUR_METER_LIST_ITEM:
      return {
        ...state,
        assetStatusList: state?.assetStatusList?.map((data) => {
          if (data.assetId === action.data.assetId) {
            return action.data;
          }
          return data;
        }),
      };

    case reportTypes.EXPORT_ALL_INSPECTION_CSV_LOADING:
      return {
        ...state,
        exportAllInspectionCSVLoading: true,
        exportAllInspectionCSVSuccess: false,
        exportAllInspectionCSVError: false,
      };

    case reportTypes.EXPORT_ALL_INSPECTION_CSV_SUCCESS:
      return {
        ...state,
        exportAllInspectionCSVLoading: false,
        exportAllInspectionCSVSuccess: true,
        exportAllInspectionCSVError: false,
      };

    case reportTypes.EXPORT_ALL_INSPECTION_CSV_ERROR:
      return {
        ...state,
        exportAllInspectionCSVLoading: false,
        exportAllInspectionCSVSuccess: false,
        exportAllInspectionCSVError: true,
      };
    case reportTypes.RESET_ALL_INSPECTION_CSV_STATE:
      return {
        ...state,
        exportAllInspectionCSVLoading: false,
        exportAllInspectionCSVSuccess: false,
        exportAllInspectionCSVError: false,
      };

    case reportTypes.EXPORT_ALL_IMPACT_CSV_LOADING:
      return {
        ...state,
        exportAllImpactCSVLoading: true,
        exportAllImpactCSVSuccess: false,
        exportAllImpactCSVError: false,
      };

    case reportTypes.EXPORT_ALL_IMPACT_CSV_SUCCESS:
      return {
        ...state,
        exportAllImpactCSVLoading: false,
        exportAllImpactCSVSuccess: true,
        exportAllImpactCSVError: false,
      };

    case reportTypes.EXPORT_ALL_IMPACT_CSV_ERROR:
      return {
        ...state,
        exportAllImpactCSVLoading: false,
        exportAllImpactCSVSuccess: false,
        exportAllImpactCSVError: true,
      };

    case reportTypes.EXPORT_ALL_IMPACT_CSV_RESET:
      return {
        ...state,
        exportAllImpactCSVLoading: false,
        exportAllImpactCSVSuccess: false,
        exportAllImpactCSVError: false,
      };
    case reportTypes.CLEAR_INSPECTION_REPORT_LIST_SUCCESS:
      return {
        ...state,
        inspectionReportList: [],
        inspectionReportTotalCount: 0,
      };
    case reportTypes.CLEAR_IMPACT_REPORT_LIST_SUCCESS:
      return {
        ...state,
        impactReportList: [],
        impactReportTotalCount: 0,
      };
    case reportTypes.CLEAR_UNAUTHORIZED_REPORT_LIST_SUCCESS:
      return {
        ...state,
        unauthorizedReportList: [],
        totalUnauthorizedReportCount: 0,
      };
    // Returning default state
    default:
      return state;
  }
};

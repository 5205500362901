import { errorTypes } from "./types";
import { initialState } from "./initialState";

export const errorsReducer = (state = initialState, action) => {
  switch (action.type) {
    case errorTypes.SET_ERROR_CODE:
      return {
        ...state,
        errorCode: action.data,
      };

    case errorTypes.GET_ERROR_LOG_LOADING:
      return {
        ...state,
        errorLogLoading: true,
        errorLogSuccess: false,
        errorLogError: false,
      };

    case errorTypes.GET_ERROR_LOG_SUCCESS:
      return {
        ...state,
        errorLogLoading: false,
        errorLogSuccess: true,
        errorLogError: false,
        errorLogCount: action.data.count,
        errorLog: action.data.data,
      };

    case errorTypes.GET_ERROR_LOG_ERROR:
      return {
        ...state,
        errorLogLoading: false,
        errorLogSuccess: false,
        errorLogError: true,
      };

    default:
      return {
        ...state,
      };
  }
};

/**
 * @author Nagarjuna Naidu <nagarjuna.naidu@siera.ai>
 *  Enterprise Landing Page Request Access Success Styling
 */
import styled from "styled-components";

// Request Access Title Styling
export const RegisterTitle = styled.div`
  font-size: 33px;
  font-style: bold;
  color: #323232;
  padding-top: 50px;
  padding-bottom: 12px;
  font-family: "Kanit";
`;
// Request Access Sub Title Styling
export const RegisterSubTitle = styled.div`
  font-size: 15px;
  font-style: Regular;
  color: #919191;
  padding-top: 3px;
  font-family: "Open Sans";
`;

// Request Access Sub Title2 Styling
export const RegisterSubTitle2 = styled.div`
  font-size: 15px;
  font-style: Regular;
  color: #919191;
  padding-top: 3px;
  padding-bottom: 20px;
  font-family: "Open Sans";
`;

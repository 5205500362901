//TODO Cleanup this file. Remove unused/commented code and optimize code. Make sure to use of proper let and const. Segregate constants, create cleaner sub components etc

import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { toValidateUserName } from "../../../../utils/validations/checkValidation";
import { updateUser } from "../../../../redux/userManagement/userManagement.action";
import { setEditForm } from "../../../../redux/forms/action";
import validationConstant from "../../../../constant/messages/validation.json";
import { toValidateEmail } from "../../../../utils/validations/checkValidation";
import { apiCall } from "../../../../utils/apiCall";
import UserEditComponent from "./UserEdit.component";
import { setUpdateUserModal } from "../../../../redux/modals/action";
import PermissionCheck from "../../../../components/PermissionCheck/PermissionCheck";
import MultiSelector from "../../../../components/multiSelector/MultiSelector.component";
import { UserPageWrapper, UserCol, FullWidth } from "./UserOverviewTab.component.styles";
import DropdownWrapper from "../../../../components/DropdownComponent/DropdownFilterWrapper";
import InputField from "../../../../components/InputFieldComponent/InputField.component";

function UserOverviewTab({
  userDetails,
  dropdownWorksiteList,
  setUserDetails,
  userOverviewDetails,
  userOverviewDetail,
  setUpdateUserModal,
  setEditForm,
  editForm,
  updateUser,
  handleUpdateUser,
  activeTab,
  userTypeDetailList,
  userTypeList,
  isOwner,
}) {
  const { t } = useTranslation();
  const [updateError, setUpdateError] = useState("");
  const [IsSubmitted, setIsSubmitted] = useState(false);
  const [UserName, setUserName] = useState("");

  const emailValidation = (e) => {
    e.preventDefault();
    const userEmail = e.target.value;
    let isError = toValidateEmail(userEmail);

    if (isError) {
      setUpdateError(isError);
      return;
    }

    apiCall(`/user/emailavailabilty`, { method: "POST", data: { email: userEmail } }).then((response) => {
      if (response.data.success === false) {
        // setUpdateButtonStatus(true);
        setUpdateError(validationConstant.update_email_duplicate_error);
      } else {
        // setUpdateButtonStatus(false);
        setUpdateError("");
      }
    });
  };

  const handleEmailChange = (e) => {
    if (userOverviewDetails?.email?.toLowerCase() !== e.target.value?.toLowerCase()) {
      emailValidation(e);
    }
    setUserDetails({ ...userDetails, email: e.target.value });
  };

  const getUserType = (userTypeId) => {
    const userTypeObj = userTypeDetailList?.find?.((userType) => userType._id === userTypeId);
    return userTypeObj?.accessLevel;
  };

  const handleWorksiteChange = (authorizedSitesArr) => {
    setUserDetails({ ...userDetails, authorizedSites: authorizedSitesArr });
  };

  const [resetState, setResetState] = useState(false);

  useEffect(() => {
    if (editForm) {
      setResetState(true);
    }
  }, [editForm]);

  const [isWorksiteMandatory, setIsWorksiteMandatory] = useState(false);

  useEffect(() => {
    if (
      (getUserType(userOverviewDetails.userTypeId) === "SINGLE_WORKSITE" ||
        getUserType(userOverviewDetails.userTypeId) === "INDIVIDUAL_EMPLOYEE" ||
        getUserType(userOverviewDetails.userTypeId) === "MULTIPLE_WORKSITES") &&
      userDetails?.authorizedSites?.length <= 0
    ) {
      setIsWorksiteMandatory(true);
    } else {
      setIsWorksiteMandatory(false);
    }
  }, [userDetails.authorizedSites]);

  return (
    <>
      <UserPageWrapper>
        {!editForm && (
          <FullWidth>
            <UserCol>
              <InputField
                label={t("Full Name")}
                value={userDetails?.fullName}
                minLength={1}
                maxLength={64}
                onChange={(e) => {
                  // if(!toValidateUserName(e?.target?.value)){
                  setUserDetails({
                    ...userDetails,
                    fullName: e.target.value,
                  });
                  // }
                }}
                disabled={editForm}
                errorMessage={(IsSubmitted && !UserName) || toValidateUserName(userDetails?.fullName)}
                fullWidth
                size="small"
                // helperText={IsSubmitted && !UserName && <Trans>Name is required</Trans>}
                isError={toValidateUserName(userDetails?.fullName)}
              />

              {/* {toValidateUserName(userDetails?.fullName)} */}

              {/* <p className="text-danger">{updateError}</p> */}
            </UserCol>
            {userOverviewDetails?.userTypeId && !isOwner && (
              <UserCol>
                <DropdownWrapper
                  label={t("User Type")}
                  disabled={editForm}
                  size="small"
                  options={userTypeList.map((type) => ({
                    _id: type._id,
                    label: type.name,
                  }))}
                  value={userOverviewDetails?.userTypeId}
                  onChange={(userTypeId) => {
                    if (userOverviewDetails?.userTypeId !== userTypeId) {
                      setUpdateUserModal({
                        ...userOverviewDetails,
                        ...userDetails,
                        oldUserTypeId: userOverviewDetails.userTypeId,
                        newUserTypeId: userTypeId,
                        authorizedSites: userDetails?.authorizedSites,
                        password: undefined,
                      });
                    }
                  }}
                />
              </UserCol>
            )}
          </FullWidth>
        )}
        <>
          {getUserType(userOverviewDetails.userTypeId) === "MULTIPLE_WORKSITES" ? (
            <UserCol>
              <MultiSelector
                options={dropdownWorksiteList?.map((option) => ({
                  label: option.name,
                  value: option._id,
                }))}
                placeholder={"Select worksites"}
                label={t("Worksites")}
                changeHandler={handleWorksiteChange}
                returnValueArray={true}
                selectedOptions={userOverviewDetails?.authorizedSites.map((option) => ({
                  label: option.name,
                  value: option._id,
                }))}
                clearAllText={t("Clear All")}
                isDisabled={editForm}
                resetState={resetState}
                setResetState={setResetState}
                maxMenuHeight={182}
                size="small"
              />
            </UserCol>
          ) : getUserType(userOverviewDetails.userTypeId) === "SINGLE_WORKSITE" ||
            getUserType(userOverviewDetails.userTypeId) === "INDIVIDUAL_EMPLOYEE" ? (
            <UserCol>
              <DropdownWrapper
                disabled={editForm}
                label={t("Worksite")}
                defaultValue={dropdownWorksiteList && dropdownWorksiteList[0] && dropdownWorksiteList[0]._id}
                required={false}
                value={
                  Array.isArray(userDetails?.authorizedSites)
                    ? userDetails?.authorizedSites[0]
                    : userDetails?.authorizedSites
                }
                onChange={(value) => setUserDetails({ ...userDetails, authorizedSites: value })}
                options={dropdownWorksiteList?.map?.((data) => ({
                  _id: data._id,
                  label: data.name,
                }))}
                feedback={t(validationConstant.worksite_required)}
                size="small"
              />
            </UserCol>
          ) : null}

          {getUserType(userOverviewDetails.userTypeId) !== "INDIVIDUAL_EMPLOYEE" && (
            <UserCol>
              <InputField
                disabled={editForm}
                type="email"
                value={userDetails.email}
                InputProps={{ disableUnderline: true }}
                label={t("Email")}
                minLength={1}
                maxLength={64}
                onChange={handleEmailChange}
                errorMessage={updateError}
              />

              {/* <p className="text-danger">{updateError}</p> */}
            </UserCol>
          )}
        </>
        <UserCol>
          <InputField
            disabled={editForm}
            label={t("Phone")}
            type="phone"
            id="outlined-basic"
            variant="standard"
            name="phone"
            value={userDetails.phone ? userDetails.phone : ""}
            onChange={(e) => setUserDetails({ ...userDetails, phone: e.target.value })}
          />
        </UserCol>
      </UserPageWrapper>
      <PermissionCheck section="DASHBOARD" permissionName={"USER_MANAGEMENT"} actionName="edit">
        <UserEditComponent
          handleUpdateUser={handleUpdateUser}
          userDetails={userDetails}
          activeTab={activeTab}
          isWorksiteMandatory={isWorksiteMandatory}
        />
      </PermissionCheck>
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  updateUser: (userDetails) => dispatch(updateUser(userDetails)),
  setEditForm: (data) => dispatch(setEditForm(data)),
  setUpdateUserModal: (details) => dispatch(setUpdateUserModal(details)),
});

const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
  dropdownWorksiteList: state.dropdownFilters.worksiteList,
  UsersLoading: state.users.UsersLoading,
  editForm: state.forms.editForm,
  userTypeList: state.userType.UserTypeList,
  userTypeDetailList: state.userType.userTypeDetailList,
  isOwner: state.users?.UserOverview?.isOwner,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserOverviewTab));

/* eslint-disable jsx-a11y/anchor-is-valid */
//TODO- Clean up this file when time permits. Remove unused/commented code, improve/optimize code
//Carve out the carousel and create a common component for Inspection/Incident and Unauthorized tabs

import React, { useEffect, useState, useContext } from "react";
import { Row, Col } from "react-bootstrap";
import { withRouter } from "react-router-dom";

import { updateHourMeterListItem } from "../../../../../redux/reports/action";
import { updateMenu, updateMenuReport } from "../../../../../redux/sideMenu/menu.action";
import { getAllCount, getInspectionCount, getGraphData } from "../../../../../redux/counts/action";
import { setInspectionReportFilter } from "../../../../../redux/filters/action";
import { setDropdownAccountId, setDropdownWorksiteId } from "../../../../../redux/dropdownFilters/action";
import { toDownloadFiles } from "../../../../../redux/config/action";
import DropdownWrapper from "../../../../../components/DropdownComponent/DropdownWrapper";
import DateRangePicker from "../../../../../components/DatePicker.component";
import { getInspectionReportListing } from "../../../../../redux/reports/action";
import { updateActiveNavItem } from "../../../../../redux/sideMenu/menu.action";
import QuickAccess from "../QuickManagement/QuickManagement.component";
import "react-multi-carousel/lib/styles.css";
// Button Component
import Button from "../../../../../components/Button/Button.component";

// Worksite
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import DashboardGraph from "../../DashboardGraph";

//import "./inspection.css";
import UnlockModal from "../../../../Modals/UnlockModal";
import { apiCall } from "../../../../../utils/apiCall";

import {
  DashboardContainer,
  CarouselDivCont,
  CarouselDivContto,
  FailedInspectionDiv,
  GraphContainer,
} from "./InspectionDashboard.component.styles";
import PermissionCheck from "../../../../../components/PermissionCheck/PermissionCheck";
import PermissionsCheck from "../../../../../components/PermissionCheck/PermissionsCheck";
import FailedInspectionTableWrapper from "./InspectionFailedTableWrapper.component";
import DivContainer from "../../../../../components/DivContanerCompnent/DivContainer.component";
import MoreFilterMobileToggle from "../../../../../components/More Filter Button/MoreFilterButton.component";
import CarouselDataInspection from "./InspectionCarouselWrapper";
import { ThemeContext } from "styled-components";

function InspectionDashboard(props) {
  let {
    loginDetails,
    isSuperAdmin,
    isSiteAdmin,
    isAccountViewer,
    inspectionReportList,
    getInspectionReportListing,
    updateHourMeterListItem,
    updateMenu,
    updateMenuReport,
    getAllCount,
    getInspectionCount,
    getGraphData,
    allCount,
    inspectionCount,
    setInspectionReportFilter,
    inspectionReportFilter,
    graphData,
    dropdownWorksiteList,
    dropdownAccountList,
    setDropdownAccountId,
    dropdownAssetTypeList,
    setDropdownWorksiteId,
    dropdownAssetList,
    dropdownFilterAccountId,
    dropdownFilterWorksiteId,
    updateActiveNavItem,
    permissions,
    isSingleAccountMultiSiteUser,
    showWorksiteDropdown,
  } = props;
  const { t } = useTranslation();
  const [showMoreFilter, setShowMoreFilter] = useState(false);
  const [filterByTypeLabel, setFilterByTypeLabel] = useState("all");
  const [lastFilterData, setLastFilterData] = useState([]);

  const themeContext = useContext(ThemeContext);
  const [managementCountBanner, setManagementCountBanner] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState(null);

  const [ignoreFilter, setIgnoreFilter] = useState(false);

  const FilterByTypeLabelData = {
    asset: t("Asset"),
    assetType: t("Asset Type"),
    inspectedBy: t("Inspected By"),
    inspectionResult: t("Inspection Result"),
  };

  let { accountId, filterByType, endDate, startDate, worksiteId } = inspectionReportFilter;

  let filterByTypeData = [
    { name: t("Asset"), _id: "asset" },
    { name: t("Asset Type"), _id: "assetType" },
    { name: t("User"), _id: "inspectedBy" },
  ];

  const onclickCarousel = (e, item, subSection) => {
    if (isAccountViewer) e.preventDefault();
    updateActiveNavItem(item?.toLowerCase());
    updateMenu({ section: "management", subSection: subSection });
  };

  const [viewReport, setViewReport] = useState(false);

  useEffect(() => {
    const managementCountBannerLocal = {
      ACCOUNT_MANAGEMENT: {
        name: t("Accounts"),
        iconClass: "accounts",
        type: "ThemePrimary",
        feildName: "accounts",
        to: "#/app/management",
        onClick: (e) => onclickCarousel(e, "Accounts", 0),
      },
      WORKSITE_MANAGEMENT: {
        name: t("Worksites"),
        iconClass: "worksites",
        type: "ThemePrimary",
        feildName: "sites",
        to: "#/app/management",
        onClick: (e) => onclickCarousel(e, "Worksites", 1),
      },
      ASSET_MANAGEMENT: {
        name: t("Assets"),
        iconClass: "assets",
        type: "ThemePrimary",
        feildName: "assets",
        to: "#/app/management",
        onClick: (e) => onclickCarousel(e, "Assets", 2),
      },
      USER_MANAGEMENT: {
        name: t("Users"),
        iconClass: "users",
        type: "ThemePrimary",
        feildName: "users",
        to: "#/app/management",
        onClick: (e) => onclickCarousel(e, "Users", 3),
      },
    };

    const permissionsNameArr = [
      "ACCOUNT_MANAGEMENT",
      "WORKSITE_MANAGEMENT",
      "ASSET_MANAGEMENT",
      "USER_MANAGEMENT",
    ];

    const managementCountBannerUpdated = [];

    if (permissions?.[0]) {
      permissions?.[0]?.permissions?.forEach((permission) => {
        if (permission.name === "REPORTS" && permission.action.view) {
          setViewReport(true);
        }
        if (permissionsNameArr.includes(permission.name)) {
          if (!permission?.action?.view) {
            const bannerObj = managementCountBannerLocal[permission.name];
            delete bannerObj.to;
            delete bannerObj.onClick;
            managementCountBannerUpdated.push(managementCountBannerLocal[permission.name]);
          } else {
            managementCountBannerUpdated.push(managementCountBannerLocal[permission.name]);
          }
        }
      });
    }
    setManagementCountBanner(managementCountBannerUpdated);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    //getInspectionReportListing({...inspectionReportFilter, status: 'fail', pageSize: 5 })
    getAllCount();
    getInspectionCount(inspectionReportFilter);
    getGraphData(inspectionReportFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginDetails, isSuperAdmin]);

  useEffect(() => {
    setInspectionReportFilter({ ...inspectionReportFilter, worksiteId: "all" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inspectionReportFilter.accountId]);

  useEffect(() => {
    setInspectionReportFilter({
      ...inspectionReportFilter,
      assetTypeId: "all",
      assetId: "all",
      inspectorId: "all",
      status: "all",
      checklistId: "all",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inspectionReportFilter.filterByType, worksiteId]);

  useEffect(() => {
    const inspectionStatus = inspectionReportFilter.status;
    if (ignoreFilter) {
      return;
    }
    getInspectionReportListing({
      ...inspectionReportFilter,
      status: inspectionStatus === "all" || !inspectionStatus ? "failed" : inspectionStatus,
      pageSize: 10,
    });
    getInspectionCount({ ...inspectionReportFilter });
    getGraphData(inspectionReportFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inspectionReportFilter]);

  const handleViewAll = () => {
    setInspectionReportFilter({
      ...inspectionReportFilter,
      status: "failed",
      pageSize: 10,
    });
    updateMenuReport({ section: "report", subSection: 0 });
  };

  const changeLastFilter = (value) => {
    if (inspectionReportFilter.filterByType === "asset") {
      setInspectionReportFilter({
        ...inspectionReportFilter,
        assetId: value,
        pageNo: 1,
      });
    } else if (inspectionReportFilter.filterByType === "assetType") {
      setInspectionReportFilter({
        ...inspectionReportFilter,
        assetTypeId: value,
        pageNo: 1,
      });
    } else if (inspectionReportFilter.filterByType === "inspectedBy") {
      setInspectionReportFilter({
        ...inspectionReportFilter,
        inspectorId: value,
        pageNo: 1,
      });
    } else if (inspectionReportFilter.filterByType === "inspectionResult") {
      setInspectionReportFilter({
        ...inspectionReportFilter,
        status: value,
        pageNo: 1,
      });
    } else if (inspectionReportFilter.filterByType === "checklist") {
      setInspectionReportFilter({
        ...inspectionReportFilter,
        checklistId: value,
        pageNo: 1,
      });
    }
  };

  const changeFilterByType = (value) => {
    setInspectionReportFilter({
      ...inspectionReportFilter,
      filterByType: value,
      pageNo: 1,
    });
    setFilterByTypeLabel(FilterByTypeLabelData[value]);
    if (value === "asset") {
      setLastFilterData(dropdownAssetList);
    } else if (value === "assetType") {
      setLastFilterData(dropdownAssetTypeList);
    } else if (value === "inspectedBy") {
      // New backend api

      const url = `/dropdown/users?accid=${
        isSuperAdmin ? inspectionReportFilter.accountId : loginDetails.accountId
      }&siteid=${
        isSiteAdmin ? loginDetails?.userData?.authorizedSites?.[0] : dropdownFilterWorksiteId
      }&status=true`;

      apiCall(url).then((resp) => {
        setLastFilterData(resp.data?.data?.data);
      });
    } else if (value === "inspectionResult") {
      setLastFilterData([
        { fullName: "Pass", _id: "true" },
        { fullName: "Fail", _id: "false" },
      ]);
    }
  };

  useEffect(() => {
    setInspectionReportFilter({
      ...inspectionReportFilter,
      assetId: "all",
      assetTypeId: "all",
      inspectorId: "all",
      status: "all",
      checklistId: "all",
      pageNo: 1,
    });
  }, [dropdownFilterAccountId]);

  useEffect(() => {
    if (inspectionReportFilter.filterByType === "asset") {
      setLastFilterData(dropdownAssetList);
    } else if (inspectionReportFilter.filterByType === "assetType") {
      setLastFilterData(dropdownAssetTypeList);
    } else if (inspectionReportFilter.filterByType === "inspectedBy") {
      // New backend api

      const url = `/dropdown/users?accid=${
        isSuperAdmin ? inspectionReportFilter.accountId : loginDetails.accountId
      }&siteid=${
        isSiteAdmin ? loginDetails?.userData?.authorizedSites?.[0] : dropdownFilterWorksiteId
      }&status=true`;

      apiCall(url).then((resp) => {
        setLastFilterData(resp.data?.data?.data);
      });
    } else if (inspectionReportFilter.filterByType === "inspectionResult") {
      setLastFilterData([
        { fullName: "Pass", _id: "true" },
        { fullName: "Fail", _id: "false" },
      ]);
    }
  }, [dropdownAssetTypeList, dropdownAssetList]);

  const carouselData = [
    {
      name: t("Inspections"),
      iconClass: "inspections-performed",
      type: "ThemePrimary",
      feildName: "totalInspection",
      state: { status: "all" },
      to: "#/app/report",
      onClick: () => {
        updateMenuReport({ section: "report", subSection: 0 });
        setInspectionReportFilter({
          ...inspectionReportFilter,
          status: "all",
        });
        updateActiveNavItem("inspectionReports");
      },
    },
    {
      name: t("Passed"),
      iconClass: "inspection-passed",
      type: "GreenPrimary",
      feildName: "PassInspection",
      state: { status: "true" },
      to: "#/app/report",
      onClick: () => {
        updateMenuReport({ section: "report", subSection: 0 });
        setInspectionReportFilter({
          ...inspectionReportFilter,
          status: "passed",
        });
        updateActiveNavItem("inspectionReports");
      },
    },
    {
      name: t("Failed"),
      iconClass: "inspection-failed",
      type: "RedPrimary",
      feildName: "FailedInspection",
      state: { status: "false" },
      to: "#/app/report",
      onClick: () => {
        updateMenuReport({ section: "report", subSection: 0 });
        setInspectionReportFilter({
          ...inspectionReportFilter,
          status: "failed",
        });
        updateActiveNavItem("inspectionReports");
      },
    },
    {
      name: t("Timed Out"),
      iconClass: "inspection-timed-out",
      type: "grayL1",
      feildName: "TimedoutInspection",
      state: { status: "false" },
      to: "#/app/report",
      onClick: () => {
        updateMenuReport({ section: "report", subSection: 0 });
        setInspectionReportFilter({
          ...inspectionReportFilter,
          status: "timedout",
        });
        updateActiveNavItem("inspectionReports");
      },
    },
  ];

  const carouselDataNoReportView = [
    {
      name: t("Inspections"),
      iconClass: "inspections-performed",
      type: "ThemePrimary",
      feildName: "totalInspection",
      state: { status: "all" },
    },
    {
      name: t("Passed"),
      iconClass: "inspection-passed",
      type: "GreenPrimary",
      feildName: "PassInspection",
      state: { status: "true" },
    },
    {
      name: t("Failed"),
      iconClass: "inspection-failed",
      type: "RedPrimary",
      feildName: "FailedInspection",
      state: { status: "false" },
    },
    {
      name: t("Timed Out"),
      iconClass: "inspection-timed-out",
      type: "grayL1",
      feildName: "TimedoutInspection",
      state: { status: "false" },
    },
  ];

  useEffect(() => {
    if (!showMoreFilter) {
      setFilterByTypeLabel("all");
    }
  }, [showMoreFilter]);

  return (
    <DashboardContainer>
      <UnlockModal
        modalShow={showModal}
        updateItem={updateHourMeterListItem}
        handleModalClose={() => setShowModal(false)}
        token={loginDetails?.token}
        data={selectedAsset}
      />

      <MoreFilterMobileToggle>
        <>
          <Row>
            {isSuperAdmin && (
              <Col lg={2}>
                <DropdownWrapper
                  filter={{
                    type: t("Account"),
                    name: "accountName",
                    key: "companyName",
                    data: dropdownAccountList,
                  }}
                  value={accountId}
                  //  label={t("filter")}
                  handleFilter={(value) => {
                    setInspectionReportFilter({
                      ...inspectionReportFilter,
                      accountId: value,
                    });
                    setDropdownAccountId(value);
                  }}
                />
              </Col>
            )}
            {showWorksiteDropdown && (
              <Col lg={2}>
                <DropdownWrapper
                  filter={{
                    type: t("Worksite"),
                    name: "worksiteName",
                    key: "name",
                    data: dropdownWorksiteList,
                  }}
                  value={worksiteId}
                  //    label={t("filter")}
                  handleFilter={(value) => {
                    setDropdownWorksiteId(value);
                    setInspectionReportFilter({
                      ...inspectionReportFilter,
                      worksiteId: value,
                      assetId: null,
                      assetTypeId: null,
                    });
                  }}
                />
              </Col>
            )}
            <Col lg={4}>
              <DateRangePicker
                startDate={startDate}
                endDate={endDate}
                setDate={setInspectionReportFilter}
                filterState={inspectionReportFilter}
              />
            </Col>
            <Col className="text-left" style={{ marginTop: "20px" }}>
              <Button
                iconClass="filter"
                label={showMoreFilter ? t("Hide Filters") : t("More Filters")}
                onClick={() => setShowMoreFilter(!showMoreFilter)}
                showIcon
                buttonType={showMoreFilter ? "primary" : "secondary"}
              />
            </Col>
          </Row>
          {showMoreFilter && (
            <Row>
              <Col lg={2}>
                <DropdownWrapper
                  filter={{
                    type: t("Filter By"),
                    name: "filterBy",
                    placeholder: t("Select a Filter"),
                    key: "name",
                    data: filterByTypeData,
                  }}
                  value={inspectionReportFilter.filterByType}
                  label={t("filter")}
                  handleFilter={(e) => changeFilterByType(e)}
                  useAsDropdown={true}
                />
              </Col>
              {filterByTypeLabel !== t("all") && (
                <Col lg={2}>
                  <DropdownWrapper
                    filter={{
                      type: `${filterByTypeLabel === t("Inspected By") ? t("User") : filterByTypeLabel}`,
                      name: "worksiteName",
                      key:
                        filterByType === "asset"
                          ? "assetName"
                          : filterByType === "assetType"
                            ? "AssetTypeName"
                            : "fullName",
                      data: lastFilterData,
                    }}
                    value={
                      inspectionReportFilter[
                        filterByType === "asset"
                          ? "assetId"
                          : filterByType === "assetType"
                            ? "assetTypeId"
                            : "inspectorId"
                      ]
                    }
                    label={t("filter")}
                    handleFilter={(e) => changeLastFilter(e)}
                    disabled={
                      filterByType === "assetType" &&
                      isSuperAdmin &&
                      (inspectionReportFilter.accountId === "all" || !inspectionReportFilter.accountId)
                    }
                  />
                </Col>
              )}
            </Row>
          )}
        </>
      </MoreFilterMobileToggle>

      <Row className="mt-2">
        <Col lg={8}>
          <CarouselDivCont>
            <CarouselDataInspection
              viewReport={viewReport}
              inspectionCount={inspectionCount}
              updateActiveNavItem={updateActiveNavItem}
              setInspectionReportFilter={setInspectionReportFilter}
              inspectionReportFilter={inspectionReportFilter}
              carouselData={carouselData}
              carouselDataNoReportView={carouselDataNoReportView}
            />
          </CarouselDivCont>
        </Col>
        <Col lg={4}>
          <CarouselDivContto>
            <CarouselDataInspection
              viewReport={false}
              allCount={allCount}
              managementCountBanner={managementCountBanner}
              size="small"
            />
          </CarouselDivContto>
        </Col>
      </Row>
      <Row>
        <Col lg={isAccountViewer ? 12 : 8}>
          <GraphContainer>
            <DashboardGraph
              title={t("Inspection Trends")}
              series={[
                {
                  name: t("Inspections Performed"),
                  data: graphData?.count ?? [],
                },
                {
                  name: t("Inspections Passed"),
                  data: graphData?.passed ?? [],
                },
                {
                  name: t("Inspections Failed"),
                  data: graphData?.failed ?? [],
                },
                {
                  name: t("Inspections Timed Out"),
                  data: graphData?.timedout ?? [],
                },
              ]}
              colors={[
                themeContext?.colors?.themePrimary || "#0D5FBE",
                themeContext?.colors?.greenPrimary || "#11A70B",
                themeContext?.colors?.redPrimary || "#DD0000",
                themeContext?.colors?.grayL1 || "#323232",
              ]}
              date={graphData?.date}
              count={graphData?.count}
              startDate={inspectionReportFilter.startDate}
              endDate={inspectionReportFilter.endDate}
            />
          </GraphContainer>
          <PermissionCheck section={"DASHBOARD"} permissionName={"REPORTS"} actionName={"view"}>
            <FailedInspectionDiv>
              <DivContainer heading="Failed Inspections" to="/app/report" onClick={() => handleViewAll()}>
                <FailedInspectionTableWrapper
                  data={inspectionReportList}
                  isSiteAdmin={isSiteAdmin}
                  isSingleAccountMultiSiteUser={isSingleAccountMultiSiteUser}
                />
              </DivContainer>
            </FailedInspectionDiv>
          </PermissionCheck>
        </Col>
        <PermissionsCheck
          section="DASHBOARD"
          permissionNames={[
            "ASSET_MANAGEMENT",
            "ASSET_TYPE_MANAGEMENT",
            "WORKSITE_MANAGEMENT",
            "USER_MANAGEMENT",
          ]}
          actionName="add"
          operator="OR"
        >
          <Col lg={isAccountViewer ? 12 : 4} className="mt-2">
            <DivContainer heading="Quick Management Access" showViewAll={false}>
              <QuickAccess />
            </DivContainer>
            {/* Below code is commented as part of SIER-3209 temporarily*/}
            {/*  <HourMeterReading
              getLastHourMeterListing={getLastHourMeterListing}
              lastHourMeterReadingLoading={lastHourMeterReadingLoading}
              reportFilter={inspectionReportFilter}
              lastHourMeterReadingList={lastHourMeterReadingList}
            /> */}
          </Col>
        </PermissionsCheck>
      </Row>
    </DashboardContainer>
  );
}

const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
  isSuperAdmin: state.user.isSuperAdmin,
  isAccountAdmin: state.user.isAccountAdmin,
  isAccountOwner: state.user.isAccountOwner,
  isSiteAdmin: state.user.isSiteAdmin,
  isAccountViewer: state.user.isAccountViewer,
  menuList: state.sideMenu.reportMenuState,
  getInspectionReportListLoading: state.reports.getInspectionReportListLoading,
  totalInspectionReport: state.reports.inspectionReportTotalCount,
  inspectionReportList: state.reports.inspectionReportList,
  worksiteList: state.worksites.Worksites,
  accountList: state.accounts.Accounts,
  assetsList: state.assets.Assets,
  assetTypeList: state.assets.AssetTypes,
  lastHourMeterReadingList: state.reports.lastHourMeterReadingList,
  lastHourMeterReadingLoading: state.reports.getLastHourMeterListLoading,
  allCount: state.counts.allCount,
  getAllCountLoading: state.counts.getAllCountLoading,
  getInspectionCountLoading: state.counts.getInspectionCountLoading,
  inspectionCount: state.counts.inspectionCount,
  inspectionReportFilter: state.filters.inspectionReportFilter,
  graphData: state.counts.graphData,
  dropdownWorksiteList: state.dropdownFilters.worksiteList,
  dropdownAccountList: state.dropdownFilters.accountList,
  dropdownAssetTypeList: state.dropdownFilters.assetTypeList,
  dropdownAssetList: state.dropdownFilters.assetList,
  dropdownFilterAccountId: state.dropdownFilters.accountId,
  dropdownFilterWorksiteId: state.dropdownFilters.worksiteId,
  fileDownloadLoading: state.config.fileDownloadLoading,
  permissions: state.user?.loginDetails?.userData?.permissions,
  isSingleAccountMultiSiteUser: state.user.isSingleAccountMultiSiteUser,
  showWorksiteDropdown: state.user.showWorksiteDropdown,
});

const mapDispatchToProps = (dispatch) => ({
  updateMenu: (menuState) => dispatch(updateMenu(menuState)),
  updateActiveNavItem: (data) => dispatch(updateActiveNavItem(data)),
  getInspectionReportListing: (filterData) => dispatch(getInspectionReportListing(filterData)),
  updateHourMeterListItem: (data) => dispatch(updateHourMeterListItem(data)),
  updateMenuReport: (menuDetails) => dispatch(updateMenuReport(menuDetails)),
  getAllCount: () => dispatch(getAllCount()),
  getInspectionCount: (filterData) => dispatch(getInspectionCount(filterData)),
  getGraphData: (filterData) => dispatch(getGraphData(filterData)),
  setInspectionReportFilter: (filterData) => dispatch(setInspectionReportFilter(filterData)),
  setDropdownAccountId: (accountId) => dispatch(setDropdownAccountId(accountId)),
  setDropdownWorksiteId: (worksiteId) => dispatch(setDropdownWorksiteId(worksiteId)),
  toDownloadFiles: (folderName, fileName) => dispatch(toDownloadFiles(folderName, fileName)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InspectionDashboard));

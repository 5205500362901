import styled from "styled-components";
export const NoAssetWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 300px);
  color: ${({ theme }) => theme.colors.grayL3 || "#676767"};
  font-family: "Open Sans", sans-serif;
  font-size: 16px !important;
  margin-bottom: 0px;
  p {
    margin-bottom: 0px;
  }
  @media (max-width: 768px) {
    height: auto;
  }
`;

//TODO - When time permits, review and optmize this file. Remove unused/commented code. Create cleaner sub components

import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";

import Styles from "./AddAssetTypeModalStyles.module.css";
import { getAllChecklist } from "../../../../../redux/checklists/action";
import { getAssetTypes } from "../../../../../redux/assets/action";
import Button from "../../../../../components/Button/Button.component";
import { createAssetType } from "../../../../../redux/assetType/assetType.action";
import alertConstant from "../../../../../constant/messages/toaster.json";
import { getDropdownAssetTypeList } from "../../../../../redux/dropdownFilters/action";
import RadioButton from "../../../../../components/RadioButton/Radio.component";
import {
  AssetTypeChecklistWrapper,
  DeleteIconStyled,
  HeadingContainer,
  DropdownDiv,
  DropdownSelect,
  DeleteButton,
  NoChecklistAdded,
  AssetTypeDetailsTableDescription,
  RowDiv,
  Column,
  RowsContainer,
  ColumnReorder,
  MobileViewText,
  AddChecklistDivB,
  EditCancelButton,
} from "./AddAssetTypeChecklist.component.styles";
import DropdownFilterWrapper from "../../../../../components/DropdownComponent/DropdownFilterWrapper";

function AddAssetTypeChecklist({
  isSuperAdmin,
  activeStep,
  setActiveStep,
  checklist,
  dropdownAccountId,
  getAllChecklist,
  addAssetTypeModalData,
  setAddAssetTypeModalData,
  setIsError,
  setIsSuccess,
  handleCloseModal,
  getAssetTypes,
  getDropdownAssetTypeList,
  loginDetails,
  createAssetType,
  addAssetTypeSuccessFlag,
  assetTypeCreationFailed,
}) {
  const [checklistData, setchecklistData] = useState([]);
  const [checklistAPIData, setChecklistAPIData] = useState([]);
  const [finalUnassignedChecklist, setFinalUnassignedChecklist] = useState([]);
  const [CurrentSelectedChecklist, setCurrentSelectedChecklist] = useState("");
  const [defaultChecklist, setDefaultChecklist] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [showAddChecklistLayout, setShowAddChecklistLayout] = useState(false);
  const tableBodyContainer = useRef(null);
  const { t } = useTranslation();
  const handleAddChecklist = () => {
    if (checklistData?.length == 0) {
      setDefaultChecklist(CurrentSelectedChecklist);
    }

    let newChecklistData = checklist?.filter((checklist) => {
      if (checklist?._id === CurrentSelectedChecklist) {
        return {
          checklistName: checklist?.checklistName,
          checklistDescription: checklist?.checklistDescription,
          _id: checklist?._id,
        };
      }
    });

    setShowAddChecklistLayout(false);
    setchecklistData([...checklistData, ...newChecklistData]);
    setCurrentSelectedChecklist("");

    tableBodyContainer &&
      tableBodyContainer.current &&
      tableBodyContainer.current.lastElementChild.scrollIntoView({ behavior: "smooth" });
  };

  const handleRemoveChecklist = (checklistId) => {
    if (checklistId == defaultChecklist) {
      if (checklistData[0]?._id == checklistId) {
        setDefaultChecklist(checklistData[1]?._id);
      } else {
        setDefaultChecklist(checklistData[0]?._id);
      }
    }
    let newChecklistData = checklistData?.filter((checklist) => {
      if (checklist?._id == checklistId) {
        return;
      } else {
        return checklist;
      }
    });
    // console.log(newChecklistData)
    if (newChecklistData) setchecklistData([...newChecklistData]);
    else setchecklistData([]);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const submitAssetType = async () => {
    setIsLoading(true);
    const accountId = isSuperAdmin ? dropdownAccountId : loginDetails.accountId;
    createAssetType(accountId, addAssetTypeModalData);
  };

  useEffect(() => {
    if (addAssetTypeSuccessFlag) {
      setIsLoading(false);
      setIsSuccess(alertConstant.asset_type_added_success);
      getDropdownAssetTypeList();
      getAssetTypes({ pageNo: 0, pageSize: 0 }, true);
      handleCloseModal();
    }

    if (assetTypeCreationFailed) {
      setIsError(alertConstant.asset_type_name_duplicate);
      setIsLoading(false);
    }
  }, [addAssetTypeSuccessFlag, assetTypeCreationFailed]);

  const handleSubmit = () => {
    if (!isLoading) {
      submitAssetType();
    }
  };

  useEffect(() => {
    if (!checklistData) {
      return;
    }
    let finalChecklist = [];
    let selectedCheckListIds = checklistData.length > 0 && checklistData?.map?.((list) => list?._id);
    let filteredCheckList =
      checklist && checklist?.filter?.((list) => !selectedCheckListIds?.includes?.(list._id));
    let checklistResult =
      filteredCheckList &&
      filteredCheckList.map((data) => {
        if ((dropdownAccountId || loginDetails?.accountId) === data.accountId) {
          finalChecklist.push({ _id: data._id, label: data.checklistName });
        }
      });

    setFinalUnassignedChecklist([...finalChecklist]);
  }, [checklist, checklistData]);

  useEffect(() => {
    getAllChecklist(
      {
        pageNo: 0,
        pageSize: 0,
        searchKey: "all",
      },
      false,
      isSuperAdmin ? dropdownAccountId : loginDetails.accountId
    );
  }, []);

  useEffect(() => {
    // console.log("Checklist Data", checklistData)
    let apiChecklistData = checklistData?.map((checklist) => {
      return {
        isDefaultChecklist: checklist?._id === defaultChecklist,
        checklistId: checklist?._id,
      };
    });
    setChecklistAPIData(apiChecklistData);
  }, [checklistData, defaultChecklist]);

  useEffect(() => {
    if (checklistAPIData?.length > 0) {
      setAddAssetTypeModalData({
        ...addAssetTypeModalData,
        checklists: [...checklistAPIData],
      });
      if (checklistData?.length === 1) {
        setDefaultChecklist(checklistData[0]?._id);
      }
    } else {
      let newChecklistData = addAssetTypeModalData;
      delete newChecklistData?.checklists;
      setAddAssetTypeModalData({ ...newChecklistData });
    }
  }, [checklistAPIData]);

  useEffect(() => {
    handleAddChecklist();
  }, [CurrentSelectedChecklist]);

  const [isIconOnly, setIsIconOnly] = useState(window.innerWidth <= 1100);
  useEffect(() => {
    const handleResize = () => {
      setIsIconOnly(window.innerWidth <= 1100);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <AssetTypeChecklistWrapper>
      <HeadingContainer>
        <Trans>Asset Type Checklists</Trans>
      </HeadingContainer>

      <div className="mb-2 asset-type-checklist-modal">
        <>
          {/* When there are no checklist */}
          {checklistData?.length === 0 && !showAddChecklistLayout && (
            <NoChecklistAdded>
              <Trans>There is no checklist added to this asset type</Trans>.<br />
              <Trans>Click '+ ADD CHECKLIST' to add a checklist</Trans>
            </NoChecklistAdded>
          )}

          <>
            <div className="userTypeDetailsHeight">
              <AssetTypeDetailsTableDescription className="userTypeDetailsHeight">
                {(checklistData?.length > 0 || showAddChecklistLayout) && (
                  <>
                    <RowDiv isHeader={true}>
                      <Column isHeader={true} textleft={"left"}>
                        <Trans>Checklist Name</Trans>
                      </Column>
                      <Column isHeader={true} textleft={"left"}>
                        <Trans>Checklist Description</Trans>
                      </Column>
                      <Column isHeader={true} textleft={"center"}>
                        <Trans>Default Checklist</Trans>
                      </Column>
                      <Column isHeader={true} textleft={"center"}>
                        <Trans>Action</Trans>
                      </Column>
                    </RowDiv>

                    <RowsContainer ref={tableBodyContainer}>
                      {checklistData.map((checklist, i) => (
                        <>
                          <RowDiv>
                            <Column
                              textleft={"left"}
                              className="firstMobileCss"
                              title={checklist?.checklistName}
                            >
                              <Trans>{checklist?.checklistName}</Trans>
                            </Column>
                            <ColumnReorder textleft={"left"} title={checklist?.checklistDescription}>
                              {checklist?.checklistDescription}
                            </ColumnReorder>
                            <ColumnReorder textleft={"center"}>
                              <MobileViewText>Default Checklist</MobileViewText>
                              <RadioButton
                                checked={defaultChecklist == checklist?._id}
                                onClick={() => {
                                  setDefaultChecklist(checklist?._id);
                                }}
                                size="small"
                                id={"id_" + i}
                              />
                            </ColumnReorder>
                            <ColumnReorder textleft={"center"}>
                              <DeleteIconStyled
                                className="icon icon-delete"
                                onClick={() => {
                                  handleRemoveChecklist(checklist?._id);
                                }}
                              />
                            </ColumnReorder>
                          </RowDiv>
                        </>
                      ))}
                      <div ref={tableBodyContainer}>
                        <span></span>
                      </div>
                    </RowsContainer>
                  </>
                )}
              </AssetTypeDetailsTableDescription>

              <DropdownDiv>
                {showAddChecklistLayout && (
                  <>
                    <DropdownSelect>
                      <DropdownFilterWrapper
                        options={finalUnassignedChecklist}
                        label={"Checklist"}
                        value={CurrentSelectedChecklist}
                        onChange={(value) => {
                          setCurrentSelectedChecklist(value);
                        }}
                        required={true}
                      />
                    </DropdownSelect>
                    <DeleteButton>
                      <DeleteIconStyled
                        title="Delete"
                        className="icon icon-delete deleteICon"
                        onClick={() => {
                          setShowAddChecklistLayout(false);
                        }}
                      />
                    </DeleteButton>
                  </>
                )}
              </DropdownDiv>
            </div>
          </>
        </>
      </div>

      {/* Add Asset Type Modal Actions */}
      <div className={`${Styles.footerActions}`}>
        <AddChecklistDivB>
          <Button
            label={t("Add Checklist")}
            onClick={() => {
              finalUnassignedChecklist?.length > 0 && setShowAddChecklistLayout(true);
            }}
            iconClass="add"
            showIcon
            buttonType="secondary"
            disabled={finalUnassignedChecklist?.length === 0}
            {...(isIconOnly && { iconOnly: true })}
          />
        </AddChecklistDivB>
        <EditCancelButton>
          <Button
            label={t("Back")}
            onClick={() => {
              handleBack();
            }}
            showIcon
            iconClass="arrow-left"
            buttonType="ghost"
          />

          {isLoading ? (
            <button className={`buttonSubmit ${Styles.submitButton} ripple`}>
              <div className="spinner-border spinner-border-sm" role="status"></div>
            </button>
          ) : (
            <Button label={t("Submit")} onClick={handleSubmit} iconClass="save" showIcon buttonType="save" />
          )}
        </EditCancelButton>
      </div>
    </AssetTypeChecklistWrapper>
  );
}

const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
  checklist: state.checklist.checklists,
  isSuperAdmin: state.user.isSuperAdmin,
  addAssetTypeSuccessFlag: state.assetType.addAssetTypeSuccessFlag,
  assetTypeCreationFailed: state.assetType.assetTypeCreationFailed,
});

const mapDispatchToProps = (dispatch) => ({
  getAllChecklist: (paginationData, isResetState, accId) =>
    dispatch(getAllChecklist(paginationData, isResetState, accId)),
  getAssetTypes: (paginationData, isResetState) => dispatch(getAssetTypes(paginationData, isResetState)),
  getDropdownAssetTypeList: () => dispatch(getDropdownAssetTypeList()),
  createAssetType: (accountId, data) => dispatch(createAssetType(accountId, data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddAssetTypeChecklist));

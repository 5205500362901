import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import BreadCrumb from "../../../components/BreadCrumb.component";
import InspectionReports from "./inspection/InspectionReport.component";
import ImpactReports from "./impact/ImpactReports.component";
import UnauthorizedReports from "./UnauthorizedReports";
import DownloadStatus from "./DownloadStatus/DownloadStatus.component";
import SnackBar from "../SnackBar";
import {
  getDropdownAccountList,
  getDropdownWorksiteList,
  getDropdownAssetList,
  getDropdownAssetTypeList,
} from "../../../redux/dropdownFilters/action";
import { updateActiveNavItem } from "../../../redux/sideMenu/menu.action";
import { TabMenuWrapper } from "./Report.component.styles";
import SiearTabs from "../../../components/TabComponent/SieraTabs.Component";
import SiearTab from "../../../components/TabComponent/SieraTab.Component";

function Reports({
  menuList,
  getDropdownAccountList,
  getDropdownWorksiteList,
  getDropdownAssetList,
  getDropdownAssetTypeList,
  updateActiveNavItem,
  loginDetails,
  isSuperAdmin,
  permissions,
}) {
  const [value, setValue] = useState(0);
  const [shareReportModal, setShareReportModal] = useState(false);
  const [reportDataToDownload, setReportDataToDownload] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleChange = (currentTab) => {
    const navItems = ["inspectionReports", "incidentReports", "unauthorizedReports", "downloadStatus"];
    updateActiveNavItem(navItems[currentTab]);
    setValue(currentTab);
  };

  const handleCloseSnackBar = () => {
    setIsSuccess(false);
    setIsError(false);
  };

  useEffect(() => {
    if (menuList) {
      setValue(menuList.subSection);
    }
  }, [menuList]);

  useEffect(() => {
    getDropdownAccountList();
    getDropdownWorksiteList();
    getDropdownAssetList();
    getDropdownAssetTypeList();
  }, [getDropdownAccountList, getDropdownWorksiteList, getDropdownAssetList, getDropdownAssetTypeList]);

  const { t } = useTranslation();

  const renderTabContent = (Component) => (
    <Component
      shareReportModal={shareReportModal}
      setShareReportModal={setShareReportModal}
      setReportDataToDownload={setReportDataToDownload}
      setIsSuccess={setIsSuccess}
      setIsError={setIsError}
    />
  );

  const getPermission = (section, permissionName, actionName) => {
    const currentSectionObj = permissions?.find?.((permission) => permission.section === section);
    const currentPermissionObj = currentSectionObj?.permissions?.find(
      (permissionObj) => permissionObj.name === permissionName
    );
    return currentPermissionObj?.action[actionName];
  };

  return (
    <>
      <BreadCrumb />
      <TabMenuWrapper>
        <SiearTabs
          defaultActiveKey={value}
          activeKey={value}
          onSelect={(key) => handleChange(key)}
          variant="horizontalNew"
          align="left"
        >
          <SiearTab eventKey={0} title={t("Inspection Reports")}>
            {renderTabContent(InspectionReports)}
          </SiearTab>
          <SiearTab eventKey={1} title={t("Incident Reports")}>
            {renderTabContent(ImpactReports)}
          </SiearTab>
          <SiearTab eventKey={2} title={t("Unauthorized Access")}>
            {renderTabContent(UnauthorizedReports)}
          </SiearTab>
          {getPermission("DASHBOARD", "DOWNLOAD_STATUS", "view") && (
            <SiearTab eventKey={3} title={t("Download Status")}>
              {renderTabContent(DownloadStatus)}
            </SiearTab>
          )}
        </SiearTabs>
      </TabMenuWrapper>

      <SnackBar
        isSuccess={isSuccess}
        isError={isError}
        label={isSuccess || isError}
        handleClose={handleCloseSnackBar}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
  isSuperAdmin: state.user.isSuperAdmin,
  menuList: state.sideMenu.reportMenuState,
  permissions: state.user?.loginDetails?.userData?.permissions,
});

const mapDispatchToProps = (dispatch) => ({
  getDropdownAccountList: () => dispatch(getDropdownAccountList()),
  getDropdownWorksiteList: () => dispatch(getDropdownWorksiteList()),
  getDropdownAssetList: () => dispatch(getDropdownAssetList()),
  getDropdownAssetTypeList: () => dispatch(getDropdownAssetTypeList()),
  updateActiveNavItem: (data) => dispatch(updateActiveNavItem(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Reports));

import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { Trans } from "react-i18next";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
const CarouselWrapperDiv = styled.div`
  .borderCar {
    background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
    border: 1px solid ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
  }
  .count-card {
    color: ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"};
    span {
      color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} !important;
    }
  }
  .borderLeftStyle {
    border-left: 1px solid ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
  }
`;
const AssetIconconDiv = styled.div`
  font-size: 18px !important;
  color: ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"};
  &:before {
    top: 4px;
    right: 7px;
    position: relative;
  }
`;
const AssetsCard = ({ assetTotalCount }) => {
  return (
    <CarouselWrapperDiv>
      <Card className="borderCar">
        <Card.Body className="p-1">
          <Row style={{ fontSize: "11px", color: "#919191" }}>
            <Col className="count-card ">
              <Trans>Assets</Trans>
              <br />
              <AssetIconconDiv className="icon icon-assets">
                <span className="h3 ml-1" style={{ verticalAlign: "middle", color: "#397CCA" }}>
                  {assetTotalCount || 0}
                </span>
              </AssetIconconDiv>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </CarouselWrapperDiv>
  );
};

const mapStateToProps = (state) => ({
  assetTotalCount: state.assets.totalCount,
});

const mapDispatchToProps = (dispatch) => ({});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AssetsCard));

import SimpleInput from "../../../../components/InputFieldComponent/InputField.component";
import { DetailsMainWrapper, DetailsHeading } from "./DeviceDetailsInfo.styles";
import { Row, Col } from "react-bootstrap";
import { assetDetails } from "../constants";
import { Trans } from "react-i18next";
import moment from "moment";
import { getTimeWithTimeZone } from "../../../../utils/timezoneConvert";

const AssetDetails = (props) => {
  const { selectedAsset } = props;
  const { siteId } = selectedAsset;

  const getValueObj = (fieldType, key, iconName, iconColor) => {
    let timeZone = siteId?.timeZone;
    if (!timeZone) {
      //adding a default timezone if timezone not found ..!!
      timeZone = "-06:00 Central America";
    }
    switch (fieldType) {
      case "date":
        return {
          value: selectedAsset?.[key]
            ? moment(selectedAsset?.[key])
                .utcOffset(timeZone && timeZone.split && timeZone.split(" ")[0])
                .format("MM/DD/YYYY, hh:mm A") +
              " " +
              getTimeWithTimeZone(timeZone)
            : null,
          iconColor: iconColor,
          iconName,
        };
      case "inspectionReport": {
        const lastPassedInspectionAt = selectedAsset?.lastPassedInspectionAt
          ? moment(selectedAsset?.lastPassedInspectionAt)
          : 0;
        const lastFailedInspectionAt = selectedAsset?.lastFailedInspectionAt
          ? moment(selectedAsset?.lastFailedInspectionAt)
          : 0;
        const lastTimedoutInspectionAt = selectedAsset?.lastTimedoutInspectionAt
          ? moment(selectedAsset?.lastTimedoutInspectionAt)
          : 0;

        const dateValue =
          selectedAsset?.lastPassedInspectionAt ||
          selectedAsset?.lastFailedInspectionAt ||
          selectedAsset?.lastTimedoutInspectionAt;

        const latestReportTime =
          lastPassedInspectionAt > lastFailedInspectionAt && lastPassedInspectionAt > lastTimedoutInspectionAt
            ? selectedAsset?.lastPassedInspectionAt
            : lastFailedInspectionAt > lastPassedInspectionAt &&
              lastFailedInspectionAt > lastTimedoutInspectionAt
            ? selectedAsset?.lastFailedInspectionAt
            : selectedAsset?.lastTimedoutInspectionAt;

        return {
          value: dateValue
            ? moment(latestReportTime)
                .utcOffset(timeZone && timeZone.split && timeZone.split(" ")[0])
                .format("MM/DD/YYYY, hh:mm A") +
              " " +
              getTimeWithTimeZone(timeZone)
            : null,
          iconColor:
            lastPassedInspectionAt > lastFailedInspectionAt &&
            lastPassedInspectionAt > lastTimedoutInspectionAt
              ? "greenPrimary"
              : lastFailedInspectionAt > lastPassedInspectionAt &&
                lastFailedInspectionAt > lastTimedoutInspectionAt
              ? "redPrimary"
              : "grayL1",
          iconName:
            lastPassedInspectionAt > lastFailedInspectionAt &&
            lastPassedInspectionAt > lastTimedoutInspectionAt
              ? "inspection-passed"
              : lastFailedInspectionAt > lastPassedInspectionAt &&
                lastFailedInspectionAt > lastTimedoutInspectionAt
              ? "inspection-failed"
              : "inspection-timed-out",
        };
      }

      case "impactReport": {
        const lowImpact = selectedAsset?.lastLowImpactAt ? moment(selectedAsset?.lastLowImpactAt) : null;
        const mediumImpact = selectedAsset?.lastMediumImpactAt
          ? moment(selectedAsset?.lastMediumImpactAt)
          : null;
        const highImpact = selectedAsset?.lastHighImpactAt ? moment(selectedAsset?.lastHighImpactAt) : null;

        let minTime = lowImpact || mediumImpact || highImpact;
        let color = "greenPrimary";

        if (lowImpact && minTime < lowImpact) {
          minTime = lowImpact;
          color = "orangePrimary";
        }

        if (mediumImpact && minTime < mediumImpact) {
          minTime = mediumImpact;
          color = "orangePrimary";
        }

        if (highImpact && minTime < highImpact) {
          minTime = highImpact;
          color = "redPrimary";
        }

        return {
          value: minTime
            ? minTime
                .utcOffset(timeZone && timeZone.split && timeZone.split(" ")[0])
                .format("MM/DD/YYYY hh:mm A") +
              " " +
              getTimeWithTimeZone(timeZone)
            : null,
          iconColor: color,
          iconName,
        };
      }

      default:
        return {
          value: selectedAsset[key],
          iconColor,
          iconName,
        };
    }
  };

  return (
    <>
      <DetailsMainWrapper>
        <DetailsHeading>
          <Trans>Asset Details</Trans>
        </DetailsHeading>
        <Row>
          {assetDetails.map((field) => {
            const valueObj = getValueObj(field.type, field.key, field.iconName, field.iconColor);
            return (
              <Col md={3} className="mb-3">
                <SimpleInput
                  label={field.label}
                  value={valueObj.value === 0 ? 0 : valueObj.value ? valueObj.value : "NA"}
                  readOnly={true}
                  iconNameRight={valueObj.value ? valueObj.iconName : null}
                  iconColor={valueObj.iconColor}
                  showIconRight
                  size="medium"
                />
              </Col>
            );
          })}
        </Row>
        <hr className="mb-0 mt-0" />
      </DetailsMainWrapper>
    </>
  );
};

export default AssetDetails;

import styled, { css } from "styled-components";
import { Modal } from "react-bootstrap";
import Button from "../../components/Button/Button.component";

export const ModalWrapper = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Open+Sans:wght@300;400;500;600;700&display=swap");
`;

export const StyledModal = styled(Modal)`
  .modal-content {
    background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme.colors.grayL3 || "#676767"};
  }
  .modal-header {
    padding: 32px 30px 0px 30px !important;
    border-bottom: 0px solid ${({ theme }) => theme.colors.grayL3 || "#676767"};
    @media (max-width: 1100px) {
      padding: 16px 20px 0px 15px !important;
    }
  }
  .modal-title {
    margin-bottom: 0;
    line-height: 1.5;
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
    font-weight: 400;
    font-size: 28px !important;
    font-family: "Kanit", sans-serif;
    @media (max-width: 1100px) {
      font-size: 22px !important;
    }
  }
`;

export const AlertModal = styled.div`
  text-align: center;
  .icon-certificate-ok {
    color: ${({ theme }) => theme.colors.greenPrimary || "#11A70B"};
  }
`;

export const AlertIcon = styled.div`
  font-size: 68px !important;
  color: ${({ theme }) => theme.colors.redPrimary || "#DD0000"};
`;

export const MessageStyled = styled.div`
  text-align: center;
  font-size: 28px !important;
  font-family: "Kanit", sans-serif;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  text-transform: uppercase;
  width: 70%;
  margin: 10px auto;
`;
export const ParagraphStyled = styled.div`
  text-align: center;
  font-size: 18px !important;
  font-family: "Open Sans", sans-serif;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  width: ${({ width }) => width};
  margin: 10px auto;
`;
export const ButtonStyled = styled.div`
  text-align: center;
  margin-top: 20px;
  margin-bottom: 50px;
  button {
    width: 200px;
    margin-top: 5px;
  }
`;

// New styled component for conditional button rendering
export const ResponsiveButton = styled.div`
  .mobileClose {
    display: none;
  }
  @media (max-width: 1100px) {
    button[title="Cancel"] {
      display: none;
    }
    .mobileClose {
      display: block;
      font-size: 18px;
    }
  }
`;

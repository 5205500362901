import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { LoginRecordsWrapper } from "./LoginRecords.styles";
import LoginRecordsTable from "./TableWrapper/LoginRecordsTable.component";
import Filters from "./Filters.component";
import BreadCrumb from "../../../../components/BreadCrumb.component";
import { updateActiveNavItem } from "../../../../redux/sideMenu/menu.action";

const LoginRecords = ({ updateActiveNavItem }) => {
  useEffect(() => {
    updateActiveNavItem("loginRecords");
  }, [updateActiveNavItem]);

  const [loginRecords, setLoginRecords] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [pageNo, setPageNo] = useState(1);
  const [downloadReportSet, addDownloadReportSet] = useState(new Set());
  const [isAllSelected, setSelectAll] = useState(false);

  const handlePageLength = (e) => {
    setPageSize(parseInt(e.target.value));
    setPageNo(1);
  };

  const previousPage = () => {
    if (pageNo === 1) {
      return;
    }
    setPageNo(pageNo - 1);
  };

  const nextPage = () => {
    if (!loginRecords?.total || pageNo * pageSize >= loginRecords?.total) {
      return;
    }
    setPageNo(pageNo + 1);
  };

  const handleCheckboxClick = (e) => {
    const newDownloadReportSet = new Set(downloadReportSet);

    if (e.target.checked) {
      newDownloadReportSet.add(e.target.value);
    } else {
      newDownloadReportSet.delete(e.target.value);
    }
    addDownloadReportSet(newDownloadReportSet);
    if (loginRecords?.data?.length === newDownloadReportSet.size) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      const newDownloadReportSet = new Set(downloadReportSet);
      loginRecords?.data?.forEach((data, i) => {
        newDownloadReportSet.add(data._id);
      });
      addDownloadReportSet(newDownloadReportSet);
      setSelectAll(true);
    } else {
      const newDownloadReportSet = new Set();
      addDownloadReportSet(newDownloadReportSet);
      setSelectAll(false);
    }
  };

  useEffect(() => {
    addDownloadReportSet(new Set());
    setSelectAll(false);
  }, [pageNo, pageSize]);

  return (
    <LoginRecordsWrapper>
      <BreadCrumb />
      <Filters
        setLoginRecords={setLoginRecords}
        pageSize={pageSize}
        pageNo={pageNo}
        downloadReportSet={downloadReportSet}
        recordSet={loginRecords?.data}
      />
      <LoginRecordsTable
        data={loginRecords?.data || []}
        rowHeight={"370"}
        previousPage={previousPage}
        nextPage={nextPage}
        handlePageLength={handlePageLength}
        pageSize={pageSize}
        pageNo={pageNo}
        totalCount={loginRecords?.total || 0}
        downloadReportSet={downloadReportSet}
        isAllSelected={isAllSelected}
        handleCheckboxClick={handleCheckboxClick}
        handleSelectAll={handleSelectAll}
      />
    </LoginRecordsWrapper>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  updateActiveNavItem: (item) => dispatch(updateActiveNavItem(item)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginRecords);

import React from "react";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import AccountNameFilter from "../../../components/DropdownFilter.component";
import { setDropdownAccountId } from "../../../redux/dropdownFilters/action";
import { useTranslation } from "react-i18next";

const ViewAssetTypes = ({ isSuperAdmin, dropdownAccountList, setDropdownAccountId, dropdownAccountId }) => {
  const { t } = useTranslation();
  return (
    <>
      {isSuperAdmin && (
        <Row style={{ marginBottom: "12px" }}>
          <Col md={4}>
            <AccountNameFilter
              filter={{
                type: "Account",
                key: "companyName",
                data: dropdownAccountList,
              }}
              label={t("filter")}
              handleFilter={(value) => setDropdownAccountId(value)}
              value={dropdownAccountId}
            />
          </Col>
        </Row>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  isSuperAdmin: state.user.isSuperAdmin,
  dropdownAccountList: state.dropdownFilters.accountList,
  dropdownAccountId: state.dropdownFilters.accountId,
});

const mapDispatchToProps = (dispatch) => ({
  setDropdownAccountId: (accountId) => dispatch(setDropdownAccountId(accountId)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ViewAssetTypes));

import React, { useState, useEffect } from "react";
import AccountNameFilter from "../../../components/DropdownFilter.component";
import WorksiteCard from "./WorksiteCard.component";
import { Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import WorksiteOverview from "./WorksiteOverview.component";
import { setEditAlertModal } from "../../../redux/modals/action";
import { useTranslation } from "react-i18next";
import { setDropdownAccountId } from "../../../redux/dropdownFilters/action";
import {
  worksiteStatusUpdate,
  resetWorksitesState,
  worksiteNameUpdate,
  preDeactivatedWorksite,
  deactivateWorksite,
} from "../../../redux/worksites/action";
import ModalComponent from "../../../components/ModalPopupComponent/ModalPopup.component";

import WorksiteDeactivationModal from "./WorksiteDeactivationModal.component";

const Worksite = ({
  loginDetails,
  filterAccountId,
  setDropdownAccountId,
  dropdownAccountList,
  dropdownAccountId,
  editForm,
  setEditAlertModal,
  isSuperAdmin,
  preDeactivatedWorksite,
  deactivateWorksite,
  preDeactivatedWorksiteList,
  deactivateWorksiteSuccess,
  selectWorksiteOverview,
  statusSuccess,
  worksiteStatusUpdate,
  resetWorksitesState,
  worksiteNameUpdate,
}) => {
  const { t } = useTranslation();
  const { name, _id, status, createdAt } = selectWorksiteOverview
    ? selectWorksiteOverview
    : {
        name: "",
        createdDate: new Date(),
        accountId: "",
        _id: "",
        s3Enable: false,
        status: true,
      };
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [statusButton, setStatusButton] = useState(status);
  useEffect(() => {
    if (preDeactivatedWorksiteList?.users?.length > 0 || preDeactivatedWorksiteList?.assets?.length > 0) {
      setShowAlertModal(true);
    }
  }, [preDeactivatedWorksiteList]);
  return (
    <>
      <div>
        <Row>
          <Col md={5}>
            {isSuperAdmin && (
              <AccountNameFilter
                filter={{
                  type: "Account",
                  name: "companyName",
                  key: "companyName",
                  data: dropdownAccountList,
                }}
                value={dropdownAccountId}
                label={t("filter")}
                handleFilter={(value) => (editForm ? setDropdownAccountId(value) : setEditAlertModal(true))}
              />
            )}
          </Col>
          <Col md={7}>
            <WorksiteCard />
          </Col>
        </Row>
      </div>
      <div style={{ paddingTop: "10px" }}>
        {/* {!editForm && <div className="alert-modal-overlay" onClick={()=>setEditAlertModal(true)}></div>} */}
        <WorksiteOverview filterAccountId={filterAccountId} />

        <ModalComponent
          show={showAlertModal}
          variant="AlertModal"
          handleClose={() => setShowAlertModal(false)}
          iconClass="alertsettings"
          message="Confirm Deactivation"
          paragraph={
            <WorksiteDeactivationModal
              siteId={_id}
              preDeactivatedWorksiteList={preDeactivatedWorksiteList}
              preDeactivatedWorksite={preDeactivatedWorksite}
              selectWorksiteOverview={selectWorksiteOverview}
            />
          }
          button1Text="Cancel"
          button2Text="Confirm"
          onClickA={() => {
            setShowAlertModal(false);
          }}
          onClickB={() => {
            setShowAlertModal(false);
            // const updatedStatus = { isActive: !isActive };
            deactivateWorksite(_id, selectWorksiteOverview?.accountId?._id);
            //worksiteStatusUpdate(_id, status);
            setStatusButton(!statusButton);
            // toggleUserTypeStatus(_id, updatedStatus);
          }}
          typeA="primary"
          typeB="ghost"
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
  accountList: state.accounts.Accounts,
  dropdownAccountList: state.dropdownFilters.accountList,
  dropdownAccountId: state.dropdownFilters.accountId,
  editForm: state.forms.editForm,
  isSuperAdmin: state.user.isSuperAdmin,

  selectWorksiteOverview: state.worksites.WorksiteOverview,
  statusMessage: state.worksites.StatusMessage,
  statusSuccess: state.worksites.StatusSuccess,
  statusError: state.worksites.StatusError,
  preDeactivatedWorksiteList: state.worksites.preDeactivatedWorksiteList,
  deactivateWorksiteSuccess: state.worksites.deactivateWorksiteSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  setDropdownAccountId: (accountId) => dispatch(setDropdownAccountId(accountId)),
  setEditAlertModal: (data) => dispatch(setEditAlertModal(data)),

  worksiteNameUpdate: (id, name) => dispatch(worksiteNameUpdate(id, name)),
  worksiteStatusUpdate: (id, status) => dispatch(worksiteStatusUpdate(id, status)),
  resetWorksitesState: () => dispatch(resetWorksitesState()),
  preDeactivatedWorksite: (siteId, accountId) => dispatch(preDeactivatedWorksite(siteId, accountId)),
  deactivateWorksite: (siteId, accountId) => dispatch(deactivateWorksite(siteId, accountId)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Worksite));

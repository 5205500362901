import { checklistManagementTypes } from "./types";
import moment from "moment";
import { initialState } from "./initialState";

export const checklistManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    // Reset checklist success
    case checklistManagementTypes.RESEST_CHECKLIST_SUCCESS:
      return {
        ...state,
        createChecklistSuccess: false,
      };

    // For Checklist List
    case checklistManagementTypes.GET_CHECKLIST_LOADING:
      return {
        ...state,
        checklistLoading: true,
        checklistSuccess: false,
        checklistError: false,
      };

    case checklistManagementTypes.GET_CHECKLIST_SUCCESS:
      if (state.checklists?.length == action.data.count && !action.isResetState) {
        return {
          ...state,
          checklistLoading: false,
          checklistSuccess: true,
          checklistError: false,
        };
      }
      let newChecklist =
        action.isResetState || action.pageNo == 1
          ? action.data.data
          : [...state.checklists, ...action.data.data];
      return {
        ...state,
        checklistLoading: false,
        checklistSuccess: true,
        checklistError: false,
        totalCount: action.data.count,
        checklists: newChecklist,
      };

    case checklistManagementTypes.GET_CHECKLIST_ERROR:
      return {
        ...state,
        checklistLoading: false,
        checklistSuccess: false,
        checklistError: true,
      };

    // For checklist template
    case checklistManagementTypes.GET_CHECKLIST_TEMPLATES_LOADING:
      return {
        ...state,
        checklistTemplatesLoading: true,
        checklistTemplatesSuccess: false,
        checklistTemplatesError: false,
      };

    case checklistManagementTypes.GET_CHECKLIST_TEMPLATES_SUCCESS:
      if (state.checklists?.length == action.data.count && !action.isResetState) {
        return {
          ...state,
          checklistTemplatesLoading: false,
          checklistTemplatesSuccess: true,
          checklistTemplatesError: false,
        };
      }
      let newChecklistTemplate =
        action.isResetState || action.pageNo == 1
          ? action.data.data
          : [...state.checklists, ...action.data.data];
      return {
        ...state,
        checklistTemplatesLoading: false,
        checklistTemplatesSuccess: true,
        checklistTemplatesError: false,
        templateTotalCount: action.data.data.length,
        checklistTemplates: newChecklistTemplate,
      };

    case checklistManagementTypes.GET_CHECKLIST_TEMPLATES_ERROR:
      return {
        ...state,
        checklistTemplatesLoading: false,
        checklistTemplatesSuccess: false,
        checklistTemplatesError: true,
      };

    // For Checklist Create
    case checklistManagementTypes.CREATE_CHECKLIST_LOADING:
      return {
        ...state,
        createChecklistLoading: true,
        createChecklistSuccess: false,
        createChecklistError: false,
      };

    case checklistManagementTypes.CREATE_CHECKLIST_SUCCESS:
      return {
        ...state,
        createChecklistLoading: false,
        createChecklistSuccess: "Checklists Created Successfully",
        createChecklistError: false,
        createChecklists: action.data,
        checklists: [...state.checklists, action.data.data],
        createChecklistSuccessFlag: true,
      };

    case checklistManagementTypes.RESET_CHECKLISTS_SUCCESS_FLAG:
      return {
        ...state,
        createChecklistSuccessFlag: false,
        createChecklistError: false,
      };

    case checklistManagementTypes.CREATE_CHECKLIST_ERROR:
      return {
        ...state,
        createChecklistLoading: false,
        createChecklistSuccess: false,
        createChecklistError: true,
      };

    // For Editing Checklist
    case checklistManagementTypes.UPDATE_CHECKLIST_LOADING:
      return {
        ...state,
        updateChecklistLoading: true,
        updateChecklistSuccess: false,
        updateChecklistError: false,
      };

    // For Editing Section
    case checklistManagementTypes.UPDATE_SECTION_LOADING:
      return {
        ...state,
        addUpdateSectionLoading: true,
        addUpdateSectionSuccess: false,
        addUpdateSectionError: false,
      };

    // For Editing Question
    case checklistManagementTypes.UPDATE_QUESTION_LOADING:
      return {
        ...state,
        addUpdateQuestionLoading: true,
        addUpdateQuestionSuccess: false,
        addUpdateQuestionError: false,
      };

    case checklistManagementTypes.UPDATE_CHECKLIST_SUCCESS:
      console.log(action.data, "()()()()()()()()()()==================>");
      return {
        ...state,
        updateChecklistLoading: false,
        updateChecklistSuccess: false,
        updateChecklistError: false,
        checklists: state.checklists.map((item) => {
          if (item._id === action.data._id) {
            // for (var key in item) {
            //   if (key != "_id") {
            //     item[key] = action.data[key];
            //   }
            // }
            console.log(action.data, "()()()()()()()()()()==================>");
            return action.data;
          }
          return item;
        }),
      };

    // Checklist Overview Selector
    case checklistManagementTypes.CHECKLIST_OVERVIEW_LOADING:
      return {
        ...state,
        checklistOverviewLoading: true,
        checklistOverviewSuccess: false,
        checklistOverviewError: false,
      };

    case checklistManagementTypes.CHECKLIST_OVERVIEW_SUCCESS:
      return {
        ...state,
        checklistOverview:
          state &&
          state.checklists.find((checklist, i) => (action.data ? checklist._id === action.data : i === 0)),
      };

    case checklistManagementTypes.CHECKLIST_TEMPLATE_OVERVIEW_SUCCESS:
      return {
        ...state,
        checklistTemplateOverview:
          state &&
          state.checklistTemplates.find((checklistTemp, i) =>
            action.data ? checklistTemp._id === action.data : i === 0
          ),
      };

    // For Response Type
    case checklistManagementTypes.GET_RESPONSE_TYPE_LOADING:
      return {
        ...state,
        responseTypeLoading: true,
        responseTypeSuccess: false,
      };

    case checklistManagementTypes.GET_RESPONSE_TYPE_SUCCESS:
      return {
        ...state,
        responseTypeLoading: false,
        responseTypeSuccess: true,
        responseTypeList: action.data,
      };

    case checklistManagementTypes.ADD_UPDATE_SECTION_SUCCESS:
      return {
        ...state,
        responseTypeLoading: false,
        responseTypeSuccess: true,
        checklists: state.checklists.map((checklist) => {
          if (checklist._id === action.data.checklistId) {
            checklist.sections.push(action.data.sectionData);
          }
          return checklist;
        }),
      };

    case checklistManagementTypes.ADD_UPDATE_QUESTION_SUCCESS:
      return {
        ...state,
        addUpdateQuestionLoading: true,
        addUpdateQuestionSuccess: true,
        addUpdateQuestionError: false,
        checklists: state.checklists.map((checklist, i) => {
          if (checklist._id === action.data.checklistId) {
            checklist.sections = action.sectionData;
          }
          return checklist;
        }),
      };

    case checklistManagementTypes.UPDATE_EDIT_TEMPLATE:
      return {
        ...state,
        editTemplate: action.data,
      };

    // Returning default state
    default:
      return state;
  }
};

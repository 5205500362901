import { useEffect, useState } from "react";
import { HeaderWrapper, HeaderTitle, FilterWrapper } from "./SettingsHeader.component.styles";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import Dropdown from "../../../../components/DropdownFilter.component";
import {
  setDropdownAccountId,
  getDropdownWorksiteList,
  getDropdownUserListByUserType,
} from "../../../../redux/dropdownFilters/action";
import { getUserTypeList } from "../../../../redux/userType/action";

import {
  setSettingsDropdownAccountId,
  setSettingsDropdownAssetId,
  setSettingsDropdownAssetTypeId,
  setSettingsDropdownUserId,
  setSettingsDropdownUserTypeId,
  setSettingsDropdownWorksiteId,
  getDropdownAssetTypeByWorksite,
  getDropdownAssetList,
} from "../../../../redux/dropdownFilters/dropdownActions";
//import userTypeList from "../../../../constant/filterContant";

const SettingsHeader = (props) => {
  const {
    dropdownWorksiteList,
    dropdownAssetList,
    dropdownAssetTypeList,
    isSiteAdmin,
    dropdownAccountId,
    title,
    handleAssetIdFilter,
    getDropdownAssetTypeByWorksite,
    dropdownWorksiteId,
    dropdownAssetTypeId,
    getDropdownAssetList,
    getDropdownWorksiteList,
    dropdownAssetId,
    showFilters,
    getDropdownUserListByUserType,
    dropdownUserTypeId,
    userListByUserType,
    showWorksiteFilter,
    showAssetTypeFilter,
    showAssetFilter,
    showUserTypeFilter,
    showUserFilter,
    setSettingsDropdownAssetId,
    setSettingsDropdownAssetTypeId,
    setSettingsDropdownUserId,
    setSettingsDropdownUserTypeId,
    setSettingsDropdownWorksiteId,
    dropdownUserId,
    assetTypeListLoading,
    assetListLoading,
    permissions,
    userTypeList,
    getUserTypeList,
    isSuperAdmin,
  } = props;
  const { t } = useTranslation();
  const [isWorksiteDropdownDisabled, setIsWorksiteDropdownDisabled] = useState(false);

  /*   useEffect(() => {
    setSettingsDropdownUserTypeId(userTypeList?.[0]?._id);
  }, []); */

  useEffect(() => {
    if (showAssetTypeFilter) {
      getDropdownAssetTypeByWorksite(dropdownWorksiteId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownWorksiteId]);

  useEffect(() => {
    if (showAssetFilter) {
      getDropdownAssetList(dropdownAssetTypeId, dropdownWorksiteId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownAssetTypeId, dropdownWorksiteId]);

  useEffect(() => {
    if (userTypeList && userTypeList?.length === 0) {
      if (isSuperAdmin) {
        getUserTypeList(dropdownAccountId);
      } else {
        getUserTypeList();
      }
    }
  }, []);

  useEffect(() => {
    if (showWorksiteFilter) {
      getDropdownWorksiteList(true);
    }
    if (isSuperAdmin) {
      getUserTypeList(dropdownAccountId);
    } else {
      getUserTypeList();
    }
  }, [dropdownAccountId]);

  useEffect(() => {
    if (showWorksiteFilter && dropdownWorksiteList?.length > 0) {
      setSettingsDropdownWorksiteId(dropdownWorksiteList[0]._id);
    } else {
      setSettingsDropdownWorksiteId(true);
      setSettingsDropdownAssetTypeId(true);
      setSettingsDropdownAssetId(true);
    }
  }, [dropdownWorksiteList]);

  useEffect(() => {
    if (showAssetTypeFilter && dropdownAssetTypeList?.length > 0) {
      setSettingsDropdownAssetTypeId(dropdownAssetTypeList[0]._id);
    } else {
      setSettingsDropdownAssetTypeId(true);
      setSettingsDropdownAssetId(true);
    }
  }, [dropdownAssetTypeList]);

  useEffect(() => {
    if (showAssetFilter && dropdownAssetList?.length > 0) {
      setSettingsDropdownAssetId(dropdownAssetList[0]._id);

      handleAssetIdFilter(dropdownAssetList[0]._id);
    } else {
      setSettingsDropdownAssetId(true);
      handleAssetIdFilter(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownAssetList]);

  useEffect(() => {
    if (showUserFilter && userListByUserType?.length > 0) {
      setSettingsDropdownUserId(userListByUserType[0]._id);
    } else {
      setSettingsDropdownUserId(true);
    }
  }, [userListByUserType]);

  const handleWorksiteFilter = (value) => {
    setSettingsDropdownWorksiteId(value);
  };

  const handleAssetTypeIdFilter = (value) => {
    setSettingsDropdownAssetTypeId(value);
  };

  const userManagementObj = permissions[0].permissions.find(
    (permission) => permission.name === "USER_MANAGEMENT"
  );

  useEffect(() => {
    if (userManagementObj.action.view) {
      if (showUserFilter) {
        setIsWorksiteDropdownDisabled(true);
        getDropdownUserListByUserType("all", dropdownUserTypeId);
      } else {
        setIsWorksiteDropdownDisabled(false);
        getDropdownUserListByUserType(dropdownWorksiteId, dropdownUserTypeId);
      }
    }
  }, [dropdownUserTypeId, dropdownWorksiteId]);

  const handleUserTypeFilter = (userTypeId) => {
    setSettingsDropdownUserTypeId(userTypeId);
  };

  const handleUserFilter = (userId) => {
    setSettingsDropdownUserId(userId);
  };

  return (
    <HeaderWrapper>
      <HeaderTitle>{title}</HeaderTitle>
      {isSuperAdmin && !dropdownAccountId ? (
        <></>
      ) : (
        showFilters && (
          <FilterWrapper>
            <Row>
              {showUserTypeFilter && (
                <Col md={4}>
                  <Dropdown
                    filter={{
                      type: "User Type",
                      name: "userType",
                      key: "name",
                      data: userTypeList,
                      placeholder: t("Select a user type"),
                    }}
                    value={dropdownUserTypeId}
                    useAsDropdown={true}
                    handleFilter={(value) => {
                      handleUserTypeFilter(value);
                    }}
                  />
                </Col>
              )}

              {!isSiteAdmin && showWorksiteFilter && (
                <Col lg={4}>
                  <Dropdown
                    filter={{
                      type: "Worksite",
                      key: "name",
                      data: dropdownWorksiteList,
                      placeholder: t("Select a worksite"),
                    }}
                    disabled={isWorksiteDropdownDisabled}
                    value={dropdownWorksiteId}
                    label={t("filter")}
                    useAsDropdown={true}
                    handleFilter={(value) => {
                      handleWorksiteFilter(value);
                    }}
                  />
                </Col>
              )}
              {showAssetTypeFilter && (
                <Col lg={4}>
                  <Dropdown
                    filter={{
                      type: "Asset Type",
                      key: "AssetTypeName",
                      data: dropdownAssetTypeList,
                      placeholder: t("Select an asset type"),
                    }}
                    value={dropdownAssetTypeId}
                    label={t("filter")}
                    useAsDropdown={true}
                    handleFilter={(value) => handleAssetTypeIdFilter(value)}
                    isLoading={assetTypeListLoading}
                  />
                </Col>
              )}

              {showAssetFilter && (
                <Col lg={4}>
                  <Dropdown
                    filter={{
                      type: "Asset",
                      key: "assetName",
                      data: dropdownAssetList,
                      placeholder: t("Select an asset"),
                    }}
                    value={dropdownAssetId || true}
                    label={t("filter")}
                    useAsDropdown={true}
                    handleFilter={(assetId) => {
                      handleAssetIdFilter(assetId, dropdownWorksiteId, dropdownAssetTypeId);
                      setSettingsDropdownAssetId(assetId);
                    }}
                    isLoading={assetListLoading || assetTypeListLoading}
                  />
                </Col>
              )}

              {showUserFilter && (
                <Col md={4}>
                  <Dropdown
                    filter={{
                      type: "User",
                      name: "user",
                      key: "name",
                      data: userListByUserType,
                      placeholder: t("Select a user"),
                    }}
                    value={dropdownUserId}
                    useAsDropdown={true}
                    handleFilter={(value) => {
                      handleUserFilter(value);
                    }}
                  />
                </Col>
              )}
            </Row>
          </FilterWrapper>
        )
      )}
    </HeaderWrapper>
  );
};

const mapStateToProps = (state) => ({
  dropdownWorksiteList: state.dropdownFilters?.settings?.worksiteList,
  dropdownAssetTypeList: state.dropdownFilters?.settings?.assetTypeList,
  assetTypeListLoading: state.dropdownFilters?.settings?.assetTypeListLoading,
  dropdownAssetList: state.dropdownFilters?.settings?.assetList,
  assetListLoading: state.dropdownFilters?.settings?.assetListLoading,
  isSiteAdmin: state.user.isSiteAdmin,
  dropdownUserTypeId: state.dropdownFilters?.settings?.userTypeId,
  userListByUserType: state.dropdownFilters?.settings?.userListByUserType,
  dropdownAccountId: state.dropdownFilters?.settings?.accountId,
  dropdownWorksiteId: state.dropdownFilters.settings?.worksiteId,
  dropdownAssetTypeId: state.dropdownFilters.settings?.assetTypeId,
  dropdownAssetId: state.dropdownFilters.settings?.assetId,
  dropdownUserId: state.dropdownFilters.settings?.userId,
  permissions: state.user?.loginDetails?.userData?.permissions,
  userTypeList: state.userType.UserTypeList,
  isSuperAdmin: state.user.isSuperAdmin,
});

const mapDispatchToProps = (dispatch) => ({
  setDropdownAccountId: (accountId) => dispatch(setDropdownAccountId(accountId)),
  getDropdownAssetTypeByWorksite: (siteId) => dispatch(getDropdownAssetTypeByWorksite(siteId)),
  getDropdownAssetList: () => dispatch(getDropdownAssetList()),
  getDropdownWorksiteList: (isSettings) => dispatch(getDropdownWorksiteList(isSettings, true)),
  getDropdownUserListByUserType: (worksiteId, userType) =>
    dispatch(getDropdownUserListByUserType(worksiteId, userType)),
  setSettingsDropdownAccountId: (accountId) => dispatch(setSettingsDropdownAccountId(accountId)),
  setSettingsDropdownWorksiteId: (worksiteId) => dispatch(setSettingsDropdownWorksiteId(worksiteId)),
  setSettingsDropdownAssetTypeId: (assetTypeId) => dispatch(setSettingsDropdownAssetTypeId(assetTypeId)),
  setSettingsDropdownAssetId: (assetId) => dispatch(setSettingsDropdownAssetId(assetId)),
  setSettingsDropdownUserTypeId: (userTypeId) => dispatch(setSettingsDropdownUserTypeId(userTypeId)),
  setSettingsDropdownUserId: (userId) => dispatch(setSettingsDropdownUserId(userId)),
  getUserTypeList: (accId) => dispatch(getUserTypeList(false, accId)),
});

SettingsHeader.defaultProps = {
  showFilters: true,
  handleAssetIdFilter: () => {},
  handleAssetTypeIdFilter: () => {},
  handleWorksiteFilter: () => {},
  showWorksiteFilter: true,
  showAssetTypeFilter: true,
  showAssetFilter: true,
  showUserTypeFilter: false,
  showUserFilter: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsHeader);

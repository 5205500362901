import styled from "styled-components";
export const ManagementRightBottomWrapper = styled.div`
  height: 100%;
  .card {
    background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
    border: 1px solid ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
  }
  .title-style {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  }
  input.form-control {
    border: 1px solid ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"} !important;
  }
  .form-control {
    background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  }
  .form-control:disabled {
    background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
    color: ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"} !important;
  }
  input.form-control::placeholder {
    color: ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"} !important;
  }
  p.statusStyle {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  }
  .noSection .text-muted{
    font-family: "Open Sans", sans-serif;
    font-size: 14px !important;
    color: ${({ theme }) => theme.colors.grayL3 || "#676767"} !important;
  }
`;
export const TextEditIcon = styled.i`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  margin-top: 8px;
  margin-left: 10px;
  cursor: pointer;
`;

export const TooltipStyled = styled.i`
 /* font-size: 16px;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  margin-top: 8px;
  margin-left: 10px;*/
  cursor: pointer;
`;

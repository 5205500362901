import React, { useEffect, useState } from "react";
import moment from "moment";

//redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Input from "../../../../../../../components/Input";
import { useTranslation } from "react-i18next";
import { setDuration } from "../../../../../../../redux/dropdownFilters/dropdownActions";
import { getAllDurations } from "./DurationFilter.utils";

function DurationFilter({ duration, setDuration }) {
  const { t } = useTranslation();

  const [dateDropdown, setDateDropdown] = useState(14);
  const [date, setDate] = useState({
    endDate: moment(new Date()).format("YYYY-MM-DD"),
    startDate: moment(new Date()).format("YYYY-MM-DD"),
    startTime: moment(Date.now()).subtract({ minute: 15 }).format("HH:mm"),
    endTime: moment(Date.now()).format("HH:mm"),
  });

  const allDurations = getAllDurations();

  useEffect(() => {
    try {
      if (dateDropdown) {
        console.log("dateDropdown", dateDropdown);
        console.log("allDurations", allDurations);
        const selectedDuration = allDurations[dateDropdown - 1];
        if (selectedDuration) {
          setDuration(selectedDuration);
        }
      }
    } catch (err) {
      console.log(err);
    }
  }, [dateDropdown]);

  const handleClearAllSelection = () => {};
  const changeDateTypeDropdown = (value) => {
    try {
      handleClearAllSelection();
      setDateDropdown(value);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      {
        <div className="d-flex flex-row flex-wrap align-items-center custom-date-dropdown">
          <div style={{ width: "156px" }}>
            <Input
              options={allDurations}
              type="select"
              as="select"
              showLabel={true}
              label={t("Duration")}
              isValidationError={false}
              value={dateDropdown}
              onChange={(value) => changeDateTypeDropdown(value)}
              sortData={false}
            />
          </div>
        </div>
      }
    </>
  );
}

const mapStateToProps = (state) => ({
  duration: state.dropdownFilters.duration,
});

const mapDispatchToProps = (dispatch) => ({
  setDuration: (duration) => dispatch(setDuration(duration)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DurationFilter));

import { useState } from "react";

import LocationFilters from "./LocationFilters";
import GPSTracking from "./GPSTracking";
import LastKnownLocation from "./LastKnownLocation";

const LocationTracking = (props) => {
  const { position, selectedAssetId } = props;
  const [showGPSTrackingMap, setShowGPSTrackingMap] = useState(false);

  return (
    <>
      <LocationFilters
        style={{ zIndex: 100 }}
        setShowGPSTrackingMap={setShowGPSTrackingMap}
        selectedAssetId={selectedAssetId}
      />
      {showGPSTrackingMap ? <GPSTracking position={position} /> : <LastKnownLocation position={position} />}
    </>
  );
};

export default LocationTracking;

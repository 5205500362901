export const csvHeadersData = [
  { label: "Account Name", key: "accountName" },
  { label: "Worksite Name", key: "siteName" },
  { label: "Asset Name", key: "assetName" },
  { label: "AssetType", key: "assetType" },
  { label: "User Name", key: "userName" },
  { label: "LoginTime", key: "loginTimeFormatted" },
  { label: "LogoutTime", key: "logOutTimeFormatted" },
  { label: "LoggedInDuration", key: "loggedInDuration" },
  { label: "NumberOfInspections", key: "numberOfInspections" },
  { label: "FirstInspectionStartTime", key: "firstInspectionTimeFormatted" },
  { label: "FirstInspectionResponseTime", key: "FirstInspectionEndTimeFormatted" },
  { label: "InspectionCompletionDuration", key: "InspectionCompletionDuration" },
  { label: "NumberOfNearMissObjects", key: "NumberOfNearMissObjects" },
  { label: "NumberOfNearMissPedestrians", key: "NumberOfNearMissPedestrians" },
  { label: "NumberOfLowImpacts", key: "NumberOfLowImpacts" },
  { label: "NumberOfMediumImpacts", key: "NumberOfMediumImpacts" },
  { label: "NumberOfHighImpacts", key: "NumberOfHighImpacts" },
];

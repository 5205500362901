/**
 * TODO:  Create a common filter component for all the reports/Dashboard pages when time permits
 * Move the actions to action file
 * Do code optimization
 * Fix lint issues
 * Once completed, please remove this comment
 */

import React, { useEffect, useState } from "react";
import { Row, Col, Accordion } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import DateRangePicker from "../../../../../components/DatePicker.component";
import DropdownFilter from "../../../../../components/DropdownFilter.component";
import DropdownWrapper from "../../../../../components/DropdownComponent/DropdownWrapper";
import "./../../Report.css";
import { apiCall } from "../../../../../utils/apiCall";
import { filterByTypeData, FilterByTypeLabelData } from "../constants";
import Button from "../../../../../components/Button/Button.component";
import DropdownComponent from "../../../../../components/DropdownComponent/Dropdown.component";
import MoreFilterMobileToggle from "../../../../../components/More Filter Button/MoreFilterButton.component";

const Filters = (props) => {
  const {
    isSuperAdmin,
    isSiteAdmin,
    setDropdownAccountId,
    setDropdownWorksiteId,
    dropdownWorksiteList,
    dropdownAccountList,
    incidentReportFilter,
    setIncidentReportFilter,
    dropdownAssetList,
    dropdownAssetTypeList,
    loginDetails,
    dropdownFilterWorksiteId,
    setIncidentTypeFilter,
    incidentTypeFilter,
    setPageNo,
    selectedAccount,
    setSelectedAccount,
  } = props;

  const { t } = useTranslation();
  const [showMoreFilter, setShowMoreFilter] = useState(false);
  const [filterByTypeLabel, setFilterByTypeLabel] = useState("all");
  const [lastFilterData, setLastFilterData] = useState([]);
  const [accountsList, setAccountsList] = useState([]);

  let { accountId, filterByType, endDate, startDate, worksiteId } = incidentReportFilter;

  const changeFilterByType = (value) => {
    setIncidentReportFilter({
      ...incidentReportFilter,
      filterByType: value,
      incidentType: "all",
      pageNo: 1,
    });
    setFilterByTypeLabel(FilterByTypeLabelData[value]);
    if (value === "asset") {
      setLastFilterData(dropdownAssetList);
    } else if (value === "assetType") {
      setLastFilterData(dropdownAssetTypeList);
    } else if (value === "inspectedBy") {
      // New backend api
      const url = `/dropdown/users?accid=${
        isSuperAdmin ? incidentReportFilter.accountId : loginDetails.accountId
      }&siteid=${
        isSiteAdmin ? loginDetails?.userData?.authorizedSites?.[0] : dropdownFilterWorksiteId
      }&status=true`;

      apiCall(url).then((resp) => {
        setLastFilterData(resp.data?.data?.data);
      });
    } else if (value === "incidentType") {
      setLastFilterData([
        { fullName: "Impact", _id: "Impact" },
        { fullName: "Near-Miss", _id: "Proximity" },
      ]);
    }

    setPageNo(1);
  };

  useEffect(() => {
    if (filterByType === "asset") {
      setLastFilterData(dropdownAssetList);
    } else if (filterByType === "assetType") {
      setLastFilterData(dropdownAssetTypeList);
    } else if (incidentReportFilter.filterByType === "inspectedBy") {
      // New backend api
      const url = `/dropdown/users?accid=${
        isSuperAdmin ? incidentReportFilter.accountId : loginDetails.accountId
      }&siteid=${
        isSiteAdmin ? loginDetails?.userData?.authorizedSites?.[0] : dropdownFilterWorksiteId
      }&status=true`;

      apiCall(url).then((resp) => {
        setLastFilterData(resp.data?.data?.data);
      });
    }
  }, [dropdownAssetTypeList, dropdownAssetList]);

  const changeLastFilter = (value) => {
    if (incidentReportFilter.filterByType === "asset") {
      setIncidentReportFilter({
        ...incidentReportFilter,
        assetId: value,
        pageNo: 1,
        obstacleType: "all",
        impactSeverity: "all",
        incidentType: "all",
      });
    } else if (incidentReportFilter.filterByType === "assetType") {
      setIncidentReportFilter({
        ...incidentReportFilter,
        assetTypeId: value,
        pageNo: 1,
        obstacleType: "all",
        impactSeverity: "all",
        incidentType: "all",
      });
    } else if (incidentReportFilter.filterByType === "inspectedBy") {
      setIncidentReportFilter({
        ...incidentReportFilter,
        inspectorId: value,
        pageNo: 1,
        obstacleType: "all",
        impactSeverity: "all",
        incidentType: "all",
      });
    } else if (incidentReportFilter.filterByType === "incidentType") {
      setIncidentReportFilter({
        ...incidentReportFilter,
        incidentType: value,
        obstacleType: "all",
        impactSeverity: "all",
        pageNo: 1,
      });
      setIncidentTypeFilter(value);
    }
    setPageNo(1);
  };

  const handleNearMissFilter = (value) => {
    setIncidentReportFilter({
      ...incidentReportFilter,
      obstacleType: value,
      pageNo: 1,
    });
  };

  const handleImpactSeverityFilter = (value) => {
    setIncidentReportFilter({
      ...incidentReportFilter,
      impactSeverity: value,
      pageNo: 1,
    });
  };

  useEffect(() => {
    const accounts = [];
    dropdownAccountList.forEach((acc) => {
      accounts.push({
        value: acc._id,
        label: acc.companyName,
      });
      if (acc._id === incidentReportFilter.accountId) {
        setSelectedAccount({
          value: acc._id,
          label: acc.companyName,
        });
      }
    });
    setAccountsList(accounts);
  }, [dropdownAccountList]);

  useEffect(() => {
    if (!selectedAccount) {
      setShowMoreFilter(false);
    }
  }, [selectedAccount]);

  return (
    <MoreFilterMobileToggle>
      <>
        <Row>
          {isSuperAdmin && (
            <Col lg={2}>
              <DropdownComponent
                label={t("Account")}
                placeholder={t("Select an Account")}
                options={accountsList}
                value={selectedAccount}
                handleFilter={(selectedData) => {
                  setSelectedAccount(selectedData);
                  setIncidentReportFilter({
                    ...incidentReportFilter,
                    accountId: selectedData.value,
                  });
                  setDropdownAccountId(selectedData.value);
                }}
                size="medium"
              />
            </Col>
          )}
          {((isSuperAdmin && selectedAccount) || !isSuperAdmin) && (
            <>
              {!isSiteAdmin && (
                <Col lg={2}>
                  <DropdownWrapper
                    filter={{
                      type: t("Worksite"),
                      name: "worksiteName",
                      key: "name",
                      data: dropdownWorksiteList,
                    }}
                    value={worksiteId}
                    //   label={t("filter")}
                    handleFilter={(value) => {
                      setIncidentReportFilter({
                        ...incidentReportFilter,
                        worksiteId: value,
                        assetId: null,
                        assetTypeId: null,
                      });
                      setDropdownWorksiteId(value);
                    }}
                  />
                </Col>
              )}
              <Col lg={4}>
                <DateRangePicker
                  startDate={startDate}
                  endDate={endDate}
                  setDate={setIncidentReportFilter}
                  filterState={incidentReportFilter}
                />
              </Col>
              <Col className="text-left" style={{ marginTop: "20px" }}>
                <Button
                  iconClass="filter"
                  label={showMoreFilter ? t("Hide Filters") : t("More Filters")}
                  onClick={() => setShowMoreFilter(!showMoreFilter)}
                  showIcon
                  buttonType={showMoreFilter ? "primary" : "secondary"}
                />
              </Col>
            </>
          )}
        </Row>
        {showMoreFilter && (
          <Row>
            <Col lg={2}>
              <DropdownWrapper
                filter={{
                  type: t("Filter By"),
                  name: "filterBy",
                  placeholder: t("Select a Filter"),
                  key: "name",
                  data: filterByTypeData,
                }}
                value={incidentReportFilter.filterByType}
                label={t("filter")}
                handleFilter={(e) => changeFilterByType(e)}
                useAsDropdown={true}
              />
            </Col>
            {filterByTypeLabel != "all" ? (
              <Col lg={2}>
                <DropdownWrapper
                  filter={{
                    type: `${filterByTypeLabel}`,
                    key:
                      filterByType === "asset"
                        ? "assetName"
                        : filterByType === "assetType"
                          ? "AssetTypeName"
                          : "fullName",
                    data: lastFilterData,
                  }}
                  value={
                    incidentReportFilter[
                      filterByType === "asset"
                        ? "assetId"
                        : filterByType === "assetType"
                          ? "assetTypeId"
                          : filterByType == "incidentType"
                            ? "incidentType"
                            : "inspectorId"
                    ]
                  }
                  label={t("filter")}
                  handleFilter={(e) => changeLastFilter(e)}
                />
              </Col>
            ) : (
              ""
            )}
            {incidentTypeFilter === "Proximity" ? (
              <>
                <Col lg={2}>
                  <DropdownWrapper
                    filter={{
                      type: `Select obstacle type `,
                      key: "obstacleType",
                      data: [
                        { obstacleType: "Pedestrian", _id: "Pedestrian" },
                        { obstacleType: "Object", _id: "Object" },
                      ],
                    }}
                    value={incidentReportFilter["obstacleType"]}
                    label={t("filter")}
                    handleFilter={(e) => handleNearMissFilter(e)}
                  />
                </Col>
              </>
            ) : (
              ""
            )}

            {incidentTypeFilter === "Impact" ? (
              <>
                <Col lg={2}>
                  <DropdownWrapper
                    filter={{
                      type: `Select impact severity`,
                      key: "impactSeverity",
                      data: [
                        { impactSeverity: "High", _id: "high" },
                        { impactSeverity: "Medium", _id: "medium" },
                        { impactSeverity: "Low", _id: "low" },
                      ],
                    }}
                    value={incidentReportFilter["impactSeverity"]}
                    label={t("filter")}
                    handleFilter={(e) => handleImpactSeverityFilter(e)}
                  />
                </Col>
              </>
            ) : (
              ""
            )}
          </Row>
        )}
      </>
    </MoreFilterMobileToggle>
  );
};

export default Filters;

import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import AssetList from "./AssetList.component";
import Assets from "./Assets.component";
import { connect } from "react-redux";
import { setEditAlertModal } from "../../../redux/modals/action";
import { useTranslation } from "react-i18next";
import SearchBox from "../../../components/SearchBox/Searchbox.component";

const AssetManagement = ({ assetTypes, setEditAlertModal, editForm, editFormSecond }) => {
  const [SearchText, setSearchText] = useState("");
  const [SiteFilter, setSiteFilter] = useState("all");
  const [AssetType, setAssetType] = useState("all");
  const [filterAccountId, setFilterAccountId] = useState("all");

  //To handle asset type
  const handleChange = (e) => {
    setAssetType(e.target.value);
  };

  const handleSiteFilter = (e) => {
    setSiteFilter(e.target.value);
  };

  const { t } = useTranslation();

  return (
    <>
      <Row className="worksiteMainbox">
        <Col lg={3}>
          <SearchBox
            label={t("Your Assets")}
            placeholder={t("Search an Asset")}
            searchText={SearchText}
            onChange={(e) => setSearchText(e.target.value)}
            setSearchText={(data) =>
              editForm && editFormSecond ? setSearchText(data) : setEditAlertModal(true)
            }
          />
          <AssetList
            style={{ boxShadow: "rgba(0, 0, 0, 0.5) 0px 8px 8px -6px" }}
            searchText={SearchText}
            assetTypes={assetTypes}
            siteFilter={SiteFilter}
            assetType={AssetType}
            handleChange={handleChange}
            filterAccountId={filterAccountId}
            setShowAssetDetail={() => {}}
          />
        </Col>
        <Col lg={9}>
          <Assets
            searchText={SearchText}
            setSearchText={setSearchText}
            handleFilter={handleSiteFilter}
            userFilter={SiteFilter}
            assetType={AssetType}
            handleChange={handleChange}
            assetTypes={assetTypes}
            filterAccountId={filterAccountId}
            setFilterAccountId={setFilterAccountId}
          />
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = (state) => ({
  assetTypes: state.assets.AssetTypes,
  editForm: state.forms.editForm,
  editFormSecond: state.forms.editFormSecond,
});

const mapDispatchToProps = (dispatch) => ({
  setEditAlertModal: (data) => dispatch(setEditAlertModal(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AssetManagement);

const localEnv = {
  baseUrl: "http://localhost:4000/apiv3",
  s3BaseUrl: "https://siera-safety-dev.s3.us-east-2.amazonaws.com",
  s3BucketLogo: "https://siera-safety-dev.s3.us-east-2.amazonaws.com/logo/",
  s3BucketProfileImg: "https://siera-safety-dev.s3.us-east-2.amazonaws.com/user/",
  s3ResponseMedia: "https://siera-safety-dev.s3.us-east-2.amazonaws.com/responsemedia/",
  s3BucketAssetImg: "https://siera-safety-dev.s3.us-east-2.amazonaws.com/asset/",
  s3BucketImpactImage: "https://siera-safety-dev.s3.us-east-2.amazonaws.com/impact/",
  s3BucketUserBulkUploadErrorLog: "https://siera-safety-dev.s3.us-east-2.amazonaws.com/errorlogs/",
  STRIPE_BASE_URL: "https://api.stripe.com",
  REACT_APP_STRIPE_PUB_KEY_TEST: "pk_test_kyHDkrdJrY10h0UERLVfCMt400zlDpupf1",
  REACT_APP_STRIPE_PUB_KEY_LIVE: "pk_test_kyHDkrdJrY10h0UERLVfCMt400zlDpupf1",
  operatorBaseUrl: "https://dev-operator.siera.ai/#/redirect-scan-badge-user/",
  redirectAssetQrScan: "https://dev-operator.siera.ai/#/redirect-scan-badge/",
  dachartsBaseUrl: "https://charts.mongodb.com/charts-siera-cloud-dev-qa-cnrso",
};

const devEnv = {
  baseUrl: "https://dev-api.siera.ai/apiv3",
  s3BaseUrl: "https://siera-safety-dev.s3.us-east-2.amazonaws.com",
  s3BucketLogo: "https://siera-safety-dev.s3.us-east-2.amazonaws.com/logo/",
  s3BucketProfileImg: "https://siera-safety-dev.s3.us-east-2.amazonaws.com/user/",
  s3ResponseMedia: "https://siera-safety-dev.s3.us-east-2.amazonaws.com/responsemedia/",
  s3BucketAssetImg: "https://siera-safety-dev.s3.us-east-2.amazonaws.com/asset/",
  s3BucketImpactImage: "https://siera-safety-dev.s3.us-east-2.amazonaws.com/impact/",
  s3BucketUserBulkUploadErrorLog: "https://siera-safety-dev.s3.us-east-2.amazonaws.com/errorlogs/",
  STRIPE_BASE_URL: "https://api.stripe.com",
  REACT_APP_STRIPE_PUB_KEY_TEST: "pk_test_kyHDkrdJrY10h0UERLVfCMt400zlDpupf1",
  REACT_APP_STRIPE_PUB_KEY_LIVE: "pk_test_kyHDkrdJrY10h0UERLVfCMt400zlDpupf1",
  operatorBaseUrl: "https://dev-operator.siera.ai/#/redirect-scan-badge-user/",
  redirectAssetQrScan: "https://dev-operator.siera.ai/#/redirect-scan-badge/",
  dachartsBaseUrl: "https://charts.mongodb.com/charts-siera-cloud-dev-qa-cnrso",
};

const stagingEnv = {
  baseUrl: "https://api-staging.siera.ai/apiv3",
  s3BaseUrl: "https://siera-safety-stage.s3.us-east-2.amazonaws.com",
  s3BucketLogo: "https://siera-safety-stage.s3.us-east-2.amazonaws.com/logo/",
  s3BucketProfileImg: "https://siera-safety-stage.s3.us-east-2.amazonaws.com/user/",
  s3ResponseMedia: "https://siera-safety-stage.s3.us-east-2.amazonaws.com/responsemedia/",
  s3BucketAssetImg: "https://siera-safety-stage.s3.us-east-2.amazonaws.com/asset/",
  s3BucketImpactImage: "https://siera-safety-stage.s3.us-east-2.amazonaws.com/impact/",
  s3BucketUserBulkUploadErrorLog: "https://siera-safety-stage.s3.us-east-2.amazonaws.com/errorlogs/",

  STRIPE_BASE_URL: "https://api.stripe.com",
  REACT_APP_STRIPE_PUB_KEY_TEST: "pk_test_kyHDkrdJrY10h0UERLVfCMt400zlDpupf1",
  REACT_APP_STRIPE_PUB_KEY_LIVE: "pk_test_kyHDkrdJrY10h0UERLVfCMt400zlDpupf1",
  operatorBaseUrl: "http://192.168.43.11:5000/#/redirect-scan-badge-user/",
  redirectAssetQrScan: "http://192.168.43.11:5000/#/redirect-scan-badge/",
  dachartsBaseUrl: "https://charts.mongodb.com/charts-siera-cloud-dev-qa-cnrso",
};

const demoEnv = {
  baseUrl: "https://demo-api.siera.ai/apiv3",
  s3BaseUrl: "https://siera-safety-demo.s3.us-east-2.amazonaws.com",
  s3BucketLogo: "https://siera-safety-demo.s3.us-east-2.amazonaws.com/logo/",
  s3BucketProfileImg: "https://siera-safety-demo.s3.us-east-2.amazonaws.com/user/",
  s3ResponseMedia: "https://siera-safety-demo.s3.us-east-2.amazonaws.com/responsemedia/",
  s3BucketAssetImg: "https://siera-safety-demo.s3.us-east-2.amazonaws.com/asset/",
  s3BucketImpactImage: "https://siera-safety-demo.s3.us-east-2.amazonaws.com/impact/",
  s3BucketUserBulkUploadErrorLog: "https://siera-safety-demo.s3.us-east-2.amazonaws.com/errorlogs/",
  STRIPE_BASE_URL: "https://api.stripe.com",
  REACT_APP_STRIPE_PUB_KEY_TEST: "pk_test_kyHDkrdJrY10h0UERLVfCMt400zlDpupf1",
  REACT_APP_STRIPE_PUB_KEY_LIVE: "pk_test_kyHDkrdJrY10h0UERLVfCMt400zlDpupf1",
  operatorBaseUrl: "http://192.168.43.11:5000/#/redirect-scan-badge-user/",
  redirectAssetQrScan: "http://192.168.43.11:5000/#/redirect-scan-badge/",
  dachartsBaseUrl: "https://charts.mongodb.com/charts-siera-cloud-dev-qa-cnrso",
};

const prodEnv = {
  baseUrl: "https://api.siera.ai/apiv3",
  s3BaseUrl: "https://siera-safety.s3.us-east-2.amazonaws.com",
  s3BucketLogo: "https://siera-safety.s3.us-east-2.amazonaws.com/logo/",
  s3BucketProfileImg: "https://siera-safety.s3.us-east-2.amazonaws.com/user/",
  s3ResponseMedia: "https://siera-safety.s3.us-east-2.amazonaws.com/responsemedia/",
  s3BucketAssetImg: "https://siera-safety.s3.us-east-2.amazonaws.com/asset/",
  s3BucketImpactImage: "https://siera-safety.s3.us-east-2.amazonaws.com/impact/",
  s3BucketUserBulkUploadErrorLog: "https://siera-safety.s3.us-east-2.amazonaws.com/errorlogs/",
  STRIPE_BASE_URL: "https://api.stripe.com",
  REACT_APP_STRIPE_PUB_KEY_TEST: "pk_test_kyHDkrdJrY10h0UERLVfCMt400zlDpupf1",
  REACT_APP_STRIPE_PUB_KEY_LIVE: "pk_test_kyHDkrdJrY10h0UERLVfCMt400zlDpupf1",
  operatorBaseUrl: "http://192.168.43.11:5000/#/redirect-scan-badge-user/",
  redirectAssetQrScan: "http://192.168.43.11:5000/#/redirect-scan-badge/",
  dachartsBaseUrl: "https://charts.mongodb.com/charts-siera-cloud-production-dbvko",
};

//alert(import.meta.env.NODE_ENV);

//module.exports = ((import.meta.env.NODE_ENV === "production") ? { ...prodEnv } : ((import.meta.env.NODE_ENV === "staging") ? { ...stagingEnv } : { ...localEnv }));
export default { ...prodEnv }; 

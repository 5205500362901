/**
 * @author Nagarjuna Naidu <nagarjuna.naidu@siera.ai>
 *  Siera Landing Page Login Request Access Tabs Styling
 */
import styled from "styled-components";

// Login Title Styling
export const EnterpriseTitle = styled.div`
  font-family: "Kanit";
  font-weight: normal;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  padding-top: 5px;
  font-size: 28px !important;
  @media (max-width: 1100px) {
    font-size: 20px !important;
  }
`;
// Login Sub Title Styling
export const EnterpriseSubTitle = styled.div`
  font-size: 15px;
  font-style: Regular;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  padding-top: 3px;
  font-family: "Open Sans";
  margin: auto;
  width: 80%;
`;
// Login Sub Title2 Styling
export const EnterpriseSubTitle2 = styled.div`
  font-size: 15px;
  font-style: Regular;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  padding-top: 3px;
  padding-bottom: 10px;
  font-family: "Open Sans";
`;

// Forgot Password Text Styling
export const ForgotPassword = styled.div`
  font-size: 14px;
  font-weight: normal;
  padding-top: 12px;
  color: ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"};
  font-family: "Kanit";
  &:hover {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  }
`;

export const LoginPageStyled = styled.div`
  background-color: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"} !important;
  text-align: center;
  height: 100%;
  button.MuiTab-root {
    min-width: 188px;
  }
  .MuiTabs-scroller span.MuiTabs-indicator {
    height: 3px;
    width: 188px !important;
    background-color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"} !important;
  }
  .logReg_page {
    margin: auto;
    width: 93%;
  }
`;
export const TabWrapperDiv = styled.div`
  width: 85%;
  margin: auto;
  .nav {
    background-color: transparent;
    width: 100%;
    text-align: center;
    border-bottom: 1px solid ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"} !important;
  }
  .nav-link,
  .nav-link.active,
  .nav .nav-link,
  .nav-link.active:hover,
  .nav-link.active:hover:last-child,
  .nav-link.active:last-child,
  .nav-link:hover:last-child {
    background-color: transparent;
    border-right: 0px;
    border-left: 0px !important;
    display: inline-block;
    flex: 1;
    text-align: center;
  }
  @media screen and (max-width: 1100px) {
    .select__control {
      margin-bottom: 20px;
    }
  }
`;

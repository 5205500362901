import styled from "styled-components";

export const styles = {
  titleName: {
    fontFamily: "Kanit",
    fontSize: "24px",
    color: "black",
    paddingTop: "2px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textAlign: "left",
  },
  accessLevelTitle: {
    fontFamily: "Open Sans",
    fontSize: "14px",
    color: "#323232",
    textAlign: "left",
    paddingTop: "2px",
  },
  date: {
    fontFamily: "Open Sans",
    fontSize: "12px",
    color: "#919191",
    textAlign: "left",
    display: "block",
  },
  dAndPrint: {
    fontFamily: "Open Sans",
    fontSize: "12px",
    paddingTop: "7px",
    paddingLeft: "2px",
  },
  downloadAndPrint: {
    fontFamily: "Open Sans",
    fontSize: "12px",
    color: "#0D5FBE",
    textAlign: "left",
    paddingTop: "8px",
  },
  safetyEnabled: {
    fontFamily: "Open Sans",
    fontSize: "15px",
    color: "#0D5FBE",
    textAlign: "left",
    fontWeight: "bold",
    paddingTop: "7px",
  },
};

export const ButtonLocked = styled.div`
  & button {
    background: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
    color: ${({ theme }) => theme.colors.redPrimary || "#DD0000"};
    border: 1.3px solid ${({ theme }) => theme.colors.redPrimary || "#DD0000"};
    &:hover {
      background: ${({ theme }) => theme.colors.redPrimary || "#DD0000"};
      color: #ffffff;
    }
  }
`;
export const ButtonUnLocked = styled.div`
  & button {
    background: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
    color: ${({ theme }) => theme.colors.greenPrimary || "#11A70B"};
    border: 1.3px solid ${({ theme }) => theme.colors.greenPrimary || "#11A70B"};
    &:hover {
      background: ${({ theme }) => theme.colors.greenPrimary || "#11A70B"};
    }
  }
`;

export const ManagementPanelWrapper = styled.div`
  background: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  .card {
    background: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  }
  header {
    background: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  }
  .bg-white {
    background: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
  }
  .assetTableszOver {
    background: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  }
  .buttontabpanels span {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  }
  .title-style,
  .asset-type-size,
  .asset-type-size span {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  }
  .assetTableView p {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  }
  label {
    color: ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"};
  }
  .input-group-text {
    background-color: ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"};
  }
  input.form-control {
    border: 1px solid ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"} !important;
  }
  .form-control {
    background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  }
  .form-control:disabled {
    background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
    color: ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"} !important;
  }
  input.form-control::placeholder {
    color: ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"} !important;
  }
  p.statusStyle {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  }
  .noSection p {
    font-family: "Open Sans", sans-serif;
    font-size: 14px !important;
    color: ${({ theme }) => theme.colors.grayL3 || "#676767"} !important;
  }
`;

/**Please clean up this file when time permits. I have done 50% but still a lot of work needs to be done to make it maintainable and lint free */

import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import {
  getAssetAutoLogoutSettings,
  updateAssetAutoLogoutSettingsByAssetId,
  resetAssetAutoLogoutSettingsByAssetId,
} from "../../../../redux/config/autologout.actions";
import SnackBar from "../../SnackBar";
import { Trans, useTranslation } from "react-i18next";
import { isEqualWith } from "lodash";
import {
  HeadingComponentTwo,
  HeadingDetailsTwoComponent,
  HeadingComponentThree,
  StyledSelect,
  DurationContainer,
  StyledInput,
  ToggleWrapper,
  OnOffText,
  SettingsBody,
  SettingsContainer,
  SettingsFooter,
  StyledInputIcons,
} from "./AutoLogoutSettings.styles";
import ActionButtons from "../../common/ActionButtons";
import SwitchComponent from "../../../../components/Switchbox/SwitchComponent";
import ApplyToAllModal from "../../common/appyToAll/ApplyToAllModal";
import SettingsHeader from "../common/SettingsHeader.component";
import { SettingsWrapper, NoAssetWrapper } from "../Settings.Styles";
import { bulkSettingsUpdate, resetNotificationSettingsFlags } from "../../../../redux/config/action";

const MAX_SHIFT_HOURS = 24;
const MAX_SHIFT_MINUTES = 1440;
const MAX_CUSTOM_MESSAGE_LENGTH = 140;

const AutoLogoutSettings = (props) => {
  const {
    autoLogoutSettingsData,
    autoLogoutSettingsLoading,
    updateWorksiteAutoLogoutSettingLoading,
    updateWorksiteAutoLogoutSettingSuccess,
    getAssetAutoLogoutSettings,
    updateAssetAutoLogoutSettingsByAssetId,
    resetAssetAutoLogoutSettingsByAssetId,
    bulkSettingsUpdate,
    notificationUpdateLoading,
    notificationBulkUpdateSuccess,
    isSystemSettingsEditable,
    dropdownAccountId,
    isSuperAdmin,
  } = props;

  const { t } = useTranslation();
  const [worksiteAutoLogoutSettings, setWorksiteAutoLogoutSettings] = useState(autoLogoutSettingsData || {});
  const [updatableChanges, setUpdatableChanges] = useState(false);
  const [toasterStatus, setToasterStatus] = useState(false);
  const [applyToAll, setApplyToAll] = useState(false);
  const [applyAllSelectedTypes, setApplyAllSelectedTypes] = useState([]);
  const [checkApplyToAll, setCheckApplyToAll] = useState(false);
  const [showApplyToAllModal, setShowApplyToAllModal] = useState(false);
  const [applyToAllObj, setApplyToAllObj] = useState({});
  const [dropdownWorksiteId, setDropdownWorksiteId] = useState(null);
  const [selectedAssetId, setSelectedAssetId] = useState(null);
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);
  const [clearSelection, setClearSelection] = useState(false);

  const settingsOptions = [
    {
      label: "All Worksites",
      isDropdown: false,
      id: "all",
      isCheckedKey: "all",
      radioGroup: "worksites",
    },
    {
      label: "Selected Worksites",
      id: "selected",
      isCheckedKey: "selected",
      isDropdown: true,
      labelKey: "name",
      radioGroup: "worksites",
      dropdown: {
        placeholder: "Select a Worksite",
      },
    },
    {
      label: "All Asset Types",
      isDropdown: false,
      id: "all",
      isCheckedKey: "all",
      radioGroup: "assetTypes",
    },
    {
      label: "Selected Asset Types",
      id: "selected",
      isCheckedKey: "selected",
      isDropdown: true,
      labelKey: "AssetTypeName",
      radioGroup: "assetTypes",
      dropdown: {
        placeholder: "Select an Asset Type",
      },
    },
    {
      label: "All Assets",
      isDropdown: false,
      id: "all",
      isCheckedKey: "all",
      radioGroup: "assets",
    },
    {
      label: "Selected Assets",
      id: "selected",
      isCheckedKey: "selected",
      isDropdown: true,
      labelKey: "assetName",
      radioGroup: "assets",
      dropdown: {
        placeholder: "Select an Asset",
      },
    },
  ];

  useEffect(() => {
    if (notificationBulkUpdateSuccess) {
      getAssetAutoLogoutSettings(selectedAssetId);
      setApplyToAllObj({});
      setCheckApplyToAll(false);
      setClearSelection(true);
    }
  }, [notificationBulkUpdateSuccess]);

  useEffect(() => {
    return () => {
      handleCloseSnackBar();
    };
  }, [resetNotificationSettingsFlags]);

  const toggleApplyToAllModal = () => {
    setShowApplyToAllModal(!showApplyToAllModal);
  };

  const saveHandler = (isApplyAll, selectedItems) => {
    setCheckApplyToAll(isApplyAll || selectedItems.length > 0);
    toggleApplyToAllModal();
  };

  useEffect(() => {
    if (applyToAllObj.allWorksites) {
      setCheckApplyToAll(true);
    } else if (Object.keys(applyToAllObj).length === 0) {
      setCheckApplyToAll(false);
    }
  }, [applyToAllObj]);

  useEffect(() => {
    setWorksiteAutoLogoutSettings(autoLogoutSettingsData);
  }, [autoLogoutSettingsData]);

  //create a util function
  const customizer = (objValue, othValue) => {
    if (
      objValue === othValue ||
      ((Number.isInteger(objValue) || Number.isInteger(othValue)) &&
        parseInt(objValue) === parseInt(othValue))
    ) {
      return true;
    }
  };

  useEffect(() => {
    if (
      ((!isEqualWith(autoLogoutSettingsData, worksiteAutoLogoutSettings, customizer) || applyToAll) &&
        (worksiteAutoLogoutSettings?.autoLogoutStatus === !autoLogoutSettingsData ||
          (!!worksiteAutoLogoutSettings?.autoLogoutDuration &&
            worksiteAutoLogoutSettings?.autoLogoutDuration !== "0"))) ||
      isApplyToAllObjValid()
    ) {
      setUpdatableChanges(true);
    } else {
      setUpdatableChanges(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [worksiteAutoLogoutSettings, autoLogoutSettingsData, applyToAllObj]);

  const handleCancel = () => {
    setApplyToAllObj({});
    setWorksiteAutoLogoutSettings(autoLogoutSettingsData);
    setApplyToAll(false);
    setClearSelection(true);
  };

  const isApplyToAllObjValid = () => {
    return (
      (applyToAllObj.worksites?.all ||
        (applyToAllObj.worksites?.selected && applyToAllObj.worksites?.selectedItems?.length > 0)) &&
      (applyToAllObj.assetTypes?.all ||
        (applyToAllObj.assetTypes?.selected && applyToAllObj.assetTypes?.selectedItems?.length > 0)) &&
      (applyToAllObj.assets?.all ||
        (applyToAllObj.assets?.selected && applyToAllObj.assets?.selectedItems?.length > 0))
    );
  };

  const handleUpdate = () => {
    /* let selectedWorksites = [];
    const worksiteId = isSiteAdmin ? loginDetails?.userData?.authorizedSites?.[0] : dropdownWorksiteId;

    if (applyToAllObj.worksites?.all) {
      selectedWorksites = ["all"];
    } else if (applyToAllObj.worksites?.selected) {
      applyToAllObj?.worksites?.selectedItems?.forEach((site) => selectedWorksites.push(site.value));

      if (!selectedWorksites.includes(worksiteId)) {
        selectedWorksites = [...selectedWorksites, worksiteId];
      }
    } else {
      selectedWorksites = [worksiteId];
    } */
    if (isApplyToAllObjValid()) {
      const siteIds = applyToAllObj?.worksites?.selectedItems?.map((site) => site.value);
      const assetTypeIds = applyToAllObj?.assetTypes?.selectedItems?.map((assetType) => assetType.value);
      const assetIds = applyToAllObj?.assets?.selectedItems?.map((asset) => asset.value);

      const updatableSettings = {
        sites: siteIds,
        assetTypes: assetTypeIds,
        assets: assetIds,
        settings: { autoLogoutSettings: worksiteAutoLogoutSettings },
      };

      bulkSettingsUpdate(updatableSettings, setIsUpdateLoading);
    }
    updateAssetAutoLogoutSettingsByAssetId(
      selectedAssetId,
      {
        ...worksiteAutoLogoutSettings,
        maxShiftHoursTimeUnit: "hours",
      },
      setIsUpdateLoading
    );
  };

  const handleCloseSnackBar = () => {
    setApplyToAllObj({});
    setToasterStatus(false);
    setIsUpdateLoading(false);
    resetNotificationSettingsFlags();
  };

  useEffect(() => {
    if (selectedAssetId) {
      getAssetAutoLogoutSettings(selectedAssetId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAssetId]);

  useEffect(() => {
    if (updateWorksiteAutoLogoutSettingSuccess) {
      setToasterStatus(true);
      setApplyToAll(false);
      setApplyToAllObj({});
      setCheckApplyToAll(false);
    }
  }, [updateWorksiteAutoLogoutSettingSuccess]);

  const handleChange = (property) => {
    setWorksiteAutoLogoutSettings({
      ...worksiteAutoLogoutSettings,
      [property]: !worksiteAutoLogoutSettings[property],
    });
  };

  const getvalidatedTime = (value, property, timeUnit) => {
    if (property === "autoLogoutDuration" && timeUnit === "minutes") {
      return value > MAX_SHIFT_MINUTES ? MAX_SHIFT_MINUTES : value;
    } else {
      return value > MAX_SHIFT_HOURS ? MAX_SHIFT_HOURS : value;
    }
  };

  const handleInputChange = (e, property) => {
    if (property === "customLogoutMessage" && e.target.value.length > MAX_CUSTOM_MESSAGE_LENGTH) {
      setWorksiteAutoLogoutSettings({
        ...worksiteAutoLogoutSettings,
        [property]: e.target.value.substring(0, MAX_CUSTOM_MESSAGE_LENGTH),
      });
      return;
    }
    setWorksiteAutoLogoutSettings({
      ...worksiteAutoLogoutSettings,
      [property]: getvalidatedTime(e.target.value, property, worksiteAutoLogoutSettings?.autoLogoutTimeUnit),
    });
  };

  const handleInputBlur = (e, property) => {
    const curatedValue =
      isNaN(parseInt(e.target.value)) || parseInt(e.target.value) === 0
        ? autoLogoutSettingsData[property]
        : e.target.value;
    setWorksiteAutoLogoutSettings({
      ...worksiteAutoLogoutSettings,
      [property]: curatedValue,
    });
  };

  const handleSelect = (event, type) => {
    const durationProperty = type === "autoLogoutTimeUnit" ? "autoLogoutDuration" : "maxShiftHoursDuration";
    setWorksiteAutoLogoutSettings({
      ...worksiteAutoLogoutSettings,
      [type]: event.target.value,
      [durationProperty]: getvalidatedTime(
        worksiteAutoLogoutSettings.autoLogoutDuration,
        durationProperty,
        event.target.value
      ),
    });
  };

  const handleInvalidCharsInput = (e) => {
    const invalidChars = ["-", "+", "e", "."];
    if (invalidChars.includes(e.key)) {
      e.preventDefault();
    }
  };

  const handlePaste = (e) => {
    e.target.value = e.target.value.replace(/[e\+\-\.]/gi, "");
    e.preventDefault();
  };

  const handleApplyToAll = () => {
    //setApplyToAll(!applyToAll);
    setShowApplyToAllModal(!showApplyToAllModal);
  };

  /*   const popoverComponent = (
    <PopoverComponentWrapper>
      <p>
        <img src={AutoLogOutMessageImg} className="w-80" alt="Auto Logout message example" />
      </p>
    </PopoverComponentWrapper>
  );

  const handleCustomMessageBlur = (e) => {
    const curatedMessage =
      e.target.value.trim().length > 0 ? e.target.value.trim() : autoLogoutSettingsData.customLogoutMessage;
    setWorksiteAutoLogoutSettings({
      ...worksiteAutoLogoutSettings,
      customLogoutMessage: curatedMessage,
    });
  }; */

  const resetAutologoutSettings = () => {
    resetAssetAutoLogoutSettingsByAssetId(selectedAssetId, setIsUpdateLoading);
  };

  const handleAssetIdFilter = (assetId, worksiteId, assetTypeId) => {
    setSelectedAssetId(assetId);
    setDropdownWorksiteId(worksiteId);
  };

  return (
    <SettingsWrapper>
      <SettingsHeader
        title={t("AUTOLOGOUT_SETTINGS_HEADER_LABEL")}
        handleAssetIdFilter={handleAssetIdFilter}
        selectedAssetId={selectedAssetId}
      />

      {isSuperAdmin && !dropdownAccountId ? (
        <NoAssetWrapper>
          <p>
            <Trans>SELECT_AN_ACCOUNT_TO_VIEW_SETTINGS</Trans>
          </p>
        </NoAssetWrapper>
      ) : selectedAssetId ? (
        <>
          <SettingsContainer>
            <SettingsBody>
              <Row className="mt-3">
                <Col md={10}>
                  <HeadingComponentTwo>
                    <Trans>Auto-Logout</Trans>
                  </HeadingComponentTwo>
                  <HeadingDetailsTwoComponent>
                    <Trans>AUTO_LOGOUT_DESCRIPTION</Trans>
                  </HeadingDetailsTwoComponent>
                </Col>
                <Col className="text-right">
                  {autoLogoutSettingsLoading || notificationUpdateLoading ? (
                    <div className="spinner-border spinner-border-sm" role="status"></div>
                  ) : (
                    <ToggleWrapper>
                      <OnOffText>{worksiteAutoLogoutSettings?.autoLogoutStatus ? "On" : "Off"}</OnOffText>

                      <SwitchComponent
                        id={"_id"}
                        onChange={() => handleChange("autoLogoutStatus")}
                        checked={worksiteAutoLogoutSettings?.autoLogoutStatus}
                        disabled={!isSystemSettingsEditable}
                      />
                    </ToggleWrapper>
                  )}
                </Col>
              </Row>
              <hr className="mt-0" />
              {worksiteAutoLogoutSettings?.autoLogoutStatus && (
                <>
                  <Row className="mt-3">
                    <Col md={10}>
                      <HeadingComponentThree>
                        <Trans>Auto-Logout after</Trans>
                      </HeadingComponentThree>
                      <HeadingDetailsTwoComponent>
                        <Trans>AUTO_LOGOUT_AFTER_DESCRIPTION</Trans>
                      </HeadingDetailsTwoComponent>
                    </Col>
                    <Col className="text-right">
                      {autoLogoutSettingsLoading || notificationUpdateLoading ? (
                        <div className="spinner-border spinner-border-sm" role="status"></div>
                      ) : (
                        <Row>
                          <DurationContainer>
                            <StyledInputIcons>
                              <StyledInput
                                className="number-field form-control"
                                value={worksiteAutoLogoutSettings?.autoLogoutDuration}
                                type="number"
                                min={1}
                                max={worksiteAutoLogoutSettings?.autoLogoutTimeUnit === "hours" ? 24 : 1440}
                                onChange={(e) => {
                                  handleInputChange(e, "autoLogoutDuration");
                                }}
                                onBlur={(e) => {
                                  handleInputBlur(e, "autoLogoutDuration");
                                }}
                                onKeyDown={handleInvalidCharsInput}
                                onPaste={handlePaste}
                                disabled={!isSystemSettingsEditable}
                              />
                            </StyledInputIcons>

                            <StyledSelect
                              name="time"
                              id="time"
                              classNamePrefix="Select"
                              className="input-border form-control"
                              onChange={(e) => handleSelect(e, "autoLogoutTimeUnit")}
                              disabled={!isSystemSettingsEditable}
                            >
                              <option
                                value="minutes"
                                selected={worksiteAutoLogoutSettings?.autoLogoutTimeUnit === "minutes"}
                              >
                                minutes
                              </option>
                              <option
                                value="hours"
                                selected={worksiteAutoLogoutSettings?.autoLogoutTimeUnit === "hours"}
                              >
                                hours
                              </option>
                            </StyledSelect>
                          </DurationContainer>
                        </Row>
                      )}
                    </Col>
                  </Row>
                  {/**Below code is commented as part ofSIER-3649 */}
                  {/* <Row className="mt-3">
            <Col>
              <HeadingComponentThree>
                <Trans>Maximum Shift Hours</Trans>
              </HeadingComponentThree>
              <HeadingDetailsTwoComponent>
                <Trans>
                  Enable/disable this setting to automatically logout the user after a maximum period of hours
                  allowed in the facility
                </Trans>
                .
              </HeadingDetailsTwoComponent>
            </Col>
            <Col className="text-right">
              {autoLogoutSettingsLoading ? (
                <div className="spinner-border spinner-border-sm" role="status"></div>
              ) : (
                <ToggleWrapper>
                  <OnOffText>{worksiteAutoLogoutSettings?.maxShiftHoursStatus ? "On" : "Off"}</OnOffText>
                  <label className="switch mt-1">
                    <input
                      id={"_id"}
                      type="checkbox"
                      checked={worksiteAutoLogoutSettings?.maxShiftHoursStatus}
                      onChange={() => handleChange("maxShiftHoursStatus")}
                    />
                    <span className="slider"></span>
                  </label>
                </ToggleWrapper>
              )}

              {autoLogoutSettingsLoading ? null : worksiteAutoLogoutSettings?.maxShiftHoursStatus ? (
                <Row>
                  <DurationContainer>
                    <StyledInput
                      className="number-field form-control"
                      value={worksiteAutoLogoutSettings?.maxShiftHoursDuration}
                      type="number"
                      min={1}
                      max={24}
                      onChange={(e) => {
                        handleInputChange(e, "maxShiftHoursDuration");
                      }}
                      onBlur={(e) => {
                        handleInputBlur(e, "maxShiftHoursDuration");
                      }}
                      onKeyDown={handleInvalidCharsInput}
                      onPaste={handlePaste}
                    />
                    <HoursWrapper>hours</HoursWrapper>
                  </DurationContainer>
                </Row>
              ) : null}
            </Col>
          </Row> */}

                  {/* {autoLogoutSettingsLoading ? null : worksiteAutoLogoutSettings?.maxShiftHoursStatus ? (
            <>
              <Row></Row>
              <Row className="mt-3">
                <Col>
                  <HeadingComponentThree>
                    <Trans> Custom Logout Message</Trans>
                    <OverlayTrigger rootClose trigger="click" placement="right" overlay={popoverComponent}>
                      <span>
                        <StyledInformationIcon />
                      </span>
                    </OverlayTrigger>
                  </HeadingComponentThree>
                  <HeadingDetailsTwoComponent>
                    <Trans>
                      {" "}
                      You can define what message the operator will see on the screen when the system logs
                      them out after reaching maximum work hours in a day
                    </Trans>
                    .
                  </HeadingDetailsTwoComponent>
                </Col>
              </Row>
              <Row>
                <Col>
                  {autoLogoutSettingsLoading ? (
                    <div className="spinner-border spinner-border-sm" role="status"></div>
                  ) : (
                    <>
                      <CustomMessage>Custom Message</CustomMessage>
                      <TextareaComponent
                        classNamePrefix="Textarea"
                        //minLength={1}
                        //maxLength={64}
                        value={worksiteAutoLogoutSettings?.customLogoutMessage}
                        rows="3"
                        onChange={(e) => handleInputChange(e, "customLogoutMessage")}
                        onBlur={handleCustomMessageBlur}
                      />

                      <CustomMessageDetails>
                        <Trans>Logout countdown timer will be added after your message</Trans>
                        <CharacterCounter
                          maxCount={
                            worksiteAutoLogoutSettings?.customLogoutMessage.length ===
                            MAX_CUSTOM_MESSAGE_LENGTH
                          }
                        >{`${worksiteAutoLogoutSettings?.customLogoutMessage.length}/${MAX_CUSTOM_MESSAGE_LENGTH} characters`}</CharacterCounter>
                      </CustomMessageDetails>
                    </>
                  )}
                </Col>
              </Row>
            </>
          ) : null} */}
                </>
              )}
            </SettingsBody>
          </SettingsContainer>
          <SettingsFooter>
            <ActionButtons
              handleApplyToAll={handleApplyToAll}
              applyToAll={isApplyToAllObjValid()}
              showAppyToAll={true}
              updatableChanges={updatableChanges}
              handleUpdate={handleUpdate}
              handleCancel={handleCancel}
              /* isLoading={updateWorksiteAutoLogoutSettingLoading} */
              showResetButton={true}
              handleReset={resetAutologoutSettings}
              isLoading={isUpdateLoading}
            />
          </SettingsFooter>
          <ApplyToAllModal
            showModal={showApplyToAllModal}
            setShowModal={toggleApplyToAllModal}
            applyChanges={saveHandler}
            applyAllSelectedAssetTypes={applyAllSelectedTypes}
            setApplyAllSelectedAssetTypes={setApplyAllSelectedTypes}
            setCheckApplyToAll={setCheckApplyToAll}
            settingsOptions={settingsOptions}
            modalHeader={"Auto-Logout Settings"}
            applyToAllObj={applyToAllObj}
            setApplyToAllObj={setApplyToAllObj}
            clearSelection={clearSelection}
            setClearSelection={setClearSelection}
          />
        </>
      ) : (
        <NoAssetWrapper>
          <p>
            <Trans>NO_ASSET_FOUND</Trans>
          </p>
          <p>
            <Trans>EMPTY_ASSET</Trans>
          </p>
        </NoAssetWrapper>
      )}

      <SnackBar
        isSuccess={isUpdateLoading}
        label={t("Auto Logout settings updated")}
        handleClose={handleCloseSnackBar}
      />
    </SettingsWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    isSiteAdmin: state.user.isSiteAdmin,
    loginDetails: state.user.loginDetails,
    autoLogoutSettingsData: state.config.worksiteAutoLogoutSettings,
    autoLogoutSettingsLoading: state.config.getWorksiteAutoLogoutSettingsLoading,
    updateWorksiteAutoLogoutSettingLoading: state.config.updateWorksiteAutoLogoutSettingLoading,
    updateWorksiteAutoLogoutSettingSuccess: state.config.updateWorksiteAutoLogoutSettingSuccess,

    notificationBulkUpdateSuccess: state.notificationSetting?.notificationBulkUpdateSuccess,
    notificationUpdateLoading: state.notificationSetting?.notificationUpdateLoading,
    isSystemSettingsEditable: state.user.isSystemSettingsEditable,
    dropdownAccountId: state.dropdownFilters?.settings?.accountId,
    isSuperAdmin: state.user.isSuperAdmin,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateAssetAutoLogoutSettingsByAssetId: (assetId, data, setIsUpdateLoading) =>
    dispatch(updateAssetAutoLogoutSettingsByAssetId(assetId, data, setIsUpdateLoading)),
  getAssetAutoLogoutSettings: (assetId) => dispatch(getAssetAutoLogoutSettings(assetId)),
  resetAssetAutoLogoutSettingsByAssetId: (assetId, setIsUpdateLoading) =>
    dispatch(resetAssetAutoLogoutSettingsByAssetId(assetId, setIsUpdateLoading)),
  bulkSettingsUpdate: (data, setIsUpdateLoading) => dispatch(bulkSettingsUpdate(data, setIsUpdateLoading)),
  resetNotificationSettingsFlags: () => dispatch(resetNotificationSettingsFlags()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AutoLogoutSettings);

/* eslint-disable jsx-a11y/anchor-is-valid */

//TODO- Clean up this file when time permits. Remove unused/commented code, improve/optimize code
import React, { useEffect, useState, useContext } from "react";
import { Row, Col } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { updateActiveNavItem } from "../../../../../redux/sideMenu/menu.action";
import { updateMenu, updateMenuReport } from "../../../../../redux/sideMenu/menu.action";
import {
  getAllCount,
  getUnauthorizedAccessCount,
  getUnauthorizedGraphData,
} from "../../../../../redux/counts/action";
import { setUnauthorizedAccessFilter } from "../../../../../redux/filters/action";
import { setDropdownAccountId, setDropdownWorksiteId } from "../../../../../redux/dropdownFilters/action";
import { toDownloadFiles } from "../../../../../redux/config/action";
import DropdownWrapper from "../../../../../components/DropdownComponent/DropdownWrapper";
import DateRangePicker from "../../../../../components/DatePicker.component";
import { getUnauthorizedUseReports } from "../../../../../redux/reports/action";
import QuickAccess from "../QuickManagement/QuickManagement.component";
import Button from "../../../../../components/Button/Button.component";

import "react-multi-carousel/lib/styles.css";
import DashboardGraph from "../../DashboardGraph";

import { filterByTypeData, FilterByTypeLabelData } from "./constants";
import {
  DashboardContainer,
  CarouselDivCont,
  CarouselDivContto,
  UnauthorizedUsageReportDiv,
  GraphContainer,
} from "./UnauthorizedDashboard.styles";
import PermissionsCheck from "../../../../../components/PermissionCheck/PermissionsCheck";
import PermissionCheck from "../../../../../components/PermissionCheck/PermissionCheck";
import UnauthorizedUsageReport from "./UnauthorizedUsageReportsTableWrapper";
import DivContainer from "../../../../../components/DivContanerCompnent/DivContainer.component";
import MoreFilterMobileToggle from "../../../../../components/More Filter Button/MoreFilterButton.component";
import CarouselDataInspection from "./UnauthorizedCarouselWrapper";
import { ThemeContext } from "styled-components";

function UnauthorizedDashboard(props) {
  let {
    loginDetails,
    isSuperAdmin,
    isSiteAdmin,
    isAccountViewer,
    unauthorizedUseReport,
    getUnauthorizedUseReports,
    lastHourMeterReadingList,
    updateMenu,
    updateMenuReport,
    getAllCount,
    getUnauthorizedAccessCount,
    allCount,
    unauthorizedCount,
    setUnauthorizedAccessFilter,
    unauthorizedAccessFilter,
    dropdownWorksiteList,
    dropdownAccountList,
    setDropdownAccountId,
    dropdownAssetTypeList,
    setDropdownWorksiteId,
    dropdownAssetList,
    getGraphData,
    graphData,
    updateActiveNavItem,
    permissions,
    isSingleAccountMultiSiteUser,
    showWorksiteDropdown,
  } = props;
  const { t } = useTranslation();
  const [showMoreFilter, setShowMoreFilter] = useState(false);
  const [filterByTypeLabel, setFilterByTypeLabel] = useState("all");
  const [lastFilterData, setLastFilterData] = useState([]);
  const [currentActiveKey, setCurrentActiveKey] = useState("");
  const [managementCountBanner, setManagementCountBanner] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [ignoreFilter, setIgnoreFilter] = useState(false);
  const [viewReport, setViewReport] = useState(false);
  const themeContext = useContext(ThemeContext);

  let searchedLastHourMeterReading =
    lastHourMeterReadingList &&
    lastHourMeterReadingList?.filter?.(
      (list) => list?.assetName?.toLowerCase?.()?.indexOf?.(searchText.toLowerCase()) !== -1
    );

  let { accountId, filterByType, endDate, startDate, worksiteId } = unauthorizedAccessFilter;

  const labelStyle = {
    position: "relative",
    height: "0",
    width: "100%",
    textAlign: "left",
    marginBottom: "0px",
    fontSize: "12px",
    color: "#919191",
  };

  /*   useEffect(() => {
    if (isAccountViewer) {
      setManagementCountBanner(managementCountBannerData.accountViewer);
    } else if (isSuperAdmin) {
      setManagementCountBanner(managementCountBannerData.superAdmin);
    } else if (isAccountOwner || isAccountAdmin) {
      setManagementCountBanner(managementCountBannerData.accountOwner);
    } else if (isSiteAdmin) {
      setManagementCountBanner(managementCountBannerData.siteAdmin);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); */

  useEffect(() => {
    if (!isSuperAdmin) {
      setUnauthorizedAccessFilter({
        ...unauthorizedAccessFilter,
        accountId: loginDetails && loginDetails.accountId,
      });
    }
    getAllCount();
    getUnauthorizedAccessCount(unauthorizedAccessFilter);
    getGraphData(unauthorizedAccessFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginDetails]);

  useEffect(() => {
    setUnauthorizedAccessFilter({
      ...unauthorizedAccessFilter,
      assetTypeId: "all",
      assetId: "all",
      inspectorId: "all",
      impactSeverity: "all",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unauthorizedAccessFilter.filterByType]);

  useEffect(() => {
    if (ignoreFilter) {
      return;
    }
    getUnauthorizedUseReports({
      ...unauthorizedAccessFilter,
      impactSeverity: "all",
      pageSize: 10,
    });
    getUnauthorizedAccessCount({ ...unauthorizedAccessFilter });
    getGraphData(unauthorizedAccessFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unauthorizedAccessFilter]);

  const handleViewAll = () => {
    setUnauthorizedAccessFilter({
      ...unauthorizedAccessFilter,
      impactSeverity: "all",
      pageSize: 10,
    });
    updateMenuReport({ section: "report", subSection: 2 });
  };

  const changeLastFilter = (value) => {
    if (unauthorizedAccessFilter.filterByType === "asset") {
      setUnauthorizedAccessFilter({
        ...unauthorizedAccessFilter,
        assetId: value,
        pageNo: 1,
      });
    } else if (unauthorizedAccessFilter.filterByType === "assetType") {
      setUnauthorizedAccessFilter({
        ...unauthorizedAccessFilter,
        assetTypeId: value,
        pageNo: 1,
      });
    }
    // else if (unauthorizedAccessFilter.filterByType === "inspectionResult") {
    //   setUnauthorizedAccessFilter({
    //     ...unauthorizedAccessFilter,
    //     status: value,
    //     pageNo: 1,
    //   });
    // }
  };

  const carouselData = [
    {
      name: t("Incidents"),
      iconClass: "total-incidents",
      color: "#0d5fbe",
      feildName: "totalUnauthorizedIncident",
      type: "ThemePrimary",
      to: "#/app/report",
      onClick: () => {
        setIgnoreFilter(true);
        setUnauthorizedAccessFilter({
          ...unauthorizedAccessFilter,
          //   ...data.state,
        });
        updateActiveNavItem("unauthorizedReports");
        updateMenuReport({ section: "report", subSection: 2 });
      },
    },
    {
      name: t("Usage"),
      iconClass: "unauthorized-user",
      color: "#323232",
      feildName: "totalUnauthorizedUsages",
      type: "ThemePrimary",
      to: "#/app/report",
      onClick: () => {
        setIgnoreFilter(true);
        setUnauthorizedAccessFilter({
          ...unauthorizedAccessFilter,
          //   ...data.state,
        });
        updateActiveNavItem("unauthorizedReports");
        updateMenuReport({ section: "report", subSection: 2 });
      },
    },
    {
      name: t("Impacts"),
      iconClass: "impact",
      color: "#323232",
      feildName: "totalImpactUnauthorized",
      type: "ThemePrimary",
      to: "#/app/report",
      onClick: () => {
        setIgnoreFilter(true);
        setUnauthorizedAccessFilter({
          ...unauthorizedAccessFilter,
          //   ...data.state,
        });
        updateActiveNavItem("unauthorizedReports");
        updateMenuReport({ section: "report", subSection: 2 });
      },
    },
    {
      name: t("Near-Miss"),
      iconClass: "near-miss-pedestrian",
      color: "#323232",
      feildName: "TotalNearMiss",
      type: "ThemePrimary",
      to: "#/app/report",
      onClick: () => {
        setIgnoreFilter(true);
        setUnauthorizedAccessFilter({
          ...unauthorizedAccessFilter,
          //   ...data.state,
        });
        updateActiveNavItem("unauthorizedReports");
        updateMenuReport({ section: "report", subSection: 2 });
      },
    },
  ];

  const carouselDataNoReportView = [
    {
      name: t("Incidents"),
      iconClass: "total-incidents",
      color: "#0d5fbe",
      feildName: "totalUnauthorizedIncident",
      type: "ThemePrimary",
    },
    {
      name: t("Usage"),
      iconClass: "unauthorized-user",
      color: "#323232",
      feildName: "totalUnauthorizedUsages",
      type: "ThemePrimary",
    },
    {
      name: t("Impacts"),
      iconClass: "impact",
      color: "#323232",
      feildName: "totalImpactUnauthorized",
      type: "ThemePrimary",
    },
    {
      name: t("Near-Miss"),
      iconClass: "near-miss-pedestrian",
      color: "#323232",
      feildName: "TotalNearMiss",
      type: "ThemePrimary",
    },
  ];

  const changeFilterByType = (value) => {
    setUnauthorizedAccessFilter({
      ...unauthorizedAccessFilter,
      filterByType: value,
      pageNo: 1,
    });
    setFilterByTypeLabel(FilterByTypeLabelData[value]);
    if (value === "asset") {
      setLastFilterData(dropdownAssetList);
    } else if (value === "assetType") {
      setLastFilterData(dropdownAssetTypeList);
    }
  };

  useEffect(() => {
    setUnauthorizedAccessFilter({
      ...unauthorizedAccessFilter,
      worksiteId: "all",
      assetId: "all",
      assetTypeId: "all",
      inspectorId: "all",
      impactSeverity: "all",
      pageNo: 1,
    });
  }, [unauthorizedAccessFilter.accountId]);

  useEffect(() => {
    if (unauthorizedAccessFilter.filterByType === "asset") {
      setLastFilterData(dropdownAssetList);
    } else if (unauthorizedAccessFilter.filterByType === "assetType") {
      setLastFilterData(dropdownAssetTypeList);
    }
  }, [dropdownAssetTypeList, dropdownAssetList]);

  useEffect(() => {
    if (!showMoreFilter) {
      setFilterByTypeLabel("all");
    }
  }, [showMoreFilter]);

  const onclickCarousel = (e, item, subSection) => {
    updateActiveNavItem(item?.toLowerCase());
    updateMenu({ section: "management", subSection: subSection });
  };

  useEffect(() => {
    const managementCountBannerLocal = {
      ACCOUNT_MANAGEMENT: {
        name: t("Accounts"),
        iconClass: "accounts",
        type: "ThemePrimary",
        feildName: "accounts",
        to: "#/app/management",
        onClick: (e) => onclickCarousel(e, "Accounts", 0),
      },
      WORKSITE_MANAGEMENT: {
        name: t("Worksites"),
        iconClass: "worksites",
        type: "ThemePrimary",
        feildName: "sites",
        to: "#/app/management",
        onClick: (e) => onclickCarousel(e, "Worksites", 1),
      },
      ASSET_MANAGEMENT: {
        name: t("Assets"),
        iconClass: "assets",
        type: "ThemePrimary",
        feildName: "assets",
        to: "#/app/management",
        onClick: (e) => onclickCarousel(e, "Assets", 2),
      },
      USER_MANAGEMENT: {
        name: t("Users"),
        iconClass: "users",
        type: "ThemePrimary",
        feildName: "users",
        to: "#/app/management",
        onClick: (e) => onclickCarousel(e, "Users", 3),
      },
    };

    const permissionsNameArr = [
      "ACCOUNT_MANAGEMENT",
      "WORKSITE_MANAGEMENT",
      "ASSET_MANAGEMENT",
      "USER_MANAGEMENT",
    ];

    const managementCountBannerUpdated = [];

    if (permissions?.[0]) {
      permissions?.[0]?.permissions?.forEach((permission) => {
        if (permission.name === "REPORTS" && permission.action.view) {
          setViewReport(true);
        }
        if (permissionsNameArr.includes(permission.name)) {
          if (!permission?.action?.view) {
            const bannerObj = managementCountBannerLocal[permission.name];
            delete bannerObj.to;
            delete bannerObj.onClick;
            managementCountBannerUpdated.push(managementCountBannerLocal[permission.name]);
          } else {
            managementCountBannerUpdated.push(managementCountBannerLocal[permission.name]);
          }
        }
      });
    }
    setManagementCountBanner(managementCountBannerUpdated);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DashboardContainer>
      <MoreFilterMobileToggle>
        <>
          <Row>
            {isSuperAdmin && (
              <Col lg={2}>
                <DropdownWrapper
                  filter={{
                    type: t("Account"),
                    name: "accountName",
                    key: "companyName",
                    data: dropdownAccountList,
                  }}
                  value={accountId}
                  // label={t("filter")}
                  handleFilter={(value) => {
                    setUnauthorizedAccessFilter({
                      ...unauthorizedAccessFilter,
                      accountId: value,
                    });
                    setDropdownAccountId(value);
                  }}
                />
              </Col>
            )}

            {showWorksiteDropdown && (
              <Col lg={2}>
                <DropdownWrapper
                  filter={{
                    type: t("Worksite"),
                    name: "worksiteName",
                    key: "name",
                    data: dropdownWorksiteList,
                  }}
                  value={worksiteId}
                  // label={t("filter")}
                  handleFilter={(value) => {
                    setDropdownWorksiteId(value);
                    setUnauthorizedAccessFilter({
                      ...unauthorizedAccessFilter,
                      worksiteId: value,
                      assetId: null,
                      assetTypeId: null,
                    });
                  }}
                />
              </Col>
            )}

            <Col lg={4}>
              <DateRangePicker
                startDate={startDate}
                endDate={endDate}
                setDate={setUnauthorizedAccessFilter}
                filterState={unauthorizedAccessFilter}
              />
            </Col>
            <Col className="text-left" style={{ marginTop: "20px" }}>
              <Button
                iconClass="filter"
                label={showMoreFilter ? t("Hide Filters") : t("More Filters")}
                onClick={() => setShowMoreFilter(!showMoreFilter)}
                showIcon
                buttonType={showMoreFilter ? "primary" : "secondary"}
              />
            </Col>
          </Row>
          {showMoreFilter && (
            <Row>
              <Col lg={2}>
                <DropdownWrapper
                  filter={{
                    type: t("Filter By"),
                    name: t("filterBy"),
                    key: "name",
                    placeholder: t("Select a Filter"),
                    data: filterByTypeData,
                  }}
                  value={unauthorizedAccessFilter.filterByType}
                  //  label={t("filter")}
                  handleFilter={(value) => changeFilterByType(value)}
                  useAsDropdown={true}
                />
              </Col>
              {filterByTypeLabel !== t("all") && (
                <Col lg={2}>
                  <DropdownWrapper
                    filter={{
                      type: `${filterByTypeLabel === t("Inspected By") ? t("User") : filterByTypeLabel}`,
                      key:
                        filterByType === t("asset")
                          ? t("assetName")
                          : filterByType === t("assetType")
                            ? t("AssetTypeName")
                            : t("fullName"),
                      data: lastFilterData,
                    }}
                    value={
                      unauthorizedAccessFilter[
                        filterByType === "asset"
                          ? "assetId"
                          : filterByType === "assetType"
                            ? "assetTypeId"
                            : "inspectorId"
                      ]
                    }
                    useAsDropdown={false}
                    //  label={t("filter")}
                    handleFilter={(e) => changeLastFilter(e)}
                    disabled={
                      filterByType === "assetType" &&
                      isSuperAdmin &&
                      (unauthorizedAccessFilter.accountId === "all" || !unauthorizedAccessFilter.accountId)
                    }
                  />
                </Col>
              )}
            </Row>
          )}
        </>
      </MoreFilterMobileToggle>

      <Row className="mt-2">
        <Col lg={8}>
          <CarouselDivCont>
            <CarouselDataInspection
              viewReport={viewReport}
              unauthorizedCount={unauthorizedCount}
              updateActiveNavItem={updateActiveNavItem}
              setUnauthorizedAccessFilter={setUnauthorizedAccessFilter}
              unauthorizedAccessFilter={unauthorizedAccessFilter}
              carouselData={carouselData}
              carouselDataNoReportView={carouselDataNoReportView}
            />
          </CarouselDivCont>
        </Col>
        <Col lg={4}>
          <CarouselDivContto>
            <CarouselDataInspection
              viewReport={false}
              allCount={allCount}
              managementCountBanner={managementCountBanner}
              size="small"
            />
          </CarouselDivContto>
        </Col>
      </Row>
      <Row>
        <Col lg={isAccountViewer ? 12 : 8}>
          <GraphContainer>
            <DashboardGraph
              title={t("Unauthorized Access Trends")}
              series={[
                {
                  name: "Usage",
                  data: graphData?.count ?? [],
                },
              ]}
              colors={[themeContext?.colors?.grayL1 || "#323232"]}
              date={graphData?.date}
              startDate={unauthorizedAccessFilter.startDate}
              endDate={unauthorizedAccessFilter.endDate}
            />
          </GraphContainer>

          <PermissionCheck section={"DASHBOARD"} permissionName={"REPORTS"} actionName={"view"}>
            <UnauthorizedUsageReportDiv>
              <DivContainer
                heading={t("Unauthorized Usage Reports")}
                to="/app/report"
                onClick={() => handleViewAll()}
              >
                <UnauthorizedUsageReport
                  data={unauthorizedUseReport}
                  isSiteAdmin={isSiteAdmin}
                  isSingleAccountMultiSiteUser={isSingleAccountMultiSiteUser}
                />
              </DivContainer>
            </UnauthorizedUsageReportDiv>
          </PermissionCheck>
        </Col>

        <PermissionsCheck
          section="DASHBOARD"
          permissionNames={[
            "ASSET_MANAGEMENT",
            "ASSET_TYPE_MANAGEMENT",
            "WORKSITE_MANAGEMENT",
            "USER_MANAGEMENT",
          ]}
          actionName="add"
          operator="OR"
        >
          <Col lg={isAccountViewer ? 12 : 4} className="mt-2">
            <DivContainer heading={t("Quick Management Access")} showViewAll={false}>
              <QuickAccess />
            </DivContainer>
            {/* Below code is commented as part of SIER-3209 temporarily*/}
            {/*  <HourMeterReading
              getLastHourMeterListing={getLastHourMeterListing}
              lastHourMeterReadingLoading={lastHourMeterReadingLoading}
              reportFilter={unauthorizedAccessFilter}
              lastHourMeterReadingList={lastHourMeterReadingList}
            /> */}
          </Col>
        </PermissionsCheck>
      </Row>
    </DashboardContainer>
  );
}

const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
  isSuperAdmin: state.user.isSuperAdmin,
  isSiteAdmin: state.user.isSiteAdmin,
  isAccountViewer: state.user.isAccountViewer,
  menuList: state.sideMenu.reportMenuState,
  getUnauthorizedUseReportLoading: state.reports.getUnauthorizedUseReportLoading,
  unauthorizedUseReport: state.reports.unauthorizedUseReport,
  worksiteList: state.worksites.Worksites,
  accountList: state.accounts.Accounts,
  assetsList: state.assets.Assets,
  assetTypeList: state.assets.AssetTypes,
  lastHourMeterReadingList: state.reports.lastHourMeterReadingList,
  allCount: state.counts.allCount,
  getAllCountLoading: state.counts.getAllCountLoading,
  unauthorizedCount: state.counts.unauthorizedCount,
  unauthorizedAccessFilter: state.filters.unauthorizedAccessFilter,
  dropdownWorksiteList: state.dropdownFilters.worksiteList,
  dropdownAccountList: state.dropdownFilters.accountList,
  dropdownAssetTypeList: state.dropdownFilters.assetTypeList,
  dropdownAssetList: state.dropdownFilters.assetList,
  dropdownFilterAccountId: state.dropdownFilters.accountId,
  graphData: state.counts.unauthorizedGraphData,
  dropdownFilterWorksiteId: state.dropdownFilters.worksiteId,
  fileDownloadLoading: state.config.fileDownloadLoading,
  permissions: state.user?.loginDetails?.userData?.permissions,
  isSingleAccountMultiSiteUser: state.user.isSingleAccountMultiSiteUser,
  showWorksiteDropdown: state.user.showWorksiteDropdown,
});

const mapDispatchToProps = (dispatch) => ({
  updateMenu: (menuState) => dispatch(updateMenu(menuState)),
  updateActiveNavItem: (data) => dispatch(updateActiveNavItem(data)),
  getUnauthorizedUseReports: (filterData) => dispatch(getUnauthorizedUseReports(filterData)),
  getGraphData: (filterData) => dispatch(getUnauthorizedGraphData(filterData)),
  updateMenuReport: (menuDetails) => dispatch(updateMenuReport(menuDetails)),
  getAllCount: () => dispatch(getAllCount()),
  getUnauthorizedAccessCount: (filterData) => dispatch(getUnauthorizedAccessCount(filterData)),
  setUnauthorizedAccessFilter: (filterData) => dispatch(setUnauthorizedAccessFilter(filterData)),
  setDropdownAccountId: (accountId) => dispatch(setDropdownAccountId(accountId)),
  setDropdownWorksiteId: (worksiteId) => dispatch(setDropdownWorksiteId(worksiteId)),
  toDownloadFiles: (folderName, fileName) => dispatch(toDownloadFiles(folderName, fileName)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UnauthorizedDashboard));

import React from "react";
import { Row, Col, Card } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import moment from "moment";
// Redux
import { connect } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import ChecklistOverviewTab from "../ChecklistOverviewTab.component";
import { setDropdownAccountId } from "../../../../redux/dropdownFilters/action";
import { setAddChecklistModal } from "../../../../redux/modals/action";
import { setUpdateEditTemplate } from "../../../../redux/checklists/action";

// Button Component
import Button from "../../../../components/Button/Button.component";

import styled from "styled-components";
const ViewChecklistDiv = styled.div`
  .view_checlHeight_setting h3 {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  }
  .subtitlesSpan {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  }
  .dateStyle {
    color: ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"} !important;
  }
  .userPage_a {
    color: ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"} !important;
  }
  .userPageSpan {
    color: ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"} !important;
  }
`;
const styles = {
  title: {
    fontFamily: "Kanit",
    fontSize: "26px",
    color: "black",
    textAlign: "left",
    paddingTop: "2px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  subText: {
    fontFamily: "Open Sans",
    fontSize: "14px",
    color: "black",
    textAlign: "left",
    paddingTop: "2px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    // whiteSpace: "nowrap",
    display: " -webkit-box",
    webkitLineClamp: "2",
    webkitBoxOrient: "vertical",
  },
  date: {
    fontFamily: "Open Sans",
    fontSize: "14px",
    color: "#919191",
    textAlign: "left",
    paddingTop: "2px",
  },
};
function ChecklistList({
  history,
  isSuperAdmin,
  checklistTemplateOverview,
  setUpdateEditTemplate,
  setAddChecklistModal,
}) {
  const { t } = useTranslation();
  const updateEditTemplate = () => {
    if (!checklistTemplateOverview) {
      return;
    }
    let newChecklist = {
      checklistName: checklistTemplateOverview.checklistTemplateName,
      checklistDescription: checklistTemplateOverview.checklistTemplateDescription,
      sections: {},
    };
    let newData = checklistTemplateOverview?.sections.map((section, i) => {
      newChecklist.sections[i] = { ...section, questions: {} };
      let sectionData = section?.questions.map((question, j) => {
        newChecklist.sections[i].questions[j] = {
          ...question,
          failedResponse: question?.failedResponse?.trim(),
          responseTypeId: question?.responseTypeId?._id,
          controlType: question?.responseTypeId?.controlType,
          failedResponseText: question?.responseTypeId?.responseText,
        };
      });
    });
    setUpdateEditTemplate(newChecklist);
    history.push("/app/checklistmanagement");
    setAddChecklistModal(true);
  };

  return (
    <ViewChecklistDiv>
      <Card className="shadow-none view_checlHeight_setting mt-1" style={{ height: "calc(100vh - 223px)" }}>
        {checklistTemplateOverview ? (
          <Card.Body>
            <Row>
              <Col md={12} className="mt-2 mb-0">
                <h3 style={styles.title}>
                  <span
                    title={checklistTemplateOverview ? checklistTemplateOverview.checklistTemplateName : ""}
                  >
                    {" "}
                    {checklistTemplateOverview ? checklistTemplateOverview.checklistTemplateName : ""}{" "}
                  </span>
                </h3>
                <div className="subtitlesSpan" style={styles.subText}>
                  <span
                    title={
                      checklistTemplateOverview ? checklistTemplateOverview.checklistTemplateDescription : ""
                    }
                  >
                    {" "}
                    {checklistTemplateOverview ? checklistTemplateOverview.checklistTemplateDescription : ""}
                  </span>
                </div>
                <div className="dateStyle" style={styles.date}>
                  <Trans>Added On</Trans>:
                  {moment(checklistTemplateOverview?.createdAt)
                    .utcOffset(
                      checklistTemplateOverview?.createdAt &&
                        checklistTemplateOverview?.createdAt.split &&
                        checklistTemplateOverview?.createdAt.split(" ")[0]
                    )
                    .format(" MMMM DD YYYY")}
                </div>
              </Col>
              <Col md={{ offset: 4 }}></Col>
            </Row>
            <>
              <ChecklistOverviewTab checklistOverview={checklistTemplateOverview} />
            </>
            <Row>
              <Col className="mt-2 text-right">
                <Button
                  iconClass="copy"
                  label={t("Use Template")}
                  onClick={() => updateEditTemplate()}
                  showIcon
                  buttonType="primary"
                />
              </Col>
            </Row>
          </Card.Body>
        ) : (
          <Card.Body>
            <span className="h3 checklist_over">
              {/*  <Trans>Please add checklist to see overview</Trans>*/}
              <Trans>There is no checklist in the worksite right now</Trans>
              <br></br>
              <Trans>Click '+ ADD CHECKLIST' to add a checklist</Trans>
            </span>
          </Card.Body>
        )}
      </Card>
    </ViewChecklistDiv>
  );
}

const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
  isSuperAdmin: state.user.isSuperAdmin,
  checklistTemplateOverview: state.checklist.checklistTemplateOverview,
  dropdownAccountList: state.dropdownFilters.accountList,
});

const mapDispatchToProps = (dispatch) => ({
  setDropdownAccountId: (accountId) => dispatch(setDropdownAccountId(accountId)),
  setUpdateEditTemplate: (data) => dispatch(setUpdateEditTemplate(data)),
  setAddChecklistModal: (data) => dispatch(setAddChecklistModal(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChecklistList));

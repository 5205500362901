import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Row, Col, Form, Card } from "react-bootstrap";
import { connect } from "react-redux";
import { Trans, useTranslation } from "react-i18next";

import { getAllChecklist } from "../../../redux/checklists/action";
import Input from "../../../components/Input";
import { setEditAlertModal } from "../../../redux/modals/action";
import { setEditForm } from "../../../redux/forms/action";
import S3ConfirmationModal from "./Modal/S3ConfirmationModal";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div>
          <div>{children}</div>
        </div>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const labelStyle = {
  position: "relative",
  height: "0px%",
  width: "100%",
  textAlign: "left",
  marginBottom: "0px",
  fontSize: "12px",
  color: "#919191",
};

const stylesCss = {
  root: {
    flexGrow: 1,
    width: "100%",
  },
};

function AssetOverviewTab({
  editForm,
  assetOverview,
  worksiteList,
  setShowEditButton,
  statusButton,
  editData,
  setEditData,
  setEditForm,
  validated,
  dropdownWorksiteList,
  dropdownFilterProductList,
  AssetLoading,
  setEditAlertModal,
  getAllChecklist,
}) {
  const [value, setValue] = useState(0);
  const [modalShow, setModalShow] = useState(false);
  const [isCheckboxChecked, setCheckboxChecked] = useState(false);
  const { t } = useTranslation();

  const changeFeildValue = (e, i) => {
    let newData = [];
    editData.assetAttributes.map((data, index) => {
      if (i == index) {
        newData.push({
          ...data,
          AssetAttributeValue: e.target.value,
        });
      } else {
        newData.push({
          ...data,
        });
      }
    });
    setEditData({ ...assetOverview, assetAttributes: [...newData] });
  };

  useEffect(() => {
    //Load the checklist
    getAllChecklist({ pageNo: 0, pageSize: 0 }, true);
  }, []);

  useEffect(() => {
    if (editForm) {
      if (assetOverview?.assetAttributes) {
        setEditData({ ...assetOverview, assetAttributes: [...assetOverview?.assetAttributes] });
      }
    }
  }, [editForm]);

  useEffect(() => {
    setValue(0);
    if (assetOverview?.assetAttributes) {
      setEditData({ ...assetOverview, assetAttributes: [...assetOverview?.assetAttributes] });
    }
  }, [assetOverview]);

  const setModalShowClose = () => {
    setModalShow(false);
    setEditData({ ...editData, s3enabled: !isCheckboxChecked });
  };

  return (
    <div className={stylesCss.root}>
      <div classes="tabPanels">
        <TabPanel value={value} index={0} className="worksite-overview-height">
          {/* {!editForm && <div className="alert-modal-overlay" onClick={()=>setEditAlertModal(true)}></div>} */}
          {!AssetLoading && assetOverview ? (
            <Form noValidate validated={validated} id="assetOverviewEdit">
              <div className="assetTableszOver">
                <Row>
                  <Col md={4}>
                    <Input
                      disabled={editForm}
                      showLabel={true}
                      label={t("SIERA.AI Product")}
                      type="select"
                      options={dropdownFilterProductList?.map((option) => ({
                        _id: option._id,
                        label: option.name,
                      }))}
                      as="select"
                      required={true}
                      // isValidationError={isValidationError}
                      value={editData?.productType || "NA"}
                      onChange={(value) => {
                        setEditData({ ...editData, productType: value });
                      }}
                      feedback={t("Please select a Product")}
                    />
                    {/* <p className="asterisk_user">*</p> */}
                  </Col>
                  <Col md={4}>
                    <Input
                      disabled={editForm}
                      showLabel={true}
                      label={t("Worksite Name")}
                      type="select"
                      options={
                        dropdownWorksiteList &&
                        dropdownWorksiteList[0] &&
                        dropdownWorksiteList.map((option) => ({
                          _id: option._id,
                          label: option.name,
                        }))
                      }
                      as="select"
                      // required={true}
                      // isValidationError={isValidationError}
                      value={editData && editData.siteId && editData.siteId._id}
                      onChange={(value) => {
                        if (value) {
                          setEditData({
                            ...editData,
                            siteId: {
                              _id: value,
                            },
                          });
                        }
                      }}
                      //feedback={t("Please select a Product")}
                    />
                  </Col>

                  {editData &&
                    editData.assetAttributes &&
                    editData.assetAttributes[0] &&
                    editData.assetAttributes.map((data, i) => (
                      <Col md={4}>
                        <Form.Group className="text-open-sans mb-0">
                          <Form.Label style={labelStyle}>
                            <Trans>{data.AssetAttributeName}</Trans>
                          </Form.Label>
                          <Form.Control
                            required={data.AssetAttributeRequired}
                            disabled={editForm}
                            //  className="inputBox"
                            variant="standard"
                            fullWidth
                            type="text"
                            minLength={"1"}
                            maxLength={"64"}
                            value={data.AssetAttributeValue ? data.AssetAttributeValue : ""}
                            InputProps={{ disableUnderline: true }}
                            label={data.AssetAttributeName}
                            onChange={(e) => changeFeildValue(e, i)}
                          />
                          <Form.Control.Feedback type="invalid">
                            <Trans>Field is required</Trans>.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    ))}
                </Row>
              </div>
            </Form>
          ) : (
            <></>
          )}
        </TabPanel>
      </div>
      <S3ConfirmationModal
        setModalShow={setModalShow}
        setModalShowClose={setModalShowClose}
        modalShow={modalShow}
        setEditData={setEditData}
        editData={editData}
        isCheckboxChecked={isCheckboxChecked}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
  assetOverview: state.assets.AssetOverview,
  worksiteList: state.worksites.Worksites,
  dropdownWorksiteList: state.dropdownFilters.worksiteList,
  dropdownFilterProductList: state.dropdownFilters.productList,
  editForm: state.forms.editForm,
});

const mapDispatchToProps = (dispatch) => ({
  // setEditAlertModal: (data) => dispatch(setEditAlertModal(data)),
  setEditForm: (data) => dispatch(setEditForm(data)),
  getAllChecklist: (paginationData, isResetState) => dispatch(getAllChecklist(paginationData, isResetState)),
  setEditAlertModal: (data) => dispatch(setEditAlertModal(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AssetOverviewTab);

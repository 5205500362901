import React, { useEffect, useState } from "react";
import { Trans } from "react-i18next";

import Checkbox from "../../../../../components/Checkbox/Checkbox.component";
import { dashboardSectionHeader, tabletSectionHeader, tabletPermissions } from "../const/permissionConstants";
import {
  UserTypePermissionDiv,
  UserTypeTableDescription,
  RowDiv,
  Column,
  RowsContainer,
  UserTypeOverviewWrapper,
  HeaderTitle,
  BodyDetails,
  MobileOnlyHeader,
  DesktopOnlyHeader,
} from "./styles/Permission.styles";

const PermissionsMatrix = (props) => {
  const { addUserTypeModalData, setAddUserTypeModalData, selectedValues, setSelectedValues, isDisabled } =
    props;

  const handleMatrixCheckboxChange = (event, actionName, permissionObject, section) => {
    const updatedPermissions = selectedValues.map((item) => {
      if (item.section === section) {
        let updatedPermissions = item.permissions.map((permission) => {
          if (permission.name === permissionObject.name) {
            let view = false;
            if (
              permission.action.view ||
              permission.action.add ||
              permission.action.edit ||
              permission.action.deactivate ||
              !permission.action[actionName]
            ) {
              view = true;
            }
            const updatedAction = {
              ...permission.action,
              view,
              [actionName]: !permission.action[actionName],
            };
            return { ...permission, action: updatedAction };
          }
          return permission;
        });

        if (permissionObject.name === "INSPECT_ASSET" && event.target.checked) {
          updatedPermissions = updatedPermissions.map((permission) => {
            if (permission.name === "ASSET_MANAGEMENT") {
              permission.action.view = true;
            }
            return permission;
          });
        }

        if (permissionObject.name === "ASSET_MANAGEMENT" && actionName === "view" && !event.target.checked) {
          updatedPermissions = updatedPermissions.map((permission) => {
            if (permission.name === "INSPECT_ASSET") {
              permission.action.view = false;
            }
            return permission;
          });
        }

        return { ...item, permissions: updatedPermissions };
      }
      return item;
    });

    setSelectedValues(updatedPermissions);
    setAddUserTypeModalData({
      ...addUserTypeModalData,
      permissions: updatedPermissions,
    });
  };

  //set the deafault values
  useEffect(() => {
    setAddUserTypeModalData({
      ...addUserTypeModalData,
      permissions: selectedValues,
    });
  }, []);

  const getDataForASection = (sectionName) => {
    let sectionPermissions = [];

    selectedValues?.forEach((selectedValue) => {
      if (sectionName === selectedValue.section) {
        sectionPermissions = selectedValue.permissions;
      }
    });
    return sectionPermissions;
  };

  const dashboardPermission = getDataForASection("DASHBOARD");
  const tabletPermission = getDataForASection("TABLET");

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <UserTypeOverviewWrapper>
      {!isDisabled && (
        <HeaderTitle>
          <Trans>User Type Permissions</Trans>
        </HeaderTitle>
      )}

      <BodyDetails>
        <UserTypePermissionDiv>
          <UserTypeTableDescription>
            {dashboardPermission.length > 0 && (
              <RowDiv isHeader={true}>
                {dashboardSectionHeader.map((data, index) => (
                  <Column
                    key={data.name}
                    isHeader={true}
                    textleft={data.textleft}
                    title={data.name}
                    className={isMobile ? "headingRow" : ""}
                    style={{ display: isMobile && index !== 0 ? "none" : "block" }}
                  >
                    <Trans>{data.name}</Trans>
                  </Column>
                ))}
              </RowDiv>
            )}

            {dashboardPermission?.map((permission) => {
              const permissionArr = Object.keys(permission.action);
              return selectedValues ? (
                <RowDiv>
                  <Column className="mobileViewColTop" textleft={"left"} title={permission?.label}>
                    <Trans>{permission?.label}</Trans>
                  </Column>
                  {dashboardSectionHeader &&
                    permissionArr?.map((action) => {
                      // Fix: action instead of (action, data)
                      let permissionCounter = 0;

                      permissionArr.forEach((element) => {
                        if (permission.action[element]) {
                          permissionCounter++;
                        }
                      });

                      return (
                        <Column key={action} textleft={"center"} className="mobileViewCol">
                          {isMobile && (
                            <MobileOnlyHeader>
                              <Column>
                                <Trans>{action}</Trans>
                              </Column>
                            </MobileOnlyHeader>
                          )}

                          <Checkbox
                            checked={permission.action[action] || false}
                            onChange={(event) =>
                              handleMatrixCheckboxChange(event, action, permission, "DASHBOARD")
                            }
                            disabled={(action === "view" && permissionCounter > 1) || isDisabled}
                          />
                        </Column>
                      );
                    })}
                </RowDiv>
              ) : (
                <></>
              );
            })}

            <RowDiv isHeader={true}>
              {tabletSectionHeader.map((data, index) => (
                <Column
                  key={data.name}
                  isHeader={true}
                  textleft={data.textleft}
                  title={data.name}
                  className={isMobile ? "headingRow" : ""}
                  style={{ display: isMobile && index !== 0 ? "none" : "block" }}
                >
                  <Trans>{data.name}</Trans>
                </Column>
              ))}

              <Column isHeader={true} />
              <Column isHeader={true} />
              <Column isHeader={true} />
            </RowDiv>

            <RowsContainer className="tableSection">
              {tabletPermission?.map((permission) => {
                return selectedValues ? (
                  <RowDiv>
                    <Column className="mobileViewColTop" textleft={"left"} title={permission?.label}>
                      <Trans>{permission?.label}</Trans>
                    </Column>
                    {tabletSectionHeader &&
                      tabletPermissions?.map((action) => {
                        return (
                          <Column textleft={"center"} className="mobileViewCol">
                            {isMobile && (
                              <MobileOnlyHeader>
                                <Column>
                                  <Trans>{action}</Trans>
                                </Column>
                              </MobileOnlyHeader>
                            )}

                            <Checkbox
                              checked={permission.action[action] || false}
                              onChange={(event) =>
                                handleMatrixCheckboxChange(event, action, permission, "TABLET")
                              }
                              disabled={isDisabled || permission.action.isDisabled}
                            />
                          </Column>
                        );
                      })}
                  </RowDiv>
                ) : (
                  <></>
                );
              })}
            </RowsContainer>
          </UserTypeTableDescription>
        </UserTypePermissionDiv>
      </BodyDetails>
    </UserTypeOverviewWrapper>
  );
};

export default PermissionsMatrix;

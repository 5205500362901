import React from "react";
import AccountCard from "./AccountCard.component";
import AccountOverview from "./AccountOverview.component";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setEditAlertModal } from "../../../redux/modals/action";
import styled from "styled-components";
const ManagementRightBottomWrapper = styled.div`
  height: 100%;
  .card {
    background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
    border: 1px solid ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
  }
  .title-style {
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  }
`;

const Account = ({ imageLoaded, setImageLoaded, editForm, setEditAlertModal }) => (
  <>
    <div>
      <AccountCard />
    </div>
    <div className="Account_Div_bott">
      <ManagementRightBottomWrapper>
        {/* {!editForm && <div className="alert-modal-overlay" onClick={()=>setEditAlertModal(true)}></div>} */}
        <AccountOverview imageLoaded={imageLoaded} setImageLoaded={(data) => setImageLoaded(data)} />
      </ManagementRightBottomWrapper>
    </div>
  </>
);

const mapDispatchToProps = (dispatch) => ({
  setEditAlertModal: (data) => dispatch(setEditAlertModal(data)),
});

const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
  editForm: state.forms.editForm,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Account));

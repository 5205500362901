// Libraries
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";

import { getDropdownAssetTypeList } from "../../../../../redux/dropdownFilters/action";
import { getAssetTypes } from "../../../../../redux/assets/action";
import alertConstant from "../../../../../constant/messages/toaster.json";
import Button from "../../../../../components/Button/Button.component";
import RadioButton from "../../../../../components/RadioButton/Radio.component";
import {
  AssetTypeDetailsWrapper,
  DeleteIconStyled,
  HeadingContainer,
  RadioButtonContainer,
  ErrorMessage,
  AssetTypeDetailsDiv,
  AssetTypeDetailsTableDescription,
  RowDiv,
  Column,
  RowsContainer,
  MobileViewText,
  ColumnReorder,
  ButtonWrapperDiv,
  AddChecklistDivB,
  EditCancelButton,
} from "./AddAssetTypeDetails.component.styles";

import InputField from "../../../../../components/InputFieldComponent/InputField.component";
import config from "../../../../../config";
const baseUrl = config.baseUrl; //Base Url

const AddAssetTypeDetails = ({
  addAssetTypeModalData,
  setAddAssetTypeModalData,
  activeStep,
  setActiveStep,
  dropdownAccountId,
  loginDetails,
  getDropdownAssetTypeList,
  isSuperAdmin,
  handleCloseModal,
  setIsError,
  setIsSuccess,
  getAssetTypes,
}) => {
  const { t } = useTranslation();

  const [isValidationError, setIsValidationError] = useState(false);
  const [isValidationErrorVisible, setIsValidationErrorVisible] = useState(false);
  const isValidationSet = new Set();
  const [isLoading, setIsLoading] = useState(false);

  const fieldContainerDiv = useRef(null);

  const removeSchemaAttribute = (e) => {
    isValidationSet.delete(e.currentTarget.id);
    addAssetTypeModalData.AssetTypeAttributes.map((data, i) => {
      if (data.id == e.currentTarget.id) {
        addAssetTypeModalData.AssetTypeAttributes.splice(i, 1);
      }
    });
    setAddAssetTypeModalData({
      ...addAssetTypeModalData,
      AssetTypeAttributes: [...addAssetTypeModalData.AssetTypeAttributes],
    });
  };

  const handleAddCustomField = () => {
    const newEntry = {
      id: Date.now(),
      AssetAttributeName: "",
      AssetAttributeRequired: false,
      AssetAttributeDescription: "",
      disabled: false,
      removeDelete: false,
    };
    setAddAssetTypeModalData({
      ...addAssetTypeModalData,
      AssetTypeAttributes: [...addAssetTypeModalData.AssetTypeAttributes, newEntry],
    });

    fieldContainerDiv.current.lastElementChild.scrollIntoView({ behavior: "smooth" });
  };

  const handleBack = () => {
    // Handle Validation for step 2 then set active step accordingly
    setActiveStep(0);
  };

  const checkForValidation = () => {
    if (isValidationError) {
      setIsValidationErrorVisible(true);
      return false;
    } else {
      return true;
    }
  };

  const submitAssetType = async () => {
    setIsLoading(true);
    const headers = {
      "auth-token": loginDetails.token,
    };
    let accountId = loginDetails.accountId;
    if (isSuperAdmin) {
      accountId = dropdownAccountId;
    }
    let data = addAssetTypeModalData;

    const responseUser = await axios.post(`${baseUrl}/assettype/create/${accountId}`, data, { headers });

    if (responseUser.data.success === true) {
      setIsLoading(false);
      setIsSuccess(alertConstant.asset_type_added_success);
      getDropdownAssetTypeList();
      getAssetTypes({ pageNo: 1, pageSize: 15 }, true);
      handleCloseModal();
    } else {
      setIsError(alertConstant.asset_type_name_duplicate);
      setIsLoading(false);
    }
  };

  const handleRequired = (value, id) => {
    if (value === "YES") {
      const newSchemaList = [...addAssetTypeModalData.AssetTypeAttributes];
      newSchemaList.forEach((data) => {
        if (data.id === id) {
          data.AssetAttributeRequired = true;
        }
      });

      setAddAssetTypeModalData({ ...addAssetTypeModalData, AssetTypeAttributes: newSchemaList });
    } else {
      const newSchemaList = [...addAssetTypeModalData.AssetTypeAttributes];
      newSchemaList.forEach((data) => {
        if (data.id === id) {
          data.AssetAttributeRequired = false;
        }
      });
      setAddAssetTypeModalData({ ...addAssetTypeModalData, AssetTypeAttributes: newSchemaList });
    }
  };

  const handleFieldNameChange = (id, value) => {
    addAssetTypeModalData.AssetTypeAttributes.map((data, i) => {
      if (data.id == id) {
        data.AssetAttributeName = value;
      }
    });
    setAddAssetTypeModalData({
      ...addAssetTypeModalData,
      AssetTypeAttributes: [...addAssetTypeModalData.AssetTypeAttributes],
    });
  };

  const handleDescription = (id, value) => {
    addAssetTypeModalData.AssetTypeAttributes.map((data, i) => {
      if (data.id == id) {
        data.AssetAttributeDescription = value;
      }
    });
    setAddAssetTypeModalData({
      ...addAssetTypeModalData,
      AssetTypeAttributes: [...addAssetTypeModalData.AssetTypeAttributes],
    });
  };

  useEffect(() => {
    addAssetTypeModalData.AssetTypeAttributes.forEach((currentAttribute) => {
      if (!currentAttribute?.removeDelete) {
        if (currentAttribute?.AssetAttributeName && currentAttribute?.AssetAttributeName.trim()) {
          // console.log("DELETING SET : ", currentAttribute.id)
          isValidationSet.delete(currentAttribute.id);
          // console.log(isValidationSet)
        } else {
          isValidationSet.add(currentAttribute.id);
        }
      }
    });
    if (isValidationSet?.size == 0) {
      setIsValidationError(false);
    } else {
      setIsValidationError(true);
    }
    setIsValidationErrorVisible(false);
  }, [addAssetTypeModalData]);

  const handleNext = () => {
    if (checkForValidation()) {
      setActiveStep(activeStep + 1);
    } else {
      console.log("ERROR");
    }
  };

  const [isIconOnly, setIsIconOnly] = useState(window.innerWidth <= 1100);
  useEffect(() => {
    const handleResize = () => {
      setIsIconOnly(window.innerWidth <= 1100);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <AssetTypeDetailsWrapper>
      {/* Add Asset Type Modal Heading */}
      <HeadingContainer>
        <Trans>Asset Type Details</Trans>
      </HeadingContainer>

      {/* Add Asset Type Modal Body */}

      <AssetTypeDetailsDiv isSuperAdmin={isSuperAdmin}>
        <AssetTypeDetailsTableDescription>
          <RowDiv isHeader={true}>
            <Column isHeader={true} textleft={"left"}>
              <Trans>Field Name</Trans>
            </Column>
            <Column isHeader={true} textleft={"left"}>
              <Trans>Required</Trans>
            </Column>
            <Column isHeader={true} textleft={"left"}>
              <Trans>Description</Trans>
            </Column>
            <Column isHeader={true} textleft={"center"}>
              <Trans>Action</Trans>
            </Column>
          </RowDiv>

          <RowsContainer className="userTypeDetailsHeight" ref={fieldContainerDiv}>
            {addAssetTypeModalData.AssetTypeAttributes[0]
              ? addAssetTypeModalData.AssetTypeAttributes.map((data, i) => (
                  <>
                    <RowDiv>
                      <Column textleft={"left"} inputWidth={"90%"} className="firstMobileCss">
                        {i === 0 && !data.removeDelete && <p>*</p>}
                        <InputField
                          minLength={1}
                          maxLength={64}
                          disabled={data.removeDelete}
                          placeholder={t("Enter Field Title")}
                          value={data.AssetAttributeName}
                          onChange={(e) => handleFieldNameChange(data.id, e.target.value)}
                        />
                      </Column>
                      <ColumnReorder textleft={"left"}>
                        <MobileViewText>Required</MobileViewText>
                        <RadioButtonContainer>
                          <RadioButton
                            onClick={() => handleRequired("YES", data.id)}
                            checked={data.AssetAttributeRequired}
                            label="Yes"
                            size="small"
                            id={"yes_" + i}
                          />
                          <RadioButton
                            onClick={() => handleRequired("OPTIONAL", data.id)}
                            checked={!data.AssetAttributeRequired}
                            label="Optional"
                            size="small"
                            id={"optional_" + i}
                          />
                        </RadioButtonContainer>
                      </ColumnReorder>
                      <Column textleft={"left"} inputWidth={"90%"}>
                        <InputField
                          id={data.id}
                          disabled={data.removeDelete}
                          minLength={1}
                          maxLength={128}
                          placeholder={t("Enter Field Description")}
                          value={data.AssetAttributeDescription}
                          onChange={(e) => handleDescription(data.id, e.target.value)}
                        />
                      </Column>
                      <Column textleft={"left"}>
                        {!data.removeDelete && (
                          <DeleteIconStyled
                            className="icon icon-delete"
                            id={data.id}
                            onClick={removeSchemaAttribute}
                          />
                        )}
                      </Column>
                    </RowDiv>
                  </>
                ))
              : ""}
            <div style={{ height: "40px" }}>
              <span></span>
            </div>
          </RowsContainer>
        </AssetTypeDetailsTableDescription>
      </AssetTypeDetailsDiv>

      {isValidationErrorVisible ? (
        <ErrorMessage>
          *<Trans>All the field name are required</Trans>
        </ErrorMessage>
      ) : (
        ""
      )}

      {/* Add Asset Type Modal Actions */}
      <ButtonWrapperDiv>
        <AddChecklistDivB>
          <Button
            label={t("Add Custom Field")}
            onClick={handleAddCustomField}
            iconClass="add"
            showIcon
            buttonType="primary"
            {...(isIconOnly && { iconOnly: true })}
          />
        </AddChecklistDivB>

        <EditCancelButton>
          <Button label={t("Cancel")} onClick={handleBack} buttonType="ghost" />

          <Button
            label={t("Next")}
            onClick={() => handleNext()}
            iconClass="arrow-right"
            showIcon
            iconPosition="after"
            buttonType="primary"
          />
        </EditCancelButton>
      </ButtonWrapperDiv>
    </AssetTypeDetailsWrapper>
  );
};

const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
  isSuperAdmin: state.user.isSuperAdmin,
});

const mapDispatchToProps = (dispatch) => ({
  getDropdownAssetTypeList: () => dispatch(getDropdownAssetTypeList()),
  getAssetTypes: (paginationData, isResetState) => dispatch(getAssetTypes(paginationData, isResetState)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddAssetTypeDetails));

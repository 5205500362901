import Header from "./details/Header";
import DeviceInfo from "./details/DeviceInfo";
import AssetDetails from "./details/AssetDetails";
import AssetLocation from "./details/AssetLocation";
import BreadCrumb from "../../../components/BreadCrumb.component";
import { DeviceInfoWrapper, LoadingFrame } from "./DeviceInformation.component.styles";
import { NoDataWrapper } from "./TableData.component.styles";

const DeviceDetails = (props) => {
  const { setShowDeviceDetails, selectedAsset, accountName, getDeviceInformation, isLoading } = props;
  return (
    <>
      <BreadCrumb />
      <DeviceInfoWrapper>
        <Header
          setShowDeviceDetails={setShowDeviceDetails}
          selectedAsset={selectedAsset}
          accountName={accountName}
          getDeviceInformation={getDeviceInformation}
        />
        <DeviceInfo selectedAsset={selectedAsset} />
        <AssetDetails selectedAsset={selectedAsset} />
        <AssetLocation selectedAsset={selectedAsset} />
      </DeviceInfoWrapper>
      {isLoading && (
        <LoadingFrame>
          <span
            className="spinner-border test mt-4"
            role="status"
            style={{ position: "fixed", top: "40%" }}
          ></span>
        </LoadingFrame>
      )}
    </>
  );
};

export default DeviceDetails;

import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
//import Input from "../../../components/Input";

import { Col, Row, Card } from "react-bootstrap";

import SnackBar from "../SnackBar";
import UpdateButtons from "../../../components/UpdateButtons.component";

// redux
import { connect } from "react-redux";
import { updateAsset } from "../../../redux/assets/action";
import { Trans, useTranslation } from "react-i18next";
import { setEditFormSecond } from "../../../redux/forms/action";
import validationConstant from "../../../constant/messages/validation.json";
import { apiCall } from "../../../utils/apiCall";
import Button from "../../../components/Button/Button.component";
import ModalComponent from "../../../components/ModalPopupComponent/ModalPopup.component";
import DropdownComponent from "../../../components/DropdownComponent/DropdownFilterWrapper";

import {
  AssetChecklistTableWrapper,
  RowDiv,
  Column,
  RowsContainer,
  DeleteIconStyled,
  AddChecklistDiv,
  HeadingContainer,
  ContainerRow,
} from "./AssetOverviewChecklistTable.component.styles";
import RadioButton from "../../../components/RadioButton/Radio.component";
import PermissionCheck from "../../../components/PermissionCheck/PermissionCheck";

const stylesCss = {
  message: {
    color: "rgba(0,0,0,0.5)",
  },
};
function AssetOverviewChecklistTable({
  checklist,
  loginDetails,
  assetOverview,
  statusButton,
  updateAsset,
  getAllChecklist,
  setEditAlertModal,
  setEditFormSecond,
  editFormSecond,
}) {
  const [modalShow, setModalShow] = useState(false);
  const [checklistModalData, setChecklistModalData] = useState({
    isDefaultChecklist: false,
  });
  const [checklistData, setChecklistData] = useState([]);
  const [selectedCheckboxId, setSelectedCheckboxId] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [checklistMapError, setChecklistMapError] = useState(false);
  const [finalUnassignedChecklist, setFinalUnassignedChecklist] = useState([]);
  const [enableAddChecklistButton, setEnableAddChecklistButton] = useState(false);
  const [isSuccessAddChecklist, setIsSuccessAddChecklist] = useState(false);

  const { t } = useTranslation();

  //TODO--move it to action file when time permits
  const updateAssetChecklist = async (e) => {
    let id = e.target.id;
    setIsLoading(true);
    const response = await await apiCall(`/asset/updateasset/${id}`, {
      method: "PUT",
      data: { assetChecklists: checklistData },
    });
    if (response.data.success === true) {
      updateAsset({ assetChecklists: response.data.data.assetChecklists, _id: id });
      setIsSuccess(validationConstant.checklist_map_update);
      setIsLoading(false);
      setEditFormSecond(true);
    } else {
      setIsLoading(false);
    }
  };

  const setAssetChecklist = (e) => {
    setChecklistMapError(false);
    if (!checklistModalData.checklistId) {
      setChecklistMapError(t(validationConstant.checklist_required));
      return;
    }
    if (checklistData.length == 0) {
      checklistData.push({ ...checklistModalData, isDefaultChecklist: true });
      setSelectedCheckboxId(checklistModalData.checklistId);
    } else {
      checklistData.push({ ...checklistModalData, isDefaultChecklist: false });
    }
    setChecklistData([...checklistData]);
    setModalShow(false);
    setEditFormSecond(false);
    setIsSuccessAddChecklist(true);
  };

  const removeFromAssetChecklist = (e) => {
    let checklistId = e.currentTarget.id;
    checklistData.map((data, i) => {
      if (data.checklistId == checklistId) {
        checklistData.splice(i, 1);
        if (data.isDefaultChecklist && checklistData.length > 0) {
          checklistData[0].isDefaultChecklist = true;
          setSelectedCheckboxId(checklistData[0].checklistId);
          setChecklistData([...checklistData]);
        }
      }
    });
    setChecklistData([...checklistData]);
  };

  let filterChecklist = (checklistId) => {
    let checklistDetail;
    checklist &&
      checklist.map((data) => {
        if (checklistId == data._id) {
          checklistDetail = data;
        }
      });
    return checklistDetail;
  };

  const updateDefaultChecked = (e) => {
    let checklistId = e.target.id;
    setSelectedCheckboxId(checklistId);
    if (checklistData.length == 0) {
      checklistId = checklistData && checklistData[0] && checklistData[0]._id;
    }
    checklistData.map((data, i) => {
      if (data.checklistId == checklistId) {
        checklistData[i].isDefaultChecklist = true;
      } else {
        checklistData[i].isDefaultChecklist = false;
      }
    });
    setChecklistData([...checklistData]);
  };

  useEffect(() => {
    setChecklistData(assetOverview.assetChecklists);
    if (assetOverview.assetChecklists) {
      assetOverview.assetChecklists.map((data) => {
        if (data.isDefaultChecklist) {
          setSelectedCheckboxId(data.checklistId);
        }
      });
    }
  }, [assetOverview]);

  const handleCloseSnackBar = () => {
    setIsSuccess(false);
    setIsSuccessAddChecklist(false);
    setChecklistMapError(false);
  };

  const handleMessage = (isSuccess, isSuccessAddChecklist) => {
    if (isSuccess) {
      return t(validationConstant.checklist_map_update);
    }
    if (isSuccessAddChecklist) {
      return t("CHECKLIST_ADDED");
    }
  };
  //clean up
  useEffect(() => {
    if (!checklistData) {
      return;
    }
    let finalChecklist = [];
    let selectedCheckListIds = checklistData.map((list) => list.checklistId);
    let filteredCheckList = checklist && checklist.filter((list) => !selectedCheckListIds.includes(list._id));
    let checklistResult =
      filteredCheckList &&
      filteredCheckList.map((data) => {
        if (assetOverview.accountId === data.accountId) {
          finalChecklist.push({ _id: data._id, label: data.checklistName });
        }
      });
    setFinalUnassignedChecklist([...finalChecklist]);
  }, [checklistData]);

  useEffect(() => {
    updateAddCheckListButtonAvailibility();
  }, [finalUnassignedChecklist]);

  const updateAddCheckListButtonAvailibility = () => {
    //console.log("enableAddChecklistButton BEFORE Update : ", enableAddChecklistButton);
    if (finalUnassignedChecklist?.length > 0) {
      setEnableAddChecklistButton(true);
    } else {
      setEnableAddChecklistButton(false);
    }
    //console.log("enableAddChecklistButton AFTER Update : ", enableAddChecklistButton);
  };

  return (
    <>
      {/* {!editForm && <div className="alert-modal-overlay" onClick={() => setEditAlertModal(true)}></div>} */}
      <Card
        className="text-style border-0 shadow-none"
        style={{
          width: "100%",
        }}
      >
        {checklistData && checklistData.length > 0 ? (
          <>
            <AssetChecklistTableWrapper>
              <RowDiv isHeader={true}>
                <Column isHeader={true} align={"left"}>
                  <Trans>Checklist Name</Trans>
                </Column>
                <Column isHeader={true} align={"left"}>
                  <Trans>Checklist Description</Trans>
                </Column>
                <Column isHeader={true} align={"center"}>
                  <Trans>Default Checklist</Trans>
                </Column>
                <Column isHeader={true} align={"center"}>
                  <Trans>Action</Trans>
                </Column>
              </RowDiv>
              <RowsContainer>
                {checklistData.map((row, i) => (
                  <RowDiv key={i}>
                    <Column
                      align={"left"}
                      textleft={"left"}
                      widthResp={"75%"}
                      floatResp={"left"}
                      RmarginResp={"5%"}
                    >
                      <span
                        title={
                          filterChecklist(row.checklistId)
                            ? filterChecklist(row.checklistId).checklistName
                            : ""
                        }
                      >
                        {" "}
                        {filterChecklist(row.checklistId)
                          ? filterChecklist(row.checklistId).checklistName
                          : ""}
                      </span>
                    </Column>
                    <Column
                      align={"left"}
                      textleft={"left"}
                      widthResp={"75%"}
                      floatResp={"left"}
                      RmarginResp={"5%"}
                    >
                      <span
                        title={
                          filterChecklist(row.checklistId)
                            ? filterChecklist(row.checklistId).checklistDescription
                            : ""
                        }
                      >
                        {" "}
                        {filterChecklist(row.checklistId)
                          ? filterChecklist(row.checklistId).checklistDescription
                          : ""}
                      </span>
                    </Column>
                    <Column align={"center"} textleft={"center"} widthResp={"20%"} floatResp={"none"}>
                      <RadioButton
                        id={row.checklistId}
                        checked={row.checklistId === selectedCheckboxId}
                        disabled={editFormSecond}
                        onChange={updateDefaultChecked}
                        size="small"
                      />

                      {row.isDefaultChecklist}
                    </Column>
                    <Column align={"center"} textleft={"center"} widthResp={"20%"} floatResp={"none"}>
                      <DeleteIconStyled
                        className="icon icon-delete"
                        id={row.checklistId}
                        isDisabled={editFormSecond}
                        onClick={editFormSecond ? null : removeFromAssetChecklist}
                      />
                    </Column>
                  </RowDiv>
                ))}
              </RowsContainer>
            </AssetChecklistTableWrapper>
          </>
        ) : (
          <>
            <div className="noSection">
              <div align="center" style={stylesCss.message}>
                <Trans>There is no checklist added to this asset</Trans>.
              </div>
              <div align="center" style={stylesCss.message}>
                <Trans>Click '+ ADD CHECKLIST' to add a checklist</Trans>
              </div>
            </div>
          </>
        )}
      </Card>
      <ModalComponent
        size="lg"
        show={modalShow}
        handleClose={() => setModalShow(false)}
        title="Add Checklist to Asset"
        withHeader={true}
      >
        <AddChecklistDiv>
          <HeadingContainer>
            <Trans>Select Checklist</Trans>
          </HeadingContainer>

          {/* modal body starts */}
          <ContainerRow>
            <Row className="p4">
              <Col md={12} className="mb-2">
                <DropdownComponent
                  options={finalUnassignedChecklist}
                  label={"Checklist"}
                  value={checklistModalData.checklistId}
                  onChange={(value) =>
                    setChecklistModalData({
                      ...checklistModalData,
                      checklistId: value,
                    })
                  }
                  required={true}
                  //inputRef = {accountRef}
                />
              </Col>
            </Row>
          </ContainerRow>
          <div className="text-right">
            <Button
              iconClass="add"
              label={t("Add")}
              onClick={setAssetChecklist}
              id={assetOverview._id}
              showIcon
              buttonType="primary"
            />
          </div>
        </AddChecklistDiv>
      </ModalComponent>
      <PermissionCheck section={"DASHBOARD"} permissionName={"ASSET_MANAGEMENT"} actionName={"edit"}>
        <Row className="asset_check_buttonFlex">
          <Col className="text-left">
            <Button
              iconClass="add"
              label={t("Add Checklist")}
              onClick={() => setModalShow(true)}
              disabled={!statusButton || !enableAddChecklistButton}
              showIcon
              buttonType="secondary"
            />
          </Col>
          <Col></Col>
          <UpdateButtons
            editOverview={editFormSecond}
            setEditOverview={setEditFormSecond}
            statusButton={checklistData && checklistData.length > 0 && statusButton}
            handleUpdate={updateAssetChecklist}
            IsLoading={isLoading}
            overviewDetail={assetOverview}
          />
        </Row>
      </PermissionCheck>
      <SnackBar
        isFailed={checklistMapError}
        isSuccess={isSuccess || isSuccessAddChecklist}
        label={checklistMapError ? checklistMapError : handleMessage(isSuccess, isSuccessAddChecklist)}
        handleClose={handleCloseSnackBar}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
  checklist: state.checklist.checklists,
  assetOverview: state.assets.AssetOverview,
  editFormSecond: state.forms.editFormSecond,
});

const mapDispatchToProps = (dispatch) => ({
  updateAsset: (data) => dispatch(updateAsset(data)),
  setEditFormSecond: (data) => dispatch(setEditFormSecond(data)),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AssetOverviewChecklistTable));

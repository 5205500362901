import styled from "styled-components";

const CarouselComponentStyle = {
  small: {
    fontSize: "24px",
    top: "1px",
    iconFontsize: "20px",
    fontHeadSize: "14px",
    iconSize: "20px",
  },
  medium: {
    fontSize: "32px",
    top: "2px",
    iconFontsize: "24px",
    fontHeadSize: "16px",
    iconSize: "25px",
  },
  large: {
    fontSize: "40px",
    top: "2px",
    iconFontsize: "32px",
    fontHeadSize: "18px",
    iconSize: "30px",
  },
};

const getColor = (colors, type, iconColor) => {
  switch (type) {
    case "ThemePrimary":
      return colors.themePrimary;
    case "ThemePrimaryDarker":
      return colors.themePrimaryDarker;
    case "GrayL1":
      return colors.grayL1;
    case "GrayL2":
      return colors.grayL2;
    case "GrayL3":
      return colors.grayL3;
    case "GrayL4":
      return colors.grayL4;
    case "GrayL5":
      return colors.grayL5;
    case "GrayL6":
      return colors.grayL6;
    case "GrayL7":
      return colors.grayL7;
    case "GreenPrimary":
      return colors.greenPrimary;
    case "GreenHover":
      return colors.greenHover;
    case "RedPrimary":
      return colors.redPrimary;
    case "RedHover":
      return colors.redHover;
    case "LinkColor":
      return colors.linkColor;
    case "OrangePrimary":
      return colors.orangePrimary;
    case "OrangeHover":
      return colors.orangeHover;
    case "PdfColor":
      return colors.PdfColor;
    case "CsvColor":
      return colors.CsvColor;
    default:
      return;
  }
};

export const CarouselContainer = styled.a`
  position: relative;
  text-align: center;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
`;

export const CarouselWrapper = styled.div`
  .react-multi-carousel-list {
    width: 100%;
  }
  background: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  box-shadow: 0px 3px 12px -7px ${({ theme }) => theme.colors.grayL1 || "#323232"};
  border-radius: 4px;

  ul {
    li {
      a {
      }
      &:after {
        content: "";
        height: 80%;
        width: 1px;
        position: absolute;
        right: 0;
        top: 10%;
        background-color: ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"};
      }
    }
  }
  div {
    .react-multiple-carousel__arrow--left {
      left: calc(-1% + 1px);
      background: none;
      &:before {
        color: ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"};
      }
    }
    .react-multiple-carousel__arrow--right {
      right: calc(-2% + -2px);
      height: 66px;
      background: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"} !important;
      background: none;
      &:before {
        color: ${({ theme }) => theme.colors.grayL4 || "#9A9A9A"};
        right: 9px;
      }
    }
  }
`;

export const CarouselHeading = styled.div`
  margin-top: 5px;
  color: ${({ theme, type, isColored }) => (isColored ? getColor(theme.colors, type) : theme.colors.grayL3 || "#676767")};
  font-size: ${(props) => CarouselComponentStyle[props.size].fontHeadSize} !important;
  font-family: "Open Sans", sans-serif;
`;

export const CarouselIconNumber = styled.div`
  color: ${({ theme, type }) => getColor(theme.colors, type)};
  font-size: ${(props) => CarouselComponentStyle[props.size].fontSize} !important;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
`;

export const IconCarousel = styled.div`
  display: initial;
  font-size: ${(props) => CarouselComponentStyle[props.size].iconFontsize} !important;
  position: relative;
  right: 10px;
  top: ${(props) => CarouselComponentStyle[props.size].top};
  color: ${({ theme, iconColor }) => getColor(theme.colors, iconColor)};
  :before {
    font-size: ${(props) => CarouselComponentStyle[props.size].iconSize} !important;
  }
`;

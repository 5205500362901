import React, { useEffect, useState } from "react";
import moment from "moment";
import config from "../../../../config";
const s3BucketImpactImage = config.s3BucketImpactImage;
import { getTimeWithTimeZone } from "../../../../utils/timezoneConvert";

import ScoutLogo from "../../../../assets/logo/Scout_Logo.png";
import { ReactComponent as ObjectIcon } from "../../../../assets/Proximity_Object_Icon.svg";
import { ReactComponent as PedestrianIcon } from "../../../../assets/Proximity_Pedestrian_Icon.svg";
import MapView from "./MapView.component";
import "./report.css";

//import ReportBanner from "../../../../assets/Report_Banner.jpg";
import { Trans } from "react-i18next";

import {
  ReportDivWrapp,
  ReportPageWrapper,
  ReportTopSection,
  PageNumber,
  SieraLogo,
  EmailHelpText,
  Anchor,
  ReportName,
  ReportDataSection,
  ReprtHeaderTop,
  ReportStatus,
  ReportCenterData,
  ReportRightSec,
  VehicalDetailsData,
  VehicleDatNA,
  ReportPageFooter,
  PedestrianButtonStyle,
  ObjectButtonStyle,
  PedestrianPositionButton,
  ObjectPositionButton,
  ReportDetailsSection,
  ReportCenterDataTableImg,
} from "../../../../components/ReportViewComponent/ReportViewComponent.Style";

function ProximityReportView(props) {
  const [reportData, setReportData] = useState(null);
  const [position, setPosition] = useState(null);
  const [unitType, setUnitType] = useState("imperial");

  useEffect(() => {
    setUnitType(localStorage.getItem("unitType"));
    const data = JSON.parse(localStorage.getItem("nearMissReportObject"));
    setReportData(data);
    setPosition(data?.location?.coordinates || null);
    document.title = data?.fullName + "(Asset Impact Report)";
  }, []);

  return (
    <ReportDivWrapp>
      {reportData ? (
        <>
          <ReportPageWrapper>
            <ReportTopSection>
              <PageNumber>Page 1</PageNumber>
              <SieraLogo src={ScoutLogo} alt="" />
              <EmailHelpText>
                <Trans> For support email us at</Trans>
                <Anchor href="mailto:support@siera.ai">
                  <Trans> support@siera.ai</Trans>
                </Anchor>
              </EmailHelpText>
              <EmailHelpText>
                <Trans> or visit our Helpdesk&nbsp;</Trans>
                <Anchor href="https://support.siera.ai" target="_blank">
                  <Trans> https://support.siera.ai</Trans>
                </Anchor>
              </EmailHelpText>
            </ReportTopSection>

            <ReportName>
              <Trans>Asset Near-Miss Report</Trans>
            </ReportName>

            <ReportDataSection>
              <ReprtHeaderTop>
                <Trans>Summary</Trans>
                <ReportStatus></ReportStatus>
              </ReprtHeaderTop>

              <ReportCenterData>
                <Trans>Obstacle Type</Trans>
                <ReportStatus>
                  {reportData?.incidentObject.toLowerCase() === "pedestrian" && (
                    <PedestrianButtonStyle>
                      <Trans>PEDESTRIAN</Trans>
                    </PedestrianButtonStyle>
                  )}
                  {reportData?.incidentObject.toLowerCase() === "object" && (
                    <ObjectButtonStyle>
                      <Trans>OBJECT</Trans>
                    </ObjectButtonStyle>
                  )}
                </ReportStatus>
              </ReportCenterData>

              <ReportCenterData>
                <Trans>Worksite</Trans>
                <ReportRightSec>{reportData?.siteName || "NA"}</ReportRightSec>
              </ReportCenterData>

              <ReportCenterData>
                <Trans>Date | Time | Timezone</Trans>
                <ReportRightSec>
                  {moment(new Date(reportData?.impactRecordedAt))
                    .utcOffset(reportData?.timeZone?.split?.(" ")[0])
                    .format("MMMM DD, YYYY | hh.mm.ss A")}{" "}
                  | {getTimeWithTimeZone(reportData?.timeZone)}
                </ReportRightSec>
              </ReportCenterData>

              <ReportCenterData>
                <Trans>Asset Name</Trans>
                <ReportRightSec>{reportData?.assetName || "NA"}</ReportRightSec>
              </ReportCenterData>

              <ReportCenterData>
                <Trans>Incident By</Trans>
                <ReportRightSec>{reportData?.fullName || "NA"}</ReportRightSec>
              </ReportCenterData>
            </ReportDataSection>

            <br />

            <ReprtHeaderTop>
              <Trans>Details</Trans>
            </ReprtHeaderTop>

            <ReportCenterData>
              <>
                {reportData?.incidentDetails && reportData?.incidentDetails?.length != 0 ? (
                  <>
                    {reportData?.incidentDetails?.map((data) => (
                      <>
                        {data?.incidentObject === "PEDESTRIAN" && (
                          <ReportDetailsSection>
                            <PedestrianPositionButton>
                              <i class="icon icon-pedestrian"></i>
                              <Trans>CAMERA {data?.cameraDirection}</Trans>
                            </PedestrianPositionButton>

                            <ReportRightSec>
                              {unitType?.toLowerCase() === "imperial"
                                ? Number(parseFloat(data?.distanceinmm / 305).toFixed(2)) + "ft"
                                : Number(parseFloat(data?.distanceinmm).toFixed(2)) + "mm"}
                            </ReportRightSec>
                          </ReportDetailsSection>
                        )}
                        {data?.incidentObject === "OBJECT" && (
                          <ReportDetailsSection>
                            <ObjectPositionButton>
                              <i class="icon icon-object"></i> <Trans>CAMERA {data?.cameraDirection}</Trans>
                            </ObjectPositionButton>

                            <ReportRightSec>
                              {unitType?.toLowerCase() === "imperial"
                                ? Number(parseFloat(data?.distanceinmm / 305).toFixed(2)) + "ft"
                                : Number(parseFloat(data?.distanceinmm).toFixed(2)) + "mm"}
                            </ReportRightSec>
                          </ReportDetailsSection>
                        )}
                      </>
                    ))}
                  </>
                ) : (
                  <>
                    <ReportDetailsSection>
                      <Trans>Distance from obstacle</Trans>

                      <ReportRightSec>
                        {unitType?.toLowerCase() === "imperial"
                          ? Number(parseFloat(reportData?.distanceinmm / 305).toFixed(2)) + "ft"
                          : Number(parseFloat(reportData?.distanceinmm).toFixed(2)) + "mm"}
                      </ReportRightSec>
                    </ReportDetailsSection>

                    <ReportDetailsSection>
                      <Trans>Camera Direction</Trans>

                      <ReportRightSec>
                        <Trans>{reportData?.cameraDirection || "NA"}</Trans>
                      </ReportRightSec>
                    </ReportDetailsSection>
                  </>
                )}
              </>
            </ReportCenterData>

            <br />

            <ReprtHeaderTop>
              <Trans>Vehicle Details</Trans>
            </ReprtHeaderTop>
            <VehicalDetailsData>
              <>
                <ReportCenterData>
                  <Trans>Vehicle Location</Trans>
                  <ReportRightSec>
                    {position && position?.length === 2 && (position[0] !== 0 || position[1] !== 0)
                      ? position.join(", ")
                      : "NA"}
                  </ReportRightSec>
                </ReportCenterData>
                {position && position?.length === 2 && (position[0] !== 0 || position[1] !== 0) && (
                  <MapView position={position} />
                )}
              </>
            </VehicalDetailsData>

            <ReportPageFooter>
              <Trans>Proudly Built in Austin, TX. Copyright </Trans>
              {new Date().getFullYear()}.&nbsp;
              <Trans>Stocked Robotics, Inc. dba SIERA.AI</Trans>
            </ReportPageFooter>
          </ReportPageWrapper>
          {(typeof reportData?.impactImage == "string" && reportData?.impactImage !== "") ||
          (typeof reportData?.impactImage == "object" && reportData?.impactImage?.length === 0) ? (
            ""
          ) : (
            <ReportPageWrapper>
              <ReportTopSection>
                <PageNumber>Page 2</PageNumber>
                <SieraLogo src={ScoutLogo} alt="" />
                <EmailHelpText>
                  For support email us at
                  <Anchor href="mailto:support@siera.ai"> support@siera.ai</Anchor>
                </EmailHelpText>
                <EmailHelpText>
                  or visit our Helpdesk&nbsp;
                  <Anchor href="https://support.siera.ai" target="_blank">
                    https://support.siera.ai
                  </Anchor>
                </EmailHelpText>
              </ReportTopSection>

              <ReportDataSection>
                <ReprtHeaderTop>
                  <Trans>Photo of Operator</Trans>
                </ReprtHeaderTop>
              </ReportDataSection>
              <ReportCenterDataTableImg>
                {typeof reportData?.impactImage == "string" ? (
                  <img
                    src={s3BucketImpactImage + "" + reportData?.impactImage}
                    width="100%"
                    alt={"impactImageFile"}
                  />
                ) : (
                  reportData?.impactImage?.map((image) => (
                    <>
                      <img
                        src={s3BucketImpactImage + "" + image}
                        className="imageSnapshot"
                        alt={"impactImageFile"}
                      />
                    </>
                  ))
                )}

                {reportData?.impactMedia?.map?.((image) => (
                  <>
                    <img
                      src={s3BucketImpactImage + "" + image?.impactImageFile}
                      alt={"impactImageFile"}
                      width="100%"
                    />
                  </>
                ))}
              </ReportCenterDataTableImg>

              <ReportPageFooter>
                <Trans>Proudly Built in Austin, TX. Copyright </Trans>
                {new Date().getFullYear()}.&nbsp;
                <Trans>Stocked Robotics, Inc. dba SIERA.AI</Trans>
              </ReportPageFooter>
            </ReportPageWrapper>
          )}
        </>
      ) : (
        <VehicleDatNA>No Reports</VehicleDatNA>
      )}
    </ReportDivWrapp>
  );
}

export default ProximityReportView;

import { useState, useEffect } from "react";
import { Row, Col, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import {
  DeleteIconStyled,
  BottomPanel,
  AddShiftDurationButton,
  SaveCancelWrapper,
  ShiftDurationWrapper,
  ShiftDurationSettingWrapper,
  ShiftDurationHours,
  ShiftDurationMessage,
  ShiftDurationH,
  StyledInformationIcon,
} from "./ShiftDurationPanel.styles";
import { isEqual } from "lodash";
import Button from "../../../components/Button/Button.component";
import PermissionCheck from "../../../components/PermissionCheck/PermissionCheck";

const ShiftDurationPanel = (props) => {
  const {
    savedShiftSettings,
    updateLoading,
    handleUpdate,
    worksiteId,
    shiftSettings,
    setShiftSettings,
    statusButton,
  } = props;
  const [updatableShiftDuration, setUpdatableShiftDuration] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setUpdatableShiftDuration(!isEqual(shiftSettings, savedShiftSettings));
  }, [shiftSettings, savedShiftSettings]);

  const shiftDurationChangeHandler = (e) => {
    setShiftSettings({
      ...shiftSettings,
      shiftDuration:
        e.target.value.trim() === "" ? "" : e.target.value > 24 ? 24 : parseInt(e.target.value, 10),
    });
  };

  const shiftDurationBlurHandler = (e) => {
    const shiftDuration =
      isNaN(parseInt(e.target.value, 10)) || parseInt(e.target.value, 10) === 0
        ? savedShiftSettings.shiftDuration
        : e.target.value > 24
          ? 24
          : Math.floor(e.target.value);
    setShiftSettings({
      ...shiftSettings,
      shiftDuration,
    });
  };

  const deleteShiftDuration = () => {
    if (statusButton) {
      setShiftSettings(null);
    }
  };

  const handleShiftSettingsCancel = () => {
    setShiftSettings(savedShiftSettings);
  };

  const addShiftDuration = () => {
    setShiftSettings({
      shiftDuration: 8,
      shiftTimeUnit: "hours",
    });
  };

  const handleInvalidCharsInput = (e) => {
    const invalidChars = ["-", "+", "e", "."];
    if (invalidChars.includes(e.key)) {
      e.preventDefault();
    }
  };

  const isSaveDisabled = () => {
    return shiftSettings && (!shiftSettings.shiftDuration || parseInt(shiftSettings.shiftDuration, 10) === 0);
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      <Trans>Define the number of hours in a shift, in this worksite</Trans>
    </Tooltip>
  );
  return (
    <>
      <ShiftDurationWrapper>
        {shiftSettings ? (
          <ShiftDurationSettingWrapper>
            <ShiftDurationHours>
              <Form.Group className="inputBoxDiv  hide-detail">
                <Form.Label>
                  <Trans>Shift Duration</Trans>
                </Form.Label>
                <Form.Control
                  onKeyDown={handleInvalidCharsInput}
                  onChange={shiftDurationChangeHandler}
                  onBlur={shiftDurationBlurHandler}
                  value={shiftSettings.shiftDuration}
                  type="number"
                  min={1}
                  max={24}
                  placeholder={t("Enter shift duration")}
                  disabled={!statusButton}
                />
                <ShiftDurationH>hours</ShiftDurationH>
              </Form.Group>
            </ShiftDurationHours>
            <div>
              <DeleteIconStyled onClick={deleteShiftDuration} isDisabled={!statusButton} />
            </div>
          </ShiftDurationSettingWrapper>
        ) : (
          <ShiftDurationMessage>
            <Trans>
              There are no shifts defined for your worksite. Click on the ‘ADD SHIFT DURATION’ button to
              define a shift
            </Trans>
          </ShiftDurationMessage>
        )}
      </ShiftDurationWrapper>

      <PermissionCheck section="DASHBOARD" permissionName="WORKSITE_MANAGEMENT" actionName="edit">
        <Row className="editWorks editworksiteT worksiteRight">
          <Col>
            <BottomPanel>
              <AddShiftDurationButton>
                <Button
                  iconClass="add"
                  label={t("Add Shift Duration")}
                  onClick={addShiftDuration}
                  showIcon
                  buttonType="secondary"
                  isActive={!shiftSettings}
                  disabled={!!shiftSettings || !statusButton}
                />

                <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={renderTooltip}>
                  <span>
                    <StyledInformationIcon />
                  </span>
                </OverlayTrigger>
              </AddShiftDurationButton>

              {updatableShiftDuration && (
                <SaveCancelWrapper>
                  <Button
                    label={t("Cancel")}
                    onClick={handleShiftSettingsCancel}
                    showIcon={false}
                    buttonType="ghost"
                  />

                  <Button
                    isActive={true}
                    iconClass="save"
                    label={t("Save")}
                    onClick={(e) => handleUpdate(e, shiftSettings)}
                    id={worksiteId}
                    disabled={isSaveDisabled()}
                    showIcon
                    buttonType="save"
                  />
                </SaveCancelWrapper>
              )}
            </BottomPanel>
          </Col>
        </Row>
      </PermissionCheck>
    </>
  );
};

export default ShiftDurationPanel;

/**
 * TODO: Clean up this file when time permits. There is a shit load of garbage
 */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { login } from "../../../../redux/user/user.action";
import SettingsSection from "../../../../components/SettingsSection";
import {
  getImpactAlertSettingsByAssetId,
  resetImpactAlertSettingsByAssetId,
  updateImpactAlertSettingsByAssetId,
  resetNotificationSettingsFlags,
} from "../../../../redux/notification/action";
import useAlert from "../../../../utils/useAlert";
import { isEqual } from "../../../../utils/validations/utilities";

import { SettingWrapper } from "./NotificationSetting.component.styles";

import ActionsBar from "./ActionsBar";
import SettingsHeader from "../common/SettingsHeader.component";
import { SettingsWrapper, SettingsContainer, SettingsFooter } from "../Settings.Styles";
import SnackBar from "../../SnackBar";
import validationConstant from "../../../../constant/messages/validation.json";
import NoSettingsDataFound from "../common/NoAssetFound.component";
import { bulkSettingsUpdate } from "../../../../redux/config/action";
import { getSelectedOptions, handleRecipientsChangeUtil } from "./common";
import Recipients from "./emailerRecipients/Recipients";

const NotificationSettings = ({
  loginDetails,
  notificationSetting,
  isAccountAdmin,
  isAccountOwner,
  isSiteAdmin,
  dropdownAccountId,
  getImpactAlertSettingsByAssetId,
  resetImpactAlertSettingsByAssetId,
  updateImpactAlertSettingsByAssetId,
  loading,
  dropdownAssetId,
  bulkSettingsUpdate,
  resetNotificationSettingsFlags,
  notificationUpdateLoading,
  notificationBulkUpdateSuccess,
}) => {
  const [impactAlertSettings, setImpactAlertSettings] = useState(null);
  const [worksiteId, setWorksiteId] = useState(null);
  const [currentAccountId, setCurrentAccountId] = useState(null);
  const [applyToAllObj, setApplyToAllObj] = useState({});
  const [checkApplyToAll, setCheckApplyToAll] = useState(false);
  const [selectedAssetId, setSelectedAssetId] = useState(null);
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);
  const [clearSelection, setClearSelection] = useState(false);

  useEffect(() => {
    if (notificationBulkUpdateSuccess) {
      getImpactAlertSettingsByAssetId(selectedAssetId);
      setApplyToAllObj({});
      setCheckApplyToAll(false);
      setClearSelection(true);
    }
  }, [notificationBulkUpdateSuccess]);

  useEffect(() => {
    return () => {
      handleCloseSnackBar();
    };
  }, [resetNotificationSettingsFlags]);

  useEffect(() => {
    if (selectedAssetId) {
      getImpactAlertSettingsByAssetId(selectedAssetId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAssetId]);

  useEffect(() => {
    const acId =
      isAccountAdmin || isAccountOwner || isSiteAdmin
        ? loginDetails && loginDetails.accountId
        : dropdownAccountId;
    setCurrentAccountId(acId);
  }, [dropdownAccountId, loginDetails, isAccountAdmin, isAccountOwner, isSiteAdmin]);

  const { t } = useTranslation();

  const setDefaultNotificationSettingsWorksite = () => {
    setClearSelection(true);
    setImpactAlertSettings(notificationSetting?.impactAlertSettings);
  };

  const hideButtonsForWorksiteSettings = isEqual(
    notificationSetting?.impactAlertSettings,
    impactAlertSettings
  );
  useEffect(() => {
    if (notificationSetting) {
      setDefaultNotificationSettingsWorksite();
    }
  }, [notificationSetting.impactAlertSettings]);

  const handleImpactAlertSettingsChange = (field, value) => {
    setImpactAlertSettings((prevAlert) => ({
      ...prevAlert,
      [field]: value,
    }));
  };

  const displaySettings = {
    impactAlerts: {
      title: "ImpactAlerts",
      description: "ImpactAlertsDescription",
      tableHeader: ["Impact Severity", "Alert"],
      onChange: handleImpactAlertSettingsChange,
      tableData: [
        {
          label: "Low",
          value: !!impactAlertSettings?.low,
          field: "low",
        },
        {
          label: "Medium",
          value: !!impactAlertSettings?.medium,
          field: "medium",
        },
        {
          label: "High",
          value: !!impactAlertSettings?.high,
          field: "high",
        },
      ],
    },

    impactAlertSettingssRecipient: {
      title: "IncidentAlertsRecipient",
      description: "incidentRecipientDescription",
      tableHeader: ["Emailer Recipients", "Email"],
      onChange: handleImpactAlertSettingsChange,
      customEmails: impactAlertSettings?.customEmails,
      accountId: currentAccountId,
      tableData: [
        {
          label: "Account Owner",
          value: !!impactAlertSettings?.toAccountOwner,
          field: "toAccountOwner",
          showOverlay: false,
        },
        {
          label: "Account Administrators",
          value: !!impactAlertSettings?.toAccountAdmins,
          field: "toAccountAdmins",
          showOverlay: false,
        },
        {
          label: "Account Viewers",
          value: !!impactAlertSettings?.toAccountViewers,
          field: "toAccountViewers",
          showOverlay: false,
        },
        {
          label: "Worksite Managers",
          value: !!impactAlertSettings?.toWorkSiteManagers,
          field: "toWorkSiteManagers",
          showOverlay: true,
          overlayMessage:
            "When this option is checked, the worksite managers will receive the alerts for their worksite only.",
        },
        {
          label: "Worksite Administrators",
          value: !!impactAlertSettings?.toWorkSiteAdmins,
          field: "toWorkSiteAdmins",
          showOverlay: true,
          overlayMessage:
            "When this option is checked, the worksite admins will receive the alerts for their worksite only.",
        },
      ],
      collapsible: true,
      hasCustomEmailField: true,
    },
  };

  const handleAssetIdFilter = (assetId, worksiteId, assetTypeId) => {
    setSelectedAssetId(assetId);
    //setDropdownWorksiteId(worksiteId);
  };

  const onReset = () => {
    resetImpactAlertSettingsByAssetId(selectedAssetId, setIsUpdateLoading);
  };

  const onUpdate = () => {
    if (Object.keys(applyToAllObj).length !== 0) {
      const selectedOptions = getSelectedOptions(applyToAllObj);

      const updatableSettings = {
        ...selectedOptions,
        settings: { notificationSettings: { impactAlertSettings } },
      };
      bulkSettingsUpdate(updatableSettings);
    }
    setIsUpdateLoading(true);
    updateImpactAlertSettingsByAssetId(
      selectedAssetId,
      {
        notificationSettings: {
          impactAlertSettings,
        },
      },
      setIsUpdateLoading
    );
  };

  const handleCloseSnackBar = () => {
    setApplyToAllObj({});
    setIsUpdateLoading(false);
    resetNotificationSettingsFlags();
  };

  const handleRecipientsChange = (userType, isChecked) => {
    handleRecipientsChangeUtil(userType, isChecked, impactAlertSettings, setImpactAlertSettings);
  };

  const handleCustomEmailsChange = (email) => {
    const temp = {
      ...impactAlertSettings,
      customEmails: [...email],
    };
    setImpactAlertSettings(temp);
  };

  return (
    <SettingsWrapper>
      <SettingsHeader
        title={t("An alert will be sent when the selected impact result occurs on an asset")}
        handleAssetIdFilter={handleAssetIdFilter}
        selectedAssetId={selectedAssetId}
      />
      {dropdownAssetId && typeof dropdownAssetId === "boolean" && dropdownAssetId === true ? (
        <NoSettingsDataFound />
      ) : (
        <>
          <SettingsContainer>
            <SettingWrapper>
              <SettingsSection
                {...displaySettings.impactAlerts}
                isLoading={notificationUpdateLoading || loading}
              />

              {selectedAssetId && (
                <Recipients
                  customEmails={impactAlertSettings?.customEmails}
                  handleCustomEmailsChange={handleCustomEmailsChange}
                  recipients={impactAlertSettings?.userTypes}
                  handleRecipientsChange={handleRecipientsChange}
                />
              )}
            </SettingWrapper>
          </SettingsContainer>
          <SettingsFooter>
            <ActionsBar
              setWorksiteId={setWorksiteId}
              showAppyToAll={true}
              updatableChanges={!hideButtonsForWorksiteSettings || Object.keys(applyToAllObj).length !== 0}
              handleUpdate={onUpdate}
              handleCancel={setDefaultNotificationSettingsWorksite}
              isLoading={loading}
              worksiteId={worksiteId}
              showWorksiteSelection={false}
              applyToAllObj={applyToAllObj}
              setApplyToAllObj={setApplyToAllObj}
              checkApplyToAll={checkApplyToAll}
              setCheckApplyToAll={setCheckApplyToAll}
              handleReset={onReset}
              clearSelection={clearSelection}
              setClearSelection={setClearSelection}
            />
          </SettingsFooter>
          <SnackBar
            isSuccess={notificationBulkUpdateSuccess || isUpdateLoading}
            label={t(validationConstant.notification_settings_updated)}
            handleClose={handleCloseSnackBar}
          />
        </>
      )}
    </SettingsWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    loginDetails: state.user.loginDetails,
    isAccountAdmin: state.user.isAccountAdmin,
    isAccountOwner: state.user.isAccountOwner,
    isSiteAdmin: state.user.isSiteAdmin,
    dropdownWorksiteList: state.dropdownFilters.worksiteList,
    notificationSetting: state.notificationSetting,
    dropdownAccountId: state.dropdownFilters.accountId,
    loading: state.notificationSetting.loading,
    dropdownAssetId: state.dropdownFilters?.settings?.assetId,
    notificationBulkUpdateSuccess: state.notificationSetting?.notificationBulkUpdateSuccess,
    notificationUpdateLoading: state.notificationSetting?.notificationUpdateLoading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setLogin: (loginDetail) => dispatch(login(loginDetail)),
  getImpactAlertSettingsByAssetId: (assetId) => dispatch(getImpactAlertSettingsByAssetId(assetId)),
  resetImpactAlertSettingsByAssetId: (assetId, setIsUpdateLoading) =>
    dispatch(resetImpactAlertSettingsByAssetId(assetId, setIsUpdateLoading)),
  updateImpactAlertSettingsByAssetId: (assetId, data, setIsUpdateLoading) =>
    dispatch(updateImpactAlertSettingsByAssetId(assetId, data, setIsUpdateLoading)),
  bulkSettingsUpdate: (data, setUpdateLoading) => dispatch(bulkSettingsUpdate(data, setUpdateLoading)),
  resetNotificationSettingsFlags: () => dispatch(resetNotificationSettingsFlags()),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationSettings);

import { languageConstant } from "../../constant/i18n/language";

export const initialState = {
  //For Language
  selectedLanguage: languageConstant[0],
  updateAccountPopUp: false,
  inspectionPlan: "",
  inspectionPlanError: false,
  getImpactThresholdLoading: false,
  getImpactThresholdSuccess: false,
  impactThresholdData: [],
  updateImpactThresholdLoading: false,
  updateImpactThresholdSuccess: false,
  updateImpactThresholdError: false,
  emailAlertSetting: {},
  emailAlertSettingLoading: false,
  emailAlertSettingSuccess: false,
  emailAlertSettingError: false,
  emailAlertRecipients: {},
  emailAlertRecipientsLoading: false,
  emailAlertRecipientsSuccess: false,
  emailAlertRecipientsError: false,
  getTimeToCollisionLoading: false,
  getTimeToCollisionSuccess: false,
  getTimeToCollisionError: false,
  timeToCollision: [],
  updateTimeToCollisionLoading: false,
  updateTimeToCollisionSuccess: false,
  updateTimeToCollisionError: false,
  getVehicleAlertSettingLoading: false,
  getVehicleAlertSettingSuccess: false,
  vehicleAlertSetting: [],
  updateVehicleAlertSettingLoading: false,
  updateVehicleAlertSettingSuccess: false,
  getWorksiteReportSettingsLoading: false,
  getWorksiteReportSettingsSuccess: false,
  getWorksiteReportSettingsError: false,
  updateWorksiteReportSettingsSuccess: false,
  worksiteReportSettings: {},
  updateWorksiteReportSettingLoading: false,
  updateWorksiteReportSettingSuccess: false,
  updateWorksiteReportSettingError: false,
  updateS3CameraSettingLoading: false,
  updateS3CameraSettingSuccess: false,
  updateS3CameraSettingError: false,
  resetS3CameraSettingLoading: false,
  resetS3CameraSettingSuccess: false,
  resetS3CameraSettingError: false,
  getS3CameraSettingLoading: false,
  getS3CameraSettingSuccess: false,
  getS3CameraSettingError: false,
  s3CameraSetting: [],
  fileDownloadLoading: false,
  //add all config initial data in the below object
  config: {
    getWorksiteAutoLogoutSettingsError: false,
    getWorksiteAutoLogoutSettingsLoading: true,
    getWorksiteAutoLogoutSettingsSuccess: false,
  },
};

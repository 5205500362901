import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Toast from "react-bootstrap/Toast";
import styled, { css } from "styled-components";

const getBackgroundColor = (type) => {
  switch (type) {
    case "information":
      return "#0D5FBE";
    case "success":
      return "#11A70B";
    case "alert":
      return "#DD0000";
    case "warning":
      return "#DD9000";
    default:
      return "transparent";
  }
};

const ToastContainer = styled.div`
  ${({ visible, ToastSize, type }) => css`
    @import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500&display=swap");
    padding: 0px;
    font-family: "Open Sans", sans-serif;
    position: fixed;
    top: ${visible ? "50px" : "-100%"};
    left: 50%;
    transform: translateX(-50%);
    z-index: 9999;
    transition: top 0.5s ease-in-out, height 0.5s ease-in-out;
    height: ${visible ? "auto" : "0"};
    overflow: hidden;
    max-width: ${ToastSize === "default" ? "initial" : ToastSize === "short" ? "360px" : "560px"};
    width: ${ToastSize === "default" ? "initial" : ToastSize === "short" ? "360px" : "560px"};
    .toast {
      flex-basis: ${ToastSize === "default" ? "initial" : ToastSize === "short" ? "360px" : "560px"};
      max-width: ${ToastSize === "default" ? "initial" : ToastSize === "short" ? "360px" : "560px"};
      border: 0px;
      background-color: ${getBackgroundColor(type)};
    }
    background-color: ${getBackgroundColor(type)};
    border-radius: 4px;
  `}
`;

const ToastBody = styled(Toast.Body)`
  ${({ type, size, ToastSize, iconBottom }) => css`
    background-color: ${getBackgroundColor(type)};
    color: #fff;
    border-radius: 4px;
    font-size: ${size === "small"
      ? "14px !important"
      : size === "medium"
      ? "16px !important"
      : "20px !important"};
    padding: 0;
    line-height: ${ToastSize === "default" ? "auto" : ToastSize === "short" ? "20px" : "20px"};
    position: relative;
    display: ${iconBottom === "topCenterRight" ? "flex" : "flow-root"};
    align-items: center;
  `}
`;
const LabelBody = styled.div`
  ${({ size, type }) => css`
    flex: 10;
    background-color: ${getBackgroundColor(type)};
    text-align: center;
    font-size: ${size === "small"
      ? "14px !important"
      : size === "medium"
      ? "16px !important"
      : "20px !important"};
    padding: ${size === "default" ? "auto" : size === "short" ? "13px 16px" : "8px 16px"};
    border-radius: 4px;
  `}
`;

const Icon = styled.i`
  position: relative;
  top: 0;
  bottom: 0;
  font-size: 20px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  align-items: center;
  float: right;
  cursor: pointer;
`;

const SnackBar = ({
  handleClose,
  isWarning,
  label,
  isSuccess,
  isFailed,
  isDefault,
  size,
  icon,
  ToastSize,
  iconBottom,
}) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (isSuccess || isFailed || isWarning || isDefault) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  }, [isSuccess, isFailed, isWarning, isDefault]);

  const handleDismiss = () => {
    setVisible(false);
    handleClose();
  };

  const backgroundColor = isDefault
    ? "information"
    : isSuccess
    ? "success"
    : isFailed
    ? "alert"
    : isWarning
    ? "warning"
    : "";

  return (
    <ToastContainer visible={visible} ToastSize={ToastSize} type={backgroundColor}>
      <Toast show={visible} onClose={handleDismiss} autohide={true} delay={4000}>
        <ToastBody size={size} iconBottom={iconBottom} type={backgroundColor}>
          <LabelBody size={size} type={backgroundColor}>
            {label}
          </LabelBody>
          {icon && <Icon className={`icon icon-${icon}`} onClick={handleDismiss}></Icon>}
        </ToastBody>
      </Toast>
    </ToastContainer>
  );
};

SnackBar.propTypes = {
  /**
   * Determines whether the SnackBar is currently being displayed.
   */
  show: PropTypes.bool.isRequired,

  /**
   * Callback function to handle the close event of the SnackBar.
   */
  handleClose: PropTypes.func.isRequired,

  /**
   * The message to be displayed in the SnackBar.
   */
  label: PropTypes.string.isRequired,

  /**
   * The size of the text in the SnackBar. Can be one of "small", "medium", or "large".
   */
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /**
   * The size of the Toast container. Can be one of "default", "short", or "long".
   */
  ToastSize: PropTypes.oneOf(["default", "short", "long"]),

  /**
   * The name of the icon to be displayed in the SnackBar.
   */
  icon: PropTypes.string,

  /**
   * The position of the icon position. Can be one of "topCenterRight" or "bottomRight".
   */
  iconBottom: PropTypes.oneOf(["topCenterRight", "bottomRight"]),
};

SnackBar.defaultProps = {
  size: "medium",
  icon: null,
  ToastSize: "default",
  iconBottom: "topCenterRight",
};

export default SnackBar;

import styled from "styled-components";
export const EmailsContainer = styled.div`
  border-left: 1px solid #dedede;
  border-right: 1px solid #dedede;
  border-bottom: 1px solid #dedede;
`;

export const ClearAll = styled.div`
  color: #0d5fbe;
  cursor: pointer;
  padding: 10px;
  font-weight: 600;
  margin: 0;
`;

export const EmailList = styled.div`
  max-height: 76px;
  overflow-y: auto;
  overflow-x: hidden;
`;

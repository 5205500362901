//TODO - Clean up this file. Optimize code, remove unused code as soon as time permits

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";

import AssetOverview from "./AssetOverview.component";
import { updateCurrentAssetStatus } from "../../../redux/asset/asset.action";
import { assetStatusUpdate } from "../../../redux/assets/action";
import { setEditAlertModal } from "../../../redux/modals/action";
import DropdownFilter from "../../../components/DropdownFilter.component";
import AssetsCard from "./AssetsCard.component";
import {
  getDropdownWorksiteList,
  getDropdownAccountList,
  setDropdownAccountId,
  setDropdownWorksiteId,
  setDropdownAssetTypeId,
  getDropdownProductList,
} from "../../../redux/dropdownFilters/action";

import ModalComponent from "../../../components/ModalPopupComponent/ModalPopup.component";
import AssetReactivation from "./AssetReactivation.component";
import { resetAllAssetStatus } from "../../../redux/assets/action";

const Assets = ({
  loginDetails,
  assetTotalCount,
  getDropdownWorksiteList,
  getDropdownProductList,
  dropdownWorksiteList,
  dropdownAssetTypeList,
  getDropdownAccountList,
  dropdownAccountList,
  setDropdownAccountId,
  setDropdownWorksiteId,
  setDropdownAssetTypeId,
  dropdownAccountId,
  dropdownWorksiteId,
  dropdownAssetTypeId,
  setEditAlertModal,
  editForm,
  editFormSecond,
  isSuperAdmin,
  showWorksiteDropdown,
  assetStatusData,
  assetStatusFailed,
  resetAllAssetStatus,
  assetOverview,
}) => {
  const { t } = useTranslation();
  const [showAlertModal, setShowAlertModal] = useState(false);

  useEffect(() => {
    getDropdownWorksiteList();
    getDropdownAccountList();
    getDropdownProductList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (assetStatusFailed) {
      setShowAlertModal(true);
    }
  }, [assetStatusFailed]);

  const handleModalClose = () => {
    setShowAlertModal(false);
    resetAllAssetStatus();
  };
  return (
    <>
      <div>
        <Row>
          {isSuperAdmin && (
            <Col>
              <DropdownFilter
                filter={{
                  type: "Account",
                  name: "companyName",
                  key: "companyName",
                  data: dropdownAccountList,
                }}
                value={dropdownAccountId}
                label={t("filter")}
                handleFilter={(value) => (editForm ? setDropdownAccountId(value) : setEditAlertModal(true))}
              />
            </Col>
          )}

          {showWorksiteDropdown && (
            <Col>
              <DropdownFilter
                filter={{
                  type: "Worksite",
                  key: "name",
                  data: dropdownWorksiteList,
                }}
                value={dropdownWorksiteId}
                label={t("filter")}
                handleFilter={(value) => (editForm ? setDropdownWorksiteId(value) : setEditAlertModal(true))}
              />
            </Col>
          )}
          <Col>
            <DropdownFilter
              filter={{
                type: "Asset Type",
                key: "AssetTypeName",
                data: dropdownAssetTypeList,
              }}
              value={dropdownAssetTypeId}
              label={t("filter")}
              handleFilter={(value) => (editForm ? setDropdownAssetTypeId(value) : setEditAlertModal(true))}
              disabled={isSuperAdmin && (!dropdownAccountId || dropdownAccountId === "all")}
            />
          </Col>
          {!showWorksiteDropdown && <Col md={3}></Col>}
          <Col md={3}>
            <AssetsCard count={assetTotalCount || 0} />
          </Col>
        </Row>
      </div>
      <div style={{ paddingTop: "10px" }}>
        {/* {!editForm && <div className="alert-modal-overlay" onClick={()=>setEditAlertModal(true)}></div>} */}
        <AssetOverview />
      </div>

      <ModalComponent
        show={showAlertModal}
        variant="AlertModal"
        handleClose={handleModalClose}
        iconClass="alertsettings"
        message="Cannot Activate Asset"
        paragraph={
          <AssetReactivation id={assetOverview ? assetOverview._id : ""} assetStatusData={assetStatusData} />
        }
        button1Text="Continue"
        button2Text=""
        onClickA={handleModalClose}
        typeA="primary"
        typeB="ghost"
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
  assetList: state.assets.Assets,
  assetTotalCount: state.assets.totalCount,
  worksiteList: state.worksites.Worksites,
  accountList: state.accounts.Accounts,
  dropdownAccountId: state.dropdownFilters.accountId,
  dropdownWorksiteId: state.dropdownFilters.worksiteId,
  dropdownAssetTypeId: state.dropdownFilters.assetTypeId,
  dropdownWorksiteList: state.dropdownFilters.worksiteList,
  dropdownAssetTypeList: state.dropdownFilters.assetTypeList,
  dropdownAccountList: state.dropdownFilters.accountList,
  editForm: state.forms.editForm,
  editFormSecond: state.forms.editFormSecond,
  isSuperAdmin: state.user.isSuperAdmin,
  showWorksiteDropdown: state.user.showWorksiteDropdown,
  assetStatusData: state.assets.assetStatusData,
  assetStatusFailed: state.assets.assetStatusFailed,
});

const mapDispatchToProps = (dispatch) => ({
  updateCurrentAssetStatus: (data) => dispatch(updateCurrentAssetStatus(data)),
  assetStatusUpdate: (id, status) => dispatch(assetStatusUpdate(id, status)),
  getDropdownWorksiteList: () => dispatch(getDropdownWorksiteList()),
  getDropdownProductList: () => dispatch(getDropdownProductList()),
  getDropdownAccountList: () => dispatch(getDropdownAccountList()),
  setDropdownAccountId: (accountId) => dispatch(setDropdownAccountId(accountId)),
  setDropdownWorksiteId: (siteId) => dispatch(setDropdownWorksiteId(siteId)),
  setDropdownAssetTypeId: (assetTypeId) => dispatch(setDropdownAssetTypeId(assetTypeId)),
  setEditAlertModal: (data) => dispatch(setEditAlertModal(data)),
  resetAllAssetStatus: () => dispatch(resetAllAssetStatus()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Assets));

import styled from "styled-components";

export const PaginationReport = styled.div`
  display: inline-flex;
  text-align: center;
  @media (max-width: 1100px) {
    display: inline-flex;
    margin-top: 20px;
    border-bottom: 0px;
  }
`;

export const ReportPageRow = styled.div``;
export const FormGroupReport = styled.div`
  float: left;
  display: flex;
  margin-right: 30px;
  & label {
    float: left;
    flex: none;
    margin-top: 2px;
    font-family: "Open Sans", sans-serif;
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
    font-size: 14px !important;
  }
  & select {
    font-family: "Open Sans", sans-serif;
    background: transparent;
    font-size: 13px !important;
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
    cursor: pointer;
    margin-top: -4px;
    padding: 0px;
    border: 0px;
  }
`;

export const ReportPageNumber = styled.div`
  float: left;
  margin-right: 20px;
  & p {
    margin-bottom: 0rem;
    margin-top: 2px;
    font-family: "Open Sans", sans-serif;
    font-size: 13px !important;
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  }
`;

export const ReportPageArrow = styled.div`
  margin-top: 2px;
  svg {
    .cls-1 {
      fill: ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
    }
  }
`;
